import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const CreateUsers = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auctionHouseId, setAuctionHouseId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const createUserMutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AUTH_API_URL}/register/employee`, {
          firstName,
          lastName,
          email,
          password,
          auctionHouseId,
        })
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "auctionHouseId":
        setAuctionHouseId(value);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Create New User</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="auctionHouseId"
                name="auctionHouseId"
                label="Auction House Id"
                variant="outlined"
                value={auctionHouseId}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={createUserMutation.mutate}
              >
                Create user
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="User has been created!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default CreateUsers;
