const emailConversationSplitter = (conversations) => {
  if (conversations.length === 0) return conversations;

  const data = conversations.map((el) => {
    //Format: On Nov 7, 2022
    const regex1 = /On\s[A-Z][a-z]{2,}\s(\d{1,2}),\s(\d{4})/;
    //Format: On Thu, 2 Feb
    const regex2 = /On\s[A-Z][a-z]{2,},\s(\d{1,2})\s[A-Z][a-z]{2}/;
    //Format: On 2 Feb 2022
    const regex3 = /On\s(\d{1,2})\s[A-Z][a-z]{2}\s(\d{4})/;

    let index = el.message.search(regex1);
    if (index === -1) {
      index = el.message.search(regex2);
    }
    if (index === -1) {
      index = el.message.search(regex3);
    }

    if (index > -1) {
      const firstMessage = el.message.slice(0, index);
      const rest = el.message.slice(index);
      return {
        ...el,
        firstMessage,
        rest,
      };
    }
    return el;
  });

  return data;
};

export default emailConversationSplitter;
