import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDate } from "../utils/formatTime";

const ReportStats = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [currentAuction, setCurrentAuction] = useState("");
  const [processedData, setProcessedData] = useState([]);
  const [processedInvoices, setProcessedInvoices] = useState(null);
  const [processedCollections, setProcessedCollections] = useState(0);
  const [toProcessData, setToProcessData] = useState([]);
  const [toProcessInvoices, setToProcessInvoices] = useState(null);
  const [toProcessCollections, setToProcessCollections] = useState(0);

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const auctionsQuery = useQuery(
    ["asAuctions", currentAuctionHouse?.id],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/auctions?auctionHouseId=${currentAuctionHouse?.id}&status=0`
        )
        .then((res) => res.data),
    {
      enabled: !!currentAuctionHouse?.id,
    }
  );

  const { isLoading, error } = useQuery(
    [
      "asStats",
      startDate,
      endDate,
      currentAuctionHouse?.id,
      currentAuction?.id,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/stats/dispatch?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&auctionId=${
            currentAuction?.id || ""
          }&startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (!data) return;

        //Processed data
        const maxLengthProcessed = Math.max(
          data.processedTotalLotsInvoicesCollections.length,
          data.groupedStockContainers.length,
          data.processedDispatchNotes.length
        );

        //Get the locations
        const processedLocations = [];
        if (
          data.processedTotalLotsInvoicesCollections.length ===
          maxLengthProcessed
        ) {
          processedLocations.push(
            ...data.processedTotalLotsInvoicesCollections.map((el) => el.name)
          );
        } else if (data.groupedStockContainers.length === maxLengthProcessed) {
          processedLocations.push(
            ...data.groupedStockContainers.map((el) => el.name)
          );
        } else if (data.processedDispatchNotes.length === maxLengthProcessed) {
          processedLocations.push(
            ...data.processedDispatchNotes.map((el) => el.name)
          );
        }

        const processed = processedLocations.map((location, i) => {
          const ps = data.groupedStockContainers.find(
            (p) => p.name === location
          );
          const ds = data.processedDispatchNotes.find(
            (d) => d.name === location
          );
          const tl = data.processedTotalLotsInvoicesCollections.find(
            (t) => t.name === location
          );
          return {
            name: location,
            totalLots: tl?.totalLots || 0,
            totalInvoices: tl?.totalInvoices || 0,
            totalCollections: tl?.totalCollections || 0,
            totalPutAway: ps?.putaway || 0,
            totalSortation: ps?.sortation || 0,
            totalDispatchLots: ds?.totalLots || 0,
          };
        });
        setProcessedData(processed);
        setProcessedInvoices({
          totalPicked: processed.reduce(
            (acc, el) => parseInt(acc) + parseInt(el.totalInvoices),
            0
          ),
          totalDispatched: data.countProcessedDispatchNotes,
        });
        setProcessedCollections(
          processed.reduce(
            (acc, el) => parseInt(acc) + parseInt(el.totalCollections),
            0
          )
        );

        //To process data
        const maxLengthToProcess = Math.max(
          data.toProcessTotalLotsInvoicesCollections.length,
          data.groupedStockContainers.length,
          data.toProcessDispatchNotes.length
        );

        //Get the locations
        const toProcessLocations = [];
        if (
          data.toProcessTotalLotsInvoicesCollections.length ===
          maxLengthToProcess
        ) {
          toProcessLocations.push(
            ...data.toProcessTotalLotsInvoicesCollections.map((el) => ({
              id: el.id,
              name: el.name,
            }))
          );
        } else if (data.groupedStockContainers.length === maxLengthToProcess) {
          toProcessLocations.push(
            ...data.groupedStockContainers.map((el) => ({
              id: el.id,
              name: el.name,
            }))
          );
        } else if (data.toProcessDispatchNotes.length === maxLengthToProcess) {
          toProcessLocations.push(
            ...data.toProcessDispatchNotes.map((el) => ({
              id: el.id,
              name: el.name,
            }))
          );
        }

        const toProcess = toProcessLocations.map((location, i) => {
          const ps = data.groupedStockContainers.find(
            (p) => p.name === location.name
          );
          const ds = data.toProcessDispatchNotes.find(
            (d) => d.name === location.name
          );
          const tl = data.toProcessTotalLotsInvoicesCollections.find(
            (t) => t.name === location.name
          );
          return {
            locationId: location.id,
            name: location.name,
            totalLots: tl?.totalLots || 0,
            totalInvoices: tl?.totalInvoices || 0,
            totalCollections: tl?.totalCollections || 0,
            totalPutAway: ps?.unknown || 0,
            totalSortation: ps?.pick || 0,
            totalDispatchLots: ds?.totalLots || 0,
          };
        });

        setToProcessData(toProcess);
        setToProcessInvoices({
          totalPicked: toProcess.reduce(
            (acc, el) => parseInt(acc) + parseInt(el.totalInvoices),
            0
          ),
          totalDispatched: data.countToProcessDispatchNotes,
        });
        setToProcessCollections(
          toProcess.reduce(
            (acc, el) => parseInt(acc) + parseInt(el.totalCollections),
            0
          )
        );
      },
    }
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleAuctionSelect = (e) => {
    const value = e.target.value;
    const auction = auctionsQuery.data.find((el) => el.title === value);
    setCurrentAuction(auction);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Stats Report</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  {auctionHousesQuery.data && (
                    <Select
                      value={currentAuctionHouse?.name}
                      label="Auction Houses"
                      onChange={handleSelect}
                      variant="outlined"
                    >
                      <MenuItem value="">All</MenuItem>
                      {auctionHousesQuery.data.map((house) => {
                        return (
                          <MenuItem value={house.name} key={house.id}>
                            {house.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction</InputLabel>
                  {auctionsQuery.data && (
                    <Select
                      label="Auctions"
                      value={currentAuction?.title}
                      onChange={handleAuctionSelect}
                      variant="outlined"
                    >
                      <MenuItem value="">All</MenuItem>
                      {auctionsQuery.data.map((auction) => (
                        <MenuItem value={auction.title} key={auction.id}>
                          {auction.title} - Ends:{formatDate(auction.endDate)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <Typography>Error: {error.message}</Typography>}
          {!isLoading && processedData?.length > 0 && (
            <>
              <Typography variant="h6">Processed</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Pick</TableCell>
                      <TableCell>Sort</TableCell>
                      <TableCell>Dispatch</TableCell>
                      <TableCell>PutAway</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedData?.map((el, i) => (
                      <TableRow key={i}>
                        <TableCell>Lots</TableCell>
                        <TableCell>{el.totalLots}</TableCell>
                        <TableCell>{el.totalSortation}</TableCell>
                        <TableCell>{el.totalDispatchLots}</TableCell>
                        <TableCell>{el.totalPutAway}</TableCell>
                        <TableCell>{el.name}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>Invoices</TableCell>
                      <TableCell>{processedInvoices?.totalPicked}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {processedInvoices?.totalDispatched}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Collections</TableCell>
                      <TableCell>{processedCollections}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="h6" style={{ "margin-top": "10px" }}>
                To Process
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Pick</TableCell>
                      <TableCell>Sort</TableCell>
                      <TableCell>Dispatch</TableCell>
                      <TableCell>PutAway</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {toProcessData?.map((el, i) => (
                      <TableRow key={i}>
                        <TableCell>Lots</TableCell>
                        <TableCell>{el.totalLots}</TableCell>
                        <TableCell>
                          <Link
                            to={`/stats/stock-container?type=2&locationId=${el.locationId}`}
                            style={{ textDecoration: "underline" }}
                          >
                            {el.totalSortation}
                          </Link>
                        </TableCell>
                        <TableCell>{el.totalDispatchLots}</TableCell>
                        <TableCell>
                          <Link
                            to={`/stats/stock-container?type=0&locationId=${el.locationId}`}
                            style={{ textDecoration: "underline" }}
                          >
                            {el.totalPutAway}
                          </Link>
                        </TableCell>
                        <TableCell>{el.name}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>Invoices</TableCell>
                      <TableCell>{toProcessInvoices?.totalPicked}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {toProcessInvoices?.totalDispatched}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Collections</TableCell>
                      <TableCell>{toProcessCollections}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportStats;
