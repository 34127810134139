import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "../utils/formatCurrency";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import VisibilityIcon from "@mui/icons-material/Visibility";

const invoiceStatuses = Object.freeze({
  OUTSTANDING: 0,
  COMPLETE: 1,
  CANCELLED: 2,
});

const useStyles = makeStyles(() => ({
  telephones: {
    borderBottom: "1px solid #3598dc",
  },
  addresses: {
    borderBottom: "1px solid #cb5a5e",
  },
  invoices: {
    borderBottom: "1px solid #8e5fa2",
  },
  registeredAuctions: {
    borderBottom: "1px solid #26a69a",
  },
  comments: {
    borderBottom: "1px solid #c49f47",
  },
  commentText: {
    whiteSpace: "pre-line",
  },
}));

const UserSummary = () => {
  const { id } = useParams();

  const { isLoading, error, data } = useQuery(
    `userSummary:${id}`,
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/buyers/${id}`)
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${id}`}>Manage User</Link>
              <Typography color="textPrimary">Summary</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    value={`${data.firstName} ${data.lastName}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    value={data.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="User Id"
                    value={data.userId}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid className={classes.telephones}>
                <Typography variant="h6" gutterBottom>
                  Telephone Numbers
                </Typography>
              </Grid>
              {data.telephones.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.telephones.map((telephone) => {
                        return (
                          <TableRow key={telephone.id} hover>
                            <TableCell>{telephone.tel}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No telephones for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid className={classes.addresses}>
                <Typography variant="h6" gutterBottom>
                  Addresses
                </Typography>
              </Grid>
              {data.addresses.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Street</TableCell>
                        <TableCell>Address 2</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>County</TableCell>
                        <TableCell>Post Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.addresses.map((Address) => {
                        return (
                          <TableRow key={Address.id} hover>
                            <TableCell>{Address.name || "-"}</TableCell>
                            <TableCell>{Address.street || "-"}</TableCell>
                            <TableCell>{Address.address2 || "-"}</TableCell>
                            <TableCell>{Address.city || "-"}</TableCell>
                            <TableCell>{Address.county || "-"}</TableCell>
                            <TableCell>{Address.postCode || "-"}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No addresses for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.invoices}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Recent Invoices
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/invoices`}>
                    <Button startIcon={<VisibilityIcon />}>View All</Button>
                  </Link>
                </Grid>
              </Grid>
              {data.totalInvoices > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice No</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell>Auction Ref</TableCell>
                        <TableCell>Auction Title</TableCell>

                        <TableCell>Hammer Price</TableCell>
                        <TableCell>Total Due</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Total Lots</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.invoices.map((invoice) => {
                        return (
                          <TableRow key={invoice.id} hover>
                            <TableCell>
                              <a
                                href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${invoice.id}`}
                              >
                                {invoice.id}
                              </a>
                            </TableCell>
                            <TableCell>
                              {formatTime(invoice.invoiceDate)}
                            </TableCell>
                            <TableCell>{invoice.auctionRef}</TableCell>
                            <TableCell>{invoice.auctionTitle}</TableCell>
                            <TableCell>
                              {formatCurrency(invoice.hammerPrice)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(invoice.totalDue)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(invoice.balance)}
                            </TableCell>
                            <TableCell>{invoice.totalLots}</TableCell>
                            <TableCell>
                              {getKeyByValue(invoiceStatuses, invoice.status)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No invoices for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.registeredAuctions}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Recent Registered Auctions
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/registered-auctions`}>
                    <Button startIcon={<VisibilityIcon />}>View All</Button>
                  </Link>
                </Grid>
              </Grid>
              {data.totalRegisteredAuctions > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>End Time</TableCell>
                        <TableCell>Auctions Ref</TableCell>
                        <TableCell>Title</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.registeredAuctions.map((auction) => {
                        return (
                          <TableRow key={auction.auctionRef} hover>
                            <TableCell>{formatTime(auction.endDate)}</TableCell>
                            <TableCell>{auction.auctionRef}</TableCell>
                            <TableCell>{auction.title}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No registered auctions for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.comments}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Recent Comments
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to={`/users/${id}/comments`}>
                    <Button startIcon={<VisibilityIcon />}>View All</Button>
                  </Link>
                </Grid>
              </Grid>
              {data.comments.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableBody>
                      {data.comments.map((comment) => {
                        return (
                          <TableRow key={comment.id} hover p>
                            <TableCell>
                              <Typography className={classes.commentText}>
                                {comment.comment}
                              </Typography>
                              <Typography variant="body2">
                                {formatTime(comment.creationDate)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No comments for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserSummary;
