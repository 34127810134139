import { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AutoComplete from "@mui/lab/Autocomplete";
import useDebounce from "../utils/useDebounce";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const TimesheetAddHoliday = () => {
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [debouncedSearch] = useDebounce(search, 500);

  const employeesQuery = useQuery(["asEmployees", debouncedSearch], () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employees?search=${debouncedSearch}`
      )
      .then((res) => res.data)
  );

  const mutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/timesheet-holidays`, {
          employeeId: selectedEmployee.id,
          startDate: startDate,
          endDate: endDate,
          isHalfDay: isHalfDay,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setStartDate("");
        setEndDate("");
        setIsHalfDay(false);
      },
      onError: () => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Holidays</Link>
          <Typography color="textPrimary">Add Holiday</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box component={Paper} p={3}>
            <Grid item xs={12} style={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isHalfDay}
                    onChange={(e) => setIsHalfDay(e.target.checked)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Less than a day"
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {isHalfDay && (
                  <TimePicker
                    margin="normal"
                    label="Start Time:"
                    value={startDate}
                    keyboardIcon={<ScheduleIcon />}
                    onChange={(time) => setStartDate(time)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <AutoComplete
                  id="search"
                  options={employeesQuery.data?.results || []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={search}
                  onChange={(e, value) => setSelectedEmployee(value)}
                  onInputChange={(e, value) => setSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {isHalfDay && (
                  <TimePicker
                    margin="normal"
                    label="End Time:"
                    value={endDate}
                    keyboardIcon={<ScheduleIcon />}
                    onChange={(time) => setEndDate(time)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={mutation.mutate}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Time Sheet Added"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={
          mutation.error?.response?.data?.message || "Error! Please try again"
        }
      />
    </Grid>
  );
};

export default TimesheetAddHoliday;
