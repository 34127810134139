import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./AomsLotStickerPrint.module.scss";
const AomsLotStickerPrint = () => {
  const [auctions, setAuctions] = useState([]);
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [printServices, setPrintServices] = useState([]);
  const [selectedPrintService, setSelectedPrintService] = useState(null);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [startLotNo, setStartLotNo] = useState("");
  const [endLotNo, setEndLotNo] = useState("");
  const [lots, setLots] = useState([]);
  const [error, setError] = useState(null);

  const getAuctionHouses = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`
    );
    setAuctionHouses(res.data);
  };

  const getAuctions = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/auctions?auctionHouseId=${
        selectedAuctionHouse.id
      }&startTime=${new Date().toISOString()}&startRowIndex=0&maximumRows=50`
    );
    setAuctions(res.data);
  };

  const getPrintServices = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
    );

    setPrintServices(res.data.printerService);
  };

  const getPrinters = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices/${selectedPrintService.id}/printers`
    );
    setPrinters(res.data.results);
  };

  useEffect(() => {
    getAuctionHouses();
    getPrintServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAuctionHouse) {
      getAuctions();
    } else {
      setAuctions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuctionHouse]);

  useEffect(() => {
    if (selectedPrintService) {
      getPrinters();
    } else {
      setPrinters([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrintService]);

  const getLots = () => async () => {
    setError(null);
    if (!selectedAuction) {
      setError("Please select an auction");
      return;
    }
    if (!startLotNo) {
      setError("Please enter a start lot number");
      return;
    }
    if (!endLotNo) {
      setError("Please enter an end lot number");
      return;
    }

    const res = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/lots?auctionId=${selectedAuction.id}&startLotNo=${startLotNo}&endLotNo=${endLotNo}&startRowIndex=0&maximumRows=50`
    );
    setLots(res.data.lots);
  };

  const printStickers = () => async () => {
    setError(null);
    if (!selectedPrinter) {
      setError("Please select a printer");
      return;
    }
    if (lots.length === 0) {
      setError("Please select lots to print");
      return;
    }

    const res = await axios.post(
      `${process.env.REACT_APP_PRINTERS_API_URL}/print/lots`,
      {
        printerId: selectedPrinter.id,
        lots: lots.map((lot) => ({
          stockId: lot.stockId,
          auctionDate: selectedAuction.endDate,
          auctionTitle: selectedAuction.title,
          lotNo: lot.lotNo,
          a: lot.a || undefined,
        })),
      }
    );

    console.log(res.data);
    setLots([]);
    setStartLotNo("");
    setEndLotNo("");
  };

  return (
    <div className={styles.main}>
      <h1>Lot Sticker Print</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="card">
        <div className="card-header">Auction Information</div>
        <div className="card-body form">
          <div className="form-group">
            <label htmlFor="auctionHouse">Auction House</label>
            <select
              name="auctionHouse"
              id="auctionHouse"
              className="form-control"
              value={selectedAuctionHouse?.id}
              onChange={(e) =>
                setSelectedAuctionHouse(
                  auctionHouses.find((ah) => ah.id === parseInt(e.target.value))
                )
              }
            >
              <option value="">Select Auction House</option>
              {auctionHouses?.map((ah) => (
                <option key={ah.id} value={ah.id}>
                  {ah.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="auction">Auction</label>
            <select
              name="auction"
              id="auction"
              className="form-control"
              value={selectedAuction?.id}
              onChange={(e) =>
                setSelectedAuction(
                  auctions.find((a) => a.id === parseInt(e.target.value))
                )
              }
            >
              <option value="">Select Auction</option>
              {auctions?.map((a) => (
                <option key={a.id} value={a.id}>
                  {a.auctionRef} - {a.title} - {a.endDate}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.lotFilters}>
            <div className="form-group">
              <label htmlFor="startLotNo">Start Lot No</label>
              <input
                type="text"
                name="startLotNo"
                id="startLotNo"
                className="form-control"
                value={startLotNo}
                inputMode="numeric"
                onChange={(e) => setStartLotNo(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="endLotNo">End Lot No</label>
              <input
                type="text"
                name="endLotNo"
                id="endLotNo"
                className="form-control"
                value={endLotNo}
                inputMode="numeric"
                onChange={(e) => setEndLotNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={getLots()}>
            Get Lots
          </button>
        </div>
      </div>
      {lots && (
        <div className="card">
          <div className="card-header">Selected Lots</div>
          <div className="card-body">
            <div className={styles.lots}>
              {lots.map((lot) => (
                <div key={lot.id} className={styles.lot}>
                  <div className={styles.lotNo}>{lot.lotNo}</div>
                  <div className={styles.lotTitle}>{lot.productTitle}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="card">
        <div className="card-header">Print Information</div>
        <div className="card-body form">
          <div className="form-group">
            <label htmlFor="printService">Print Service</label>
            <select
              name="printService"
              id="printService"
              className="form-control"
              value={selectedPrintService?.id}
              onChange={(e) =>
                setSelectedPrintService(
                  printServices.find((ps) => ps.id === parseInt(e.target.value))
                )
              }
            >
              <option value="">Select Print Service</option>
              {printServices?.map((ps) => (
                <option key={ps.id} value={ps.id}>
                  {ps.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="printer">Printer</label>
            <select
              name="printer"
              id="printer"
              className="form-control"
              value={selectedPrinter?.id}
              onChange={(e) =>
                setSelectedPrinter(
                  printers.find((p) => p.id === parseInt(e.target.value))
                )
              }
            >
              <option value="">Select Printer</option>
              {printers?.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
          {selectedPrinter && lots.length > 0 && (
            <div>
              <div>Total Sticker to Print: {lots.length}</div>
              <div>
                <button className="btn btn-green" onClick={printStickers()}>
                  Print
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AomsLotStickerPrint;
