import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import {
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import AutoComplete from "@mui/lab/Autocomplete";

import Locations from "../components/AssignUsersComp/Locations";
import useDebounce from "../utils/useDebounce";

const AssignUsers = () => {
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    name: "Simon Charles Latham",
    id: 6,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [debouncedSearch] = useDebounce(search, 500);

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const usersData = useQuery(
    ["users", debouncedSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/users?search=${debouncedSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedSearch,
    }
  );

  const usersQuery = useQuery(
    ["asUsersQuery", page, currentAuctionHouse, selectedUser],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses/${
            currentAuctionHouse.id
          }/users?startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}&userId=${
            selectedUser ? selectedUser.id : ""
          }`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const locationsQuery = useQuery(
    ["asLocationQuery", currentAuctionHouse],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses/${currentAuctionHouse.id}/locations`
        )
        .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Assign Users</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <AutoComplete
                id="search"
                options={usersData.data ? usersData.data?.users : []}
                getOptionLabel={(option) => option.name || ""}
                inputValue={search}
                onChange={(e, value) => setSelectedUser(value)}
                onInputChange={(e, value) => setSearch(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search user" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {usersQuery.isLoading && <CircularProgress />}
          {usersQuery.error &&
            `An error has occurred: ${usersQuery.error.message}`}
          {usersQuery.data && usersQuery.data.users && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Dispatch Location</TableCell>
                    <TableCell>Username</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersQuery.data.users.map((user) => (
                    <TableRow key={user.id}>
                      <Locations
                        currentAuctionHouse={currentAuctionHouse}
                        user={user}
                        locationsQuery={locationsQuery}
                      />
                      <TableCell>{user.username}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={usersQuery.data.count ? usersQuery.data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AssignUsers;
