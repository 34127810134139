import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { useQuery } from "react-query";
import { getLocationString } from "../utils/formatStockLocation";
import { urlify } from "../utils/urls";

const ManageLotEnquiry = () => {
  const { id } = useParams();
  const [auctionId, setAuctionId] = useState(null);
  const [stockId, setStockId] = useState(null);
  const [queryUserId, setQueryUserId] = useState(null);
  const [auction, setAuction] = useState({});
  const [stock, setStock] = useState({});
  const [queryUser, setQueryUser] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [queryResponse, setQueryResponse] = useState("");
  const [viewOnLot, setViewOnLot] = useState(false);
  const [lotenquiry, setLotEnquiry] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isAuctionLoading, setIsAuctionLoading] = useState(true);
  const [isStockLoading, setIsStockLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [standardResponse, setStandardResponse] = useState("");

  const standardResponsesQuery = useQuery("lotEnquryResponses", () =>
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/lotenquiryresponses`)
      .then((res) => res.data.lotEnquiryResponses)
  );

  useEffect(
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/lotenquiries/${id}`)
        .then((res) => {
          setAuctionId(res.data.lot.auctionId);
          setViewOnLot(res.data.enquiry.public);
          setQueryResponse(res.data.enquiry.response);
          setStockId(res.data.lot.aomsStockId);
          setQueryUserId(res.data.enquiry.creationUserId);
          setIsAuctionLoading(true);
          setIsStockLoading(true);
          setLotEnquiry(res.data);
          setIsLoading(false);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (standardResponsesQuery.isLoading) return;
    let lotEnquiryResponse = standardResponsesQuery.data.find(
      (r) => r.id === standardResponse
    );
    if (lotEnquiryResponse) setQueryResponse(lotEnquiryResponse.response);
    else setQueryResponse("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardResponse]);

  useEffect(() => {
    if (auctionId) {
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctions/${auctionId}`)
        .then((response) => {
          setAuction(response.data);
          setIsAuctionLoading(false);
        });
    }
  }, [auctionId]);

  useEffect(() => {
    if (stockId) {
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/stocks/${stockId}/container`
        )
        .then((response) => {
          setStock(response.data);
          setIsStockLoading(false);
        });
    }
  }, [stockId]);

  useEffect(() => {
    if (queryUserId) {
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/users/${queryUserId}`)
        .then((response) => {
          setQueryUser(response.data);
          setIsUserLoading(false);
        });
    }
  }, [queryUserId]);

  const handleInput = (e) => {
    switch (e.target.name) {
      case "response":
        setQueryResponse(e.target.value);
        break;
      case "viewOnLot":
        setViewOnLot(e.target.checked);
        break;
      case "standardResponse":
        const id = parseInt(e.target.value);
        if (id > 0) {
          setStandardResponse(id);
        } else {
          setStandardResponse(id);
          setQueryResponse("");
        }
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    lotenquiry.enquiry.response = queryResponse;
    lotenquiry.enquiry.public = viewOnLot;
    axios
      .put(`${process.env.REACT_APP_AUCTION_API_URL}/lotenquiries/${id}`, {
        response: queryResponse,
        public: viewOnLot,
      })
      .then(() => setOpenSuccess(true))
      .catch((err) => console.error(err));
  };

  return (
    <>
      {isLoading ||
      isStockLoading ||
      isAuctionLoading ||
      isUserLoading ||
      standardResponsesQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/lot-enquiries">Manage Lot Enquiries</Link>
              <Typography color="textPrimary">Lot Enquiry {id}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Enquiry Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Question"
                    value={lotenquiry.enquiry.question}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Question By</InputLabel>
                  <Button href={`/users/${queryUserId}`} variant="outlined">
                    {`${queryUser.firstName} ${queryUser.lastName}`}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Lot Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Lot Number"
                    value={lotenquiry.lot.lotNo}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Auction"
                    value={
                      auction
                        ? `${auction.title} - ${formatTime(
                            lotenquiry.lot.endTime
                          )}`
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    multiline
                    variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12} sm={12} spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Description"
                      multiline
                      value={lotenquiry.lot.title}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Condition Report"
                      multiline
                      value={lotenquiry.lot.conditionReport}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    value={
                      stock
                        ? `${getLocationString(stock.location)} - ${stock.name}`
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <a
                    href={`${process.env.REACT_APP_SITE_URL}/lots/${
                      lotenquiry.lot.id
                    }/${urlify(lotenquiry.lot.title)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="contained" size="large">
                      Go to Lot
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Your Response
              </Typography>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  hidden={
                    standardResponsesQuery.isLoading ||
                    standardResponsesQuery.data.length === 0
                  }
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="lbl-standard-responses">
                      Standard Responses
                    </InputLabel>
                    <Select
                      labelId="lbl-standard-responses"
                      label="Standard Responses"
                      value={standardResponse}
                      onChange={handleInput}
                      name="standardResponse"
                    >
                      <MenuItem value={0}> Unassigned </MenuItem>
                      {standardResponsesQuery.data.map((r) => {
                        return (
                          <MenuItem key={r.id} value={r.id}>
                            {r.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Your response"
                    name="response"
                    value={queryResponse}
                    onChange={handleInput}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="viewOnLot"
                        checked={viewOnLot}
                        onChange={handleInput}
                      />
                    }
                    label="Show on lot"
                  />
                </Grid>
              </Grid>

              <SuccessAlert
                openSuccess={openSuccess}
                setOpenSuccess={setOpenSuccess}
                message="Response Updated"
              />
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSave}
              >
                Send Response
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ManageLotEnquiry;
