import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";

const Dispatch = ({ currentAuctionHouse, showTable }) => {
  const [dispatchStatus, setDispatchStatus] = useState(0);

  const { data, isLoading, error } = useQuery(
    ["dispatch", currentAuctionHouse, dispatchStatus, showTable],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/dispatchnotes?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&status=${dispatchStatus}&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Dispatch</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Pending"
                    value={data?.pending}
                    onClick={() => setDispatchStatus(0)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Processing"
                    value={data?.processing}
                    onClick={() => setDispatchStatus(2)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data.results && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                {data?.results && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>Invoice Id</TableCell>
                          <TableCell>Auction House</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.results.map((dispatch) => (
                          <TableRow key={dispatch.id}>
                            <TableCell>{dispatch.id}</TableCell>
                            <TableCell>
                              {formatTime(dispatch.creationDate)}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`https://portal.auctionoms.com/accounts/invoices/${dispatch.invoiceId}`}
                              >
                                {dispatch.invoiceId}
                              </Link>
                            </TableCell>
                            <TableCell>{dispatch.name}</TableCell>
                            <TableCell>
                              <Link
                                to={`https://portal.auctionoms.com/dispatch/${dispatch.id}`}
                              >
                                View
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Dispatch;
