import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const transactionTypes = Object.freeze({
  INVOICE: 0,
  DEPOSIT: 1,
  MEMBERSHIP: 2,
  MANUAL: 3,
  DEPOSIT_REFUND: 4,
});

const transactionStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  FAILED: 2,
});

const Transactions = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { isLoading, error, data } = useQuery(
    ["scTransactions", page],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_PAYMENTS_API_URL
          }/users/transactions?startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return <>
    {isLoading ? (
      <CircularProgress />
    ) : error ? (
      "An error has occurred: " + error.message
    ) : (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/users">Users</Link>
            <Typography color="textPrimary">Transactions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              All Transactions
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Invoice ID</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.transactions.map((transaction) => {
                    return (
                      <TableRow key={transaction.id} hover>
                        <TableCell>{transaction.id}</TableCell>
                        <TableCell>
                          {formatTime(transaction.creationDate)}
                        </TableCell>
                        <TableCell>
                          <Link to={`users/${transaction.authUserId}`}>
                            {transaction.authUserId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {getKeyByValue(transactionTypes, transaction.type)}
                        </TableCell>
                        <TableCell>
                          {transaction.aomsInvoiceId || "-"}
                        </TableCell>
                        <TableCell>{transaction.comments || "-"}</TableCell>
                        <TableCell>{transaction.amount}</TableCell>
                        <TableCell>
                          {getKeyByValue(
                            transactionStatuses,
                            transaction.status
                          )}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`users/${transaction.authUserId}/transactions/${transaction.id}`}
                          >
                            View
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.totalResults || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    )}
  </>;
};

export default Transactions;
