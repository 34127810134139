import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/lab/Alert";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  btnContainer: { display: "flex", alignItems: "center" },
}));

const CustomerReprimand = () => {
  const [invoiceId, setInvoiceId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  const reprimandMutation = useMutation(
    () =>
      axios
        .put(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/reprimand/${invoiceId}`
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setErrorMessage("");
      },
      onError: (err) => {
        setOpenError(true);
        setErrorMessage(err.response.data.message);
      },
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Customer Reprimand</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                label="Invoice Id"
                variant="outlined"
                fullWidth
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    reprimandMutation.mutate();
                  }
                }}
              />
            </Grid>

            <Grid item xs={2} className={classes.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={reprimandMutation.mutate}
              >
                Reprimand
              </Button>
            </Grid>
          </Grid>
          {errorMessage && (
            <Alert severity="error" style={{ marginTop: 10 }}>
              {errorMessage}
            </Alert>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Success!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Grid>
  );
};

export default CustomerReprimand;
