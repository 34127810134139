import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";

const UserSms = () => {
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const { isLoading, error, data } = useQuery(
    `userSummary:${id}`,
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/buyers/${id}`)
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const handleInput = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "message":
        setValue(value);
        break;
      default:
        break;
    }
  };

  const sendSms = async (message) => {
    setSending(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/buyers/${id}/sms`,
        {
          message,
        }
      );
      setOpenSuccess(true);
      setSending(false);
      setValue("");
    } catch (error) {
      console.error("axios error:", error);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    sendSms(value);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${id}`}>Manage User</Link>
              <Typography color="textPrimary">SMS</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Name"
                    value={`${data.firstName} ${data.lastName}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Email"
                    value={data.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="User Id"
                    value={data.userId}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      label="Message to Send"
                      multiline
                      fullWidth
                      name="message"
                      value={value}
                      onChange={handleInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {sending ? (
                      <p>Sending...</p>
                    ) : (
                      <Button type="submit" variant="contained" color="primary">
                        Send SMS
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Changes Saved"
      />
    </>
  );
};

export default UserSms;
