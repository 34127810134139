import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatDate, formatTime, secondsToTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import { getDecodedAccessToken } from "../utils/Tokens";

const useStyles = makeStyles((theme) => ({
  break: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  timeElement: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const timesheetStatuses = {
  "Clocked In": 0,
  "Clocked Out": 1,
  "On Break": 2,
};

const timesheetBreaksStatuses = {
  Start: 0,
  End: 1,
};

const TimesheetMy = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isClockedId, setIsClockedIn] = useState(null);
  const [isOnBreak, setIsOnBreak] = useState(null);
  const [currentWorktime, setCurrentWorktime] = useState(null);
  const [currentBreakTime, setCurrentBreakTime] = useState(null);

  const decodedToken = getDecodedAccessToken();
  let permissions = [
    ...decodedToken.permissions.applications,
    ...decodedToken.permissions.roles,
  ];

  const hasRemotePermission = permissions.includes("timesheet: Remote Worker");

  const classes = useStyles();

  const { data, isLoading, error, refetch } = useQuery(
    ["weekly-timesheet", startDate, endDate],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/user-weekly?startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => res.data),
    {
      enabled: !!startDate && !!endDate,
      onSuccess: (res) => {
        setCurrentWorktime(null);
        setCurrentBreakTime(null);
        //Check if there is a timesheet
        if (!res.activeTimesheet) return setIsClockedIn(false);

        //Check if there is an ongoing break
        const ongoingBreak = res.activeBreaks.filter(
          (el) => el.status === timesheetBreaksStatuses.Start
        );
        //If there is no ongoing break
        if (ongoingBreak.length === 0) {
          setIsClockedIn(true);
          setIsOnBreak(false);

          const now = new Date();
          const clockIn = new Date(res.activeTimesheet.clockIn);
          let diff = now.getTime() - clockIn.getTime();
          diff = Math.round(diff / 1000);
          diff -= res.activeTimesheet.totalBreakTime || 0;
          setCurrentWorktime(diff);
          return;
        }
        setIsOnBreak(true);
        setIsClockedIn(null);

        const now = new Date();
        const breakStart = new Date(ongoingBreak[0].startTime);
        let diff = now.getTime() - breakStart.getTime();
        diff = Math.round(diff / 1000);
        setCurrentBreakTime(diff);
      },
    }
  );

  useEffect(() => {
    if (startDate && endDate) return;
    const today = new Date();
    const currentDayOfWeek = today.getDay();

    //Set the first day of the week as Monday
    const start = new Date(today);
    start.setDate(today.getDate() - (currentDayOfWeek - 1));
    start.setHours(0, 0, 0, 0);

    //Set the last day of the week as Sunday
    const end = new Date(today);
    end.setDate(today.getDate() + (7 - currentDayOfWeek));
    end.setHours(23, 59, 59, 999);

    setStartDate(start);
    setEndDate(end);

    return () => {
      setStartDate(null);
      setEndDate(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClockedId === false || currentWorktime === null) return;
    const interval = setInterval(() => {
      setCurrentWorktime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentWorktime, isClockedId]);

  useEffect(() => {
    if (isOnBreak === false || currentBreakTime === null) return;
    const interval = setInterval(() => {
      setCurrentBreakTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isOnBreak, currentBreakTime]);

  const clockInMutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/clock-in`, {
          clockNo: data?.clockNo,
          deviceId: null,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setSuccessMessage("Clocked In");
        refetch();
      },
      onError: () => {
        setOpenError(true);
      },
    }
  );

  const clockOutMutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/clock-out`, {
          clockNo: data?.clockNo,
          deviceId: null,
          timesheetId: data?.activeTimesheet?.id,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setIsOnBreak(null);
        setSuccessMessage("Clocked Out");
        refetch();
      },
      onError: () => {
        setOpenError(true);
      },
    }
  );

  const breakStartMutation = useMutation(
    (breakTypeId) =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/break-start`, {
          clockNo: data?.clockNo,
          deviceId: null,
          breakTypeId,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setSuccessMessage("Break Started");
        refetch();
      },
      onError: () => {
        setOpenError(true);
      },
    }
  );

  const breakEndMutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/break-end`, {
          clockNo: data?.clockNo,
          deviceId: null,
          breakId: data?.activeBreaks[0]?.id,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        setSuccessMessage("Break Ended");
        refetch();
      },
      onError: () => {
        setOpenError(true);
      },
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Typography color="textPrimary">My Timesheet</Typography>
        </Breadcrumbs>
      </Grid>
      {data && (
        <Grid item xs={12}>
          <Box component={Paper} p={2}>
            {hasRemotePermission && isClockedId === false && (
              <Button
                variant="contained"
                color="primary"
                onClick={clockInMutation.mutate}
              >
                Clock In
              </Button>
            )}
            {hasRemotePermission && isOnBreak === false && (
              <>
                {data.breakTypes?.map((el) => (
                  <Button
                    variant="contained"
                    color="primary"
                    key={el.name}
                    style={{ marginRight: "1rem" }}
                    onClick={() => breakStartMutation.mutate(el.id)}
                  >
                    Start {el.name}
                  </Button>
                ))}
              </>
            )}
            {hasRemotePermission && isOnBreak && (
              <Button
                variant="contained"
                color="primary"
                onClick={breakEndMutation.mutate}
              >
                End Break
              </Button>
            )}

            {hasRemotePermission && isClockedId && (
              <Button
                variant="contained"
                color="primary"
                onClick={clockOutMutation.mutate}
              >
                Clock Out
              </Button>
            )}
          </Box>
        </Grid>
      )}
      {isClockedId && currentWorktime && (
        <Grid item xs={12}>
          <Box component={Paper} p={2} className={classes.timeElement}>
            <Typography gutterBottom>Total Work Time</Typography>
            <Typography variant="h3" gutterBottom>
              {secondsToTime(currentWorktime)}
            </Typography>
          </Box>
        </Grid>
      )}
      {isOnBreak && currentBreakTime && (
        <Grid item xs={12}>
          <Box component={Paper} p={2} className={classes.break}>
            <div className={classes.timeElement}>
              <Typography gutterBottom>Current</Typography>
              <Typography variant="h3" gutterBottom>
                {secondsToTime(currentBreakTime)}
              </Typography>
            </div>
            <div className={classes.timeElement}>
              <Typography gutterBottom>Total</Typography>
              <Typography variant="h3" gutterBottom>
                {secondsToTime(data?.activeBreaks[0].duration * 60)}
              </Typography>
            </div>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          {isLoading && <p>Loading...</p>}
          {error && <p>There was an error</p>}
          {data && (
            <>
              <Typography variant="h6" gutterBottom>
                <b>Timesheet</b> | {formatDate(startDate)} -{" "}
                {formatDate(endDate)}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Clock In</TableCell>
                      <TableCell>Clock Out</TableCell>
                      {data.completeBreakTypes?.map((el) => (
                        <Fragment key={el.name}>
                          <TableCell>Start {el.name}</TableCell>
                          <TableCell>End {el.name}</TableCell>
                        </Fragment>
                      ))}
                      <TableCell>Total Breaks</TableCell>
                      <TableCell>Total Hours</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.timesheets?.map((el) => (
                      <TableRow key={el.id}>
                        <TableCell>{formatTime(el.clockIn)}</TableCell>
                        <TableCell>
                          {el.clockOut && formatTime(el.clockOut)}
                        </TableCell>
                        {el.breaks.length > 0 ? (
                          el.breaks?.map((b) => (
                            <Fragment key={b.id}>
                              <TableCell>
                                {b.startTime && formatTime(b.startTime)}
                              </TableCell>
                              <TableCell>
                                {b.endTime && formatTime(b.endTime)}
                              </TableCell>
                            </Fragment>
                          ))
                        ) : (
                          <TableCell
                            colSpan={data.completeBreakTypes.length * 2}
                          />
                        )}
                        <TableCell>{secondsToTime(el.totalBreaks)}</TableCell>
                        <TableCell>{secondsToTime(el.totalWorkTime)}</TableCell>
                        <TableCell>
                          {getKeyByValue(timesheetStatuses, el.status)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert open={openError} setOpen={setOpenError} message="Error" />
    </Grid>
  );
};

export default TimesheetMy;
