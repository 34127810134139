import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";

const CreateAuctionHouse = () => {
  const aomsAuctionHousesQuery = useQuery(
    "aomsAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [aomsAuctionHouseId, setAomsAuctionHouseId] = useState("");
  const [gmapsLink, setGmapsLink] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "street":
        setStreet(value);
        break;
      case "address2":
        setAddress2(value);
        break;
      case "city":
        setCity(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "postCode":
        setPostCode(value);
        break;
      case "tel":
        setTel(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "aomsAuctionHouseId":
        setAomsAuctionHouseId(value);
        break;
      case "gmapsLink":
        setGmapsLink(value);
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const houseId = parseInt(aomsAuctionHouseId);

    const data = {
      name: name,
      street: street,
      address2: address2,
      city: city,
      country: country,
      postCode: postCode,
      tel: tel,
      email: email,
      aomsAuctionHouseId: houseId || null,
      gmapsLink: gmapsLink,
    };

    axios
      .post(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`, data)
      .then((res) => {
        setOpenSuccess(true);
        setName("");
        setStreet("");
        setAddress2("");
        setCity("");
        setCountry("");
        setPostCode("");
        setTel("");
        setEmail("");
        setAomsAuctionHouseId("");
        setGmapsLink("");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Fragment>
      {aomsAuctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : aomsAuctionHousesQuery.error ? (
        "An error has occurred: " + aomsAuctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auction-houses">Manage Auction Houses</Link>
              <Typography color="textPrimary">
                Create New Auction House
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                item
                spacing={4}
                component="form"
                onSubmit={handleCreate}
              >
                <Grid item>
                  <Typography variant="h5">New Auction House</Typography>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      name="name"
                      value={name}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Street"
                      name="street"
                      value={street}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Address 2"
                      name="address2"
                      value={address2}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="City"
                      name="city"
                      value={city}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Country"
                      name="country"
                      value={country}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Post Code"
                      name="postCode"
                      value={postCode}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Telephone"
                      name="tel"
                      value={tel}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={email}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="aoms-auctionhouse-id-label">
                        AOMS Auction House Id
                      </InputLabel>
                      <Select
                        labelId="aoms-auctionhouse-id-label"
                        label="AOMS Auction House Id"
                        name="aomsAuctionHouseId"
                        value={aomsAuctionHouseId}
                        onChange={handleInput}
                        required
                      >
                        {aomsAuctionHousesQuery.data.length > 0 &&
                          aomsAuctionHousesQuery.data.map(
                            (aomsAuctionHouse) => {
                              return (
                                <MenuItem
                                  key={aomsAuctionHouse.id}
                                  value={aomsAuctionHouse.id}
                                >
                                  {aomsAuctionHouse.id}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Google Maps Link"
                      name="gmapsLink"
                      value={gmapsLink}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="outlined" size="large">
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Auction House Created"
      />
    </Fragment>
  );
};

export default CreateAuctionHouse;
