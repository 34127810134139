import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const ReportInbound = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [generate, setGenerate] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const csvRef = useRef();

  const { data, loading, error } = useQuery(
    ["inbound", page, rowsPerPage, generate],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/inbound?auctionHouseId=${
            currentAuctionHouse.id
          }&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        )
        .then((res) => res.data),
    {
      enabled: generate,
      onSuccess: (res) => {
        if (res.count === 0) setGenerate(false);
      },
    }
  );

  const cvsDataMutation = useMutation(
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/inbound?auctionHouseId=${
            currentAuctionHouse.id
          }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setCsvData(res.result);
        csvRef.current.link.click();
        setOpenSuccess(true);
      },
      onError: () => setOpenError(true),
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
    setGenerate(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Inbound Report</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {auctionHousesQuery.data && (
            <Grid container spacing={7}>
              <Grid item xs={3} className={classes.center}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={3}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    autoOk={true}
                    label="Start date:"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e);
                      setGenerate(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    autoOk={true}
                    label="End date:"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e);
                      setGenerate(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "end date",
                    }}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={3} className={classes.center}>
                {!generate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setGenerate(true)}
                  >
                    Generate Report
                  </Button>
                ) : (
                  <>
                    <CSVLink
                      ref={csvRef}
                      data={csvData}
                      filename={`inbound-report-${currentAuctionHouse.name}-${startDate}-${endDate}.csv`}
                      target="_blank"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => cvsDataMutation.mutateAsync()}
                    >
                      Download Report
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {loading && <CircularProgress />}
          {error && "An error has occurred: " + error.message}
          {!data && "No inbound data for this period or auction house"}
          {data && data.result && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>EstateNo</TableCell>
                    <TableCell>Vendor</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>BookedInDate</TableCell>
                    <TableCell>ArrivalDate</TableCell>
                    <TableCell>Pallets</TableCell>
                    <TableCell>UnloadingUser</TableCell>
                    <TableCell>UnloadingStaff</TableCell>
                    <TableCell>UnloadingStart</TableCell>
                    <TableCell>UnloadingEnd</TableCell>
                    <TableCell>SetOutUser</TableCell>
                    <TableCell>SetOutStaff</TableCell>
                    <TableCell>SetOutStart</TableCell>
                    <TableCell>SetOutEnd</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.result.map((row) => (
                    <TableRow key={row.estateNo}>
                      <TableCell>{row.estateNo}</TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.location}</TableCell>
                      <TableCell>{row.bookedInDate}</TableCell>
                      <TableCell>{row.arrivalDate}</TableCell>
                      <TableCell>{row.pallets}</TableCell>
                      <TableCell>{row.unloadingUser}</TableCell>
                      <TableCell>{row.unloadingStaff}</TableCell>
                      <TableCell>{row.unloadingStart}</TableCell>
                      <TableCell>{row.unloadingEnd}</TableCell>
                      <TableCell>{row.settingOutUser}</TableCell>
                      <TableCell>{row.settingOutStaff}</TableCell>
                      <TableCell>{row.settingOutStart}</TableCell>
                      <TableCell>{row.settingOutEnd}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ReportInbound;
