import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MarkdownEditor from "../components/misc/MarkdownEditor";
import SuccessAlert from "../components/misc/SuccessAlert";

const EditPage = (props) => {
  const { page } = props.location.state;
  const { id } = useParams();

  const [title, setTitle] = useState(page.title);
  const [metaKeywords, setMetaKeywords] = useState(page.metaKeywords);
  const [metaDescription, setMetaDescription] = useState(page.metaDescription);
  const [url, setUrl] = useState(page.url);
  const [markdown, setMarkdown] = useState(page.markdown.toString());
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "metaKeywords":
        setMetaKeywords(value);
        break;
      case "metaDescription":
        setMetaDescription(value);
        break;
      case "markdown":
        setMarkdown(value);
        break;
      case "url":
        setUrl(value);
        break;
      default:
        break;
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const data = {
      title,
      metaKeywords,
      metaDescription,
      markdown,
      url,
    };

    axios
      .put(`${process.env.REACT_APP_CONTENT_API_URL}/pages/${id}/edit`, data)
      .then((res) => {
        setOpenSuccess(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/pages">Manage Pages</Link>
            <Typography color="textPrimary">Edit</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid
              container
              item
              spacing={4}
              component="form"
              onSubmit={handleEdit}
            >
              <Grid item>
                <Typography variant="h5">Edit Page</Typography>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    name="title"
                    value={title}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Page Url"
                    name="url"
                    value={url}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Meta Keywords"
                    name="metaKeywords"
                    value={metaKeywords}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Meta Description"
                    name="metaDescription"
                    value={metaDescription}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
              </Grid>
              <MarkdownEditor
                markdown={markdown}
                name="markdown"
                handleInput={handleInput}
              />
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="outlined"
                  size="large"
                  disabled={title === "" || markdown === ""}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Page Updated"
      />
    </Fragment>
  );
};

export default EditPage;
