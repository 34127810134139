const getPreviousWeekSunday = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - (day + 7); // adjust when day is sunday
  const final = new Date(today.setDate(diff));
  return final;
};

const getPreviousWeekSaturday = () => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - (day + 1); // adjust when day is sunday
  const final = new Date(today.setDate(diff));
  return final;
};

exports.getPreviousWeekSaturday = getPreviousWeekSaturday;
exports.getPreviousWeekSunday = getPreviousWeekSunday;

exports.toISODateString = (date) => {
  return date.toISOString().split("T")[0];
};
