import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { getKeyByValue } from "../utils/getKeyByValue";
import { formatDate } from "../utils/formatTime";

const articleStatuses = Object.freeze({
  DRAFTED: 0,
  PUBLISHED: 1,
  DELETED: 2,
});

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const B2BBlogArticles = () => {
  const [status, setStatus] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(["blogArticles", status], () =>
    axios
      .get(
        `${
          process.env.REACT_APP_CONTENT_API_URL
        }/b2b-blog-articles?startRowIndex=${
          page * rowsPerPage
        }&maximumRows=${rowsPerPage}&status=${status}`
      )
      .then((res) => res.data)
  );

  const updateStatusMutation = useMutation(
    ({ id, updatedStatus }) =>
      axios
        .put(
          `${process.env.REACT_APP_CONTENT_API_URL}/b2b-blog-articles/${id}/update-status/${updatedStatus}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(["blogArticles", status], (old) => {
          return {
            ...old,
            results: old.results.filter((el) => el.id !== res.id),
          };
        });
        setOpenSuccess(true);
      },
      onError: () => setOpenError(true),
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Blog Articles</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <Grid item xs={12} className={classes.rightAlign}>
            <FormControl
              variant="outlined"
              className={classes.marginRight}
              size="small"
            >
              <Select
                labelId="status-select-label"
                id="status-select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={0}>DRAFTED</MenuItem>
                <MenuItem value={1}>PUBLISHED</MenuItem>
                <MenuItem value={2}>DELETED</MenuItem>
              </Select>
            </FormControl>
            <Link to="/b2b/blog-articles/add">
              <Button variant="contained" color="primary">
                Add Article
              </Button>
            </Link>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          {isLoading && <CircularProgress />}
          {error && `An error has occurred: ${error.message}`}
          {data && data.results.length === 0 && `No articles found`}
          {data && data.results.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Article Title</TableCell>
                    <TableCell>Summary</TableCell>
                    <TableCell>Article Date</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell width="320px"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((article) => (
                    <TableRow key={article.id}>
                      <TableCell>{article.title}</TableCell>
                      <TableCell>{article.summary}</TableCell>
                      <TableCell>{formatDate(article.articleDate)}</TableCell>
                      <TableCell>{article.author}</TableCell>
                      <TableCell>
                        {getKeyByValue(articleStatuses, article.status)}
                      </TableCell>
                      <TableCell size="small">
                        <Link
                          to={`/b2b/blog-articles/update/${article.id}`}
                          className={classes.marginRight}
                        >
                          <Button variant="contained" color="primary">
                            Edit
                          </Button>
                        </Link>
                        {article.status === articleStatuses.DRAFTED && (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.marginRight}
                            onClick={() =>
                              updateStatusMutation.mutate({
                                id: article.id,
                                updatedStatus: articleStatuses.PUBLISHED,
                              })
                            }
                          >
                            Publish
                          </Button>
                        )}
                        {article.status === articleStatuses.PUBLISHED && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.marginRight}
                            onClick={() =>
                              updateStatusMutation.mutate({
                                id: article.id,
                                updatedStatus: articleStatuses.DRAFTED,
                              })
                            }
                          >
                            Unpublish
                          </Button>
                        )}
                        {article.status !== articleStatuses.DELETED && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              updateStatusMutation.mutate({
                                id: article.id,
                                updatedStatus: articleStatuses.DELETED,
                              })
                            }
                          >
                            Delete
                          </Button>
                        )}
                        {article.status === articleStatuses.DELETED && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              updateStatusMutation.mutate({
                                id: article.id,
                                updatedStatus: articleStatuses.DRAFTED,
                              })
                            }
                          >
                            Restore
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Successfully updated status"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error updating status"
      />
    </Grid>
  );
};

export default B2BBlogArticles;
