import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  FormHelperText,
  TablePagination,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "moment-timezone";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useState, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { formatTime } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function FormDialog(props) {
  const { inputValue, open, setOpen, auctionHousesQuery, handleCreateViewing } =
    props;
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const [startTime, setStartTime] = useState(
    new Date(new Date().toISOString().slice(0, 11) + "08:30")
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().toISOString().slice(0, 11) + "16:30")
  );
  const [multiView, setMultiView] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [duration, setDuration] = useState(15);
  const [buffer, setBuffer] = useState(5);
  const handleDurationChange = (event) => {
    inputValue.duration = event.target.value;
    setDuration(inputValue.duration);
  };
  const handleBufferChange = (event) => {
    inputValue.buffer = event.target.value;
    setBuffer(inputValue.buffer);
  };

  useEffect(
    () =>
      axios.get(`http://worldclockapi.com/api/json/utc/now`).then((res) => {
        var clientNow = new Date();
        var serverNow = new Date(res.data.currentDateTime);
        setTimeError(
          clientNow.toISOString().slice(0, 16) ===
            serverNow.toISOString().slice(0, 16)
            ? false
            : true
        );
      }),
    [timeError]
  );
  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      inputValue.auctionHouseId = id.props.value;
      setSelectedAuctionHouse(inputValue.auctionHouseId);
    }
  };
  const handleViewingDateChange = (date) => {
    inputValue.viewingDate = date;
    setStartTime(date);
  };
  const handleStartTimeChange = (time) => {
    setStartTime(time);
    inputValue.startTime = time;
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
    inputValue.endTime = time;
  };
  const handleMultiView = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    switch (name) {
      case "multiView":
        setMultiView(value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    inputValue.auctionHouseId = selectedAuctionHouse;
    inputValue.viewingDate = startTime;
    if (startTime) inputValue.startTime = startTime;
    else
      inputValue.startTime = new Date(
        new Date().toISOString().slice(0, 11) + "08:30"
      );
    if (endTime) inputValue.endTime = endTime;
    else
      inputValue.endTime = new Date(
        new Date().toISOString().slice(0, 11) + "16:30"
      );
    inputValue.multiView = multiView;
    inputValue.duration = duration;
    inputValue.buffer = buffer;
  }, [
    selectedAuctionHouse,
    startTime,
    endTime,
    multiView,
    duration,
    buffer,
    inputValue,
  ]);
  useEffect(() => {
    if (
      inputValue &&
      Object.keys(inputValue).length === 0 &&
      inputValue.constructor === Object
    ) {
      //Checking whether the object is empty
      setSelectedAuctionHouse(1);
      setStartTime(new Date(new Date().toISOString().slice(0, 11) + "08:30"));
      setEndTime(new Date(new Date().toISOString().slice(0, 11) + "16:30"));
      setMultiView(false);
      setDuration(15);
      setBuffer(5);
    }
  }, [inputValue]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Viewing</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container justifyContent="space-around">
            <FormControl variant="outlined">
              <InputLabel>Auction House</InputLabel>

              <Select
                onChange={handleAuctionhouseChange}
                value={selectedAuctionHouse}
              >
                {auctionHousesQuery.data.map((auctionHouse) => {
                  return (
                    <MenuItem value={auctionHouse.id}>
                      {auctionHouse.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Select the Auction House</FormHelperText>
            </FormControl>
            <DatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              label="Select the Date:"
              value={startTime}
              onChange={handleViewingDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <TimePicker
              margin="normal"
              label="Starting From Time:"
              value={startTime}
              defaultValue="08:30"
              keyboardIcon={<ScheduleIcon />}
              onChange={handleStartTimeChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              ampm={false}
            />
            <TimePicker
              margin="normal"
              label="Ending Before Time:"
              value={endTime}
              defaultValue="16:30"
              onChange={handleEndTimeChange}
              keyboardIcon={<ScheduleIcon />}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              ampm={false}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={multiView}
                  onChange={handleMultiView}
                  name="multiView"
                  color="primary"
                />
              }
              label="Multiple Views Allowed"
            />
            <TextField
              label="Duration(min)"
              variant="outlined"
              name="duration"
              type="number"
              InputProps={{ inputProps: { min: 5, max: 60 } }}
              value={duration}
              onChange={handleDurationChange}
            />
            <TextField
              label="Buffer(min)"
              variant="outlined"
              name="buffer"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 30 } }}
              value={buffer}
              onChange={handleBufferChange}
            />
          </Grid>
        </LocalizationProvider>
        {timeError ? (
          <DialogContentText>
            The time/timezone is not correct. Please fix that.
          </DialogContentText>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleCreateViewing}
          disabled={timeError}
          color="primary"
        >
          Create Viewing
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const ManageViewings = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const newViewing = {};
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          setRefresh(!refresh);
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 min
  );

  const viewingsQuery = useQuery(
    [
      "scViewings",
      page,
      rowsPerPage,
      selectedAuctionHouse,
      startDate,
      endDate,
      refresh,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/viewings?auctionHouseId=${selectedAuctionHouse}&startDate=${startDate}&endDate=${endDate}&maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          setTotalRows(res.data.totalResults);
          return res.data.viewings;
        }),
    { keepPreviousData: true }
  );
  const handleClickOpen = () => {
    setCreateDialogOpen(true);
  };
  useEffect(() => {
    endDate.setMonth(startDate.getMonth() + 1);
  }, [startDate, endDate]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      setSelectedAuctionHouse(id.props.value);
    }
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleCreateViewing = (e) => {
    e.preventDefault();

    newViewing.recurringViewId = null;
    newViewing.startTime = new Date(
      newViewing.viewingDate.toISOString().slice(0, 11) +
        newViewing.startTime.toISOString().slice(11)
    );
    newViewing.endTime = new Date(
      newViewing.viewingDate.toISOString().slice(0, 11) +
        newViewing.endTime.toISOString().slice(11)
    );
    axios
      .post(`${process.env.REACT_APP_BOOKING_API_URL}/viewings`, newViewing)
      .then((res) => {
        setSuccessMessage("View Created Successfully");
        setOpenSuccess(true);
        setCreateDialogOpen(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setErrorMessage(
          "View Creation Failed: " + err.response
            ? err.response.data.message
            : err.message
        );
        setOpenError(true);
      });
  };
  return (
    <Fragment>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Manage Viewings</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="space-around">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Auction House</InputLabel>

                    <Select
                      onChange={handleAuctionhouseChange}
                      value={selectedAuctionHouse}
                    >
                      {auctionHousesQuery.data.map((auctionHouse) => {
                        return (
                          <MenuItem value={auctionHouse.id}>
                            {auctionHouse.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Select the Auction House</FormHelperText>
                  </FormControl>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Viewing Dates From:"
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Viewing Dates Till:"
                    value={endDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
          </Grid>
          {viewingsQuery.isLoading || viewingsQuery.data === undefined ? (
            <CircularProgress />
          ) : viewingsQuery.error ? (
            "An error has occurred: " + viewingsQuery.error.message
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={"Viewings"}
                  handleCreate={handleClickOpen}
                  createTitle="Create New Viewing"
                />
                <FormDialog
                  handleCreate={handleClickOpen}
                  inputValue={newViewing}
                  open={createDialogOpen}
                  auctionHousesQuery={auctionHousesQuery}
                  setOpen={setCreateDialogOpen}
                  handleCreateViewing={handleCreateViewing}
                />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Viewing Date</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Bookings Count</TableCell>
                        <TableCell>Duration(min)</TableCell>
                        <TableCell>Buffer(min)</TableCell>
                        <TableCell>Multiple people at a time</TableCell>
                        <TableCell>View</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {viewingsQuery.data.map((viewing) => {
                        return (
                          <TableRow key={viewing.id} hover tabIndex={-1}>
                            <TableCell>{viewing.id}</TableCell>
                            <TableCell>
                              {formatTime(viewing.viewingDate).slice(0, 10)}
                            </TableCell>
                            <TableCell>
                              {formatTime(viewing.startTime).slice(12, 17)}
                            </TableCell>
                            <TableCell>
                              {formatTime(viewing.endTime).slice(12, 17)}
                            </TableCell>
                            <TableCell>{viewing.bookingCount}</TableCell>
                            <TableCell>{viewing.duration}</TableCell>
                            <TableCell>{viewing.buffer}</TableCell>
                            <TableCell>
                              <Checkbox
                                name="multiView"
                                checked={viewing.multiView}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Link to={`/lot-viewings/${viewing.id}`}>
                                Go to Viewing
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default ManageViewings;
