import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import MarkdownEditor from "../components/misc/MarkdownEditor";
import SuccessAlert from "../components/misc/SuccessAlert";

const CreateAuctionTemplate = () => {
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const [auctionHouseId, setAuctionHouseId] = useState("");
  const [type, setType] = useState(1);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [name, setName] = useState("");
  const [depositRequired, setDepositRequired] = useState(false);
  const [buyersPremium, setBuyersPremium] = useState("");
  const [vat, setVat] = useState("");
  const [internetPremium, setInternetPremium] = useState("");
  const [collectionTimes, setCollectionTimes] = useState("");
  const [viewingTimes, setViewingTimes] = useState("");
  const [importantNotes, setImportantNotes] = useState("");
  const [auctionTerms, setAuctionTerms] = useState("");
  const [endTimeExtension, setEndTimeExtension] = useState("");
  const [premium, setPremium] = useState(false);
  const [onlineOnly, setOnlineOnly] = useState(true);
  const [postalOnly, setPostalOnly] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "name":
        setName(value);
        break;
      case "type":
        setType(value);
        break;
      case "depositRequired":
        setDepositRequired(e.target.checked);
        break;
      case "postalOnly":
        setPostalOnly(e.target.checked);
        break;
      case "onlineOnly":
        setOnlineOnly(e.target.checked);
        break;
      case "premium":
        setPremium(e.target.checked);
        break;
      case "viewingTimes":
        setViewingTimes(value);
        break;
      case "collectionTimes":
        setCollectionTimes(value);
        break;
      case "buyersPremium":
        setBuyersPremium(value);
        break;
      case "vat":
        setVat(value);
        break;
      case "internetPremium":
        setInternetPremium(value);
        break;
      case "endTimeExtension":
        setEndTimeExtension(value);
        break;
      case "importantNotes":
        setImportantNotes(value);
        break;
      case "auctionTerms":
        setAuctionTerms(value);
        break;
      case "auctionHouseId":
        setAuctionHouseId(value);
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const data = {
      auctionHouseId: parseInt(auctionHouseId),
      type: parseInt(type),
      title: title,
      subTitle: subTitle,
      name: name,
      depositRequired: depositRequired,
      buyersPremium: parseFloat(buyersPremium),
      vat: parseFloat(vat),
      internetPremium: parseFloat(internetPremium),
      collectionTimes: collectionTimes,
      viewingTimes: viewingTimes,
      importantNotes: importantNotes,
      auctionTerms: auctionTerms,
      endTimeExtension: endTimeExtension,
      premium: premium,
      onlineOnly: onlineOnly,
      postalOnly: postalOnly,
    };

    axios
      .post(`${process.env.REACT_APP_AUCTION_API_URL}/auctiontemplates`, data)
      .then((res) => {
        setOpenSuccess(true);
        setAuctionHouseId(0);
        setType(1);
        setTitle("");
        setSubTitle("");
        setDepositRequired(false);
        setBuyersPremium("");
        setVat("");
        setInternetPremium("");
        setCollectionTimes("");
        setViewingTimes("");
        setImportantNotes("");
        setAuctionTerms("");
        setEndTimeExtension("");
        setPremium(false);
        setOnlineOnly(false);
        setPostalOnly(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auctiontemplates">Auction Templates</Link>
              <Typography color="textPrimary">
                Create New Auction Template
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                item
                spacing={4}
                component="form"
                onSubmit={handleCreate}
              >
                <Grid item>
                  <Typography variant="h5">New Auction Template</Typography>
                </Grid>
                <Grid container item spacing={8}>
                  <Grid container item spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="primary-category-id-label">
                          Auction House Id
                        </InputLabel>
                        <Select
                          labelId="primary-category-id-label"
                          label="Auction House Id"
                          name="auctionHouseId"
                          value={auctionHouseId}
                          onChange={handleInput}
                          required
                        >
                          {auctionHousesQuery.data.map((auctionHouse) => {
                            return (
                              <MenuItem
                                key={auctionHouse.id}
                                value={auctionHouse.id}
                              >
                                {auctionHouse.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Title"
                        name="title"
                        value={title}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Sub Title"
                        name="subTitle"
                        value={subTitle}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        name="name"
                        value={name}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="endTimeExtension"
                        label="Time Extension"
                        type="time"
                        value={endTimeExtension}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleInput}
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="auction-type-label">
                          Auction Type
                        </InputLabel>
                        <Select
                          labelId="auction-type-label"
                          label="Auction Type"
                          name="type"
                          value={type}
                          onChange={handleInput}
                          required
                        >
                          <MenuItem value={0}>Live Auction</MenuItem>
                          <MenuItem value={1}>Timed Auction</MenuItem>
                          <MenuItem value={2}>Hybrid Auction</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Buyers Premium"
                        name="buyersPremium"
                        value={buyersPremium}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="VAT"
                        name="vat"
                        value={vat}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Internet Premium"
                        name="internetPremium"
                        value={internetPremium}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={depositRequired}
                            onChange={handleInput}
                            name="depositRequired"
                            color="primary"
                          />
                        }
                        label="Deposit Required"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={premium}
                            onChange={handleInput}
                            name="premium"
                            color="primary"
                          />
                        }
                        label="Premium"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={onlineOnly}
                            onChange={handleInput}
                            name="onlineOnly"
                            color="primary"
                          />
                        }
                        label="Online Only"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={postalOnly}
                            onChange={handleInput}
                            name="postalOnly"
                            color="primary"
                          />
                        }
                        label="Postal Only"
                      />
                    </Grid>
                  </Grid>
                  <MarkdownEditor
                    title="Viewing Times"
                    name="viewingTimes"
                    markdown={viewingTimes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Collection Times"
                    name="collectionTimes"
                    markdown={collectionTimes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Important Notes"
                    name="importantNotes"
                    markdown={importantNotes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Auction Terms"
                    name="auctionTerms"
                    markdown={auctionTerms}
                    handleInput={handleInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="outlined" size="large">
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Auction Template Created"
      />
    </Fragment>
  );
};

export default CreateAuctionTemplate;
