import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { CSVLink } from "react-csv";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ReportStocks = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({ name: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const stockReportMutation = useMutation(
    ({ auctionHouseId }) =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/stocks?auctionHouseId=${auctionHouseId}&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}&getAll=false`
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setOpenSuccess(true);
      },
      onError: (err) => {
        setOpenError(true);
      },
    }
  );

  const stocksFullReportMutation = useMutation(
    ({ auctionHouseId }) =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/reports/stocks?auctionHouseId=${auctionHouseId}&startRowIndex=0&maximumRows=100000&getAll=true`
        )
        .then((res) => {
          return {
            result: res.data.result.map((el) => {
              return {
                ...el,
                productTitle: el.productTitle
                  ? el.productTitle.replaceAll(`"`, "")
                  : el.productTitle,
              };
            }),
            count: res.data.count,
          };
        }),
    {
      onSuccess: (data) => {
        setOpenSuccess(true);
      },
      onError: (err) => {
        setOpenError(true);
      },
    }
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
    stockReportMutation.mutate({ auctionHouseId: house.id });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    stockReportMutation.mutate({ auctionHouseId: currentAuctionHouse.id });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
    stockReportMutation.mutate({ auctionHouseId: currentAuctionHouse.id });
  };

  const generateReport = () => {
    stocksFullReportMutation.mutate({ auctionHouseId: currentAuctionHouse.id });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Stocks Report</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {auctionHousesQuery.data && (
            <Grid container spacing={7}>
              <Grid item xs={8}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {!stocksFullReportMutation.data ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generateReport}
                  >
                    {stocksFullReportMutation.isLoading
                      ? "Loading..."
                      : "Generate Report"}
                  </Button>
                ) : (
                  <CSVLink
                    data={stocksFullReportMutation.data?.result}
                    filename={`${currentAuctionHouse.name}-stocks-report.csv`}
                  >
                    <Button variant="contained" color="primary">
                      Download Ready
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {stockReportMutation.isLoading ? (
            <CircularProgress />
          ) : stockReportMutation.error ? (
            "An error has occurred: " + stockReportMutation.error.message
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Stock Id</b>
                    </TableCell>
                    <TableCell>
                      <b>Creation Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Modification Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Product Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Product Title</b>
                    </TableCell>
                    <TableCell>
                      <b>Location</b>
                    </TableCell>
                    <TableCell>
                      <b>Lot No</b>
                    </TableCell>
                    <TableCell>
                      <b>Auction End Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockReportMutation.data?.result?.map((stock, i) => (
                    <TableRow key={stock.id}>
                      <TableCell>{stock.id}</TableCell>
                      <TableCell>{stock.creationDate}</TableCell>
                      <TableCell>{stock.modificationDate}</TableCell>
                      <TableCell>{stock.productCode}</TableCell>
                      <TableCell>{stock.productTitle}</TableCell>
                      <TableCell>{stock.location}</TableCell>
                      <TableCell>{stock.lotNo}</TableCell>
                      <TableCell>{stock.auctionEndDate}</TableCell>
                      <TableCell>{stock.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[20, 40]}
                component="div"
                count={
                  stockReportMutation.data?.count
                    ? stockReportMutation.data.count
                    : 0
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Estate cost report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="An error has occurred"
      />
    </Grid>
  );
};

export default ReportStocks;
