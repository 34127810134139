import axios from "axios";
import { useState } from "react";
import styles from "./AomsInvoiceLotters.module.scss";

const LotView = ({ lot }) => {
  return (
    <div className={styles.lot}>
      <div className={styles.lotInfo}>
        <div className={styles.lotNo}>
          <span className={styles.lotNoLabel}>Lot No:</span>
          <span className={styles.lotNoValue}>
            {lot.lotNo + (lot.a ? lot.a : "")}
          </span>
        </div>
        <div className={styles.lotTitle}>
          <span className={styles.lotTitleLabel}>Title:</span>
          <span className={styles.lotTitleValue}>{lot.productTitle}</span>
        </div>
      </div>
      <div className={styles.lotDetails}>
        <div className={styles.label}>Stock ID:</div>
        <div className={styles.value}>{lot.stockId}</div>
        <div className={styles.label}>Lot ID:</div>
        <div className={styles.value}>{lot.id}</div>
        <div className={styles.label}>Stock Creation User:</div>
        <div className={styles.value}>{lot.stockCreationUser}</div>
        <div className={styles.label}>Lot Creation User:</div>
        <div className={styles.value}>{lot.lotCreationUser}</div>
        <div className={styles.label}>Stock Creation Date:</div>
        <div className={styles.value}>
          {new Date(lot.stockCreationDate).toLocaleString()}
        </div>
        <div className={styles.label}>Lot Creation Date:</div>
        <div className={styles.value}>
          {new Date(lot.lotCreationDate).toLocaleString()}
        </div>
        <div className={styles.label}>Product Code:</div>
        <div className={styles.value}>{lot.productCode}</div>
        <div className={styles.label}>Item Reference:</div>
        <div className={styles.value}>{lot.itemReference}</div>
      </div>
      {lot.images && lot.images.length > 0 && (
        <div className={styles.lotImages}>
          {lot.images.map((image) => (
            <div className={styles.lotImage} key={image.id}>
              <a
                href={`https://${image.httpsUrl}.webp`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`https://${image.httpsUrl}-480.webp`}
                  alt={image.id}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AomsInvoiceLotters = () => {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [error, setError] = useState(null);
  const [lots, setLots] = useState(null);

  const getDispatcher = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/invoices/${invoiceId}/info/lotters`
      );
      console.log(response.data);
      setLots(response.data.results);
    } catch (error) {
      console.log(error);
      setError("Error: retrieving dispatcher info");
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setError(null);

    const id = parseInt(invoiceNo);
    if (!isNaN(id) && id > 0) {
      getDispatcher(invoiceNo);
    } else {
      console.log("Invalid invoice number");
      setError("Invalid invoice number");
    }
  };

  return (
    <div className={styles.page}>
      {error && <div className="alert alert-danger">{error}</div>}
      <h1>Aoms Invoice Lotters</h1>
      <div className={styles.search}>
        <form onSubmit={handleSearch} className="form">
          <label htmlFor="invoiceNo">Invoice No:</label>
          <input
            type="text"
            name="invoiceNo"
            id="invoiceNo"
            value={invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </form>
      </div>
      {lots && (
        <div className={styles.lotsInfo}>
          {lots.map((lot) => (
            <LotView key={lot.id} lot={lot} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AomsInvoiceLotters;
