import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SuccessAlert from "../components/misc/SuccessAlert";

const useStyles = makeStyles(() => ({
  comments: {
    borderBottom: "1px solid #c49f47",
  },
  commentText: {
    whiteSpace: "pre-line",
  },
}));

const UserComments = () => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(10);
  const [newComment, setNewComment] = useState("");
  const [openNewComment, setOpenNewComment] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const classes = useStyles();

  const toggleNewComment = () => {
    setOpenNewComment(!openNewComment);
  };

  const commentsQuery = useQuery(
    [`userComments:${id}`, page, maximumRows],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/buyers/${id}/comments?startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const { isLoading, error, data } = commentsQuery;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSaveComment = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/buyers/${id}/comments`,
        { comment: newComment }
      );
      commentsQuery.refetch();
      setOpenSuccess(true);
      setNewComment("");
    } catch (error) {
      console.error(error);
    }

    toggleNewComment();
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "newComment":
        setNewComment(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${id}`}>Manage User</Link>
              <Link to={`/users/${id}/summary`}>Summary</Link>
              <Typography color="textPrimary">Comments</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.comments}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    All Comments
                  </Typography>
                </Grid>
                <Grid item>
                  <Button startIcon={<Add />} onClick={toggleNewComment}>
                    Add Comment
                  </Button>
                </Grid>
              </Grid>
              {data.totalResults > 0 ? (
                <TableContainer>
                  <Table>
                    <TableBody>
                      {data.comments.map((comment) => {
                        return (
                          <TableRow key={comment.id} hover p>
                            <TableCell>
                              <Typography className={classes.commentText}>
                                {comment.comment}
                              </Typography>
                              <Typography variant="body2">
                                {formatTime(comment.creationDate)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={data.totalResults}
                    rowsPerPage={maximumRows}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No comments for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openNewComment}
        maxWidth="lg"
        onClose={toggleNewComment}
        aria-labelledby="new-comment"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Add New Comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="newComment"
            name="newComment"
            label="New Comment"
            fullWidth
            onChange={handleInput}
          />
        </DialogContent>
        <DialogActions>
          <Box mr={4}>
            <Button onClick={toggleNewComment} color="primary">
              Cancel
            </Button>
          </Box>
          <Button onClick={handleSaveComment} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Comment Saved"
      />
    </>
  );
};

export default UserComments;
