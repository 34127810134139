import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { marked } from "marked";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    minHeight: "100px",
    maxHeight: "260px",
    maxWidth: "100%",
    minWidth: "100%",
    padding: theme.spacing(2),
    fontSize: "14px",
  },
  preview: {
    width: "100%",
    border: "1px solid #ccc",
    paddingLeft: theme.spacing(2),
  },
}));

const TextAreaComp = ({ value, handleChange, name, placeholder }) => {
  const [view, setView] = useState(false);
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {view ? (
        <div
          className={classes.preview}
          dangerouslySetInnerHTML={{
            __html: marked(value),
          }}
        />
      ) : (
        <textarea
          className={classes.textArea}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setView(!view)}
        style={{ marginTop: "10px" }}
      >
        {view ? "Edit" : "Preview"}
      </Button>
    </Grid>
  );
};

const CategoriesSEO = () => {
  const { id } = useParams();
  const [seoReady, setSeoReady] = useState(false);
  const [categorySeoName, setCategorySeoName] = useState("");
  const [categoryDescriptionAbove, setCategoryDescriptionAbove] = useState("");
  const [categoryDescriptionBelow, setCategoryDescriptionBelow] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  useQuery("asCategorySeO", () =>
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/categories/${id}/seo`)
      .then((res) => {
        if (res.data.result) {
          setSeoReady(res.data.result.seoReady);
          setCategorySeoName(res.data.result.seoName);
          setCategoryDescriptionAbove(res.data.result.seoDescriptionAbove);
          setCategoryDescriptionBelow(res.data.result.seoDescriptionBelow);
        }
      })
  );

  const submitMutation = useMutation(
    () =>
      axios
        .put(`${process.env.REACT_APP_AUCTION_API_URL}/categories/${id}/seo`, {
          seoReady,
          seoName: categorySeoName,
          seoDescriptionAbove: categoryDescriptionAbove,
          seoDescriptionBelow: categoryDescriptionBelow,
        })
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/categories">Categories</Link>
          <Typography color="textPrimary">Categories Manage SEO</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Category SEO name"
                variant="outlined"
                fullWidth
                value={categorySeoName}
                onChange={(e) => setCategorySeoName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "0px" }}>
              <Link
                to="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
              >
                Markdown Guide
              </Link>
            </Grid>
            <TextAreaComp
              value={categoryDescriptionAbove}
              handleChange={(e) => setCategoryDescriptionAbove(e.target.value)}
              name="category description above"
              placeholder="Category description above"
            />
            <TextAreaComp
              value={categoryDescriptionBelow}
              handleChange={(e) => setCategoryDescriptionBelow(e.target.value)}
              name="categoryDescriptionBelow"
              placeholder="Category description below"
            />
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={seoReady}
                    onChange={(e) => setSeoReady(e.target.checked)}
                    name="seoReady"
                    color="primary"
                  />
                }
                label="SEO Ready"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={submitMutation.mutate}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Changes Saved"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default CategoriesSEO;
