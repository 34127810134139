import { useEffect, useState } from "react";

const useTimer = (initialDate, direction) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (!initialDate) return setTime("No Date");
    const interval = setInterval(() => {
      const result = timeDifference();
      setTime(result);
    }, 1000);

    function timeDifference() {
      let secs = null;
      const currentDate = new Date();
      const savedDate = new Date(initialDate);

      if (direction === "up") {
        secs = Math.floor((currentDate - savedDate) / 1000);
      } else {
        secs = Math.floor((savedDate - currentDate) / 1000);
      }

      let hours = Math.floor(secs / 3600);
      let minutes = Math.floor(secs / 60) % 60;
      let seconds = secs % 60;
      return [hours, minutes, seconds]
        .map((v) => ("" + v).padStart(2, "0"))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    }

    return () => {
      clearInterval(interval);
    };
  }, [direction, initialDate]);

  return [time];
};

export default useTimer;
