import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
  Collapse,
  FormControlLabel,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Link } from "react-router-dom";
import axios from "axios";
import SuccessAlert from "../components/misc/SuccessAlert";

const ReturnsCreate = (props) => {
  const [assignedAgentId, setAssignedAgentId] = useState("");
  const [assignedRole, setAssignedRole] = useState("");

  const [title, setTitle] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [conversation, setConversation] = useState("");
  const [readOnlyRequester, setReadOnlyRequester] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [ticketCreated, setTicketCreated] = useState(false);
  const [returnCreated, setReturnCreated] = useState(false);
  const [printCreated, setPrintCreated] = useState(false);

  const [searchId, setSearchId] = useState("");
  const [searchType, setSearchType] = useState("");
  const [notes, setNotes] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [agents, setAgents] = useState([]);
  const [roles, setRoles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [authUserId, setAuthUserId] = useState("");
  const [generateTicket, setGenerateTicket] = useState(false);
  const [inBoundDate, setInBoundDate] = useState(new Date());
  const [printServices, setPrintServices] = useState([]);
  const [printServiceId, setPrintServiceId] = useState("");
  const [printers, setPrinters] = useState([]);
  const [printerId, setPrinterId] = useState("");
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [auctionHouseId, setAuctionHouseId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const resetForm = () => {
    setAssignedAgentId("");
    setAssignedRole("");

    setTitle("");
    setInvoiceId("");
    setTopicId("");
    setConversation("");
    setReadOnlyRequester(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setNotes("");
    setShopLocation("");
    setInBoundDate(new Date());
    setPrintServiceId("");
    setPrinterId("");
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    switch (name) {
      case "searchId":
        setSearchId(value);
        break;
      case "searchType":
        setSearchType(value);
        break;
      case "location":
        setShopLocation(value);
        break;
      case "notes":
        setNotes(value);
        break;
      case "agentId":
        setAssignedAgentId(value);
        break;
      case "role":
        setAssignedRole(value);
        break;
      case "topicId":
        setTopicId(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "invoiceId":
        setInvoiceId(value);
        break;
      case "conversation":
        setConversation(value);
        break;
      case "generateTicket":
        setGenerateTicket(evt.target.checked);
        break;
      case "printServiceLocation":
        setPrintServiceId(value);
        setPrinterId("");
        getPrinters(value);
        break;
      case "printerId":
        setPrinterId(value);
        break;
      case "auctionhouse":
        setAuctionHouseId(value);
        break;
      default:
        break;
    }
  };

  const getPrinters = async (printerId) => {
    let printers = [];
    if (printerId) {
      const ps = await axios.get(
        `${process.env.REACT_APP_PRINTERS_API_URL}/printers/${printerId}`
      );
      if (ps.data.printers != null) {
        printers = ps.data.printers.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
      }
    }
    setPrinters(printers);
  };

  const handleViewingDateChange = (date) => {
    setInBoundDate(date);
  };

  const asBindData = async () => {
    const ps = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
    );
    const printServices = ps.data.printerService.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setPrintServices(printServices);

    setPrintServiceId(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));
    getPrinters(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));

    const ah = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`
    );
    const auctionHouses = ah.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setAuctionHouses(auctionHouses);

    const rolesResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`
    );
    const roles = rolesResponse.data
      .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
      .map((r) => r.name);
    setRoles(roles);

    const topicsResponse = await axios.get(
      `${process.env.REACT_APP_QMS_API_URL}/topics`
    );
    const topics = topicsResponse.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setTopics(topics);

    const usersResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/applications/12/users`
    );
    const agents = usersResponse.data.map((u) => {
      return {
        name: u.firstName + " " + u.lastName,
        id: u.id,
      };
    });
    setAgents(agents);
  };

  const bindData = useCallback(asBindData, []);

  useEffect(() => {
    bindData();
  }, [bindData]);

  const getUserByAuthId = async (authUserId) => {
    setAuthUserId(authUserId);
    try {
      // TODO: Create steps for pulling user info using invoice ID
      const result = await axios.get(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${authUserId}`
      );
      const data = result.data;
      setAssignedRole("dept: Returns");
      setTopicId(5);
      setTitle("Returned Product Automatic Ticket Using " + searchType);
      setConversation(
        "Automatic ticket using " +
          searchType +
          " to follow up for a product returned to Simon Charles."
      );
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
    } catch (err) {
      console.log("Invoice finding error:", err);
    }
  };

  const handleTicket = async (evt) => {
    resetForm();
    evt.preventDefault();
    if (searchType !== "ticketID" && searchType !== "") {
      try {
        // TODO: Create steps for pulling user info using invoice ID
        const result = await axios.get(
          `${process.env.REACT_APP_AOMS_API_URL}/buyers/${searchType}/${searchId}`
        );
        const data = result.data;
        setInvoiceId(data.id);
        getUserByAuthId(data.authUserId);
        setErrorMessage("");
      } catch (err) {
        console.log("Invoice finding error:", err);
        if (err.response.status === 404) {
          setErrorMessage("No Matching Users");
        }
      }
    } else if (searchType === "ticketID") {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_QMS_API_URL}/tickets/${searchId}`
        );
        const data = result.data;
        setAssignedAgentId(data.assignedAgentId);
        setAssignedRole(data.assignedRole);
        setTopicId(data.topicId);
        setInvoiceId(data.invoiceId);
        setTitle(data.title);
        const originalConv = data.conversations;
        setConversation(originalConv[originalConv.length - 1].message);
        const originalRequester = data.requester;
        setFirstName(originalRequester.firstName);
        setLastName(originalRequester.lastName);
        setEmail(originalRequester.email);
        setErrorMessage("");
      } catch (err) {
        console.log("Ticket finding error:", err);
        if (err.response.status === 404) {
          setErrorMessage("No Matching Users");
        }
      }
    }
  };

  const createTicket = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_QMS_API_URL}/tickets`,
        {
          title: title,
          invoiceId: searchId.length > 0 ? searchId : undefined,
          topicId: topicId,
          status: 0,
          requester: {
            authUserId: authUserId,
            email: email,
            firstName: firstName,
            lastName: lastName,
          },
          refundRequired: false,
          assignedAgentId: assignedAgentId > 0 ? assignedAgentId : undefined,
          assignedRole: assignedRole.length > 0 ? assignedRole : undefined,
          conversation: {
            type: 0,
            message: conversation,
          },
        }
      );
      setTicketCreated(true);
      return response.data.id;
    } catch (err) {
      console.log("Error creating the ticket with: ", err);
    }
  };

  const createReturn = async (tId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns`,
        {
          ticketId: tId != null ? tId : undefined,
          location: shopLocation.length > 0 ? shopLocation : undefined,
          status: 0,
          inBoundDate: inBoundDate,
          auctionHouseId: auctionHouseId,
          notes: notes,
        }
      );
      setReturnCreated(true);
      return response.data.id;
    } catch (err) {
      console.log("Error creating return with: ", err.response);
    }
  };

  const doSubmit = (evt) => {
    evt.preventDefault();

    async function call() {
      await handleSubmit();
    }
    call();
  };

  const handleSubmit = async () => {
    let tId = null;
    let rId = null;
    if (generateTicket) {
      if (searchType !== "ticketID") {
        tId = await createTicket();
      } else if (searchType === "ticketID") {
        tId = searchId;
      }
    }
    rId = await createReturn(tId);

    try {
      await axios.post(
        `${process.env.REACT_APP_PRINTERS_API_URL}/print/returns`,
        {
          printerId,
          returns: [
            {
              id: rId != null ? rId : undefined,
              inBoundDate,
            },
          ],
        }
      );
      setPrintCreated(true);
    } catch (err) {
      console.log("Error printing return with: ", err.response);
    }

    sessionStorage.setItem("SELECTED_PRINTER_SERVICE", printServiceId);
    resetForm();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/returns">Returns</Link>
          <Typography color="textPrimary">Create New Return</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={generateTicket}
                onChange={handleChange}
                name="generateTicket"
              />
            }
            label="Generate Ticket From Returned Lot"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={generateTicket}>
          <Box p={2} component={Paper}>
            <form onSubmit={handleTicket}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="textPrimary">
                    Search / Create For Ticket
                  </Typography>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12} md={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-search-type">Search By</InputLabel>
                      <Select
                        labelId="lbl-search-type"
                        label="SearchType"
                        name="searchType"
                        value={searchType}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth={true}
                      >
                        <MenuItem key={0} value="invoiceID">
                          Invoice ID
                        </MenuItem>
                        <MenuItem key={1} value="ticketID">
                          Ticket ID
                        </MenuItem>
                        <MenuItem key={2} value="stockID">
                          Stock ID
                        </MenuItem>
                        <MenuItem key={3} value="trackingCode">
                          DPD Tracking Code
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label={
                          searchType
                            ? "Search using " + searchType
                            : "Select Search By Option"
                        }
                        name="searchId"
                        onChange={handleChange}
                        value={searchId}
                        fullWidth
                        InputProps={{
                          readOnly: readOnlyRequester,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Grid item xs={12}>
            {!errorMessage ? (
              <Box p={2} component={Paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary">
                      Requester Details
                    </Typography>
                  </Grid>
                  <Grid container item spacing={4}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        onChange={handleChange}
                        value={firstName}
                        fullWidth
                        InputProps={{
                          readOnly: readOnlyRequester,
                        }}
                        variant="outlined"
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        value={lastName}
                        fullWidth
                        InputProps={{
                          readOnly: readOnlyRequester,
                        }}
                        variant="outlined"
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          readOnly: readOnlyRequester,
                        }}
                        variant="outlined"
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary">Ticket Details</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Title"
                      name="title"
                      value={title}
                      fullWidth
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Invoice ID"
                      name="invoiceId"
                      value={invoiceId}
                      fullWidth
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-assigned-topic">Topic</InputLabel>
                      <Select
                        labelId="lbl-assigned-topic"
                        label="Topic"
                        value={topicId}
                        name="topicId"
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth={true}
                      >
                        {topics.map((r) => {
                          return (
                            <MenuItem key={`topic-${r.id}`} value={r.id}>
                              {r.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-assigned-role">Dept</InputLabel>
                      <Select
                        labelId="lbl-assigned-role"
                        label="Dept"
                        value={assignedRole}
                        name="role"
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth={true}
                      >
                        {roles.map((r) => {
                          return (
                            <MenuItem key={`role-${r}`} value={r}>
                              {r}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-assigned-agent">Agent</InputLabel>
                      <Select
                        labelId="lbl-assigned-agent"
                        label="Agent"
                        name="agentId"
                        value={assignedAgentId}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth={true}
                      >
                        {agents.map((r) => {
                          return (
                            <MenuItem key={`agent-${r.id}`} value={r.id}>
                              {r.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Conversation"
                      name="conversation"
                      multiline
                      variant="outlined"
                      fullWidth={true}
                      value={conversation}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box p={2} component={Paper}>
                <Typography variant="h6">{errorMessage}</Typography>
              </Box>
            )}
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={doSubmit}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="textPrimary">Return Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Inbound Date:"
                    value={inBoundDate}
                    onChange={handleViewingDateChange}
                    variant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Location"
                  name="location"
                  onChange={handleChange}
                  value={shopLocation}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  name="notes"
                  multiline
                  variant="outlined"
                  fullWidth
                  value={notes}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-auctionhouse">Auction House</InputLabel>
                  <Select
                    labelId="lbl-auctionhouse"
                    id="auctionhouse"
                    label="Auction House"
                    value={auctionHouseId}
                    name="auctionhouse"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                  >
                    {auctionHouses.map((r) => {
                      return (
                        <MenuItem key={`auctionhouse-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-printLocation">Print Location</InputLabel>
                  <Select
                    labelId="lbl-printLocation"
                    id="printLocation"
                    label="Print Location"
                    value={printServiceId}
                    name="printServiceLocation"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                  >
                    {printServices.map((r) => {
                      return (
                        <MenuItem key={`printservice-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-printer">Printer</InputLabel>
                  <Select
                    labelId="lbl-printer"
                    id="printer"
                    label="Printer"
                    value={printerId}
                    name="printerId"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                  >
                    {printers.map((r) => {
                      return (
                        <MenuItem key={`printer-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit & Print Return
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>
      <SuccessAlert
        openSuccess={returnCreated}
        setOpenSuccess={setReturnCreated}
        message="New Return Created"
      />
      <SuccessAlert
        openSuccess={ticketCreated}
        setOpenSuccess={setTicketCreated}
        message="New Ticket Created"
      />
      <SuccessAlert
        openSuccess={printCreated}
        setOpenSuccess={setPrintCreated}
        message="New Print Created"
      />
    </Grid>
  );
};

export default ReturnsCreate;
