import { useState } from "react";
import {
  AppBar,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Modal,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import clsx from "clsx";
import MenuList from "../components/MenuList";
import axios from "axios";
import { ExitToApp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useAuctionHouse } from "../providers/AuctionHouseProvider";
import {
  getRefreshToken,
  getDecodedAccessToken,
  deleteTokens,
} from "../utils/Tokens";

const isMobile = window.innerWidth < 600;

const drawerWidth = isMobile ? 0 : 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: isMobile ? 18 : 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  titleLink: {
    color: "white",
    "&:hover": {
      textDecoration: "none",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: isMobile ? window.innerWidth : drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: isMobile ? 0 : theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  greeting: {
    marginRight: isMobile ? 0 : "18px",
  },
  logoutButton: {
    paddingRight: 0,
  },
  selectAuctionHouse: {
    cursor: "pointer",
    marginRight: "18px",
    padding: "5px",
    "&:hover": {
      border: "1px solid white",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100vh",
    width: "80vw",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    padding: theme.spacing(2, 4, 3),
  },
}));

const SelectAuctionHouseModal = ({ open, onClose, auctionHouses }) => {
  const classes = useStyles();
  const { handleSetCurrentAuctionHouse, handleClear } = useAuctionHouse();
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="options-modal"
      disableScrollLock={false}
    >
      <Box className={classes.modal}>
        <Grid container spacing={2} className={classes.modalBody}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" gutterBottom>
              Select an auction house
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleClear();
                onClose();
              }}
            >
              Clear
            </Button>
          </Grid>
          {auctionHouses?.map((auctionHouse) => (
            <Grid item xs={12} key={auctionHouse.id}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  handleSetCurrentAuctionHouse(auctionHouse);
                  onClose();
                }}
              >
                {auctionHouse.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

const Layout = (props) => {
  const [open, setOpen] = useState(isMobile ? false : true);
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { auctionHouses, currentAuctionHouse } = useAuctionHouse();

  const token = getDecodedAccessToken();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    const data = { refreshToken: getRefreshToken() };
    axios
      .put(`${process.env.REACT_APP_AUTH_API_URL}/logout`, data)
      .then((res) => {
        deleteTokens();
        history.push("/");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCloseDrawer = () => {
    if (isMobile && open) {
      setOpen(!open);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SelectAuctionHouseModal
        open={showModal}
        onClose={() => setShowModal(false)}
        auctionHouses={auctionHouses}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={clsx(
              classes.menuButton,
              open && !isMobile && classes.menuButtonHidden
            )}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <Link to={"/dashboard"} className={classes.titleLink}>
              Simon Charles Admin
            </Link>
          </Typography>
          {location.pathname === "/dashboard" && (
            <Typography
              variant="body2"
              className={classes.selectAuctionHouse}
              onClick={() => setShowModal(true)}
            >
              {currentAuctionHouse?.name || "Select an auction house"}
            </Typography>
          )}

          <Typography
            variant="body2"
            className={classes.greeting}
          >{`Hi ${token.firstName}`}</Typography>

          <IconButton
            aria-label="logout"
            color="inherit"
            onClick={handleLogout}
            className={classes.logoutButton}
            size="large"
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerToggle} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuList handleCloseDrawer={handleCloseDrawer} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {props.children}
        </Container>
      </main>
    </div>
  );
};

export default Layout;
