import {
  AppBar,
  Divider,
  Grid,
  Paper,
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Card,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Breadcrumbs,
  FormControl,
  Collapse,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import TabPanel from "../components/misc/TabPanel";

import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";

const ReturnsView = (props) => {
  const { id } = useParams();
  const [responseTab, setResponseTab] = useState(0);
  const [assignedAgentId, setAssignedAgentId] = useState("");
  const [assignedRole, setAssignedRole] = useState("");
  const [topicId, setTopicId] = useState("");
  const [status, setStatus] = useState("");
  const [roles, setRoles] = useState([]);
  const [agents, setAgents] = useState([]);
  const [topics, setTopics] = useState([]);
  const [title, setTitle] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [modificationDate, setModificationDate] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [returnId, setReturnId] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [creationUserId, setCreationUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [generateTicket, setGenerateTicket] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchId, setSearchId] = useState("");
  const [conversation, setConversation] = useState("");
  const [authUserId, setAuthUserId] = useState("");
  const [inBoundDate, setInBoundDate] = useState(null);
  const [printServices, setPrintServices] = useState([]);
  const [printServiceId, setPrintServiceId] = useState("");
  const [printers, setPrinters] = useState([]);
  const [printerId, setPrinterId] = useState("");
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [auctionHouseId, setAuctionHouseId] = useState("");

  const [ticketCreated, setTicketCreated] = useState(false);
  const [returnCreated, setReturnCreated] = useState(false);
  const [printCreated, setPrintCreated] = useState(false);
  const [internalResponse, setInternalResponseSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const asBindData = async () => {
    try {
      const returnResult = await axios.get(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns/${id}`
      );
      const rData = returnResult.data;

      setReturnId(rData.id);
      setCreationDate(new Date(rData.creationDate));
      setModificationDate(new Date(rData.modificationDate));
      setTicketId(rData.ticketId);
      setShopLocation(rData.location);
      setCreationUserId(rData.creationUserId);
      setInBoundDate(new Date(rData.inBoundDate));
      setStatus(rData.status);
      setAuctionHouseId(rData.auctionHouseId);
      setNotes(rData.notes);

      if (rData.ticketId) {
        setGenerateTicket(false);
        const ticketResult = await axios.get(
          `${process.env.REACT_APP_QMS_API_URL}/tickets/${rData.ticketId}`
        );
        const data = ticketResult.data;
        setAssignedAgentId(data.assignedAgentId);
        setAssignedRole(data.assignedRole);
        setTopicId(data.topicId);
        setInvoiceId(data.invoiceId);
        setFirstName(data.requester.firstName);
        setLastName(data.requester.lastName);
        setEmail(data.requester.email);
        setTitle(data.title);
        const originalConv = data.conversations;
        setConversation(originalConv[originalConv.length - 1].message);
      } else {
        setGenerateTicket(true);
      }
    } catch (err) {
      console.log("bindData error:", err);
    }
  };

  const bindData = useCallback(asBindData, [id]);

  const asBindOtherData = async () => {
    const ps = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
    );
    const printServices = ps.data.printerService.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setPrintServices(printServices);

    setPrintServiceId(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));
    getPrinters(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));

    const ah = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`
    );
    const auctionHouses = ah.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setAuctionHouses(auctionHouses);

    const rolesResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`
    );
    const roles = rolesResponse.data
      .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
      .map((r) => r.name);
    setRoles(roles);

    const topicsResponse = await axios.get(
      `${process.env.REACT_APP_QMS_API_URL}/topics`
    );
    const topics = topicsResponse.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setTopics(topics);

    const usersResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/applications/12/users`
    );
    const agents = usersResponse.data.map((u) => {
      return {
        name: u.firstName + " " + u.lastName,
        id: u.id,
      };
    });

    setAgents(agents);
  };

  const bindOtherData = useCallback(asBindOtherData, []);

  useEffect(() => {
    bindData();
    bindOtherData();
  }, [bindData, bindOtherData]);

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 1:
        return "Re-Lot";
      case 2:
        return "Closed";
      default:
        break;
    }
  };

  const getPrinters = async (printerId) => {
    let printers = [];
    if (printerId) {
      const ps = await axios.get(
        `${process.env.REACT_APP_PRINTERS_API_URL}/printers/${printerId}`
      );
      if (ps.data.printers != null) {
        printers = ps.data.printers.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
      }
    }
    setPrinters(printers);
  };

  const handleTabChange = (evt, value) => {
    setResponseTab(value);
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "topicId":
        setTopicId(value);
        break;
      case "agentId":
        setAssignedAgentId(value);
        break;
      case "role":
        setAssignedRole(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "invoiceId":
        setInvoiceId(value);
        break;
      case "location":
        setShopLocation(value);
        break;
      case "notes":
        setNotes(value);
        break;
      case "ticketId":
        setTicketId(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "searchId":
        setSearchId(value);
        break;
      case "searchType":
        setSearchType(value);
        break;
      case "conversation":
        setConversation(value);
        break;
      case "printServiceLocation":
        setPrintServiceId(value);
        setPrinterId("");
        getPrinters(value);
        break;
      case "printerId":
        setPrinterId(value);
        break;
      case "auctionhouse":
        setAuctionHouseId(value);
        break;
      case "note":
        setNote(value);
        break;
      default:
        break;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns/${id}`,
        {
          ticketId: ticketId != null ? ticketId : undefined,
          status: status,
          location: shopLocation,
          inBoundDate: inBoundDate,
          auctionHouseId: auctionHouseId,
        }
      );

      setReturnCreated(true);
      bindData();
    } catch (err) {
      console.log("Error updating return with: ", err.response);
    }
  };

  const createTicket = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_QMS_API_URL}/tickets`,
        {
          title: title,
          invoiceId: searchId.length > 0 ? searchId : undefined,
          topicId: topicId,
          status: 0,
          requester: {
            authUserId: authUserId,
            email: email,
            firstName: firstName,
            lastName: lastName,
          },
          refundRequired: false,
          assignedAgentId: assignedAgentId > 0 ? assignedAgentId : undefined,
          assignedRole: assignedRole.length > 0 ? assignedRole : undefined,
          conversation: {
            type: 0,
            message: conversation,
          },
        }
      );
      setTicketCreated(true);
      return response.data.id;
    } catch (err) {
      console.log("Error creating the ticket with: ", err);
    }
  };

  const doSubmit = async (evt) => {
    evt.preventDefault();
    let tId = null;
    if (generateTicket) {
      if (searchType !== "ticketID") {
        tId = await createTicket();
      } else if (searchType === "ticketID") {
        tId = searchId;
      }
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns/${id}`,
        {
          ticketId: tId != null ? tId : undefined,
          status: status,
          location: shopLocation,
          inBoundDate: inBoundDate,
          auctionHouseId: auctionHouseId,
        }
      );
      setReturnCreated(true);
      setGenerateTicket(false);

      await axios.put(`${process.env.REACT_APP_QMS_API_URL}/tickets/${tId}`, {
        conversation: {
          message: `Generated a return with the ID: ${returnId}`,
          type: 1,
        },
      });
      setInternalResponseSent(true);
      await bindData();
    } catch (err) {
      console.log("Error creating return with: ", err.response);
    }
  };

  const getUserByAuthId = async (authUserId) => {
    setAuthUserId(authUserId);
    try {
      // TODO: Create steps for pulling user info using invoice ID
      const result = await axios.get(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${authUserId}`
      );
      const data = result.data;
      setAssignedRole("dept: Returns");
      setTopicId(5);
      setStatus(0);
      setTitle("Returned Product Automatic Ticket Using " + searchType);
      setConversation(
        "Automatic ticket using " +
          searchType +
          " to follow up for a product returned to Simon Charles."
      );
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
    } catch (err) {
      console.log("Invoice finding error:", err);
    }
  };

  const handleNote = async (evt) => {
    evt.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns/note`,
        {
          returnId: returnId != null ? returnId : undefined,
          note: note,
        }
      );
      setNote("");
      await bindData();
      return response.data.id;
    } catch (err) {
      console.log("Error creating note with: ", err.response);
    }
  };

  const handleTicket = async (evt) => {
    evt.preventDefault();
    if (searchType !== "ticketID" && searchType !== "") {
      try {
        // TODO: Create steps for pulling user info using invoice ID
        const result = await axios.get(
          `${process.env.REACT_APP_AOMS_API_URL}/buyers/${searchType}/${searchId}`
        );
        const data = result.data;
        setInvoiceId(data.id);
        getUserByAuthId(data.authUserId);
      } catch (err) {
        console.log("Invoice finding error:", err);
        if (err.response.status === 404) {
          setErrorMessage("No Matching Users");
        }
      }
    } else if (searchType === "ticketID") {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_QMS_API_URL}/tickets/${searchId}`
        );
        const data = result.data;
        setAssignedAgentId(data.assignedAgentId);
        setAssignedRole(data.assignedRole);
        setTopicId(data.topicId);
        setInvoiceId(data.invoiceId);
        setStatus(data.status);
        setTitle(data.title);
        setCreationDate(data.creationDate);
        setModificationDate(data.modificationDate);
        const originalConv = data.conversations;
        setConversation(originalConv[originalConv.length - 1].message);
        const originalRequester = data.requester;
        setFirstName(originalRequester.firstName);
        setLastName(originalRequester.lastName);
        setEmail(originalRequester.email);
      } catch (err) {
        console.log("Ticket finding error:", err);
        if (err.response.status === 404) {
          setErrorMessage("No Matching Users");
        }
      }
    }
  };

  const regeneratePrintRequest = async (evt) => {
    evt.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PRINTERS_API_URL}/print/returns`,
        {
          printerId,
          returns: [
            {
              id: returnId != null ? returnId : undefined,
              inBoundDate,
            },
          ],
        }
      );
      sessionStorage.setItem("SELECTED_PRINTER_SERVICE", printServiceId);
      setPrinterId();
      setPrintCreated(true);
      return response.data.id;
    } catch (err) {
      console.log("Error printing return with: ", err.response);
    }
  };

  const handleViewingDateChange = (date) => {
    setInBoundDate(date);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/returns">Returns</Link>
          <Typography color="textPrimary">View Return</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item sm={12} md={3} lg={2}>
        <Grid container spacing={2}>
          <Card>
            <Box p={2}>
              <form onSubmit={handleSave}>
                <FormControl fullWidth>
                  <TextField
                    label="Ticket Id"
                    name="ticketId"
                    value={ticketId}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Location"
                    name="location"
                    value={shopLocation}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                <Divider />
                <FormControl fullWidth>
                  <InputLabel id="lbl-assigned-status">Status</InputLabel>
                  <Select
                    labelId="lbl-assigned-status"
                    label="Status"
                    value={status}
                    name="status"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Open</MenuItem>
                    <MenuItem value={1}>Re-lot</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                  </Select>
                </FormControl>
                <Divider />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Select the inbound Date:"
                    value={inBoundDate}
                    onChange={handleViewingDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
                <InputLabel id="lbl-auctionhouse">Auction House:</InputLabel>
                <Select
                  labelId="lbl-auctionhouse"
                  id="auctionhouse"
                  value={auctionHouseId}
                  name="auctionhouse"
                  fullWidth
                  onChange={handleChange}
                >
                  {auctionHouses.map((r) => {
                    return (
                      <MenuItem key={`auctionhouse-${r.id}`} value={r.id}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Divider />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  Save Changes
                </Button>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Grid item sm={12} md={9} lg={10}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography component="h2" variant="h6" color="inherit">
              Return: #{returnId}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography>
              {" "}
              Created By: {agents.find((r) => r.id === creationUserId)?.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <b>Status:</b> {getStatus(status)}
          </Grid>
          <Grid item xs={12} sm={4}>
            <b>Created:</b>{" "}
            {creationDate
              ? new Intl.DateTimeFormat("en-GB", {
                  dateStyle: "short",
                  timeStyle: "medium",
                }).format(new Date(creationDate))
              : ""}
          </Grid>
          <Grid item xs={12} sm={4}>
            <b>Last Modified:</b>{" "}
            {modificationDate
              ? new Intl.DateTimeFormat("en-GB", {
                  dateStyle: "short",
                  timeStyle: "medium",
                }).format(new Date(modificationDate))
              : ""}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <AppBar position="static">
              <Tabs
                value={responseTab}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="inherit"
                aria-label="response tab options"
              >
                <Tab label="Return Detials" />
                <Tab label="Notes" />
                <Tab
                  label={generateTicket ? "Generate Ticket" : "View Ticket"}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={responseTab} index={0}>
              <form onSubmit={regeneratePrintRequest}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-printLocation">
                        Print Location
                      </InputLabel>
                      <Select
                        labelId="lbl-printLocation"
                        id="printLocation"
                        label="Print Location"
                        value={printServiceId}
                        name="printServiceLocation"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      >
                        {printServices.map((r) => {
                          return (
                            <MenuItem key={`printservice-${r.id}`} value={r.id}>
                              {r.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="lbl-printer">Printer</InputLabel>
                      <Select
                        labelId="lbl-printer"
                        id="printer"
                        label="Printer"
                        value={printerId}
                        name="printerId"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      >
                        {printers.map((r) => {
                          return (
                            <MenuItem key={`printer-${r.id}`} value={r.id}>
                              {r.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Re Print Return Label
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value={responseTab} index={1} p={0}>
              <form onSubmit={handleNote}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="lbl-Note"
                    label="Note"
                    name="note"
                    onChange={handleChange}
                    value={note}
                    fullWidth
                    variant="outlined"
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Create Note
                  </Button>
                </FormControl>
              </form>
            </TabPanel>
            <TabPanel value={responseTab} index={2}>
              <Collapse in={generateTicket}>
                <Grid item xs={12}>
                  <Box p={2} component={Paper}>
                    <form onSubmit={handleTicket}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography color="textPrimary">
                            Search / Create For Ticket
                          </Typography>
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} md={2}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="lbl-search-type">
                                Search By
                              </InputLabel>
                              <Select
                                labelId="lbl-search-type"
                                label="SearchType"
                                name="searchType"
                                value={searchType}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth={true}
                              >
                                <MenuItem key={0} value="invoiceID">
                                  Invoice ID
                                </MenuItem>
                                <MenuItem key={1} value="ticketID">
                                  Ticket ID
                                </MenuItem>
                                <MenuItem key={2} value="stockID">
                                  Stock ID
                                </MenuItem>
                                <MenuItem key={3} value="trackingCode">
                                  DPD Tracking Code
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                label={
                                  searchType
                                    ? "Search using " + searchType
                                    : "Select Search By Option"
                                }
                                name="searchId"
                                onChange={handleChange}
                                value={searchId}
                                fullWidth
                                variant="outlined"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Search
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              </Collapse>
              <Grid item>
                {!errorMessage ? (
                  <Box p={2} component={Paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography color="textPrimary">
                          Requester Details
                        </Typography>
                      </Grid>
                      <Grid container item spacing={4}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            value={firstName}
                            fullWidth
                            InputProps={{
                              readOnly: !generateTicket,
                            }}
                            variant="outlined"
                            readOnly
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            value={lastName}
                            fullWidth
                            InputProps={{
                              readOnly: !generateTicket,
                            }}
                            variant="outlined"
                            readOnly
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                              readOnly: !generateTicket,
                            }}
                            variant="outlined"
                            readOnly
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography color="textPrimary">
                          Ticket Details
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          label="Title"
                          name="title"
                          value={title}
                          fullWidth
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Invoice ID"
                          name="invoiceId"
                          value={invoiceId}
                          fullWidth
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="lbl-assigned-topic">Topic</InputLabel>
                          <Select
                            labelId="lbl-assigned-topic"
                            label="Topic"
                            value={topicId}
                            name="topicId"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth={true}
                          >
                            {topics.map((r) => {
                              return (
                                <MenuItem key={`topic-${r.id}`} value={r.id}>
                                  {r.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="lbl-assigned-role">Dept</InputLabel>
                          <Select
                            labelId="lbl-assigned-role"
                            label="Dept"
                            value={assignedRole}
                            name="role"
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth={true}
                          >
                            {roles.map((r) => {
                              return (
                                <MenuItem key={`role-${r}`} value={r}>
                                  {r}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="lbl-assigned-agent">Agent</InputLabel>
                          <Select
                            labelId="lbl-assigned-agent"
                            label="Agent"
                            name="agentId"
                            value={assignedAgentId}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth={true}
                          >
                            {agents.map((r) => {
                              return (
                                <MenuItem key={`agent-${r.id}`} value={r.id}>
                                  {r.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Conversation"
                          name="conversation"
                          multiline
                          variant="outlined"
                          fullWidth={true}
                          value={conversation}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Box p={2} component={Paper}>
                    <Typography variant="h6">{errorMessage}</Typography>
                  </Box>
                )}
              </Grid>
              {generateTicket ? (
                <Grid item xs={12}>
                  <Box p={2} component={Paper}>
                    <form onSubmit={doSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Generate Ticket
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box p={2} component={Paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Link to={`/qms/tickets/${ticketId}`}>
                          <Button variant="contained" color="primary">
                            View Ticket {ticketId}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </TabPanel>
          </Card>
        </Grid>
        <Divider />
        {notes.map((c) => {
          return (
            <Grid key={`notes-${c.id}`} item xs={12}>
              <Paper>
                <Box mb={1} ml={1} p={1}>
                  <Grid container>
                    <Typography color="textPrimary">
                      Note by {c.creationUserId}
                    </Typography>
                    <Grid item xs={9} style={{ textAlign: "right" }}>
                      {new Intl.DateTimeFormat("en-GB", {
                        dateStyle: "short",
                        timeStyle: "medium",
                      }).format(new Date(c.creationDate))}
                    </Grid>
                    <Grid item xs={12}>
                      {c.note}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <SuccessAlert
        openSuccess={returnCreated}
        setOpenSuccess={setReturnCreated}
        message="Return Updated"
      />
      <SuccessAlert
        openSuccess={ticketCreated}
        setOpenSuccess={setTicketCreated}
        message="New Ticket Created"
      />
      <SuccessAlert
        openSuccess={printCreated}
        setOpenSuccess={setPrintCreated}
        message="New Print Created"
      />
      <SuccessAlert
        openSuccess={internalResponse}
        setOpenSuccess={setInternalResponseSent}
        message="Created Internal Log"
      />
    </Grid>
  );
};

export default ReturnsView;
