import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import { makeStyles } from "@mui/styles";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  dangerButton: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
  },
}));

const DeleteDialog = ({ open, toggleOpen, handleDelete, isLoading }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <Alert severity="error">Are you sure you wish to delete this?</Alert>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={toggleOpen} color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleDelete}
              className={classes.dangerButton}
              startIcon={<DeleteSweepIcon />}
              disabled={isLoading}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const DeleteBtn = ({ handleDelete, isLoading }) => {
  const classes = useStyles();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleOpen = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  return (
    <>
      <Button
        className={classes.dangerButton}
        variant="outlined"
        size="large"
        onClick={toggleOpen}
        disabled={isLoading}
      >
        Delete
      </Button>
      <DeleteDialog
        open={openDeleteDialog}
        toggleOpen={toggleOpen}
        handleDelete={handleDelete}
        isLoading={isLoading}
      />
    </>
  );
};

export default DeleteBtn;
