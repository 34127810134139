import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { marked } from "marked";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    height: "60px",
    padding: theme.spacing(2),
    maxHeight: "100px",
    maxWidth: "100%",
    fontSize: "14px",
  },
  preview: {
    width: "100%",
    border: "1px solid #ccc",
    paddingLeft: theme.spacing(2),
  },
  auctionCategoryContainer: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    gap: "1rem",
  },
}));

const TextAreaComp = ({ value, handleChange, name, placeholder }) => {
  const [view, setView] = useState(false);

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {view ? (
        <div
          className={classes.preview}
          dangerouslySetInnerHTML={{
            __html: marked(value),
          }}
        />
      ) : (
        <TextareaAutosize
          className={classes.textArea}
          name={name}
          minRows={4}
          maxRows={6}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setView(!view)}
      >
        {view ? "Edit" : "View"}
      </Button>
    </Grid>
  );
};

const ContentLandingPageCreate = () => {
  const [type, setType] = useState(0);
  const [category, setCategory] = useState("");
  const [auctionId, setAuctionId] = useState("");
  const [auctionsOrCategoriesId, setAuctionsOrCategoriesId] = useState([]);
  const [title, setTitle] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [educationOne, setEducationOne] = useState("");
  const [educationTwo, setEducationTwo] = useState("");
  const [educationThree, setEducationThree] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();

  const categories = useQuery(
    "asCategories",
    async () =>
      await axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage/categories`)
        .then((res) => res.data)
  );

  const addPageMutation = useMutation(
    () =>
      axios
        .post(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage`, {
          auctionsOrCategoriesId: auctionsOrCategoriesId,
          type: type,
          title: title,
          seoTitle: seoTitle,
          description: description,
          image: image,
          url: url,
          educationOne: educationOne,
          educationTwo: educationTwo,
          educationThree: educationThree,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => setOpenSuccess(true),
      onError: (error) => setOpenError(true),
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "type":
        setType(value);
        setAuctionsOrCategoriesId([]);
        break;
      case "title":
        setTitle(value);
        break;
      case "seoTitle":
        setSeoTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "category":
        setCategory(value);
        setAuctionsOrCategoriesId((prev) => [...prev, value]);
        break;
      case "auctionId":
        setAuctionId(value);
        break;
      case "educationOne":
        setEducationOne(value);
        break;
      case "educationTwo":
        setEducationTwo(value);
        break;
      case "educationThree":
        setEducationThree(value);
        break;
      default:
        break;
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.onerror = () =>
        console.error(`Error reading file: ${reader.error}`);
    } else {
      setImage("");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/content/landing-pages">Landing Pages</Link>
          <Typography color="textPrimary">Create Landing Page</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h6" color="inherit">
                  Create Landing Page
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="inherit">Select Type</Typography>
                <Select
                  labelId="type-label"
                  name="type"
                  label="Type"
                  variant="outlined"
                  value={type}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Categories</MenuItem>
                  <MenuItem value={1}>Auctions</MenuItem>
                </Select>
              </Grid>
              {type === 0 && (
                <Grid item xs={12}>
                  <Typography color="inherit">Select Category</Typography>
                  <Select
                    labelId="category-label"
                    name="category"
                    label="Category"
                    variant="outlined"
                    fullWidth
                    value={category}
                    onChange={handleChange}
                  >
                    {categories.data &&
                      categories.data.result.map((el) => (
                        <MenuItem value={el.id}>{el.name}</MenuItem>
                      ))}
                  </Select>
                </Grid>
              )}
              {type === 1 && (
                <Grid item xs={12}>
                  <Typography color="inherit">Add Auction Id</Typography>
                  <TextField
                    id="auctionId"
                    name="auctionId"
                    label="Auction Id"
                    variant="outlined"
                    fullWidth
                    value={auctionId}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setAuctionsOrCategoriesId((prev) => [
                          ...prev,
                          auctionId,
                        ]);
                        setAuctionId("");
                      }
                    }}
                  />
                </Grid>
              )}
              {auctionsOrCategoriesId.length > 0 && (
                <Grid item xs={12} className={classes.container}>
                  {auctionsOrCategoriesId.map((el, index) => (
                    <div
                      className={classes.auctionCategoryContainer}
                      key={index}
                    >
                      <TextField
                        id="auctionsOrCategoriesId"
                        name="auctionsOrCategoriesId"
                        label="Id"
                        variant="outlined"
                        value={el}
                        disabled
                      />
                      <HighlightOffIcon
                        onClick={() =>
                          setAuctionsOrCategoriesId(
                            auctionsOrCategoriesId.filter(
                              (item, i) => i !== index
                            )
                          )
                        }
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ))}
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="seoTitle"
                  name="seoTitle"
                  label="SEO Title"
                  variant="outlined"
                  fullWidth
                  value={seoTitle}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={6}
                  maxRows={8}
                  aria-label="minimum height"
                  name="description"
                  variant="outlined"
                  placeholder="Description"
                  value={description}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "14px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="url"
                  name="url"
                  label="View Lots Button Url"
                  variant="outlined"
                  fullWidth
                  value={url}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <input type="file" onChange={handleImage} accept="image/*" />
              </Grid>
              <Grid item xs={12}>
                {image && (
                  <img
                    src={image}
                    alt="landingPageImage"
                    style={{ maxHeight: "300px" }}
                  />
                )}
              </Grid>
              <TextAreaComp
                key="educationOne"
                value={educationOne}
                handleChange={handleChange}
                name="educationOne"
                placeholder="Education One"
              />
              <TextAreaComp
                key="educationTwo"
                value={educationTwo}
                handleChange={handleChange}
                name="educationTwo"
                placeholder="Education Two"
              />
              <TextAreaComp
                key="educationThree"
                value={educationThree}
                handleChange={handleChange}
                name="educationThree"
                placeholder="Education Three"
              />
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addPageMutation.mutate()}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Landing Page created successfully!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ContentLandingPageCreate;
