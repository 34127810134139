import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  FormHelperText,
  TablePagination,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useState, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import { formatTime } from "../utils/formatTime";
const DAY_MAP = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
function FormDialog(props) {
  const { inputValue, open, setOpen, auctionHousesQuery, handleCreateViewing } =
    props;
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(new Date().toISOString().slice(0, 11) + "08:30").getTime()
  );
  const [endDate, setEndDate] = useState(
    new Date(
      new Date(new Date().toISOString().slice(0, 11) + "16:30").setMonth(
        new Date(startDate).getMonth() + 1
      )
    ).getTime()
  );
  const [recurringType, setRecurringType] = useState(1);
  const [recurringDay, setRecurringDay] = useState(1);
  const [multiView, setMultiView] = useState(false);
  const [duration, setDuration] = useState(15);
  const [timeError, setTimeError] = useState(false);
  const [buffer, setBuffer] = useState(5);
  useEffect(
    () =>
      axios.get(`http://worldclockapi.com/api/json/utc/now`).then((res) => {
        var clientNow = new Date();
        var serverNow = new Date(res.data.currentDateTime);
        setTimeError(
          clientNow.toISOString().slice(0, 16) ===
            serverNow.toISOString().slice(0, 16)
            ? false
            : true
        );
      }),
    [timeError]
  );
  const handleDurationChange = (event) => {
    inputValue.duration = event.target.value;
    setDuration(inputValue.duration);
  };
  const handleBufferChange = (event) => {
    inputValue.buffer = event.target.value;
    setBuffer(inputValue.buffer);
  };
  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      inputValue.auctionHouseId = id.props.value;
      setSelectedAuctionHouse(inputValue.auctionHouseId);
    }
  };
  const handleStartDateChange = (date) => {
    inputValue.startDate = date.getTime();
    setStartDate(date.getTime());
  };
  const handleEndDateChange = (date) => {
    inputValue.endDate = date.getTime();
    setEndDate(date.getTime());
  };
  const handleStartTimeChange = (time) => {
    setStartDate(time);
    inputValue.startTime = time.toTimeString().slice(0, 5);
  };
  const handleEndTimeChange = (time) => {
    setEndDate(time);
    inputValue.endTime = time.toTimeString().slice(0, 5);
  };
  const handleMultiView = (event) => {
    setMultiView(event.target.checked);
    inputValue.multiView = event.target.checked;
  };
  const handleRecurringTypeChange = (event, id) => {
    if (id.props.value) {
      inputValue.recurringType = id.props.value;
      setRecurringType(inputValue.recurringType);
    }
  };
  const handleRecurringDayChange = (event, id) => {
    if (id.props.value !== null) {
      inputValue.recurringDay = id.props.value;
      setRecurringDay(inputValue.recurringDay);
    }
  };
  useEffect(() => {
    inputValue.auctionHouseId = selectedAuctionHouse;
    inputValue.startDate = startDate;
    inputValue.endDate = endDate;
    inputValue.startTime = "08:30";
    inputValue.endTime = "16:30";
    inputValue.recurringDay = recurringDay;
    inputValue.recurringType = recurringType;
    inputValue.multiView = multiView;
    inputValue.duration = duration;
    inputValue.buffer = buffer;
  }, [
    inputValue,
    selectedAuctionHouse,
    startDate,
    endDate,
    recurringDay,
    recurringType,
    multiView,
    duration,
    buffer,
  ]);
  useEffect(() => {
    if (
      inputValue &&
      Object.keys(inputValue).length === 0 &&
      inputValue.constructor === Object
    ) {
      //Checking whether the object is empty
      setSelectedAuctionHouse(1);
      setStartDate(new Date(new Date().toISOString().slice(0, 11) + "08:30"));
      setEndDate(new Date(new Date().toISOString().slice(0, 11) + "16:30"));
      setRecurringDay(-1);
      setRecurringType(-1);
      setMultiView(false);
      setDuration(15);
      setBuffer(5);
    }
  }, [inputValue]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Viewing</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container justifyContent="space-around">
            <FormControl variant="outlined">
              <InputLabel>Auction House</InputLabel>

              <Select
                onChange={handleAuctionhouseChange}
                value={selectedAuctionHouse}
              >
                {auctionHousesQuery.data.map((auctionHouse) => {
                  return (
                    <MenuItem value={auctionHouse.id}>
                      {auctionHouse.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Select the Auction House</FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>Recurring Type</InputLabel>

              <Select
                onChange={handleRecurringTypeChange}
                value={recurringType}
              >
                <MenuItem value={1}>Weekly</MenuItem>
                <MenuItem value={2}>Monthly</MenuItem>
              </Select>
              <FormHelperText>Select the Recurring Type</FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>Recurring Day</InputLabel>

              <Select
                onChange={handleRecurringDayChange}
                value={recurringDay}
                required
              >
                {recurringType === 1
                  ? DAY_MAP.map((day, i) => (
                      <MenuItem value={i}>{day}</MenuItem>
                    ))
                  : [...Array(32).keys()]
                      .slice(1)
                      .map((day) => <MenuItem value={day}>{day}</MenuItem>)}
              </Select>
              <FormHelperText>Select the Recurring Day</FormHelperText>
            </FormControl>

            <DatePicker
              disableToolbar
              required
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              label="Select the Start Date:"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <DatePicker
              disableToolbar
              required
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              label="Select the End Date:"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <TimePicker
              margin="normal"
              label="Starting From Time:"
              value={startDate}
              required
              defaultValue="08:30"
              keyboardIcon={<ScheduleIcon />}
              onChange={handleStartTimeChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              ampm={false}
            />
            <TimePicker
              margin="normal"
              label="Ending Before Time:"
              value={endDate}
              defaultValue="16:30"
              required
              onChange={handleEndTimeChange}
              keyboardIcon={<ScheduleIcon />}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              ampm={false}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={multiView}
                  onChange={handleMultiView}
                  name="multiView"
                  color="primary"
                  required
                />
              }
              label="Multiple Views Allowed"
            />
            <TextField
              label="Duration(min)"
              variant="outlined"
              name="duration"
              type="number"
              InputProps={{ inputProps: { min: 5, max: 60 } }}
              value={duration}
              onChange={handleDurationChange}
            />
            <TextField
              label="Buffer(min)"
              variant="outlined"
              name="buffer"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 30 } }}
              value={buffer}
              onChange={handleBufferChange}
            />
          </Grid>
        </LocalizationProvider>
        {timeError ? (
          <DialogContentText>
            The time/timezone is not correct. Please fix that.
          </DialogContentText>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleCreateViewing}
          disabled={timeError}
          color="primary"
        >
          Create Viewing
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ManageRecurringViewings = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRecurringType, setSelectedRecurringType] = useState(0);
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const newViewing = {};
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 min
  );

  const viewingsQuery = useQuery(
    [
      "scRecurringViewings",
      page,
      rowsPerPage,
      selectedAuctionHouse,
      startDate,
      endDate,
      selectedRecurringType,
      refresh,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/recurring-viewings?auctionHouseId=${selectedAuctionHouse}&startDate=${startDate}&endDate=${endDate}&maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }&recurringType=${selectedRecurringType}`
        )
        .then((res) => {
          setTotalRows(res.data.totalResults);
          return res.data.recurringviewings;
        }),
    { keepPreviousData: true }
  );
  const handleClickOpen = () => {
    setCreateDialogOpen(true);
  };
  const handleCreateViewing = (e) => {
    e.preventDefault();
    newViewing.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios
      .post(
        `${process.env.REACT_APP_BOOKING_API_URL}/recurring-viewings`,
        newViewing
      )
      .then((res) => {
        setSuccessMessage("View Created Successfully");
        setOpenSuccess(true);
        setCreateDialogOpen(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setErrorMessage(
          "View Creation Failed: " + err.response
            ? err.response.data.message
            : err.message
        );
        setOpenError(true);
      });
  };
  useEffect(() => {
    endDate.setMonth(new Date(startDate).getMonth() + 1);
  }, [startDate, endDate]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleRecurringTypeChange = (event, id) => {
    if (id.props.value) {
      setSelectedRecurringType(id.props.value);
    }
  };
  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      setSelectedAuctionHouse(id.props.value);
    }
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  return (
    <Fragment>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">
                Manage Recurring Viewings
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="space-around">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Auction House</InputLabel>

                    <Select
                      onChange={handleAuctionhouseChange}
                      value={selectedAuctionHouse}
                    >
                      {auctionHousesQuery.data.map((auctionHouse) => {
                        return (
                          <MenuItem value={auctionHouse.id}>
                            {auctionHouse.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Select the Auction House</FormHelperText>
                  </FormControl>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Recurring Viewings From:"
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Recurring Viewings Till:"
                    value={endDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Recurring Type</InputLabel>

                    <Select
                      onChange={handleRecurringTypeChange}
                      value={selectedRecurringType}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>Weekly</MenuItem>
                      <MenuItem value={2}>Monthly</MenuItem>
                    </Select>
                    <FormHelperText>Select the Recurring Type</FormHelperText>
                  </FormControl>
                </Grid>
              </LocalizationProvider>
            </Box>
          </Grid>
          {viewingsQuery.isLoading || viewingsQuery.data === undefined ? (
            <CircularProgress />
          ) : viewingsQuery.error ? (
            "An error has occurred: " + viewingsQuery.error.message
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={"Recurring Viewings"}
                  handleCreate={handleClickOpen}
                  createTitle="Create New Viewing"
                />
                <FormDialog
                  handleCreate={handleClickOpen}
                  inputValue={newViewing}
                  open={createDialogOpen}
                  auctionHousesQuery={auctionHousesQuery}
                  setOpen={setCreateDialogOpen}
                  handleCreateViewing={handleCreateViewing}
                />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Recurring Type</TableCell>
                        <TableCell>Recurring Day</TableCell>
                        <TableCell>Multiple people at a time</TableCell>
                        <TableCell>Duration(min)</TableCell>
                        <TableCell>Buffer(min)</TableCell>
                        <TableCell>Viewings Count</TableCell>
                        <TableCell>View</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {viewingsQuery.data.map((viewing) => {
                        return (
                          <TableRow key={viewing.id} hover tabIndex={-1}>
                            <TableCell>{viewing.id}</TableCell>
                            <TableCell>
                              {formatTime(viewing.startDate).slice(0, 10)}
                            </TableCell>
                            <TableCell>
                              {formatTime(viewing.endDate).slice(0, 10)}
                            </TableCell>
                            <TableCell>{viewing.startTime}</TableCell>
                            <TableCell>{viewing.endTime}</TableCell>
                            <TableCell>
                              {viewing.recurringType === 1
                                ? "Weekly"
                                : "Monthly"}
                            </TableCell>
                            <TableCell>
                              {viewing.recurringType === 2
                                ? viewing.recurringDay
                                : DAY_MAP[viewing.recurringDay]}
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                name="multiView"
                                checked={viewing.multiView}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </TableCell>
                            <TableCell>{viewing.duration}</TableCell>
                            <TableCell>{viewing.buffer}</TableCell>
                            <TableCell>{viewing.viewingsCount}</TableCell>
                            <TableCell>
                              <Link
                                to={`/recurring-lot-viewings/${viewing.id}`}
                              >
                                Go to Recurring Viewing
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default ManageRecurringViewings;
