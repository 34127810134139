export function urlify(value) {
  return value
    .replace(/ /g, "-")
    .replace(/[^a-z0-9-&]/gi, "")
    .toLowerCase();
}

export function deUrlify(value) {
  if (value) {
    return value.replace(/[-,--]/g, " ");
  } else return;
}

export function capitalise(words) {
  return words
    .split(" ")
    .map((word) => {
      if (word) return word[0].toUpperCase() + word.substring(1);
      else return null;
    })
    .join(" ");
}
