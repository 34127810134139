import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Alert from "@mui/lab/Alert";
import { formatTime, formatDate } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const bookingsStatuses = Object.freeze({
  WAITING_CONFIRMATION: 0,
  CONFIRMED: 1,
  QUEUED: 2,
  DENIED: 3,
});

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  marginZero: {
    margin: 0,
  },
  noWrap: {
    flexWrap: "nowrap",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2, 4, 3),
  },
}));

const InvoiceDetailsModal = ({ invoiceId, open, handleClose }) => {
  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    "asInvoiceDetails",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/invoice/${invoiceId}`
        )
        .then((res) => res.data),
    {
      enabled: open === true && invoiceId !== "",
    }
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="invoiceDetailsModal"
      disableScrollLock={true}
    >
      <Box className={classes.modal}>
        {isLoading && <CircularProgress />}
        {error && `An error has occurred: ${error.message}`}
        {data && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Image</TableCell>
                <TableCell>StockId</TableCell>
                <TableCell>LotNo</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Container</TableCell>
              </TableHead>
              <TableBody>
                {data.result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <a
                        href={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                          alt={item.productTitle}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </a>
                    </TableCell>
                    <TableCell>{item.stockId}</TableCell>
                    <TableCell>{item.lotNo}</TableCell>
                    <TableCell>{item.productTitle}</TableCell>
                    <TableCell>{item.containerName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};

const CustomersBookings = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [invoiceId, setInvoiceId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    ["asBookings", currentAuctionHouse.id, startDate, endDate],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/all?auctionHouseId=${currentAuctionHouse.id}&startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => res.data),
    {
      enabled: !!currentAuctionHouse.id && !!startDate && !!endDate,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const updateBookingMutation = useMutation(
    ({ bookingId, status }) =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/${bookingId}/update/${status}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        if (res && res.booking) {
          queryClient.setQueryData(
            ["asBookings", currentAuctionHouse.id, startDate, endDate],
            (old) => {
              return {
                results: old.results.map((el) =>
                  el.id === res.booking.id ? { ...el, ...res.booking } : el
                ),
              };
            }
          );
          return res;
        }
      },
    }
  );

  const submitInvoiceMutation = useMutation(({ forced, invoiceId }) =>
    axios
      .put(`${process.env.REACT_APP_AOMS_API_URL}/collections/create`, {
        invoiceId,
        forced,
      })
      .then((res) => res.data)
  );

  const handleUpdate = async (bookingId, status) => {
    try {
      const result = await updateBookingMutation.mutateAsync({
        bookingId,
        status,
      });

      if (status === bookingsStatuses.QUEUED) {
        await submitInvoiceMutation.mutateAsync({
          forced: true,
          invoiceId: result.booking.invoiceId,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  return (
    <Grid container spacing={3}>
      <InvoiceDetailsModal
        invoiceId={invoiceId}
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Bookings</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Box>
      </Grid>
      {submitInvoiceMutation.data && !submitInvoiceMutation.data.success && (
        <Grid item xs={12} onClick={submitInvoiceMutation.reset}>
          <Grid container style={{ marginTop: "20px" }}>
            <Alert severity="error">{submitInvoiceMutation.data.message}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <CircularProgress />}
          {error && "An error has occurred: " + error.message}
          {!data && "No bookings for this period or auction house"}
          {data && data.results && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice</TableCell>
                    <TableCell>Total Lots</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Booked Date</TableCell>
                    <TableCell>Booked Slot</TableCell>
                    <TableCell>Confirmed Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Telephone</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.invoiceId}</TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                      <TableCell>{el.customerName}</TableCell>
                      <TableCell>{formatDate(el.bookedDate)}</TableCell>
                      <TableCell>{el.slot}</TableCell>
                      <TableCell>{formatTime(el.confirmedDate)}</TableCell>
                      <TableCell>
                        {getKeyByValue(bookingsStatuses, el.status)}
                      </TableCell>
                      <TableCell>{el.tel}</TableCell>
                      <TableCell>
                        <Grid container className={classes.noWrap}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: "10px" }}
                            disabled={el.status > bookingsStatuses.CONFIRMED}
                            onClick={() => {
                              if (
                                el.status ===
                                bookingsStatuses.WAITING_CONFIRMATION
                              ) {
                                handleUpdate(el.id, bookingsStatuses.CONFIRMED);
                              } else if (
                                el.status === bookingsStatuses.CONFIRMED
                              ) {
                                handleUpdate(el.id, bookingsStatuses.QUEUED);
                              }
                            }}
                          >
                            {el.status === bookingsStatuses.WAITING_CONFIRMATION
                              ? "Confirm"
                              : "Add"}
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={el.status === bookingsStatuses.DENIED}
                            onClick={() =>
                              handleUpdate(el.id, bookingsStatuses.DENIED)
                            }
                          >
                            Delete
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setInvoiceId(el.invoiceId);
                              setShowModal(true);
                            }}
                          >
                            View
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomersBookings;
