import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
  const accessToken = sessionStorage.getItem("ACCESS_TOKEN");
  if (accessToken) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
