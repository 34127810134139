import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

const CreateResponse = (props) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const responses = props.responses;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (name.length === 0 || message.length === 0) {
      return;
    }
    await axios.post(`${process.env.REACT_APP_QMS_API_URL}/responses`, {
      name,
      message,
    });
    responses.refetch();
    setName("");
    setMessage("");
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" color="inherit">
            Create New Response
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            onChange={handleInput}
            variant="outlined"
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            multiline
            fullWidth={true}
            variant="outlined"
            name="message"
            value={message}
            onChange={handleInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ManageResponses = (props) => {
  const responses = props.responses;

  const handleDelete = async (id) => {
    await axios.delete(`${process.env.REACT_APP_QMS_API_URL}/responses/${id}`);
    responses.refetch();
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h6" color="inherit">
          Manage Responses
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {responses.isLoading ? (
          <CircularProgress />
        ) : responses.error ? (
          "An error has occurred: " + responses.error.message
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responses.data.map((r) => {
                  return (
                    <TableRow key={`topic-${r.id}`}>
                      <TableCell>{r.id}</TableCell>
                      <TableCell>{r.name}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleDelete(r.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

const QmsResponses = (props) => {
  const responses = useQuery("getResponses", async () => {
    return (await axios.get(`${process.env.REACT_APP_QMS_API_URL}/responses`))
      .data;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/qms/tickets">Tickets</Link>
          <Typography color="textPrimary">Manage Responses</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <CreateResponse responses={responses} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <ManageResponses responses={responses} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default QmsResponses;
