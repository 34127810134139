import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment } from "react";
import { useQuery } from "react-query";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";

const AuctionTemplates = () => {
  const auctionTemplatesQuery = useQuery(
    "auctionTemplates",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctiontemplates`)
        .then((res) => res.data.results),
    { staleTime: 1000 * 60 } // 1 minutes
  );
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Auction Templates</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">Auction Templates</Typography>
                  </Grid>
                  <Grid item>
                    <Link to="/auctiontemplates/create">
                      <Button variant="outlined" startIcon={<AddIcon />}>
                        Create New Auction Template
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {auctionTemplatesQuery.isLoading ? (
                  <CircularProgress />
                ) : (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Auction House</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Last Modified</TableCell>
                          <TableCell>Buyers Premium</TableCell>
                          <TableCell>VAT</TableCell>
                          <TableCell>Premium?</TableCell>
                          <TableCell>Online?</TableCell>
                          <TableCell>Postal?</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {auctionTemplatesQuery.data.map((auctionTemplate) => (
                          <TableRow key={auctionTemplate.id}>
                            <TableCell>{auctionTemplate.id}</TableCell>
                            <TableCell>
                              {auctionTemplate.auctionHouseName}
                            </TableCell>
                            <TableCell>{auctionTemplate.name}</TableCell>
                            <TableCell>{auctionTemplate.title}</TableCell>
                            <TableCell>
                              {auctionTemplate.modificationDate}
                            </TableCell>
                            <TableCell>
                              {auctionTemplate.buyersPremium}
                            </TableCell>
                            <TableCell>{auctionTemplate.vat}</TableCell>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={auctionTemplate.premium}
                                readOnly
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={auctionTemplate.onlineOnly}
                                readOnly
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                type="checkbox"
                                checked={auctionTemplate.postalOnly}
                                readOnly
                              />
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/auctiontemplates/${auctionTemplate.id}`}
                              >
                                Edit
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AuctionTemplates;
