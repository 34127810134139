import axios from "axios";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  TextField,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useQuery } from "react-query";

const useStyles = makeStyles(() => ({
  warning: {
    backgroundColor: "#ffdee1",
    border: "2px solid #f5c2c7",
  },
}));

const Qms = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const token = jwt_decode(sessionStorage.getItem("ACCESS_TOKEN"));
  const admin = token.permissions.roles.includes("qms: Administrator");

  const [assigned, setAssigned] = useState(!admin);
  const [unassigned, setUnassigned] = useState(!admin);
  const [status, setStatus] = useState(0);
  // const [status, setStatus] = useState(params.status ? params.status : 0);
  const [agent, setAgent] = useState(0);
  const [roles, setRoles] = useState(
    admin
      ? []
      : token.permissions.roles
          .filter((r) => r.includes("qms: "))
          .map((r) => r.replace("qms: ", ""))
  );
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const ticketsQuery = useQuery(
    [
      "qmsTickets",
      assigned,
      unassigned,
      status,
      agent,
      role,
      page,
      rowsPerPage,
      searchInput,
    ],
    async () => {
      const url = new URL(`${process.env.REACT_APP_QMS_API_URL}/tickets`);
      url.searchParams.append("startRowIndex", page * rowsPerPage);
      url.searchParams.append("maximumRows", rowsPerPage);
      url.searchParams.append("assigned", assigned);
      if (admin) {
        url.searchParams.append("unassigned", unassigned);
      }
      if (status !== false) {
        if (status >= 0) url.searchParams.append("status", status);
      }

      if (role.length > 0) url.searchParams.append("roles", role);
      if (search.length > 0) url.searchParams.append("search", search);
      if (agent > 0) url.searchParams.append("agentId", agent);
      try {
        const data = await axios.get(url).then((res) => res.data);
        return data;
      } catch (error) {
        console.log("axios error:", error);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const usersResponseQuery = useQuery(
    "qmsUsersResponse",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/applications/12/users`)
        .then((res) => {
          const agents = res.data.map((u) => {
            return {
              name: u.firstName + " " + u.lastName,
              id: u.id,
            };
          });
          return agents;
        }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  useEffect(() => {
    (async function bindOtherData() {
      if (admin) {
        const rolesResponse = await axios.get(
          `${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`
        );
        const roles = rolesResponse.data
          .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
          .map((r) => r.name);
        setRoles(roles);
      }
    })();
  }, [admin]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setStatus(params.get("status") ? params.get("status") : 0);
      setAssigned(params.get("assigned") ? params.get("assigned") : !admin);
    }
  }, [location.search, admin]);

  const getAgentName = (id) => {
    if (!usersResponseQuery.data || usersResponseQuery.data.length === 0) {
      return id.toString();
    }
    const agent = usersResponseQuery.data.find((a) => a.id === id);
    if (!agent) {
      return id.toString();
    }
    return agent.name;
  };

  const handleChangePage = async (evt, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 1:
        return "Pending";
      case 2:
        return "Closed";
      default:
        return null;
    }
  };

  const handleOnKeyUp = async (evt) => {
    const name = evt.target.name;
    switch (name) {
      case "search":
        if (evt.keyCode === 13) {
          setPage(0);
          setSearchInput(search);
        }
        break;
      default:
        return;
    }
  };

  const handleAddQuery = (type, value) => {
    const params = new URLSearchParams(location.search);
    if (value === false) params.delete(type);
    else params.set(type, value);
    history.push(`/qms/tickets?${params.toString()}`);
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "assigned":
        setAssigned(evt.target.checked);
        setUnassigned(false);
        setPage(0);
        handleAddQuery("assigned", evt.target.checked);
        break;
      case "unassigned":
        setUnassigned(evt.target.checked);
        setAssigned(false);
        setPage(0);
        handleAddQuery("assigned", false);
        break;
      case "status":
        setStatus(value);
        setPage(0);
        handleAddQuery("status", value);
        break;
      case "agent":
        setAgent(value);
        setUnassigned(false);
        setAssigned(false);
        setPage(0);
        break;
      case "role":
        setRole(value);
        setUnassigned(false);
        setPage(0);
        break;
      case "search":
        setSearch(value);
        break;
      default:
        return;
    }
  };

  const d = new Date();
  const passedDate = d.getDate() - 7;
  const dateOneWeekAgo = new Date(d.setDate(passedDate));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Tickets</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container alignItems="center" spacing={3} item xs={12}>
            <Grid item>
              <TextField
                name="search"
                label="Search"
                variant="outlined"
                onChange={handleChange}
                onKeyUp={handleOnKeyUp}
                value={search}
              />
            </Grid>
            <Grid item>
              <Link color="primary" to="/qms/create">
                Create New Ticket
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} md={2}>
        <Card>
          <Box p={2}>
            <Typography component="h3" variant="h6">
              Filters
            </Typography>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={assigned}
                        onChange={handleChange}
                        name="assigned"
                      />
                    }
                    label="Assigned to you"
                    color="primary"
                  />
                </Grid>
                {admin && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={unassigned}
                          onChange={handleChange}
                          name="unassigned"
                        />
                      }
                      label="Unassigned"
                      color="primary"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputLabel id="lbl-status">Status:</InputLabel>
                  <Select
                    labelId="lbl-status"
                    id="select-status"
                    value={status}
                    name="status"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={false}>All Statuses</MenuItem>
                    <MenuItem value={0}>Open</MenuItem>
                    <MenuItem value={1}>Pending</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                  </Select>
                </Grid>
                <Grid item hidden={!admin} xs={12}>
                  <InputLabel id="lbl-agent">Agent:</InputLabel>
                  <Select
                    labelId="lbl-agent"
                    id="select-agent"
                    name="agent"
                    value={agent}
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>All Agents</MenuItem>
                    {usersResponseQuery.data ? (
                      usersResponseQuery.data.map((a) => {
                        return (
                          <MenuItem key={`agent-${a.id}`} value={a.id}>
                            {a.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        key={`agent-${token.userId}`}
                        value={token.userId}
                      >
                        {`${token.firstName} ${token.lastName}`}
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="lbl-role">Department:</InputLabel>
                  <Select
                    labelId="lbl-role"
                    id="select-role"
                    value={role}
                    name="role"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>All Depts</MenuItem>
                    {roles.map((a) => {
                      return (
                        <MenuItem key={`roles-${a}`} value={a}>
                          {a}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={10}>
        <Card>
          <Box p={2}>
            <Typography component="h3" variant="h6">
              Results
            </Typography>
            {ticketsQuery.isLoading ? (
              <CircularProgress />
            ) : ticketsQuery?.data && ticketsQuery.data.results.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Last Modified</TableCell>
                      <TableCell>Assigned Agent</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketsQuery.data.results.map((r) => {
                      return (
                        <TableRow
                          key={`result-${r.id}`}
                          hover
                          className={
                            dateOneWeekAgo > new Date(r.creationDate) &&
                            r.status !== 2
                              ? classes.warning
                              : ""
                          }
                        >
                          <TableCell>{r.id}</TableCell>
                          <TableCell>{r.title}</TableCell>
                          <TableCell>{getStatus(r.status)}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.creationDate))}
                          </TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.modificationDate))}
                          </TableCell>
                          <TableCell>
                            {r.assignedAgentId != null && r.assignedAgentId > 0
                              ? getAgentName(r.assignedAgentId)
                              : ""}
                          </TableCell>
                          <TableCell>
                            <Link to={`/qms/tickets/${r.id}`}>View</Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 20, 50]}
                  count={ticketsQuery.data.recordCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <Typography variant="h6">No matching results</Typography>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Qms;
