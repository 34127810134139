import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  Breadcrumbs,
  Typography,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Attendance from "./Components/Attendance";
import Dispatch from "./Components/Dispatch";
import Returns from "./Components/Returns";
import Lotting from "./Components/Lottings";
import Refunds from "./Components/Refunds";
import AuctionStatistics from "./Components/AuctionStatistics";
import Picking from "./Components/Picking";
import Collections from "./Components/Collections";
import Inbound from "./Components/Inbound";
import Tickets from "./Components/Tickets";
import Enquiries from "./Components/Enquiries";
import Viewings from "./Components/Viewings";
import { useAuctionHouse } from "../../providers/AuctionHouseProvider";
import { getDecodedAccessToken } from "../../utils/Tokens";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2, 4, 3),
    "@media screen and (max-width: 968px)": {
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      transform: "translate(0, 0)",
    },
  },
  modalButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modalOptionsContainer: {
    padding: "12px",
    cursor: "grab",
    backgroundImage: "url(./icons/drag.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left 0px top 60%",
    transition: "transform 0.5s ease-in-out",
  },
  dragged: {
    backgroundColor: "#f5f5f5",
    transform: "translateX(0)",
  },
  draggedOver: {
    border: "3px dotted black",
  },
}));

const OptionsModal = ({
  open,
  onClose,
  otherOptions,
  permissions,
  handleSave,
}) => {
  const [optionsGroup, setOptionsGroup] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    setOptionsGroup(otherOptions);
  }, [otherOptions]);

  const handleOptionChange = (optionName, checked, index) => {
    if (optionName === "showRefunds") {
      return;
    }
    const newOptions = [...optionsGroup];
    newOptions[index][optionName] = checked;
    setOptionsGroup(newOptions);
  };

  const splitOption = (val) => {
    return val
      .split(/(?=[A-Z])/)
      .map((el, i) => (i === 0 ? "Show" : el))
      .join(" ");
  };

  const onDragStart = (e, index) => {
    setDraggedIndex(index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
    setDraggedOverIndex(index);
  };

  const onDrop = (e, index) => {
    const newOptions = [...optionsGroup];
    const draggedOption = newOptions[draggedIndex];
    newOptions.splice(draggedIndex, 1);
    newOptions.splice(index, 0, draggedOption);
    setOptionsGroup(newOptions);
  };

  const onDragEnd = () => {
    setDraggedIndex(null);
    setDraggedOverIndex(null);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        handleSave(optionsGroup);
      }}
      aria-labelledby="options-modal"
      disableScrollLock={false}
    >
      <Box className={classes.modal}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" gutterBottom>
              Options
            </Typography>
          </Grid>
          {optionsGroup.map((group, index) => {
            if (!permissions?.includes(group.permission)) return null;
            return (
              <Grid
                item
                xs={12}
                key={index}
                draggable
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={(e) => onDragOver(e, index)}
                onDrop={(e) => onDrop(e, index)}
                onDragEnd={onDragEnd}
                className={`${classes.modalOptionsContainer} ${
                  index === draggedIndex ? classes.dragged : ""
                } ${index === draggedOverIndex ? classes.draggedOver : ""}`}
              >
                <Grid container spacing={3}>
                  {Object.keys(group)
                    .slice(0, 2)
                    .map((option) => (
                      <Grid item xs={12} md={5} key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={group[option]}
                              onChange={(e) =>
                                handleOptionChange(
                                  option,
                                  e.target.checked,
                                  index
                                )
                              }
                              name={option}
                              color="primary"
                            />
                          }
                          label={splitOption(option)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} className={classes.modalButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave(optionsGroup)}
          >
            Save
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

const ComponentsList = ({
  otherOptions,
  permissions,
  auctionHouseName,
  currentAuctionHouse,
}) => {
  const [components, setComponents] = useState([
    { name: "Attendance", component: Attendance },
    { name: "Returns", component: Returns },
    { name: "Dispatch", component: Dispatch },
    { name: "Lotting", component: Lotting },
    { name: "Refunds", component: Refunds },
    { name: "AuctionStatistics", component: AuctionStatistics },
    { name: "Picking", component: Picking },
    { name: "Collections", component: Collections },
    { name: "Inbound", component: Inbound },
    { name: "Tickets", component: Tickets },
    { name: "Enquiries", component: Enquiries },
    { name: "Viewings", component: Viewings },
  ]);

  useEffect(() => {
    const order = otherOptions.map((el) => Object.keys(el)[0]);

    let orderedComponents = [];
    order.forEach((el) => {
      orderedComponents.push(
        components.find((comp) => comp.name === el.slice(4))
      );
    });
    setComponents(orderedComponents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherOptions]);

  return (
    <>
      {components.map(
        ({ name, component: Component }, index) =>
          otherOptions[index][`show${name}`] &&
          permissions?.includes(otherOptions[index].permission) && (
            <Component
              key={index}
              auctionHouseName={auctionHouseName}
              currentAuctionHouse={currentAuctionHouse}
              showTable={otherOptions[index][`show${name}Table`]}
            />
          )
      )}
    </>
  );
};

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [otherOptions, setOtherOptions] = useState([
    {
      showAttendance: false,
      showAttendanceTable: false,
      permission: "timesheet",
    },
    {
      showDispatch: false,
      showDispatchTable: false,
      permission: "aoms",
    },
    {
      showReturns: false,
      showReturnsTable: false,
      permission: "qms",
    },
    {
      showLotting: false,
      showLottingTable: false,
      permission: "lotting",
    },
    {
      showRefunds: true,
      showRefundsTable: false,
      permission: "qms: Administrator",
    },
    {
      showAuctionStatistics: false,
      showAuctionStatisticsTable: false,
      permission: "scweb: View Auction Stats",
    },
    {
      showCollections: false,
      showCollectionsTable: false,
      permission: "collections",
    },
    {
      showPicking: false,
      showPickingTable: false,
      permission: "aoms",
    },
    {
      showInbound: false,
      showInboundTable: false,
      permission: "aoms",
    },
    {
      showTickets: false,
      showTicketsTable: false,
      permission: "qms",
    },
    {
      showEnquiries: false,
      showEnquiriesTable: false,
      permission: "scweb: Manage Lot Enquiries",
    },
    {
      showViewings: false,
      showViewingsTable: false,
      permission: "scweb: Manage Viewings",
    },
  ]);
  const [permissions, setPermissions] = useState([]);

  const classes = useStyles();
  const {
    currentAuctionHouse,
    currentAuctionHouseName,
    handleSetCurrentAuctionHouse,
  } = useAuctionHouse();

  useEffect(() => {
    const init = async () => {
      const decodedToken = await getDecodedAccessToken();
      setPermissions([
        ...decodedToken.permissions.applications,
        ...decodedToken.permissions.roles,
      ]);

      const dashboardOptions = JSON.parse(
        localStorage.getItem("dashboardOptions")
      );
      const dashboardAuctionHouse = JSON.parse(
        localStorage.getItem("auctionHouse")
      );
      if (dashboardOptions) {
        if (Object.keys(dashboardOptions).length < otherOptions.length) {
          return;
        }
        setOtherOptions(dashboardOptions);
      }
      if (dashboardAuctionHouse) {
        handleSetCurrentAuctionHouse(dashboardAuctionHouse);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = (modalOptions) => {
    localStorage.setItem("dashboardOptions", JSON.stringify(modalOptions));
    setOtherOptions(modalOptions);
    setShowModal(false);
  };

  return (
    <Grid container spacing={3}>
      <OptionsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        otherOptions={otherOptions}
        permissions={permissions}
        handleSave={handleSave}
      />
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Dashboard</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.modalButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModal(true)}
              >
                Options
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <ComponentsList
        otherOptions={otherOptions}
        permissions={permissions}
        auctionHouseName={currentAuctionHouseName}
        currentAuctionHouse={currentAuctionHouse}
      />
    </Grid>
  );
};

export default Dashboard;
