import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Paper,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";

const UserRegisteredAuctions = () => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(10);

  const { isLoading, error, data } = useQuery(
    [`userRegisteredAuctions:${id}`, page, maximumRows],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/buyers/${id}/registeredauctions?startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  return <>
    {isLoading ? (
      <CircularProgress />
    ) : error ? (
      "An error has occurred: " + error.message
    ) : (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/users">Users</Link>
            <Link to={`/users/${id}`}>Manage User</Link>
            <Link to={`/users/${id}/summary`}>Summary</Link>
            <Typography color="textPrimary">Registered Auctions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              All Registered Auctions
            </Typography>
            {data.totalResults > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>End Time</TableCell>
                      <TableCell>Auctions Ref</TableCell>
                      <TableCell>Title</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.auctions.map((auction) => {
                      return (
                        <TableRow key={auction.auctionRef} hover>
                          <TableCell>{formatTime(auction.endDate)}</TableCell>
                          <TableCell>{auction.auctionRef}</TableCell>
                          <TableCell>{auction.title}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={data.totalResults}
                  rowsPerPage={maximumRows}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            ) : (
              <Grid container item>
                <Typography varaint="body1">
                  No registered auctions for this user.
                </Typography>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    )}
  </>;
};

export default UserRegisteredAuctions;
