import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import HelpIcon from "@mui/icons-material/Help";

const feedbackTypes = Object.freeze({
  sentiment_very_dissatisfied_black_48dp: 1,
  sentiment_dissatisfied_black_48dp: 2,
  sentiment_neutral_black_48dp: 3,
  sentiment_satisfied_black_48dp: 4,
  sentiment_very_satisfied_black_48dp: 5,
});

const useStyles = makeStyles(() => ({
  feedbackIcon: {
    padding: "4px",
    borderRadius: "0.25rem",
  },
  sentiment_very_dissatisfied_black_48dp: {
    background: "#e73100",
  },
  sentiment_dissatisfied_black_48dp: {
    background: "#f68e36",
  },
  sentiment_neutral_black_48dp: {
    background: "#ffce31",
  },
  sentiment_satisfied_black_48dp: {
    background: "#44de80",
  },
  sentiment_very_satisfied_black_48dp: {
    background: "#00bd62",
  },
}));

const UserFeedback = (props) => {
  const classes = useStyles();

  const { id } = useParams();

  const userFeedbackQuery = useQuery(
    [`feedback-${id}`],
    () =>
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/feedback/${id}`)
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  return (
    <>
      {userFeedbackQuery.isLoading ? (
        <CircularProgress />
      ) : userFeedbackQuery.error ? (
        "An error has occurred: " + userFeedbackQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to={`/feedback`}>User Feedback</Link>
              <Typography color="textPrimary">Feedback</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              {userFeedbackQuery.data && (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Invoice ID</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={userFeedbackQuery.data.feedback.id} hover>
                        <TableCell>
                          <img
                            alt="Feedback Type"
                            src={`/icons/${getKeyByValue(
                              feedbackTypes,
                              userFeedbackQuery.data.feedback.type
                            )}.svg`}
                            className={`${classes.feedbackIcon} ${
                              classes[
                                getKeyByValue(
                                  feedbackTypes,
                                  userFeedbackQuery.data.feedback.type
                                )
                              ]
                            }`}
                          />
                        </TableCell>
                        <TableCell>
                          {formatTime(
                            userFeedbackQuery.data.feedback.submittedDate
                          )}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/users/${userFeedbackQuery.data.feedback.userId}`}
                          >
                            {`${userFeedbackQuery.data.feedback.firstName} ${userFeedbackQuery.data.feedback.lastName}`}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {userFeedbackQuery.data.feedback.invoiceId}
                        </TableCell>
                        <TableCell>
                          {userFeedbackQuery.data.feedback.feedback}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={{
                              pathname: `/qms/create`,
                              state: {
                                userId: userFeedbackQuery.data.feedback.userId,
                                invoiceId:
                                  userFeedbackQuery.data.feedback.invoiceId,
                                feedback: `Hi ${
                                  userFeedbackQuery.data.feedback.firstName
                                }\n\n\nYour feedback (submited ${formatTime(
                                  userFeedbackQuery.data.feedback.submittedDate
                                )}): \n\n${
                                  userFeedbackQuery.data.feedback.feedback
                                }`,
                              },
                            }}
                          >
                            <Button startIcon={<HelpIcon />}>Respond</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserFeedback;
