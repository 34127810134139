import {
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Box,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableContainer,
  Link,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback, useEffect, useState } from "react";
import {
  getPreviousWeekSaturday,
  getPreviousWeekSunday,
} from "../utils/date.utils";
import axios from "axios";

const AomsPrintBatches = ({ newPrintBatch }) => {
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [printBatches, setPrintBatches] = useState([]);

  const getPrintBatches = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/printbatches?startRowIndex=${startRowIndex}&maximumRows=${maximumRows}`
      )
      .then((res) => {
        setPrintBatches(res.data.results);
        console.log(res.data.results);
        setTotalRows(res.data.totalResults);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [maximumRows, startRowIndex]);

  useEffect(() => {
    getPrintBatches();
  }, [getPrintBatches]);

  useEffect(() => {
    getPrintBatches();
  }, [startRowIndex, maximumRows, newPrintBatch, getPrintBatches]);

  const downloadCsv = (printBatchId) => {
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/printbatches/${printBatchId}/csv`,
        { responseType: "blob" }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `printbatch_${printBatchId}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Print Batches</Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Printed</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {printBatches.map((printBatch) => (
                      <TableRow key={printBatch.id}>
                        <TableCell>{printBatch.id}</TableCell>
                        <TableCell>
                          {new Date(printBatch.creationDate).toLocaleString(
                            "en-GB"
                          )}
                        </TableCell>
                        <TableCell>{printBatch.name}</TableCell>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={printBatch.printed}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            href={`https://portal.auctionoms.com/accounts/print-batches/${printBatch.id}`}
                            target="_blank"
                            variant="contained"
                            color="primary"
                          >
                            PDF
                          </Link>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => downloadCsv(printBatch.id)}
                          >
                            CSV
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={maximumRows}
                  page={startRowIndex / maximumRows}
                  onPageChange={(event, newPage) => {
                    setStartRowIndex(newPage * maximumRows);
                  }}
                  onRowsPerPageChange={(event) => {
                    setMaximumRows(parseInt(event.target.value, 10));
                    setStartRowIndex(0);
                  }}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const AomsSBIPrintBatcher = () => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [newPrintBatch, setNewPrintBatch] = useState(null);

  const _setStartTime = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    setVendors([]);
    setStartTime(d);
  };

  const _setEndTime = (date) => {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    setVendors([]);
    setEndTime(d);
  };

  useEffect(() => {
    _setStartTime(getPreviousWeekSunday());
    _setEndTime(getPreviousWeekSaturday());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/selfbillinginvoices/vendorsummary?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`
      )
      .then((res) => {
        setVendors(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const createPrintBatch = (vendorId) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_AOMS_API_URL}/printbatches`, {
        vendorId,
        type: 0,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      })
      .then((res) => {
        setLoading(false);
        setNewPrintBatch(res.data.printBatchId);
        setVendors(vendors.filter((v) => v.vendorId !== vendorId));
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Aoms SBI Print Batcher</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">Select Date Range</Typography>
              </Grid>
              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    name="startTime"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    placeholder="yyyy-MM-dd"
                    label="Start Date"
                    value={startTime}
                    onChange={_setStartTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    name="endTime"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    placeholder="yyyy-MM-dd"
                    label="End Date"
                    value={endTime}
                    onChange={_setEndTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {vendors.length > 0 && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6">Vendors</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor ID</TableCell>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Total SBIs</TableCell>
                  <TableCell>Total Hammer</TableCell>
                  <TableCell>Total Due</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendors.map((vendor) => (
                  <TableRow>
                    <TableCell>{vendor.vendorId}</TableCell>
                    <TableCell>{vendor.name}</TableCell>
                    <TableCell>{vendor.totalSbis}</TableCell>
                    <TableCell>{vendor.totalHammer}</TableCell>
                    <TableCell>{vendor.totalDue}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => createPrintBatch(vendor.vendorId)}
                        disabled={loading}
                      >
                        Create Print Batch
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      )}
      <AomsPrintBatches newPrintBatch={newPrintBatch} />
    </Grid>
  );
};

export default AomsSBIPrintBatcher;
