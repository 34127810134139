import axios from "axios";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  Box,
  Breadcrumbs,
  TextField,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Printers = (props) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [printServices, setPrintServices] = useState([]);

  const asBindData = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_PRINTERS_API_URL}/printers`);
      url.searchParams.append("startRowIndex", page * rowsPerPage);
      console.log("rows per page: ", rowsPerPage);
      url.searchParams.append("maximumRows", rowsPerPage);

      setIsLoading(true);
      console.log(url.toString());
      const results = await axios.get(url.toString());
      setIsLoading(false);
      setResults(results.data.results);
      setTotalRecords(results.data.recordCount);
    } catch (err) {
      console.log("binding data error:", err);
    }
  };

  const bindData = useCallback(asBindData, [page, rowsPerPage]);

  useEffect(() => {
    bindData();
  }, [page, rowsPerPage, bindData]);

  const asBindOtherData = async () => {
    const ps = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
    );
    const printServices = ps.data.printerService.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setPrintServices(printServices);
  };

  const bindOtherData = useCallback(asBindOtherData, []);

  const handleChangePage = async (evt, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("rows per page:", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleOnKeyUp = async (evt) => {
    const name = evt.target.name;
    switch (name) {
      case "search":
        if (evt.keyCode === 13) {
          setPage(0);
          await bindData();
        }
        break;
      default:
        return;
    }
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "search":
        setSearch(value);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    bindData();
    bindOtherData();
  }, [bindData, bindOtherData]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Printers</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container alignItems="center" spacing={3} item xs={12}>
            <Grid item>
              <TextField
                name="search"
                label="Search"
                variant="outlined"
                onChange={handleChange}
                onKeyUp={handleOnKeyUp}
                value={search}
              />
            </Grid>
            <Grid item>
              <Link color="primary" to="/printers/create">
                Create New Printer
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={10}>
        <Card>
          <Box p={2}>
            <Typography component="h3" variant="h6">
              Results
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : results && results.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>IP Address</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Modified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((r) => {
                      return (
                        <TableRow key={`result-${r.id}`} hover>
                          <TableCell>{r.id}</TableCell>
                          <TableCell>{r.name}</TableCell>
                          <TableCell>
                            {
                              printServices.find(
                                (ps) => ps.id === r.printServiceId
                              ).name
                            }
                          </TableCell>
                          <TableCell>{r.ipAddress}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.creationDate))}
                          </TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.modificationDate))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 20, 50]}
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <Typography variant="h6">No matching results</Typography>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Printers;
