import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useDebounce from "../../utils/useDebounce";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { utils, writeFileXLSX } from "xlsx";

const VendorTotalInbounds = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [vendorId, setVendorId] = useState();
  const [results, setResults] = useState([]);
  const [vendorSearch, setVendorSearch] = useState("");
  const [debouncedVendorSearch] = useDebounce(vendorSearch, 500);

  const vendorsData = useQuery(
    ["vendors", debouncedVendorSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/vendors?search=${debouncedVendorSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedVendorSearch,
    }
  );

  useEffect(() => {
    if (!startDate || !endDate || !vendorId) return;
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stats/vendors/${vendorId}/estates?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      )
      .then((res) => {
        setResults(res.data.results);
      });
  }, [startDate, endDate, vendorId]);

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999);
    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    setStartDate(date);
  };

  const downloadXlsx = () => {
    const data = results.map((result) => ({
      Year: result.year,
      Week: result.week,
      Count: result.count,
    }));
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Auction House Inbound Stats");
    writeFileXLSX(wb, `Vendor ${vendorId} Inbound Stats.xlsx`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color={"textPrimary"}>Vendor Inbound Stats</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="vendor-search"
                  options={vendorsData.data ? vendorsData.data?.vendors : []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={vendorSearch}
                  onChange={(e, value) => setVendorId(value.id)}
                  onInputChange={(e, value) => setVendorSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search vendor" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Auction End Date From:"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Auction End Date Till:"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button variant="outlined" onClick={downloadXlsx}>
                  Download XLSX
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={`${result.year}-${result.week}`}>
                    <TableCell>{result.year}</TableCell>
                    <TableCell>{result.week}</TableCell>
                    <TableCell>{result.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VendorTotalInbounds;
