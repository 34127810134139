import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  Collapse,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyle = makeStyles(() => ({
  dropDown: {
    paddingBottom: "0px!important",
    paddingTop: "0px!important",
  },
  checkBoxContainer: {
    paddingTop: "0px!important",
  },
}));

const ReportEstatesCost = () => {
  const [showGenerateReport, setShowGenerateReport] = useState(false);
  const [estateNo, setEstateNo] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const classes = useStyle();

  const { data, isLoading, error, refetch } = useQuery(
    "asCosts",
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/estates/costs-ended?page=${
            page * rowsPerPage
          }&count=${rowsPerPage}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const generateReportMutation = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/estates/${estateNo}/generate-report`
      ),
    {
      onSettled: (data, error) => {
        if (data) {
          setOpenSuccess(true);
          refetch();
        }
        if (error) {
          setOpenError(true);
        }
      },
    }
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "checkbox":
        setShowGenerateReport(!showGenerateReport);
        break;
      case "estateNo":
        setEstateNo(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Calculate Cost</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} className={classes.checkBoxContainer}>
        <Box p={2} component={Paper}>
          <FormControlLabel
            control={
              <Checkbox
                name="checkbox"
                checked={showGenerateReport}
                onChange={handleChange}
              />
            }
            label="Generate Estate Cost Report"
          />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.dropDown}>
        <Collapse in={showGenerateReport}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Estate No"
                  type="text"
                  variant="outlined"
                  name="estateNo"
                  value={estateNo}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateReportMutation.mutate}
                >
                  Generate Report
                </Button>
                {generateReportMutation.isLoading && (
                  <CircularProgress size={24} style={{ marginLeft: "1rem" }} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            "An error has occurred: " + error.message
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Estate No</TableCell>
                    <TableCell>Vendor Reference</TableCell>
                    <TableCell>Total Lots</TableCell>
                    <TableCell>Total Hours</TableCell>
                    <TableCell>Average Hours</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Average Cost</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.result.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.estateNo}</TableCell>
                      <TableCell>{el.vendorReference}</TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                      <TableCell>{el.totalHours}</TableCell>
                      <TableCell>
                        {(el.totalHours / (el.totalLots || 1)).toFixed(2)}
                      </TableCell>
                      <TableCell>£{el.totalCost}</TableCell>
                      <TableCell>
                        £{(el.totalCost / (el.totalLots || 1)).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <Link to={`/reports/estates-cost/${el.estateNo}`}>
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Estate cost report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="An error has occurred"
      />
    </Grid>
  );
};

export default ReportEstatesCost;
