const imageArray = [
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 12_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 12_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 1_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 1_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 2_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 2_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 2_40PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 3_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 4_00M.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 4_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 5_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 6_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 6_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 7_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 7_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 8_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 8_30PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 9_00PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 9_15PM.jpg",
  "https://scwebv7storage.blob.core.windows.net/notifications-images/Ends From 9_30PM.jpg",
];
export default imageArray;
