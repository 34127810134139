import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

const SortOptions = {
  AUCTION_HOUSE: { asc: 0, desc: 1 },
  TITLE: { asc: 2, desc: 3 },
  START_TIME: { asc: 4, desc: 5 },
  END_TIME: { asc: 6, desc: 7 },
};

const minusOneYear = (date) => {
  let newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() - 1);
  return newDate;
};

const Auctions = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [orderBy, setOrderBy] = useState("END_TIME");
  const [order, setOrder] = useState("desc");
  const startDate = minusOneYear(new Date(new Date().getFullYear(), 0, 1));

  const auctionsQuery = useQuery(
    ["scAuctions", page, rowsPerPage, orderBy, order],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AUCTION_API_URL
          }/auctions?startTime=${startDate}&maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }&sort=${SortOptions[orderBy][order]}`
        )
        .then((res) => {
          setTotalRows(res.data.totalResults);
          return res.data.auctions;
        }),
    { keepPreviousData: true }
  );

  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const categoriesQuery = useQuery(
    "scCategories",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/categories?full=true`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setSortPreference = (key) => {
    if (orderBy === key) {
      switch (order) {
        case "asc":
          setOrder("desc");
          break;
        case "desc":
          setOrder("asc");
          break;
        default:
          break;
      }
    } else {
      setOrderBy(key);
    }
  };

  const isLoading =
    auctionHousesQuery.isLoading ||
    auctionsQuery.isLoading ||
    categoriesQuery.isLoading;
  const error =
    auctionHousesQuery.error || auctionsQuery.error || categoriesQuery.error;

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Auctions</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">Manage Auctions</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Link to="/auctions-new">
                        <Button variant="outlined" startIcon={<AddIcon />}>
                          Create New Auction
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "AUCTION_HOUSE"}
                          direction={order}
                          onClick={() => setSortPreference("AUCTION_HOUSE")}
                        >
                          Auction House
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "TITLE"}
                          direction={order}
                          onClick={() => setSortPreference("TITLE")}
                        >
                          Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Primary Category</TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "START_TIME"}
                          direction={order}
                          onClick={() => setSortPreference("START_TIME")}
                        >
                          Starts
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "END_TIME"}
                          direction={order}
                          onClick={() => setSortPreference("END_TIME")}
                        >
                          Ends
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Online Only</TableCell>
                      <TableCell>Postal Only</TableCell>
                      <TableCell>Premium</TableCell>
                      <TableCell>View/Edit Auction</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auctionsQuery.data.map((auction) => (
                      <TableRow key={auction.id} hover>
                        <TableCell>{auction.id}</TableCell>
                        <TableCell>
                          {
                            auctionHousesQuery.data.filter(
                              (auctionHouse) =>
                                auctionHouse.id === auction.auctionHouseId
                            )[0].name
                          }
                        </TableCell>
                        <TableCell>{auction.title}</TableCell>
                        <TableCell>
                          {
                            categoriesQuery.data.filter(
                              (category) =>
                                category.id === auction.primaryCategoryId
                            )[0]?.name
                          }
                        </TableCell>
                        <TableCell>{formatTime(auction.startTime)}</TableCell>
                        <TableCell>{formatTime(auction.endTime)}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={auction.onlineOnly}
                            readOnly
                            disabled
                            inputProps={{
                              "aria-label": "online only checkbox",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={auction.postalOnly}
                            readOnly
                            disabled
                            inputProps={{
                              "aria-label": "postal only checkbox",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={auction.premium}
                            readOnly
                            disabled
                            inputProps={{
                              "aria-label": "premium checkbox",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Link to={`/auctions/${auction.id}`}>
                            <Button startIcon={<VisibilityIcon />}>View</Button>
                          </Link>
                          /
                          <Link to={`/auctions/${auction.id}/edit`}>
                            <Button startIcon={<EditIcon />}>edit</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Auctions;
