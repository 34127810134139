import {
  Grid,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Alert } from "@mui/lab";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getKeyByValue } from "../utils/getKeyByValue";
import { Link } from "react-router-dom";

const pickOrderLotStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  ISSUE: 2,
});

const StockCheckPickOrder = (props) => {
  const { pickOrderId } = useParams();

  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/stocks/check/${pickOrderId}`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
        setErrorMessage("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error:", err);
        if (err.response.status === 404) {
          setErrorMessage(`Pick order not found`);
        } else if (err.response.status === 400) {
          setErrorMessage(`Bad pick order ID`);
        } else {
          setErrorMessage(`Error checking pick order`);
        }
        setData(null);
        setIsLoading(false);
      });
  }, [pickOrderId]);

  return (
    <Grid container spacing={6}>
      {isLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : errorMessage ? (
        <Grid item xs={12}>
          <Alert severity="warning">{errorMessage}</Alert>
        </Grid>
      ) : (
        data && (
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Linked Pick Order Lots
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Lot Number</TableCell>
                      <TableCell>Pick Issue</TableCell>
                      <TableCell>Pick Order Lot Status</TableCell>
                      <TableCell>Container</TableCell>
                      <TableCell>Sortation Container</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.pickOrderLots.map((pickOrderLot) => {
                      return (
                        <TableRow
                          key={pickOrderLot.lotId}
                          hover
                          component={Link}
                          style={{ cursor: "pointer" }}
                          to={`/stock-check?id=${pickOrderLot.stockId}`}
                        >
                          <TableCell>{pickOrderLot.lotNo || "-"}</TableCell>
                          <TableCell>
                            <Checkbox
                              checked={pickOrderLot.pickIssueId !== null}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {pickOrderLot.pickOrderLotStatus
                              ? getKeyByValue(
                                  pickOrderLotStatuses,
                                  pickOrderLot.pickOrderLotStatus
                                )
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {pickOrderLot.containerName || "-"}
                          </TableCell>
                          <TableCell>
                            {pickOrderLot.sortationContainerName || "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default StockCheckPickOrder;
