import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AuctionStatuses from "../constants/AuctionStatuses";
import {
  getPreviousWeekSaturday,
  getPreviousWeekSunday,
} from "../utils/date.utils";
import axios from "axios";

const {
  Box,
  Grid,
  Paper,
  Breadcrumbs,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} = require("@mui/material");
const { useState, useEffect } = require("react");
const CloseAuctionJobStatuses = require("../constants/closeauctionjobstatuses");

const CreateNewJob = ({ created, setCreated }) => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [auctionHouseId, setAuctionHouseId] = useState(0);
  const [checked, setChecked] = useState(false);
  const [auctions, setAuctions] = useState([]);

  const [auctionHouses, setAuctionHouses] = useState([]);

  const _setStartTime = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    setStartTime(d);
    setChecked(false);
  };
  const _setEndTime = (date) => {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    setEndTime(d);
    setChecked(false);
  };
  const _setAuctionHouseId = (value) => {
    setAuctionHouseId(value);
    setChecked(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => {
        setAuctionHouses(res.data);
        _setStartTime(getPreviousWeekSunday());
        _setEndTime(getPreviousWeekSaturday());
      });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checked) {
      setError("Please check the auctions to confirm.");
      return;
    }
    setIsSubmitting(true);
    setError(null);
    if (startTime > endTime) {
      setError("Start time cannot be after end time");
      setIsSubmitting(false);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_AOMS_API_URL}/closeauctionjobs`, {
        startTime,
        endTime,
        auctionHouseId: auctionHouseId > 0 ? auctionHouseId : null,
      })
      .then((res) => {
        setIsSubmitting(false);
        setCreated(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setIsSubmitting(false);
      });
  };

  const getAuctionData = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/auctions?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}${
          auctionHouseId > 0 ? `&auctionHouseId=${auctionHouseId}` : ``
        }`
      )
      .then((res) => {
        setAuctions(res.data);
        setChecked(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const getAuctionStatus = (status) => {
    switch (status) {
      case AuctionStatuses.PRE_SALE:
        return "Pre-Sale";
      case AuctionStatuses.POST_SALE:
        return "Post-Sale";
      case AuctionStatuses.CLOSED:
        return "Closed";
      case AuctionStatuses.CANCELLED:
        return "Cancelled";
      default:
        return "Unknown";
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Create New Job</h1>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Box color="error.main">{error}</Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="action-house-id">
                    Action House
                  </InputLabel>
                  <Select
                    labelId="action-house-id"
                    label="Action House"
                    value={auctionHouseId}
                    onChange={(e) => _setAuctionHouseId(e.target.value)}
                    required
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>

                    {auctionHouses.map((auctionHouse) => (
                      <MenuItem key={auctionHouse.id} value={auctionHouse.id}>
                        {auctionHouse.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    name="startTime"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    placeholder="yyyy-MM-dd"
                    label="Start Date"
                    value={startTime}
                    onChange={_setStartTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    name="endTime"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    placeholder="yyyy-MM-dd"
                    label="End Date"
                    value={endTime}
                    onChange={_setEndTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {checked ? (
                <>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Auction ID</TableCell>
                            <TableCell>Ref</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {auctions.map((auction) => (
                            <TableRow key={auction.id}>
                              <TableCell>
                                <a
                                  href={`https://portal.auctionoms.com/accounts/auctions/${auction.id}/edit`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {auction.id}
                                </a>
                              </TableCell>
                              <TableCell>{auction.auctionRef}</TableCell>
                              <TableCell>{auction.title}</TableCell>
                              <TableCell>
                                {new Date(auction.startDate).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {new Date(auction.endDate).toLocaleString()}
                              </TableCell>
                              <TableCell
                                className={
                                  auction.status !== AuctionStatuses.POST_SALE
                                    ? `tableError`
                                    : ``
                                }
                              >
                                {getAuctionStatus(auction.status)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Create Job
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Button
                      onClick={getAuctionData}
                      variant="contained"
                      color="primary"
                    >
                      Get Auction Data
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box color="text.secondary">
                  <b>Note: </b>Auctions will be ignored from this service if any
                  of the below is true:
                  <ul>
                    <li>Auction Status is not set to POST SALE / Sale Ready</li>
                    <li>Auction has outstanding invoices</li>
                    <li>Auction has unverified self billing invoices</li>
                    <li>Auction has any lots awaiting sale</li>
                    <li>Auction has any outstanding lot edit requests</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

const AomsCloseAuctionList = ({ created }) => {
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/closeauctionjobs?startRowIndex=${
          page * limit
        }&maximumRows=${limit}`
      )
      .then((res) => {
        setJobs(res.data.results);
        setTotalRows(res.data.totalResults);
      });
  }, [page, limit, created]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const getJobStatus = (status) => {
    switch (status) {
      case CloseAuctionJobStatuses.FAILED:
        return "Failed";
      case CloseAuctionJobStatuses.PENDING:
        return "Pending";
      case CloseAuctionJobStatuses.PROCESSING:
        return "Processing";
      case CloseAuctionJobStatuses.SUCCESS:
        return "Success";
      default:
        return "Unknown";
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <h1>Close Auction Jobs</h1>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Auction House</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs.map((job) => (
                  <TableRow key={job.id}>
                    <TableCell>
                      {new Date(job.startTime).toDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(job.endTime).toDateString()}
                    </TableCell>
                    <TableCell>{job.auctionHouse?.name}</TableCell>
                    <TableCell>{job.creationUserId}</TableCell>
                    <TableCell>{getJobStatus(job.status)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`/aoms/close-auctions/${job.id}`}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={limit}
            onRowsPerPageChange={handleLimitChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const AomsCloseAuctions = () => {
  const [created, setCreated] = useState(false);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Close Auctions</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <CreateNewJob created={created} setCreated={setCreated} />
      </Grid>
      <Grid item xs={12}>
        <AomsCloseAuctionList created={created} setCreated={setCreated} />
      </Grid>
    </Grid>
  );
};

export default AomsCloseAuctions;
