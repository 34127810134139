import { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Checkbox,
  Collapse,
  FormControlLabel,
  CircularProgress,
  Card,
  CardActionArea,
  TextField,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Note from "@mui/icons-material/Note";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import useTimer from "../utils/useTimer";
import { makeStyles } from "@mui/styles";
import { getDecodedAccessToken } from "../utils/Tokens";
import { getKeyByValue } from "../utils/getKeyByValue";
import { formatTime } from "../utils/formatTime";

import { v4 as uuidv4 } from "uuid";
import {
  websocketConnect,
  websocketAddCustomersQueListeners,
  websocketAddObserver,
  websocketRemoveObserver,
} from "../utils/websockets";

/*const collectionPoint = Object.freeze({
  collectionPointA: 0, // Collection for large items. Expected to be slow
  collectionPointB: 1, // Collection for small items or one large item. Expected to be fast
});*/
const status = Object.freeze({
  CREATED: 0,
  PENDING: 1,
  ISSUES: 2,
  PICKED: 3,
  FINAL: 4, // For collection in ISSUES that won't be complete
  HANDOVER_START: 5,
  HANDOVER_COMPLETE: 6,
});

const pickOrderLotsStatus = Object.freeze({
  PENDING: 0,
  PICKED: 1,
  ISSUES: 2,
});

const useStyles = makeStyles((theme) => ({
  invoiceContainer: {
    display: "flex",
    marginBottom: "2px",
    marginTop: "2px",
    padding: "15px",
    border: "1px solid gray",
    borderRadius: "20px",
    color: "white",
  },
  invoiceCard: {
    boxShadow: "none",
  },
  arrow: {
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  },
  addBtn: {
    "&:hover": {
      color: "green",
      cursor: "pointer",
    },
  },
  tableHead: {
    padding: "0px!important",
  },
  dropDown: {
    paddingTop: "0px!important",
    paddingBottom: "0px!important",
  },
  waiting: { background: "#445a3e" },
  issues: { background: "purple", display: "flex", alignItems: "center" },
  nextInvoiceContainer: { background: "#f2a900", color: "black" },
  pending: { background: "navy" },
  que: { border: "1px solid", borderRadius: "20px" },
  variablesContainer: { display: "flex" },
  variablesButtons: {
    display: "flex",
    width: "70px",
    justifyContent: "center",
  },
  wsAlert: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    marginLeft: "15%",
    top: "60px",
    width: "50%",
    zIndex: 99,
  },
  variablesContainerBox: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  createdContainer: {
    backgroundColor: "#445a3e",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#2e3e2e",
    },
  },
  createdCell: {
    color: "white",
    fontSize: "16px",
  },
  pendingContainer: {
    backgroundColor: "navy",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3131d7",
    },
  },
  pendingCell: {
    color: "white",
    fontSize: "16px",
  },

  pickIssuesContainer: {
    borderRadius: "10px",
    backgroundColor: "purple",
    "&:hover": {
      backgroundColor: "#8f018f",
    },
  },
  pickIssues: {
    color: "white",
    fontSize: "16px",
  },
  pickIssuesOptions: {
    display: "flex",
  },
  border: {
    borderRight: "1px solid",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2, 4, 3),
  },
  modalDates: {
    fontSize: "18px",
  },
  modalCompleteBtn: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
}));

const InvoiceDetailsModal = ({
  open,
  onClose,
  invoiceNo,
  setOpenError,
  setOpenSuccess,
  refetch,
}) => {
  const [reason, setReason] = useState("");

  const classes = useStyles();
  const { data, isLoading, error } = useQuery(
    "asInvoiceDetails",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/invoice/${invoiceNo}`
        )
        .then((res) => res.data),
    {
      enabled: open === true && invoiceNo !== "",
    }
  );

  // const completeCollectionMutation = useMutation(
  //   () =>
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_AOMS_API_URL}/collections/update-status/4`,
  //         {
  //           invoiceId: invoiceNo,
  //         }
  //       )
  //       .then((res) => res.data),
  //   {
  //     onSettled: (data, error) => {
  //       if (data) {
  //         refetch();
  //         setOpenSuccess(true);
  //       }
  //       if (error) setOpenError(true);
  //     },
  //   }
  // );

  const suspendCollectionMutation = useMutation(
    () =>
      axios
        .put(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/suspend/${invoiceNo}`,
          {
            reason: reason,
          }
        )
        .then((res) => res.data),
    {
      onSettled: (data, error) => {
        if (data) {
          refetch();
          setOpenSuccess(true);
        }
        if (error) setOpenError(true);
      },
    }
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="invoiceDetailsModal"
      disableScrollLock={true}
    >
      <Box className={classes.modal}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.modalDates}>
              InvoiceNo: {invoiceNo}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.modalDates}>
              InvoiceDate: {formatTime(data?.dates[0]?.invoiceDate)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.modalDates}>
              ColDeadLine:
              {formatTime(data?.dates[0]?.collectionDeadline)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.modalDates}>
              AuctionEndDate: {formatTime(data?.dates[0]?.endDate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="reason"
            label="Reason For Suspending Collection"
            multiline
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className={classes.modalCompleteBtn}>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => completeCollectionMutation.mutate()}
          >
            Complete Collection
          </Button> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => suspendCollectionMutation.mutate()}
            style={{ marginLeft: "10px" }}
          >
            Suspend Collection
          </Button>
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          `An error has occurred: ${error.message}`
        ) : !data ? (
          "No data"
        ) : (
          <Grid container spacing={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>StockId</TableCell>
                  <TableCell>LotNo</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Container</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Collected</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <a
                        href={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                          alt={item.productTitle}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </a>
                    </TableCell>
                    <TableCell>{item.stockId}</TableCell>
                    <TableCell>{item.lotNo}</TableCell>
                    <TableCell>{item.productTitle}</TableCell>
                    <TableCell>{item.containerName}</TableCell>
                    <TableCell>
                      {getKeyByValue(pickOrderLotsStatus, item.status)}
                    </TableCell>
                    <TableCell>
                      {item.stockStatus === 1 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {item.modificationDate
                        ? formatTime(item.modificationDate)
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

const NextInvoice = ({ data, showModal, setInvoiceNo }) => {
  const classes = useStyles();
  const [time] = useTimer(data.creationDate, "up");

  return (
    <Card
      spacing={4}
      className={classes.invoiceCard}
      onClick={() => {
        showModal(true);
        setInvoiceNo(data.invoiceId);
      }}
    >
      <CardActionArea
        className={`${classes.invoiceContainer} ${classes.nextInvoiceContainer}`}
      >
        <Grid item xs={1}>
          <Typography align="center" className={`${classes.que}`}>
            {data.quePosition + 1}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.border}>
          <Typography align="center">{data.customerName}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.border}>
          <Typography align="center" component="div">
            <Box fontWeight="bold" display="inline" fontSize="large">
              {data.invoiceId}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="center">{time}</Typography>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

const CollectionsComponent = ({ data, showModal, setInvoiceNo }) => {
  const [containerStyle, setContainerStyle] = useState();

  const classes = useStyles();
  const [time] = useTimer(data.creationDate, "up");

  useEffect(() => {
    if (data) {
      if (data.status === status.CREATED)
        setContainerStyle(classes.createdContainer);
      if (data.status === status.PENDING)
        setContainerStyle(classes.pendingContainer);
    }
  }, [classes.createdContainer, classes.pendingContainer, data]);

  return (
    <Card
      className={classes.invoiceCard}
      onClick={() => {
        showModal(true);
        setInvoiceNo(data.invoiceId);
      }}
    >
      <CardActionArea
        className={`${classes.invoiceContainer} ${containerStyle}`}
      >
        <Grid item xs={1}>
          <Typography align="center" className={`${classes.que}`}>
            {data.quePosition + 1}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.border}>
          <Typography align="center">{data.customerName}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.border}>
          <Typography align="center" component="div">
            {data.pickerName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="center">{time}</Typography>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

const HandoverComponent = ({ data, showModal, setInvoiceNo }) => {
  const [containerStyle, setContainerStyle] = useState();
  const [time] = useTimer(data.handoverDate);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      if (data.status === status.PICKED || data.status === status.FINAL)
        setContainerStyle(classes.createdContainer);
      if (data.status === status.HANDOVER_START)
        setContainerStyle(classes.pendingContainer);
    }
  }, [classes.createdContainer, classes.pendingContainer, data]);

  return (
    <Card
      className={classes.invoiceCard}
      onClick={() => {
        showModal(true);
        setInvoiceNo(data.invoiceId);
      }}
    >
      <CardActionArea
        className={`${classes.invoiceContainer} ${containerStyle}`}
      >
        <Grid item xs={4} className={classes.border}>
          <Typography align="center">{data.customerName}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.border}>
          <Typography align="center" component="div">
            {data.handoverPickerName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="center">{time}</Typography>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

const CustomersQueue = () => {
  const classes = useStyles();

  const [showIssues, setShowIssues] = useState(false);
  const [nextInvoice, setNextInvoice] = useState();
  const [pickingCollections, setPickingCollections] = useState([]);
  const [handoverCollections, setHandoverCollections] = useState([]);
  const [updatedCollection, setUpdatedCollection] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [wsConnectionLost, setWsConnectionLost] = useState(false);
  const [showInvoiceDetailsModal, setShowInvoiceDetailsModal] = useState(false);
  const [modalInvoiceNo, setModalInvoiceNo] = useState("");
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);

  const decodedToken = getDecodedAccessToken();
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch } = useQuery("asCollections", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/collections/all`)
      .then((res) => res.data)
  );

  const token = useQuery("asToken", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/collections/get-token/1111`)
      .then((res) => res.data.token)
  );

  const pickOrderLots = useQuery(
    "asPickOrderLots",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/get-pick-order-lots`
        )
        .then((res) => res.data),
    {
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (token && token.data && !isWebsocketConnected) {
      websocketConnect(token.data, setWsConnectionLost);
      setIsWebsocketConnected(true);
    }
  }, [isWebsocketConnected, token]);

  useEffect(() => {
    const handleWsUpdate = (WsData) => {
      setUpdatedCollection(WsData);
    };

    const uuid = uuidv4();
    websocketAddObserver(
      [decodedToken.userId + "-admin"],
      handleWsUpdate,
      uuid
    );
    websocketAddCustomersQueListeners([decodedToken.userId]);

    return () => {
      websocketRemoveObserver([decodedToken.userId + "-admin"], uuid);
      // websocketRemoveCustomersQueListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Sort the received data
    if (data) {
      if (data.results.length === 0) return;
      const picking = [];
      const handover = [];

      data.results.forEach((item) => {
        if (item.status < status.ISSUES) picking.push(item);
        if (
          item.status > status.ISSUES &&
          item.status !== status.HANDOVER_COMPLETE
        ) {
          handover.push(item);
        }
      });
      handover.sort((a, b) => a.handoverDate - b.handoverDate);
      setPickingCollections(picking);
      setHandoverCollections(handover);
    }
  }, [data]);

  useEffect(() => {
    if (pickingCollections.length === 0) {
      return setNextInvoice([]);
    }
    let isUserInvoicePending = false;
    const nextInvoice = pickingCollections.filter(
      (el) => el.status !== status.PENDING
    );

    for (let i = 0; i < nextInvoice.length; i++) {
      if (nextInvoice[i]) {
        isUserInvoicePending =
          pickingCollections.filter(
            (el) =>
              el.status === status.PENDING &&
              el.userId === nextInvoice[i].userId
          ).length > 0;

        if (!isUserInvoicePending) {
          return setNextInvoice(nextInvoice[i]);
        }
      }
    }

    if (nextInvoice[0]) return setNextInvoice(nextInvoice[0]);
    setNextInvoice();
  }, [pickingCollections, data]);

  const pickOrderLotsMutation = useMutation(
    (params) =>
      axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/collections/get-pick-order-lots/${params.invoiceId}`
      ),
    {
      onSettled: (data, error) => {
        if (error) {
          setOpenError(true);
        }
        if (data) {
          const newData = [...pickOrderLots.data.result, ...data.data.result];
          queryClient.setQueryData("asPickOrderLots", { result: newData });
        }
      },
    }
  );

  useEffect(() => {
    if (updatedCollection && updatedCollection.status === status.ISSUES) {
      pickOrderLotsMutation.mutate({ invoiceId: updatedCollection.invoiceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedCollection]);

  useEffect(() => {
    //Update the collections data when receiving data through the websocket
    if (updatedCollection) {
      const index = data.results.findIndex(
        (el) => el.id === updatedCollection.collectionId
      );
      if (index === -1) {
        // refetch();
      } else {
        queryClient.setQueryData("asCollections", (old) => {
          return {
            results: old.results.map((el) => {
              if (el.id === updatedCollection.collectionId) {
                return {
                  ...el,
                  quePosition: updatedCollection.quePosition,
                  status: updatedCollection.status,
                  bay: updatedCollection.bay,
                  pickerName:
                    updatedCollection.status === status.PENDING
                      ? updatedCollection.picker
                      : el.pickerName,
                  handoverPickerName:
                    updatedCollection.status === status.HANDOVER_START
                      ? updatedCollection.picker
                      : el.handoverPickerName,
                };
              }
              return el;
            }),
          };
        });
        setUpdatedCollection(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedCollection]);

  const updateMissingLotsMutation = useMutation(
    (params) =>
      axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/collections/${params.invoiceId}`,
        { pickOrderLotId: params.pickOrderLotId, itemFound: params.itemFound }
      ),
    {
      onSettled: (data, error) => {
        if (error) setOpenError(true);
        if (data) {
          const i = pickOrderLots.data.result.findIndex(
            (el) => el.pickOrderLotId === data.data.pickOrderLotId
          );
          pickOrderLots.data.result.splice(i, 1);
          const newData = [...pickOrderLots.data.result];
          queryClient.setQueryData("asPickOrderLots", { result: newData });
          setOpenSuccess(true);
        }
      },
    }
  );

  const createTicketMutation = useMutation(
    (params) =>
      axios.post(`${process.env.REACT_APP_QMS_API_URL}/tickets`, params),
    {
      onSuccess: () => {
        setOpenSuccess(true);
      },
      onError: () => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={3}>
      <InvoiceDetailsModal
        open={showInvoiceDetailsModal}
        onClose={() => setShowInvoiceDetailsModal(false)}
        invoiceNo={modalInvoiceNo}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        refetch={refetch}
      />
      {wsConnectionLost && (
        <Alert severity="error" className={classes.wsAlert}>
          <AlertTitle>Error</AlertTitle>
          You are no longer receiving live updates, please refresh your page to
          receive live updates.
        </Alert>
      )}
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Queue</Typography>
        </Breadcrumbs>
      </Grid>
      {isLoading && <CircularProgress />}
      {error && `An error has occurred: ${error.message}`}

      <Grid item xs={12}>
        <Box p={1} component={Paper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showIssues}
                onChange={(e) => setShowIssues(e.target.checked)}
              />
            }
            label={`Show Missing Lots - ${pickOrderLots.data?.result.length} lots`}
          />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.dropDown}>
        <Collapse in={showIssues}>
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>InvoiceNo</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>PickingStart</TableCell>
                    <TableCell>CollectionDeadline</TableCell>
                    <TableCell>Picker</TableCell>
                    <TableCell>Issue</TableCell>
                    <TableCell>Options</TableCell>
                    <TableCell>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pickOrderLots.data?.result.map((el) => (
                    <TableRow
                      key={el.pickOrderLotId}
                      className={classes.pickIssuesContainer}
                    >
                      <TableCell className={classes.pickIssues}>
                        {el.invoiceId}
                      </TableCell>
                      <TableCell className={classes.pickIssues}>
                        {el.surname} {el.forename}
                      </TableCell>
                      <TableCell className={classes.pickIssues}>
                        {formatTime(el.pickingStart)}
                      </TableCell>
                      <TableCell className={classes.pickIssues}>
                        {formatTime(el.collectionDeadline)}
                      </TableCell>
                      <TableCell className={classes.pickIssues}>
                        {el.pickerName}
                      </TableCell>
                      <TableCell className={classes.pickIssues}>
                        {el.pickIssue}
                      </TableCell>
                      <TableCell>
                        <Grid item className={classes.pickIssuesOptions}>
                          <HighlightOffIcon
                            color="error"
                            fontSize="large"
                            className={classes.arrow}
                            onClick={() =>
                              updateMissingLotsMutation.mutate({
                                invoiceId: el.invoiceId,
                                pickOrderLotId: el.pickOrderLotId,
                                itemFound: false,
                              })
                            }
                          />
                          <CheckCircleOutlineIcon
                            className={classes.addBtn}
                            color="primary"
                            fontSize="large"
                            onClick={() =>
                              updateMissingLotsMutation.mutate({
                                invoiceId: el.invoiceId,
                                pickOrderLotId: el.pickOrderLotId,
                                itemFound: true,
                              })
                            }
                          />
                          {decodedToken.permissions.roles.includes(
                            "qms: Administrator"
                          ) && (
                            <Note
                              className={classes.addBtn}
                              color="textPrimary"
                              fontSize="large"
                              onClick={() => {
                                //Create Ticket
                                const ticket = createTicketMutation.mutateAsync(
                                  {
                                    title: `Refund`,
                                    invoiceId: el.invoiceId,
                                    topicId: 1, //Other
                                    status: 0,
                                    requester: {
                                      authUserId: el.authUserId,
                                      email: el.email,
                                      firstName: el.forename,
                                      lastName: el.surname,
                                    },
                                    refundRequired: false,
                                    assignedAgentId: decodedToken.userId,
                                    assignedRole: "dept: Stanley Court",
                                    conversation: {
                                      type: 0,
                                      message: `Dear Customer,
                                          A ticket for invoice ${el.invoiceId}, lot No ${el.lotNo} has been created. We are investigating the issue and one of our agents will be in touch with you shortly.
                                          Kind regards
                                          Simon Charles Auctioneers`,
                                    },
                                    internalConversation: `Ticket created by ${decodedToken.firstName} ${decodedToken.lastName} from the Portal Queue Screen. The issues is ${data.pickIssue}`,
                                  }
                                );

                                ticket.then((res) => {
                                  if (res.data) {
                                    updateMissingLotsMutation.mutate({
                                      invoiceId: el.invoiceId,
                                      pickOrderLotId: el.pickOrderLotId,
                                      itemFound: false,
                                    });
                                  }
                                });
                              }}
                            />
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/stock-check?id=${el.stockId}`}
                          target="_blank"
                          className={classes.pickIssues}
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={6}>
        {pickingCollections.length > 0 && (
          <>
            {nextInvoice && (
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <Box p={3} component={Paper}>
                  <NextInvoice
                    data={nextInvoice}
                    showModal={() => setShowInvoiceDetailsModal(true)}
                    setInvoiceNo={setModalInvoiceNo}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <Typography variant="h6">
                  Picking - {pickingCollections.length}{" "}
                  {pickingCollections.length === 1 ? "invoice" : "invoices"}
                </Typography>
                {pickingCollections.length > 0 &&
                  pickingCollections.map((el) => (
                    <CollectionsComponent
                      key={el.id}
                      data={el}
                      showModal={() => setShowInvoiceDetailsModal(true)}
                      setInvoiceNo={setModalInvoiceNo}
                    />
                  ))}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {handoverCollections.length > 0 && (
          <Box p={3} component={Paper}>
            <Typography variant="h6">
              Handover - {handoverCollections.length}{" "}
              {handoverCollections.length === 1 ? "invoice" : "invoices"}
            </Typography>
            {handoverCollections.map((el) => (
              <HandoverComponent
                key={el.id}
                data={el}
                showModal={() => setShowInvoiceDetailsModal(true)}
                setInvoiceNo={setModalInvoiceNo}
              />
            ))}
          </Box>
        )}
      </Grid>

      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Changes Saved"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default CustomersQueue;
