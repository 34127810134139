import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";

const isMobile = window.innerWidth < 600;

const useStyles = makeStyles(() => ({
  fullwidthContent: {
    width: isMobile ? "100%" : "50%",
  },
}));

const CategoryImages = (props) => {
  const classes = useStyles();

  const { name } = props.location.state;
  const { id } = useParams();

  const categoryQuery = useQuery("scCategory", () =>
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/categories/${id}`)
      .then((res) => res.data)
  );

  const [imageSrc, setImageSrc] = useState("");
  const [priority, setPriority] = useState(2);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selected, setSelected] = useState([]);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "uploadImage":
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => setImageSrc(reader.result);
          reader.onerror = () =>
            console.error(`Error reading file: ${reader.error}`);
        } else {
          setImageSrc("");
        }
        break;
      case "priority":
        setPriority(value);
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const data = {
      priority,
      base64Image: imageSrc,
    };

    axios
      .post(
        `${process.env.REACT_APP_AUCTION_API_URL}/categories/${id}/images`,
        data
      )
      .then((res) => {
        setOpenSuccess(true);
        setImageSrc("");
        setPriority(2);
        setErrorMessage("");
        categoryQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 413) {
          setErrorMessage("Image must be smaller than 20mb");
        }
      });
  };

  const handleDelete = () => {
    selected.forEach((imageId) => {
      axios
        .delete(
          `${process.env.REACT_APP_AUCTION_API_URL}/categories/${id}/images/${imageId}`
        )
        .then((res) => {
          categoryQuery.refetch();
          setOpenSuccess(true);
          setSelected([]);
        })
        .catch((err) => console.error(`Error deleting images ${id}: ${err}`));
    });
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      {categoryQuery.isLoading ? (
        <CircularProgress />
      ) : categoryQuery.error ? (
        "An error has occurred: " + categoryQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/categories">Categories</Link>
              <Typography color="textPrimary">{name}</Typography>
              <Typography color="textPrimary">Images</Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={"Manage Images"}
                numSelected={selected.length}
                handleDelete={handleDelete}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Image</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categoryQuery.data.images &&
                      categoryQuery.data.images.length > 0 &&
                      categoryQuery.data.images.map((image) => {
                        const isItemSelected = isSelected(image);

                        return (
                          <TableRow
                            key={image}
                            hover
                            onClick={(event) => handleSelect(event, image)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell>{image}</TableCell>
                            <TableCell>
                              <img
                                src={`https://scwebv7cdn.azureedge.net/content/${image}`}
                                className={classes.fullwidthContent}
                                alt=""
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                item
                spacing={4}
                component="form"
                onSubmit={handleCreate}
              >
                <Grid item>
                  <Typography variant="h6">Add Image</Typography>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="priority-label">Priority</InputLabel>
                      <Select
                        labelId="priority-label"
                        label="Priority"
                        name="priority"
                        value={priority}
                        onChange={handleInput}
                        required
                      >
                        <MenuItem value={1}>Priority</MenuItem>
                        <MenuItem value={2}>Active</MenuItem>
                        <MenuItem value={3}>Unactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type="file"
                      name="uploadImage"
                      accept="image/*"
                      onChange={handleInput}
                    />
                    {errorMessage && (
                      <Typography variant="body2" color="textSecondary">
                        {errorMessage}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {imageSrc ? (
                      <img
                        src={imageSrc}
                        className={classes.fullwidthContent}
                        alt=""
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="outlined" size="large">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Image Saved"
      />
    </Fragment>
  );
};

export default CategoryImages;
