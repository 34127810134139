import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const transactionStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  FAILED: 2,
});

const paymentStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  FAILED: 2,
});

const Transaction = () => {
  const { userId, transactionId } = useParams();

  const { isLoading, error, data } = useQuery("scTransaction", () =>
    axios
      .get(
        `${process.env.REACT_APP_PAYMENTS_API_URL}/users/${userId}/transactions/${transactionId}`
      )
      .then((res) => res.data)
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to="/transactions">Transactions</Link>
              <Typography color="textPrimary">
                {`Transaction - ${data.transaction.id}`}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container item xs={12} spacing={4}>
                <Grid item>
                  <Link to={`/users/${data.transaction.authUserId}`}>
                    View User
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    to={`/users/${data.transaction.authUserId}/transactions`}
                  >
                    View User Transactions
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="User ID"
                    value={data.transaction.authUserId}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Name"
                    value={`${data.user.firstName} ${data.user.lastName}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Email"
                    value={data.user.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Transaction Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Transaction ID"
                    value={data.transaction.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Comment"
                    value={data.transaction.comment || "-"}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Invoice ID"
                    value={data.transaction.aomsInvoiceId || "-"}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Amount"
                    value={data.transaction.amount}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Status"
                    value={getKeyByValue(
                      transactionStatuses,
                      data.transaction.status
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Payments
              </Typography>
              {data.payments.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Realex Result</TableCell>
                        <TableCell>Realex Batch ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.payments.map((payment) => {
                        return (
                          <TableRow key={payment.id} hover>
                            <TableCell>{payment.id}</TableCell>
                            <TableCell>
                              {formatTime(payment.creationDate)}
                            </TableCell>
                            <TableCell>{payment.amount}</TableCell>
                            <TableCell>
                              {getKeyByValue(paymentStatuses, payment.status)}
                            </TableCell>
                            <TableCell>{payment.realexResult || "-"}</TableCell>
                            <TableCell>
                              {payment.realexBatchId || "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1">None</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Transaction;
