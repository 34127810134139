import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ContentSeoBanners = () => {
  const [getDisabled, setGetDisabled] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery("contentSeoBanners", () =>
    axios
      .get(`${process.env.REACT_APP_CONTENT_API_URL}/seo-banners`)
      .then((res) => res.data)
  );

  const deleteMutation = useMutation(
    (id) =>
      axios.put(
        `${process.env.REACT_APP_CONTENT_API_URL}/seo-banners/${id}/delete`
      ),
    {
      onSuccess: (data) => {
        queryClient.setQueryData("contentSeoBanners", (oldData) => {
          return {
            results: oldData.results.filter(
              (banner) => banner.id !== data.data.id
            ),
          };
        });
        setOpenSuccess(true);
      },
      onError: () => setOpenError(true),
    }
  );

  const handleChangeIndex = (e) => {
    const { name, value } = e.target;

    queryClient.setQueryData("contentSeoBanners", (oldData) => {
      return {
        results: oldData.results.map((banner) => {
          if (banner.id === parseInt(name)) {
            return {
              ...banner,
              idx: parseInt(value),
            };
          }
          return banner;
        }),
      };
    });
  };

  const getAllDisabledQuery = useQuery(
    ["getAllDisabled", getDisabled],
    () =>
      axios
        .get(`${process.env.REACT_APP_CONTENT_API_URL}/seo-banners/disabled`)
        .then((res) => res.data),
    {
      enabled: getDisabled,
    }
  );

  const enableMutation = useMutation(
    ({ id }) =>
      axios
        .put(
          `${process.env.REACT_APP_CONTENT_API_URL}/seo-banners/${id}/enable`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData("contentSeoBanners", (oldData) => {
          const enabled = getAllDisabledQuery.data.results.find(
            (banner) => banner.id === res.id
          );
          return {
            results: [...oldData.results, enabled],
          };
        });
        queryClient.setQueryData(["getAllDisabled", getDisabled], (oldData) => {
          return {
            results: oldData.results.filter((banner) => banner.id !== res.id),
          };
        });
      },
    }
  );

  const updateAllIndexesMutation = useMutation(
    ({ list }) =>
      axios
        .put(
          `${process.env.REACT_APP_CONTENT_API_URL}/seo-banners/update-all-indexes`,
          { list }
        )
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  const handleUpdateAllIndexes = () => {
    const list = data.results
      .map((banner) => {
        return {
          id: banner.id,
          index: banner.idx,
        };
      })
      .filter((banner) => banner.index !== null);
    updateAllIndexesMutation.mutate({ list });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Banners</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box
          p={2}
          component={Paper}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{ marginRight: "10px" }}
            variant="contained"
            color="primary"
            onClick={() => setGetDisabled(true)}
            disabled={getDisabled}
          >
            Get All Disabled
          </Button>
          <Link to="seo-banners/create">
            <Button variant="contained" color="primary">
              Create New SEO Banner
            </Button>
          </Link>
        </Box>
      </Grid>
      {getAllDisabledQuery.data && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6">Disabled</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAllDisabledQuery.data.results.map((banner) => (
                    <TableRow key={banner.id}>
                      <TableCell>{banner.id}</TableCell>
                      <TableCell>{banner.title}</TableCell>
                      <TableCell>
                        {banner.position === 0 ? "Top" : "Bottom"}
                      </TableCell>
                      <TableCell>
                        <Link to={`seo-banners/${banner.id}/edit`}>
                          <Button variant="contained" color="primary">
                            Edit
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: 10 }}
                          onClick={() =>
                            enableMutation.mutate({ id: banner.id })
                          }
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      )}
      {isLoading && <CircularProgress />}
      {error && <div>{"An error has occurred: " + error.message}</div>}
      {data && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Index</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((banner) => {
                    return (
                      <TableRow key={banner.id} hover>
                        <TableCell>{banner.id}</TableCell>
                        <TableCell>{banner.title}</TableCell>
                        <TableCell>
                          {banner.position === 0 ? "Top" : "Bottom"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ padding: 0, margin: 0, width: 70 }}
                            name={banner.id.toString()}
                            value={banner.idx || ""}
                            onChange={handleChangeIndex}
                            size="small"
                          />
                        </TableCell>
                        <TableCell size="small">
                          <Link to={`seo-banners/${banner.id}/edit`}>
                            <Button variant="contained" color="primary">
                              Edit
                            </Button>
                          </Link>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            onClick={() => deleteMutation.mutate(banner.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "20px 40px 0px 0px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateAllIndexes}
              >
                Update All Indexes
              </Button>
            </Grid>
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Banner deleted successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ContentSeoBanners;
