import axios from "axios";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { useState, useEffect, useCallback } from "react";
import useDebounce from "../HOCs/UseDebounce";

const UserSearchDropDown = ({ searchLabel, setUserId, email }) => {
  const [searchQuery, setSearchQuery] = useState(email ? email : "");
  const [users, setUsers] = useState([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const searchUsers = useCallback(
    (searchQuery) => {
      axios
        .get(
          `${process.env.REACT_APP_AUTH_API_URL}/users?search=${searchQuery}`
        )
        .then((res) => {
          setUsers(res.data.matchingUsersResults);
          if (res.data.matchingUsersResults.length > 0 && email) {
            setUserId(res.data.matchingUsersResults[0].id);
          }
        })
        .catch((err) => {
          console.error("User search error:", err);
          setUsers([]);
        });
    },
    [email, setUserId]
  );

  useEffect(() => {
    if (debouncedSearchQuery) {
      searchUsers(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, searchUsers]);

  useEffect(() => {
    // Auto search for email if provided
    if (email) {
      setSearchQuery(email);
    }
  }, [email]);

  const handleSearch = (e) => {
    if (e.key === "Enter" && searchQuery) {
      searchUsers(searchQuery);
    }
  };

  return (
    <Autocomplete
      label="Search Users"
      type="text"
      options={users}
      inputValue={searchQuery}
      isOptionEqualToValue={(option, value) => {
        if (option && value) return option.id === value.id;
      }}
      onChange={(event, value) => {
        if (value) setUserId(value.id);
      }}
      onInputChange={(event, newInputValue) => {
        if (event) setSearchQuery(newInputValue);
      }}
      variant="outlined"
      onKeyPress={handleSearch}
      getOptionLabel={(option) =>
        `${option.firstName} ${option.lastName} <${option.email}>`
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={searchLabel}
          type="text"
          name="searchQuery"
          variant="outlined"
          size="small"
        />
      )}
    />
  );
};

export default UserSearchDropDown;
