import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import DeleteBtn from "../../Buttons/DeleteBtn/DeleteBtn";
import ErrorAlert from "../../misc/ErrorAlert";
import SuccessAlert from "../../misc/SuccessAlert";
import UserSearchDropDown from "../../UserSearchDropDown/UserSearchDropDown";
import VisibilityIcon from "@mui/icons-material/Visibility";

const refundStatuses = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  CANCELLED: 3,
});

const RefundForm = ({ refund }) => {
  const history = useHistory();

  const [refundTypeId, setRefundTypeId] = useState(
    refund?.refundTypeId ? refund.refundTypeId : null
  );
  const [amount, setAmount] = useState(refund?.amount ? refund.amount : "");
  const [totalAmount, setTotalAmount] = useState(
    refund?.totalAmount ? refund.totalAmount : ""
  );
  const [reason, setReason] = useState(refund?.reason ? refund.reason : "");
  const [refundUserId, setRefundUserId] = useState(
    refund?.refundUserId ? refund.refundUserId : ""
  );
  const [invoiceId, setInvoiceId] = useState(
    refund?.invoiceId ? refund.invoiceId : ""
  );
  const [faultUserId, setFaultUserId] = useState(
    refund?.faultUserId ? refund.faultUserId : ""
  );
  const [ticketId, setTicketId] = useState(
    refund?.ticketId ? refund.ticketId : ""
  );
  const [comment, setComment] = useState(refund?.comment ? refund.comment : "");
  const [status, setStatus] = useState(
    refund?.status ? refund.status : refundStatuses.PENDING
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [createdRefundId, setCreatedRefundId] = useState(null);
  const [deductedFromInvoice, setDeductedFromInvoice] = useState(
    refund?.deductedFromInvoice
  );
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(null);

  const refundTypesQuery = useQuery(
    "refundTypes",
    () =>
      axios
        .get(`${process.env.REACT_APP_QMS_API_URL}/refunds/types`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const auctionHousesQuery = useQuery(
    "asAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setCurrentAuctionHouse(
          data.find((el) => el.id === refund?.auctionHouseId)
        );
      },
    }
  );

  // Observe amount and reason to keep up to date with QMS ticket refund summary
  useEffect(() => {
    if (refund?.amount) {
      setAmount(refund.amount);
    }
  }, [refund?.amount]);
  useEffect(() => {
    if (refund?.reason) {
      setReason(refund.reason);
    }
  }, [refund?.reason]);
  useEffect(() => {
    if (refund?.invoiceId) {
      setInvoiceId(refund.invoiceId);
    }
  }, [refund?.invoiceId]);
  useEffect(() => {
    if (refund?.status) {
      setStatus(refund.status);
    }
  }, [refund?.status]);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "refundTypeId":
        setRefundTypeId(value);
        break;
      case "amount":
        setAmount(value);
        break;
      case "totalAmount":
        setTotalAmount(value);
        break;
      case "reason":
        setReason(value);
        break;
      case "invoiceId":
        setInvoiceId(value);
        break;
      case "ticketId":
        setTicketId(value);
        break;
      case "comment":
        setComment(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "refundUserId":
        setRefundUserId(value);
        break;
      case "faultUserId":
        setFaultUserId(value);
        break;
      case "deductedFromInvoice":
        setDeductedFromInvoice(e.target.checked);
        break;
      case "currentAuctionHouse":
        const house = auctionHousesQuery.data.find((el) => el.name === value);
        setCurrentAuctionHouse(house);
        break;
      default:
        break;
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    const data = {
      status,
      refundTypeId,
      amount: parseFloat(amount),
      reason,
      refundUserId: parseInt(refundUserId),
      invoiceId: parseInt(invoiceId),
      faultUserId: parseInt(faultUserId),
      ticketId: parseInt(ticketId),
      comment,
      auctionHouseId: currentAuctionHouse ? currentAuctionHouse.id : null,
    };

    try {
      setIsLoading(true);
      const createdRefund = await axios.post(
        `${process.env.REACT_APP_QMS_API_URL}/refunds`,
        data
      );
      setOpenSuccess(true);
      setIsLoading(false);
      setCreatedRefundId(createdRefund.data.id);
      setRefundTypeId(null);
      setStatus(refundStatuses.PENDING);
      setAmount("");
      setReason("");
      setRefundUserId("");
      setInvoiceId("");
      setFaultUserId("");
      setTicketId("");
      setComment("");
      setCurrentAuctionHouse(null);
    } catch (error) {
      setOpenError(`There has been an error: ${error}`);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const data = {
      status,
      refundTypeId,
      amount: parseFloat(amount),
      reason,
      refundUserId: parseInt(refundUserId),
      invoiceId: parseInt(invoiceId),
      faultUserId: parseInt(faultUserId),
      ticketId: parseInt(ticketId),
      comment,
      totalAmount: totalAmount ? parseFloat(totalAmount) : null,
      deductedFromInvoice,
      auctionHouseId: currentAuctionHouse ? currentAuctionHouse.id : null,
    };

    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_QMS_API_URL}/refunds/${refund.id}`,
        data
      );
      setOpenSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setOpenError(`There has been an error: ${error}`);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_QMS_API_URL}/refunds/${refund.id}`
      );
      setIsLoading(false);
      history.push("/qms/refunds");
    } catch (error) {
      setOpenError(`There has been an error: ${error}`);
      setIsLoading(false);
    }
  };

  const isEditRefundForm = refund?.refundTypeId;

  return (
    <Grid item xs={12}>
      {createdRefundId && refund?.ticketId ? (
        <Link to={`/qms/refunds/${createdRefundId}/edit`}>
          <Button variant="outlined" color="primary">
            View Refund
          </Button>
        </Link>
      ) : (
        <Grid
          container
          item
          spacing={4}
          component="form"
          onSubmit={isEditRefundForm ? handleUpdate : handleCreate}
        >
          <Grid item>
            <Typography variant="h5">
              {isEditRefundForm ? "Edit Refund" : "Create Refund"}
            </Typography>
          </Grid>
          <Grid container item spacing={4}>
            <Grid item xs={12} sm={6} md={isEditRefundForm ? 3 : 4}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="refund-type-id-label">Refund Type</InputLabel>
                <Select
                  labelId="refund-type-id-label"
                  label="Refund Type"
                  name="refundTypeId"
                  value={refundTypeId}
                  onChange={handleInput}
                  required
                >
                  {refundTypesQuery.data?.length > 0 &&
                    refundTypesQuery.data.map((refundType) => {
                      return (
                        <MenuItem key={refundType.id} value={refundType.id}>
                          {refundType.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={isEditRefundForm ? 3 : 4}>
              <TextField
                label="Amount"
                name="amount"
                value={amount}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                required
              />
            </Grid>
            {isEditRefundForm && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Total Amount"
                  name="totalAmount"
                  value={totalAmount}
                  onChange={handleInput}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={isEditRefundForm ? 3 : 4}>
              <TextField
                label="Invoice ID"
                name="invoiceId"
                value={invoiceId}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={
                  isEditRefundForm &&
                  invoiceId && {
                    endAdornment: (
                      <a
                        href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${invoiceId}`}
                      >
                        <Button title="View Invoice">
                          <VisibilityIcon />
                        </Button>
                      </a>
                    ),
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Ticket ID"
                name="ticketId"
                value={ticketId}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={
                  isEditRefundForm &&
                  ticketId && {
                    endAdornment: (
                      <Link to={`/qms/tickets/${ticketId}`}>
                        <Button title="View Ticket">
                          <VisibilityIcon />
                        </Button>
                      </Link>
                    ),
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <UserSearchDropDown
                searchLabel="Search refund users"
                setUserId={setRefundUserId}
                email={refund?.refundUserEmail ? refund.refundUserEmail : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <UserSearchDropDown
                searchLabel="Search user at fault"
                setUserId={setFaultUserId}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  name="status"
                  value={status}
                  onChange={handleInput}
                  required
                >
                  {Object.entries(refundStatuses).map((refundStatus) => {
                    return (
                      <MenuItem key={refundStatus[1]} value={refundStatus[1]}>
                        {refundStatus[0]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Refund User ID"
                name="refundUserId"
                value={refundUserId}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                required
                InputProps={
                  isEditRefundForm &&
                  refundUserId && {
                    endAdornment: (
                      <Link to={`/users/${refundUserId}`}>
                        <Button title="View Refund User">
                          <VisibilityIcon />
                        </Button>
                      </Link>
                    ),
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Refund Fault ID"
                name="faultUserId"
                value={faultUserId}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={
                  isEditRefundForm &&
                  faultUserId && {
                    endAdornment: (
                      <Link to={`/users/${faultUserId}`}>
                        <Button title="View Refund Fault User">
                          <VisibilityIcon />
                        </Button>
                      </Link>
                    ),
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {auctionHousesQuery.data && (
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="auction_house">Auction House</InputLabel>
                  <Select
                    labelId="auction_house"
                    label="Auction House"
                    name="currentAuctionHouse"
                    value={currentAuctionHouse ? currentAuctionHouse.name : ""}
                    onChange={handleInput}
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Reason"
                name="reason"
                value={reason}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Comment"
                name="comment"
                value={comment}
                onChange={handleInput}
                variant="outlined"
                fullWidth
                size="small"
              />
            </Grid>
            {isEditRefundForm && (
              <Grid item xs={12}>
                <FormControlLabel
                  label="Deducted From Invoice"
                  control={
                    <Checkbox
                      name="deductedFromInvoice"
                      checked={deductedFromInvoice}
                      onChange={handleInput}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}
              >
                {isEditRefundForm ? "Save" : "Create"}
              </Button>
              {isLoading && <CircularProgress size={20} />}
            </Grid>
            {isEditRefundForm && (
              <Grid item>
                <DeleteBtn handleDelete={handleDelete} isLoading={isLoading} />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={isEditRefundForm ? "Updates saved" : "New refund created"}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={openError}
      />
    </Grid>
  );
};

export default RefundForm;
