import React, { useState, Fragment } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles } from "@mui/styles";
import { formatTime, secondsToTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const timesheetStatuses = {
  "Clocked In": 0,
  "Clocked Out": 1,
  "On Break": 2,
};

const breaksStatuses = {
  Start: 0,
  End: 1,
};

const useStyles = makeStyles((theme) => ({
  image: {
    width: "150px",
    height: "150px",
    imageFit: "cover",
  },
  imagesContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1rem",
  },
}));

const CollapsibleRows = ({ row }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Fragment key={row.id}>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatTime(row.clockIn)}</TableCell>
        <TableCell>{row.clockOut && formatTime(row.clockOut)}</TableCell>
        <TableCell>{row.employee}</TableCell>
        <TableCell>{row.device}</TableCell>
        <TableCell>{row.auctionHouse}</TableCell>
        <TableCell>{secondsToTime(row.totalBreaks || 0)}</TableCell>
        <TableCell>{secondsToTime(row.totalWorkTime)}</TableCell>
        <TableCell>{getKeyByValue(timesheetStatuses, row.status)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={7}
          style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ marginBottom: "1rem" }}>
              <Grid item xs={12} className={classes.imagesContainer}>
                <div>
                  <Typography gutterBottom component="div">
                    Clock In Image
                  </Typography>
                  <img
                    className={classes.image}
                    src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${row.clockInImage}`}
                    alt="clock in"
                  />
                </div>
                <div>
                  <Typography gutterBottom component="div">
                    Clock Out Image
                  </Typography>
                  <img
                    className={classes.image}
                    src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${row.clockOutImage}`}
                    alt="clock out"
                  />
                </div>
              </Grid>
              <Typography variant="h6" gutterBottom component="div">
                Breaks
              </Typography>
              {row.breaks.map((breakRow) => (
                <Fragment key={breakRow.id}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Total Time</TableCell>
                        <TableCell>Allowed</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{breakRow.name}</TableCell>
                        <TableCell>{formatTime(breakRow.startTime)}</TableCell>
                        <TableCell>
                          {breakRow.endTime && formatTime(breakRow.endTime)}
                        </TableCell>
                        <TableCell>
                          {secondsToTime(breakRow.totalTimeInSeconds || 0)}
                        </TableCell>
                        <TableCell>
                          {secondsToTime(breakRow.duration * 60)}
                        </TableCell>
                        <TableCell>
                          {getKeyByValue(breaksStatuses, breakRow.status)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Grid item xs={12} className={classes.imagesContainer}>
                    <div>
                      <Typography gutterBottom component="div">
                        Start Image
                      </Typography>
                      <img
                        className={classes.image}
                        src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${breakRow.startImage}`}
                        alt="break start"
                      />
                    </div>
                    <div>
                      <Typography gutterBottom component="div">
                        End Image
                      </Typography>
                      <img
                        className={classes.image}
                        src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${breakRow.endImage}`}
                        alt="break end"
                      />
                    </div>
                  </Grid>
                </Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const TimesheetEmployee = () => {
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [maximumRows, setMaximumRows] = useState(10);
  const { id } = useParams();

  const { data, isLoading, error } = useQuery(
    ["timesheet-employee", startRowIndex, maximumRows],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/attendance/employee/${id}?startRowIndex=${startRowIndex}&maximumRows=${maximumRows}`
        )
        .then((res) => res.data)
  );

  const handleChangePage = (event, newPage) => {
    setStartRowIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setStartRowIndex(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Link to="/timesheets/attendance">Attendance</Link>
          <Typography color="textPrimary">Employee {id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Clock In</TableCell>
                    <TableCell>Clock Out</TableCell>
                    <TableCell>Employee</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>Auction House</TableCell>
                    <TableCell>Total Breaks</TableCell>
                    <TableCell>Total Work Time</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((row) => (
                    <CollapsibleRows key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 50]}
                component="div"
                count={data.totalRows}
                rowsPerPage={maximumRows}
                page={startRowIndex}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TimesheetEmployee;
