import { Box, Grid, TextField, Typography } from "@mui/material";
import { Link as ExternalLink } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  markdown: {
    minHeight: "40px",
    border: "1px solid black",
    borderRadius: "4px",
    padding: "0 14px",
  },
  fullwidthContent: {
    width: "100%",
    marginTop: "10.5px",
  },
  iframeEmbed: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  iframeEmbedWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
    marginTop: "10.5px",
  },
}));

const MarkdownEditor = ({
  markdown,
  imageSrc,
  youtubeUrl,
  handleInput,
  title,
  name,
}) => {
  const classes = useStyles();

  return (
    <Grid container item spacing={4}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">
          {title ? `${title} Content` : "Content"}
        </Typography>
        <TextField
          name={name}
          value={markdown}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          multiline
          size="small"
          required
        />
        <Box mt={1}>
          <Typography variant="body2">
            <ExternalLink
              target="_blank"
              href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
            >
              Markdown reference guide
            </ExternalLink>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Preview</Typography>
        {imageSrc || youtubeUrl ? (
          <Box className={classes.markdown}>
            {imageSrc ? (
              <img src={imageSrc} className={classes.fullwidthContent} alt="" />
            ) : youtubeUrl ? (
              <div className={classes.iframeEmbedWrapper}>
                <iframe
                  title="youtubeEmbed"
                  id="youtubeEmbed"
                  src={youtubeUrl}
                  frameBorder="0"
                  allowFullScreen
                  className={classes.iframeEmbed}
                />
              </div>
            ) : null}
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </Box>
        ) : (
          <ReactMarkdown className={classes.markdown}>{markdown}</ReactMarkdown>
        )}
      </Grid>
    </Grid>
  );
};

export default MarkdownEditor;
