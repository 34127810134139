import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Alert } from "@mui/lab";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getDecodedAccessToken } from "../utils/Tokens";

const Users = (props) => {
  const savedSearchQuery =
    props.location.search.slice(props.location.search.lastIndexOf("=") + 1) ||
    sessionStorage.getItem("USER_SEARCH_QUERY") ||
    "";

  const decodedToken = getDecodedAccessToken();
  let permissions = [
    ...decodedToken.permissions.applications,
    ...decodedToken.permissions.roles,
  ];

  const [searchQuery, setSearchQuery] = useState(savedSearchQuery);
  const [search, setSearch] = useState("");
  const [searchByPostcode, setSearchByPostcode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const queryFn = () => {
    let url = `${process.env.REACT_APP_AUTH_API_URL}/users`;
    if (searchByPostcode) {
      url = `${process.env.REACT_APP_AOMS_API_URL}/buyers/search-by-postcode`;
    }
    url += `?search=${search}&startRowIndex=${
      page * rowsPerPage
    }&maximumRows=${rowsPerPage}`;
    return axios.get(url);
  };

  const { data, isLoading, error } = useQuery(
    [`userSearch-${search}`, search, page, rowsPerPage, searchByPostcode],
    () =>
      queryFn()
        .then((res) => {
          props.history.push({ search: `?search=${searchQuery}` });
          sessionStorage.setItem("USER_SEARCH_QUERY", searchQuery);
          return res.data;
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 404") {
            throw new Error("404");
          } else {
            throw new Error(error.message);
          }
        }),
    {
      enabled: Boolean(search),
      keepPreviousData: true,
      retry: false,
    }
  );

  useEffect(() => {
    if (searchQuery) setSearch(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "searchQuery":
        setSearchQuery(value);
        setPage(0);
        if (!value) {
          props.history.push({ search: "" });
          sessionStorage.removeItem("USER_SEARCH_QUERY");
        }
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    if (e.key === "Enter" && searchQuery) {
      setSearch(searchQuery);
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Users</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container item xs={12} spacing={4}>
            <Grid item>
              <Link to="/transactions">Transactions</Link>
            </Grid>
            <Grid item>
              <Link to="/memberships">Memberships</Link>
            </Grid>
            {permissions.includes("auth") && (
              <>
                <Grid item>
                  <Link to="/verification-ids">ID Verification</Link>
                </Grid>
                <Grid item>
                  <Link to="/feedback">Feedback</Link>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Search Users"
          type="text"
          name="searchQuery"
          value={searchQuery}
          variant="outlined"
          onChange={handleInput}
          onKeyPress={handleSearch}
          component={Paper}
        />
        <FormControlLabel
          component={Paper}
          control={
            <Checkbox
              checked={searchByPostcode}
              onChange={(e) => setSearchByPostcode(e.target.checked)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Search by Postcode"
          labelPlacement="top"
          style={{ marginLeft: "10px" }}
        />
      </Grid>
      <Grid item xs={12}>
        {isLoading && <CircularProgress />}
        {error &&
          (error.message === "404" ? (
            <Alert severity="warning">No Matching Users</Alert>
          ) : (
            <Alert severity="error">{`An error has occurred: ${error}`}</Alert>
          ))}
        {data && !searchByPostcode && (
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Account Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.matchingUsersResults?.map((user) => {
                    return (
                      <TableRow key={user.id} hover>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>
                          {user.locked.data[0] ? "Locked" : "Active"}
                        </TableCell>
                        <TableCell>
                          <Link to={`/users/${user.id}`}>View</Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={{
                              pathname: `/users/${user.id}/edit`,
                              state: {
                                userId: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                accountStatus: user.locked.data[0],
                                emailStatus: user.emailVerified.data[0],
                              },
                            }}
                          >
                            Edit
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.totalResults}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        )}
        {data && searchByPostcode && (
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Street</TableCell>
                    <TableCell>Address2</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Postcode</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results?.map((buyer) => {
                    return (
                      <TableRow key={buyer.id} hover>
                        <TableCell>{buyer.authUserId}</TableCell>
                        <TableCell>{buyer.email}</TableCell>
                        <TableCell>{buyer.name}</TableCell>
                        <TableCell>{buyer.street}</TableCell>
                        <TableCell>{buyer.address2}</TableCell>
                        <TableCell>{buyer.city}</TableCell>
                        <TableCell>{buyer.postCode}</TableCell>
                        <TableCell>
                          {buyer.authUserId && (
                            <Link to={`/users/${buyer.authUserId}`}>View</Link>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.totalResults}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Users;
