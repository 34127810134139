import React, { useState } from "react";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Cookies from "universal-cookie/es6";
import { getDecodedAccessToken } from "../utils/Tokens";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(() => ({
  page: {
    minHeight: "100vh",
  },
}));

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_AUTH_API_URL}/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        sessionStorage.setItem("ACCESS_TOKEN", res.data.accessToken);

        // Check if user has auth permissions to enter admin
        const decodedToken = getDecodedAccessToken();
        let permissions = [
          ...decodedToken.permissions.applications,
          ...decodedToken.permissions.roles,
        ];
        if (!permissions.includes("auth")) {
          sessionStorage.removeItem("ACCESS_TOKEN");
          console.log("User does not have access");
          return <Redirect to="/" />;
        }

        localStorage.setItem("TOKEN", res.data.refreshToken);
        const cookies = new Cookies();
        const expiryDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 90); // 90 days from now
        cookies.set("refreshToken", res.data.refreshToken, {
          path: "/",
          expires: expiryDate,
          domain: process.env.COOKIE_DOMAIN,
          secure: process.env.NODE_ENV === "production",
        });
        props.history.replace("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage("Incorrect email or password");
      });
  };

  return (
    <Container maxWidth="xs">
      <Grid
        className={classes.page}
        container
        alignItems="center"
        justifyContent="center"
      >
        <form onSubmit={handleLogin}>
          <Grid container spacing={2}>
            <Grid container item>
              <Typography variant="h4">Admin Login</Typography>
            </Grid>
            {errorMessage && (
              <Grid container item>
                <Typography>{errorMessage}</Typography>
              </Grid>
            )}
            <Grid container item>
              <TextField
                label="Email"
                type="email"
                name="email"
                variant="filled"
                value={email}
                fullWidth
                required
                onChange={handleInput}
                error={errorMessage !== ""}
              />
            </Grid>
            <Grid container item>
              <TextField
                label="Password"
                type="password"
                name="password"
                variant="filled"
                value={password}
                fullWidth
                required
                onChange={handleInput}
                error={errorMessage !== ""}
              />
            </Grid>
            <Grid container item>
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                disabled={email === "" || password === ""}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default Login;
