import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatTime } from "../utils/formatTime";

const Notifications = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const queryClient = useQueryClient();

  const notificationsList = useQuery("getNotifications", async () => {
    return (
      await axios.get(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications`
      )
    ).data;
  });

  const deleteMutation = useMutation(
    (id) =>
      axios.delete(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications/${id}`
      ),
    {
      onSettled: (data, error, variables, context) => {
        if (error) setOpenError(true);
        if (data) setOpenSuccess(true);
      },
    }
  );

  const handleDeleteNotification = (id) => {
    deleteMutation.mutate(id, {
      onSuccess: () =>
        queryClient.fetchQuery("getNotifications", async () => {
          return (
            await axios.get(
              `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications`
            )
          ).data;
        }),
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Notifications</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item spacing={3}>
        <Box p={2} component={Paper}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="inherit">
              Manage Notifications
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {notificationsList.isLoading ? (
              <CircularProgress />
            ) : notificationsList.error ? (
              "An error has occurred: " + notificationsList.error.message
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Body</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Schedule At</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notificationsList.data &&
                      notificationsList.data.response.map((el) => {
                        return (
                          <TableRow key={el.id}>
                            <TableCell>{el.type}</TableCell>
                            <TableCell>{el.title}</TableCell>
                            <TableCell>{el.body}</TableCell>
                            <TableCell>{el.size}</TableCell>
                            <TableCell>{formatTime(el.scheduleAt)}</TableCell>
                            <TableCell>
                              <Link to={`/notifications/update/${el.id}`}>
                                View
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Button variant="contained" onClick={() => handleDeleteNotification(el.id)}>
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Notification deleted!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default Notifications;
