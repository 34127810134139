import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TableCell from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SuccessAlert from "../misc/SuccessAlert";
import ErrorAlert from "../misc/ErrorAlert";

const Locations = ({ currentAuctionHouse, user, locationsQuery }) => {
  const [currentLocation, setCurrentLocation] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const mutation = useMutation(
    (updateDispatchLocationId) =>
      axios.patch(
        `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses/${currentAuctionHouse.id}/users`,
        updateDispatchLocationId
      ),
    {
      onSettled: (data, error) => {
        if (error) {
          setOpenError(true);
          setCurrentLocation(currentLocation);
        }
        if (data) setOpenSuccess(true);
      },
    }
  );

  const handleFormSelect = (event) => {
    // event.preventDefault();
    const selectedCurrentLocation = locationsQuery.data.find(
      (el) => el.name === event.target.value
    );
    mutation.mutate({
      userId: user.id,
      dispatchLocationId: selectedCurrentLocation.id,
    });

    setCurrentLocation(selectedCurrentLocation);
  };

  const findSelectValue = () => {
    if (currentLocation) return currentLocation.name;
    if (locationsQuery.isSuccess) {
      const selectedLocation = locationsQuery.data.find(
        (el) => el.id === user.dispatchLocationId
      );

      return selectedLocation ? selectedLocation.name : "";
    }
  };

  const showAuctionHouseIfAssigned = () => {
    if (!locationsQuery.isSuccess) return;
    const dispatchLocation = locationsQuery.data.find(
      (location) => location.name === user.dispatchLocationName
    );

    return !dispatchLocation && user.dispatchLocationName ? (
      <Typography variant="h7">{`Assigned to ${user.dispatchLocationName}`}</Typography>
    ) : null;
  };

  return (
    <>
      {locationsQuery.isLoading ? (
        <CircularProgress />
      ) : locationsQuery.error ? (
        `An error has occurred: ${locationsQuery.error.message}`
      ) : (
        <TableCell>
          <FormControl variant="standard" size="small">
            {showAuctionHouseIfAssigned()}
            <Select
              defaultValue={""}
              value={findSelectValue()}
              label="location-label"
              onChange={handleFormSelect}
            >
              {locationsQuery.data.map((el) => {
                return (
                  <MenuItem value={el.name} key={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            message="Changes Saved"
          />
          <ErrorAlert
            openError={openError}
            setOpenError={setOpenError}
            message="Error! Please try again"
          />
        </TableCell>
      )}
    </>
  );
};

export default Locations;
