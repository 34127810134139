import { Fragment, useState } from "react";
import {
  Breadcrumbs,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { formatTime } from "../utils/formatTime";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

const UserLotViewings = (props) => {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );

  const bookingsQuery = useQuery(
    ["scBookingsUser", id],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/bookings-internal/user/${id}?maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          setTotalRecords(res.data.totalRecords);
          return res.data.bookings;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );

  const handleChangePage = async (evt, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log("rows per page:", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Fragment>
      {auctionHousesQuery.isLoading || bookingsQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${id}`}>Manage User</Link>
              <Typography color="textPrimary">Lot Viewings</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              {bookingsQuery.data.length ? (
                <div>
                  <EnhancedTableToolbar title={"Booked Viewings"} />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Auction House</TableCell>
                          <TableCell>Viewing Date</TableCell>
                          <TableCell>Start Time</TableCell>
                          <TableCell>End Time</TableCell>
                          <TableCell>Lots Count</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bookingsQuery.data.map((r) => {
                          return (
                            <TableRow key={`cell-${r.id}`} hover>
                              <TableCell>{r.id}</TableCell>
                              <TableCell>
                                {
                                  auctionHousesQuery.data.filter(
                                    (auctionHouse) =>
                                      auctionHouse.id === r.auctionHouseId
                                  )[0].name
                                }
                              </TableCell>
                              <TableCell>
                                {formatTime(r.viewingDate).slice(0, 10)}
                              </TableCell>
                              <TableCell>
                                {formatTime(r.startTime).slice(11)}
                              </TableCell>
                              <TableCell>
                                {formatTime(r.endTime).slice(11)}
                              </TableCell>
                              <TableCell>{r.lotsCount}</TableCell>
                              <TableCell>
                                {r.visited
                                  ? "Visited"
                                  : r.cancelled
                                  ? "Cancelled"
                                  : new Date(r.endTime) < new Date()
                                  ? "Missed"
                                  : "Pending"}
                              </TableCell>
                              <TableCell>
                                <Link to={`/lot-view-booking/${r.id}`}>
                                  Got to View Booking
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 20, 50]}
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </div>
              ) : (
                <Typography variant="h6">No viewings found</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default UserLotViewings;
