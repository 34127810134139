import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatDate } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  marginZero: {
    margin: 0,
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  updateAllCell: {
    display: "flex",
    width: "100%",
    alignItems: "end",
  },
}));

const InputValue = ({ totalLots, id, setOpenSuccess, setOpenError }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(totalLots);
  }, [totalLots]);

  const updateValueMutation = useMutation(
    () =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/piking-slots/${id}`,
          { totalLots: value }
        )
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  return (
    <>
      <TextField
        id={"input" + id}
        type="number"
        style={{ marginRight: 10 }}
        inputProps={{ size: 10 }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            updateValueMutation.mutate();
          }
        }}
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={updateValueMutation.mutate}
      >
        Update
      </Button>
    </>
  );
};

const CustomerQueuePickingSlots = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [amount, setAmount] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    [
      "asAuctionHouses",
      currentAuctionHouse.id,
      startDate,
      endDate,
      page,
      rowsPerPage,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/bookings/piking-slots?auctionHouseId=${
            currentAuctionHouse.id
          }&startDate=${startDate}&endDate=${endDate}&startRowIndex=${
            page * rowsPerPage
          }&maxRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    {
      enabled: !!currentAuctionHouse.id && !!startDate && !!endDate,
    }
  );

  const updateAllPickingSlotsMutation = useMutation(
    () =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/piking-slots/update-all`,
          {
            auctionHouseId: currentAuctionHouse.id,
            amount,
            startDate,
            endDate,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenSuccess(true);
        queryClient.setQueryData(
          [
            "asAuctionHouses",
            currentAuctionHouse.id,
            startDate,
            endDate,
            page,
            rowsPerPage,
          ],
          (oldData) => {
            oldData.results.map((el) => {
              el.totalLots = amount;
              return el;
            });

            return { results: oldData.results, count: oldData.count };
          }
        );
      },
      onError: () => setOpenError(true),
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Picking Slots</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <CircularProgress />}
          {error && "An error has occurred: " + error.message}
          {!data && "No picking slots found"}
          {data && data.results && data.results.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Slot</TableCell>
                    <TableCell>Total Lots</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{formatDate(el.date)}</TableCell>
                      <TableCell>{el.slot}</TableCell>
                      <TableCell style={{ width: "25%" }}>
                        <InputValue totalLots={el.totalLots} id={el.id} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.updateAllCell}>
                      <TextField
                        label="All"
                        type="number"
                        style={{ marginRight: 10 }}
                        inputProps={{ size: 10 }}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={updateAllPickingSlotsMutation.mutate}
                      >
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Picking slots updated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default CustomerQueuePickingSlots;
