import axios from "axios";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  Box,
  Breadcrumbs,
  TextField,
  TablePagination,
  Paper,
  CircularProgress,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Returns = (props) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [inBoundDate, setInBoundDate] = useState(null);
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [auctionHouseId, setAuctionHouseId] = useState("");

  const asBindData = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_RETURNS_API_URL}/returns`);
      url.searchParams.append("startRowIndex", page * rowsPerPage);
      console.log("rows per page: ", rowsPerPage);
      url.searchParams.append("maximumRows", rowsPerPage);
      if (search.length > 0) url.searchParams.append("search", search);
      if (inBoundDate !== null)
        url.searchParams.append("inBoundDate", inBoundDate);
      if (auctionHouseId !== "")
        url.searchParams.append("auctionHouseId", auctionHouseId);
      if (status >= 0) url.searchParams.append("status", status);
      setIsLoading(true);
      console.log(url.toString());
      const results = await axios.get(url.toString());
      setIsLoading(false);
      setResults(results.data.results);
      setTotalRecords(results.data.recordCount);
    } catch (err) {
      console.log("binding data error:", err);
    }
  };

  const bindData = useCallback(asBindData, [
    status,
    inBoundDate,
    auctionHouseId,
    page,
    rowsPerPage,
    search,
  ]);

  const asBindOtherData = async () => {
    const ah = await axios.get(
      `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`
    );
    const auctionHouses = ah.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setAuctionHouses(auctionHouses);
  };

  const bindOtherData = useCallback(asBindOtherData, []);

  useEffect(() => {
    bindData();
    bindOtherData();
  }, [bindData, bindOtherData]);

  const handleChangePage = async (evt, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("rows per page:", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 1:
        return "Re-Lot";
      case 2:
        return "Closed";
      default:
        return null;
    }
  };

  const handleOnKeyUp = async (evt) => {
    const name = evt.target.name;
    switch (name) {
      case "search":
        if (evt.keyCode === 13) {
          setPage(0);
          await bindData();
          await bindOtherData();
        }
        break;
      default:
        return;
    }
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "status":
        setStatus(value);
        setPage(0);
        break;
      case "search":
        setSearch(value);
        break;
      case "auctionhouse":
        setAuctionHouseId(value);
        setPage(0);
        break;
      default:
        return;
    }
  };

  const handleViewingDateChange = (date) => {
    setInBoundDate(date);
    setPage(0);
  };

  const handleClear = () => {
    setInBoundDate(null);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Returns</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container alignItems="center" spacing={3} item xs={12}>
            <Grid item>
              <TextField
                name="search"
                label="Search"
                variant="outlined"
                onChange={handleChange}
                onKeyUp={handleOnKeyUp}
                value={search}
              />
            </Grid>
            <Grid item>
              <Link color="primary" to="/returns/create">
                Create New Return
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={2}>
        <Card>
          <Box p={2}>
            <Typography component="h3" variant="h6">
              Filters
            </Typography>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      label="Select the inbound Date:"
                      value={inBoundDate}
                      onChange={handleViewingDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </LocalizationProvider>
                  <Button
                    onClick={handleClear}
                    variant="contained"
                    color="primary"
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="lbl-auctionhouse">Auction House:</InputLabel>
                  <Select
                    labelId="lbl-auctionhouse"
                    id="auctionhouse"
                    value={auctionHouseId}
                    name="auctionhouse"
                    fullWidth
                    onChange={handleChange}
                  >
                    {auctionHouses.map((r) => {
                      return (
                        <MenuItem key={`auctionhouse-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="lbl-status">Status:</InputLabel>
                  <Select
                    labelId="lbl-status"
                    id="select-status"
                    value={status}
                    name="status"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={-1}>All Statuses</MenuItem>
                    <MenuItem value={0}>Open</MenuItem>
                    <MenuItem value={1}>Re-Lot</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={10}>
        <Card>
          <Box p={2}>
            <Typography component="h3" variant="h6">
              Results
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : results && results.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Ticket ID</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Inbound Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Last Modified</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.map((r) => {
                      return (
                        <TableRow key={`result-${r.id}`} hover>
                          <TableCell>{r.id}</TableCell>
                          <TableCell>
                            {" "}
                            <Link to={`/qms/tickets/${r.ticketId}`}>
                              {r.ticketId}
                            </Link>{" "}
                          </TableCell>
                          <TableCell>{r.location}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                            }).format(new Date(r.inBoundDate))}
                          </TableCell>
                          <TableCell>{getStatus(r.status)}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.creationDate))}
                          </TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(r.modificationDate))}
                          </TableCell>
                          <TableCell>
                            <Link to={`/returns/view/${r.id}`}>View</Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 20, 50]}
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <Typography variant="h6">No matching results</Typography>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Returns;
