import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
} from "@mui/material";
import AutoComplete from "@mui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import useDebounce from "../utils/useDebounce";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "hidden",
    padding: theme.spacing(2, 4, 3),
    "@media (max-width: 1240px)": {
      width: "100%",
    },
  },
  input: {
    display: "none",
  },
  imageContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "gray",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const UpdateModal = ({
  open,
  handleClose,
  employee,
  setUpdateSuccess,
  shiftPatterns,
  setError,
  setOpenError,
}) => {
  const [name, setName] = useState("");
  const [payrollNo, setPayrollNo] = useState("");
  const [clockNo, setClockNo] = useState("");
  const [shiftPattern, setShiftPattern] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!employee) return;
    setName(employee?.name);
    setPayrollNo(employee?.payrollNo);
    setClockNo(employee?.clockNo);
    if (shiftPatterns?.results) {
      const shift = shiftPatterns?.results.find(
        (el) => el.id === employee.shiftPatternId
      );
      setShiftPattern(shift);
    }
  }, [employee, shiftPatterns]);

  const classes = useStyles();
  const imageRef = useRef(null);

  const handleUpdate = async () => {
    if (!name) return;
    if (!shiftPattern) return;
    try {
      const formData = new FormData();
      formData.append("file", image);
      await axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employee/${employee.id}?name=${name}&clockNo=${clockNo}&payrollNo=${payrollNo}&shiftPatternId=${shiftPattern.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      handleClose();
      setUpdateSuccess(true);
    } catch (err) {
      setError(err.response?.data || err);
      setOpenError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <Typography variant="h6" component="h2" gutterBottom>
          Update Employee
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Payroll No"
              variant="outlined"
              value={payrollNo}
              onChange={(e) => setPayrollNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Clock No"
              variant="outlined"
              value={clockNo}
              onChange={(e) => setClockNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="shift-pattern-label">Shift Pattern</InputLabel>
              <Select
                defaultValue={""}
                value={shiftPattern}
                labelId="shift-pattern-label"
                fullWidth
                onChange={(e) => setShiftPattern(e.target.value)}
              >
                {shiftPatterns?.results.map((el) => (
                  <MenuItem key={el.id} value={el}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "1rem" }}
              onClick={handleUpdate}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => imageRef?.current.click()}
            >
              Add Photo
            </Button>
            <input
              ref={imageRef}
              className={classes.input}
              type="file"
              accept="image/*"
              capture="camera"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const TimesheetManageEmployees = () => {
  const [active, setActive] = useState(true);
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [filterEmployee, setFilterEmployee] = useState(null);
  const [search, setSearch] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();
  const classes = useStyles();
  const [debouncedSearch] = useDebounce(search, 500);

  const { data, isLoading, error, refetch } = useQuery(
    ["employees", active, page, maximumRows, filterEmployee?.id],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/timesheet/manage-employees?active=${active}&startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&employeeId=${filterEmployee?.id || ""}`
        )
        .then((res) => res.data)
  );

  const { data: shiftPatterns } = useQuery(["shiftPatterns"], () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-patterns`)
      .then((res) => res.data)
  );

  const employeesQuery = useQuery(["asEmployees", debouncedSearch], () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employees?search=${debouncedSearch}`
      )
      .then((res) => res.data)
  );

  const activeMutation = useMutation(
    (props) =>
      axios
        .put(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employee/${props.id}/active/${props.active}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(["employees", active], (oldData) => ({
          ...oldData,
          results: oldData.results.map((row) =>
            row.id === res.employeeId ? { ...row, active: res.active } : row
          ),
        }));
        setOpenSuccess(true);
      },
      onError: () => setOpenError(true),
    }
  );

  useEffect(() => {
    if (updateSuccess) {
      setOpenSuccess(true);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <UpdateModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        employee={selectedEmployee}
        setUpdateSuccess={setUpdateSuccess}
        shiftPatterns={shiftPatterns}
        setError={setErrorMessage}
        setOpenError={setOpenError}
      />

      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Typography color="textPrimary">Manage</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <AutoComplete
                id="search"
                options={employeesQuery.data?.results || []}
                getOptionLabel={(option) => option.name || ""}
                inputValue={search}
                onChange={(e, value) => setFilterEmployee(value)}
                onInputChange={(e, value) => setSearch(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search user" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                fullWidth
                value={active}
                onChange={(e) => setActive(e.target.value)}
                label="Active"
                variant="outlined"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Payroll No</TableCell>
                    <TableCell>Clock No</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Shift Pattern</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className={classes.imageContainer}>
                          <img
                            src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheet-profile/${row.profileImage}`}
                            alt="profile"
                            className={classes.image}
                          />
                        </div>
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.payrollNo}</TableCell>
                      <TableCell>{row.clockNo}</TableCell>
                      <TableCell>{row.active ? "Yes" : "No"}</TableCell>
                      <TableCell>{row.shiftPatternName}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "1rem" }}
                          onClick={() => {
                            setShowModal(true);
                            setSelectedEmployee(row);
                          }}
                        >
                          Edit
                        </Button>
                        {row.active && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              activeMutation.mutate({
                                active: false,
                                id: row.id,
                              })
                            }
                          >
                            Delete
                          </Button>
                        )}
                        {!row.active && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              activeMutation.mutate({
                                active: true,
                                id: row.id,
                              })
                            }
                          >
                            Enable
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={data.totalRows}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Employee updated Successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage?.message || "Error updating status"}
      />
    </Grid>
  );
};

export default TimesheetManageEmployees;
