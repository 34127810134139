import { useState, useEffect } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { utils, writeFileXLSX } from "xlsx";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutoComplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatTime, secondsToTime, getDayAndMonth } from "../utils/formatTime";
import useDebounce from "../utils/useDebounce";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
}));

const Timesheets = () => {
  const [startDate, setStartTime] = useState(null);
  const [endDate, setEndTime] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [auctionHouseName, setAuctionHouseName] = useState("");
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(10);
  const [active, setActive] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [breakTypes, setBreakTypes] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [fullReport, setFullReport] = useState(false);

  const classes = useStyles();
  const [debouncedSearch] = useDebounce(search, 500);

  const { data, isLoading, error } = useQuery(
    [
      "timesheets",
      selectedEmployee,
      startDate,
      endDate,
      auctionHouseName,
      page,
      maximumRows,
      active,
    ],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/report?employeeId=${
            selectedEmployee?.id || ""
          }&startDate=${startDate}&endDate=${endDate}&auctionHouse=${
            auctionHouseName || ""
          }&startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&active=${active}`
        )
        .then((res) => res.data),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const employeesQuery = useQuery(["asEmployees", debouncedSearch], () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employees?search=${debouncedSearch}`
      )
      .then((res) => res.data)
  );

  const csvDataMutator = useMutation(
    (props) =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/report?employeeId=${
            selectedEmployee?.id || ""
          }&startDate=${startDate}&endDate=${endDate}&auctionHouse=${
            auctionHouseName || ""
          }&startRowIndex=0&maximumRows=${data.totalRows}&fullReport=${
            props.fullReport
          }&active=${active}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setBreakTypes(res.breakTypes);
        setHolidays(res.holidays);
        setCsvData(res.results);
        setOpenSuccess(true);
      },
      onError: (error) => {
        setOpenError(true);
      },
    }
  );

  useEffect(() => {
    const today = new Date();
    const day = today.getDay();
    const daysUntilMonday = day === 0 ? 6 : day - 1;

    //TODO: use commented code
    const start = new Date(today);
    start.setDate(today.getDate() - daysUntilMonday);
    // start.setDate(today.getDate() - daysUntilMonday - 7);

    const end = new Date(start);
    end.setDate(start.getDate() + 6);

    setStartTime(start);
    setEndTime(end);
  }, []);

  useEffect(() => {
    if (csvData.length === 0) return;
    //Get all the employee
    const employees = [...new Set(csvData.map((el) => el.employee))];
    //Get all the payroll numbers
    const payrollNo = [];
    let selectedEmployee = null;
    for (let i = 0; i < employees.length; i++) {
      if (!selectedEmployee || employees[i] !== selectedEmployee) {
        selectedEmployee = employees[i];
        payrollNo.push(
          csvData.find((el) => el.employee === employees[i]).payrollNo
        );
      }
    }

    //Calculate the total number of selected days
    const timeDifference = endDate.getTime() - startDate.getTime();
    const totalDays = Math.round(timeDifference / (1000 * 3600 * 24));

    //Check if the date is a weekend
    const isWeekend = (date) => date.getDay() % 6 === 0;

    const allTimesheets = [];
    for (let i = 0; i < employees.length; i++) {
      //Get all the timesheets for the employee
      const timesheets = csvData.filter((el) => el.employee === employees[i]);
      const timesheetsDates = timesheets.map((el) => {
        if (!el.clockIn) return null;
        const d = new Date(el.clockIn);
        return d.getDate();
      });

      //Get all the holidays for the employee
      const employeeHolidays = holidays.filter(
        (el) => el.employeeId === timesheets[0].employeeId
      );
      const employeeHolidaysDates = [];
      const holidaysHours = [];
      if (employeeHolidays.length > 0) {
        //Add all the dates for the holidays
        employeeHolidays.forEach((h) => {
          const start = new Date(h.startDate);
          const end = new Date(h.endDate);

          //Get the number of days between the start and end date
          const timeDiff = Math.abs(end - start);
          const numberOfDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

          //Add the dates to the array
          for (let i = 0; i < numberOfDays; i++) {
            const date = new Date(start);
            date.setDate(date.getDate() + i);
            if (!isWeekend(date)) {
              date.setHours(0, 0, 0, 0);
              employeeHolidaysDates.push(date.getDate());
              h.hours < 8
                ? holidaysHours.push(h.hours)
                : holidaysHours.push(null);
            }
          }
        });
      }

      //Check if the timesheet at the date exists
      for (let j = 0; j <= totalDays; j++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + j);

        const index = employeeHolidaysDates.indexOf(date.getDate());
        if (index !== -1) {
          allTimesheets.push({
            date: date,
            employee: employees[i],
            payrollNo: payrollNo[i],
            clockIn: "",
            clockOut: "",
            totalWorkTime: 0,
            totalBreaks: 0,
            comment: !holidaysHours[index]
              ? "Full Day Holiday"
              : `${holidaysHours[index]}h Holiday`,
          });
        } else if (!timesheetsDates.includes(date.getDate())) {
          allTimesheets.push({
            date: date,
            employee: employees[i],
            payrollNo: payrollNo[i],
            clockIn: "",
            clockOut: "",
            totalWorkTime: 0,
            totalBreaks: 0,
            comment: "Absent",
          });
          continue;
        }

        //If there is an existing timesheet for the date, add it to the array
        const existingTimesheet = timesheets.filter(
          (el) => new Date(el.clockIn).getDate() === date.getDate()
        );

        if (existingTimesheet.length > 0) {
          existingTimesheet.forEach((el) => {
            el.date = date;
            allTimesheets.push(el);
          });
        }
      }
    }

    //Sort the data by name and date
    allTimesheets.sort((a, b) => {
      const employeeComparison = a.employee.localeCompare(b.employee);
      if (employeeComparison !== 0) {
        return employeeComparison;
      }
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate - bDate;
    });

    //Format the data to be exported
    const formatBreaks = (breaks) =>
      breaks?.map((b) => ({
        [`Start ${b.name}`]: b.startTime && formatTime(b.startTime),
        [`End ${b.name}`]: b.endTime && formatTime(b.endTime),
        [`Total ${b.name}`]: formatSeconds(b.totalTimeInSeconds),
        [`Start ${b.name} Image`]:
          b.startImage &&
          `${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${b.startImage}`,
        [`End ${b.name} Image`]:
          b.endImage &&
          `${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${b.endImage}`,
      })) || [];

    let exportData = allTimesheets.map((el) => ({
      Name: el.employee,
      "Payroll No": el.payrollNo,
      Date: getDayAndMonth(el.date),
      "Clock In": el.clockIn && formatTime(el.clockIn),
      "Clock Out": el.clockOut && formatTime(el.clockOut),
      "Total Work Time": formatSeconds(el.totalWorkTime),
      "Total Breaks": formatSeconds(el.totalBreaks),
      "Clocked In Late By": el.clockInDifference && el.clockInDifference,
      "Clocked Out Early By": el.clockOutDifference && el.clockOutDifference,
      Comment: el.comment,
      ...(fullReport && {
        "Clock In Image":
          el.clockInImage &&
          `${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${el.clockInImage}`,
        "Clock Out Image":
          el.clockOutImage &&
          `${process.env.REACT_APP_IMAGE_CDN_URL}/timesheets/${el.clockOutImage}`,
        ...formatBreaks(el.breaks).reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        ),
      }),
    }));

    // //Add empty row between employees
    // for (let i = 0; i < employees.length; i++) {
    //   const lastIndex = exportData.findLastIndex(
    //     (el) => el.Name === employees[i]
    //   );
    //   exportData.splice(lastIndex + 1, 0, {});
    // }

    //Add the header
    let header = [
      "Name",
      "Payroll No",
      "Date",
      "Clock In",
      "Clock Out",
      "Total Work Time",
      "Total Breaks",
      "Comment",
    ];
    if (fullReport) {
      const breakTypesHeader = breakTypes.map((el) => [
        `Start ${el.name}`,
        `End ${el.name}`,
        `Total ${el.name}`,
        `Start ${el.name} Image`,
        `End ${el.name} Image`,
      ]);

      header.push(
        ...["Clock In Image", "Clock Out Image", ...breakTypesHeader.flat(1)]
      );
    }
    const ws = utils.json_to_sheet(exportData, {
      header: header,
    });

    //Format the work and break cell to display as time
    const totalWorkTimeColumnIndex = 5;
    const totalBreaksColumnIndex = 6;
    for (let i = 1; i <= exportData.length; i++) {
      const workCell = utils.encode_cell({
        r: i,
        c: totalWorkTimeColumnIndex,
      });
      const breakCell = utils.encode_cell({
        r: i,
        c: totalBreaksColumnIndex,
      });
      if (ws[workCell]) {
        ws[workCell].z = "hh:mm:ss";
        ws[workCell].f = `=TIMEVALUE("${ws[workCell].v}")`;
      }
      if (ws[breakCell]) {
        ws[breakCell].z = "hh:mm:ss";
        ws[breakCell].f = `=TIMEVALUE("${ws[breakCell].v}")`;
      }
    }

    //Make the images links URL
    if (fullReport) {
      const clockInImageColumnIndex = 8;
      const clockOutImageColumnIndex = 9;
      const breakTypesIndexes = [];
      for (let i = 0; i < breakTypes.length; i++) {
        const si = header.indexOf(`Start ${breakTypes[i].name} Image`);
        const ei = header.indexOf(`End ${breakTypes[i].name} Image`);
        breakTypesIndexes.push(si);
        breakTypesIndexes.push(ei);
      }

      const addImageLink = (loopI, index) => {
        const cell = utils.encode_cell({
          r: loopI,
          c: index,
        });
        if (ws[cell]) {
          ws[cell].l = {
            Target: ws[cell].v,
            Tooltip: "Click to open",
          };
          ws[cell].v = "Click to view Image";
        }
      };
      for (let i = 1; i <= exportData.length; i++) {
        addImageLink(i, clockInImageColumnIndex);
        addImageLink(i, clockOutImageColumnIndex);
        breakTypesIndexes.forEach((el) => addImageLink(i, el));
      }
    }

    const name = fullReport
      ? `timesheet-report-full ${getDayAndMonth(startDate)} - ${getDayAndMonth(
          endDate
        )}.xlsx`
      : `timesheet-report ${getDayAndMonth(startDate)} - ${getDayAndMonth(
          endDate
        )}.xlsx`;
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Timesheet Report");
    writeFileXLSX(wb, name);
    setCsvData([]);
    setFullReport(false);

    return () => {
      setCsvData([]);
      setFullReport(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  // const formatSeconds = (seconds) => {
  //   if (!seconds) return "00:00";
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   return `${hours.toString().padStart(2, "0")}:${minutes
  //     .toString()
  //     .padStart(2, "0")}`;
  // };

  const formatSeconds = (seconds) => {
    if (!seconds) return "00:00:00";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    const name = house ? house.name.split("Simon Charles ")[1] : "";
    setCurrentAuctionHouse(house);
    setAuctionHouseName(name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Timesheet</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <AutoComplete
                  id="search"
                  options={employeesQuery.data?.results || []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={search}
                  onChange={(e, value) => setSelectedEmployee(value)}
                  onInputChange={(e, value) => setSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartTime(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndTime(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  {auctionHousesQuery.data && (
                    <Select
                      value={currentAuctionHouse?.name}
                      label="Auction Houses"
                      onChange={handleSelect}
                      variant="outlined"
                    >
                      <MenuItem value="">All</MenuItem>
                      {auctionHousesQuery.data.map((house) => {
                        return (
                          <MenuItem value={house.name} key={house.id}>
                            {house.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "0.5rem" }}>
              <Grid item xs={12} md={3}>
                <Select
                  value={active}
                  onChange={(e) => setActive(e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={9} className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => csvDataMutator.mutate({ fullReport: false })}
                >
                  Download Report
                </Button>
                <Button
                  style={{ marginLeft: "1rem" }}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setFullReport(() => true);
                    csvDataMutator.mutate({ fullReport: true });
                  }}
                >
                  Download Full Report
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <Typography>Error: {error.message}</Typography>}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee</TableCell>
                    <TableCell>Clock In</TableCell>
                    <TableCell>Clock Out</TableCell>
                    <TableCell>Auction House</TableCell>
                    <TableCell>Total Breaks</TableCell>
                    <TableCell>Total Work Time</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell>{row.employee}</TableCell>
                      <TableCell>
                        {row.clockIn && formatTime(row.clockIn)}
                      </TableCell>
                      <TableCell>
                        {row.clockOut && formatTime(row.clockOut)}
                      </TableCell>
                      <TableCell>{row.auctionHouse}</TableCell>
                      <TableCell>{secondsToTime(row.totalBreaks)}</TableCell>
                      <TableCell>{secondsToTime(row.totalWorkTime)}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 50]}
                component="div"
                count={data.totalRows}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default Timesheets;
