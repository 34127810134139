import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";

const AuctionHouses = () => {
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    console.log("To Delete");
    // selected.forEach((id) => {
    //   axios
    //     .delete(
    //       `${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses/${id}/delete`
    //     )
    //     .then((res) => {
    //       auctionHousesQuery.refetch();
    //       setSelected([]);
    //     })
    //     .catch((err) =>
    //       console.error(`Error deleting auction house ${id}: ${err}`)
    //     );
    // });
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Auction Houses</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Link to="/auction-houses/new">Create New Auction House</Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={"Manage Auction Houses"}
                numSelected={selected.length}
                handleDelete={handleDelete}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Street</TableCell>
                      <TableCell>Address 2</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Post Code</TableCell>
                      <TableCell>Tel</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>aom Auction House ID</TableCell>
                      {/* <TableCell></TableCell>
                      <TableCell></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auctionHousesQuery.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((auctionHouse) => {
                        const isItemSelected = isSelected(auctionHouse.id);

                        return (
                          <TableRow
                            key={auctionHouse.id}
                            hover
                            onClick={(event) =>
                              handleSelect(event, auctionHouse.id)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell>{auctionHouse.id}</TableCell>
                            <TableCell>{auctionHouse.name}</TableCell>
                            <TableCell>{auctionHouse.street}</TableCell>
                            <TableCell>{auctionHouse.address2}</TableCell>
                            <TableCell>{auctionHouse.city}</TableCell>
                            <TableCell>{auctionHouse.country}</TableCell>
                            <TableCell>{auctionHouse.postCode}</TableCell>
                            <TableCell>{auctionHouse.tel}</TableCell>
                            <TableCell>{auctionHouse.email}</TableCell>
                            <TableCell>
                              {auctionHouse.aomsAuctionHouseId}
                            </TableCell>
                            {/* <TableCell>
                              <Link
                                to={{
                                  pathname: `/auction-houses/${auctionHouse.id}/edit`,
                                  state: { article: auctionHouse },
                                }}
                              >
                                Edit
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link to={`/auction-houses`}>View</Link>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={auctionHousesQuery.data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default AuctionHouses;
