import axios from "axios";
import { useQuery } from "react-query";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SmallStat from "./SmallStat";
import useTimer from "../../../utils/useTimer";

const useStyles = makeStyles((theme) => ({
  warning: {
    color: "#ff8c00",
  },
  danger: {
    color: "#ff0000",
  },
}));

const LastPicked = ({ date }) => {
  const [time] = useTimer(date, "up");
  const classes = useStyles();

  const now = new Date();
  const lastPicked = new Date(date);
  const diff = now - lastPicked;
  const minutes = Math.floor(diff / 1000 / 60);

  return (
    <span
      className={
        (minutes > 30) & (minutes < 60)
          ? classes.warning
          : minutes > 60
          ? classes.danger
          : ""
      }
    >
      <b>{time}</b>
    </span>
  );
};

const Lotting = ({ currentAuctionHouse, showTable }) => {
  const { data, isLoading, error } = useQuery(
    ["lotting", currentAuctionHouse, showTable],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/lotting?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Lotting</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat title="Created" value={data?.totalLots} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data?.lottingStats && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Lotter</TableCell>
                        <TableCell>Last Created</TableCell>
                        <TableCell>Created Lots</TableCell>
                        <TableCell>Auction House</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.lottingStats.map((lotter) => (
                        <TableRow key={lotter.lotterName}>
                          <TableCell>{lotter.lotterName}</TableCell>
                          <TableCell>
                            <LastPicked date={lotter.lastCreated} />
                          </TableCell>
                          <TableCell>{lotter.createdLots}</TableCell>
                          <TableCell>{lotter.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Lotting;
