import React, { useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import AutoComplete from "@mui/lab/Autocomplete";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatTime, formatDate } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import useDebounce from "../utils/useDebounce";

const timesheetStatuses = {
  "Clocked In": 0,
  "Clocked Out": 1,
  "On Break": 2,
  "Not Present": -1,
};

const useStyles = makeStyles((theme) => ({
  status: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
    backgroundColor: "red",
  },
  in: {
    backgroundColor: "green",
  },
  break: {
    backgroundColor: "yellow",
  },
  holiday: {
    backgroundColor: "blue",
  },
  imageContainer: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "gray",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  dates: {
    display: "flex",
    gap: "1rem",
  },
}));

const StatusComponent = ({ el }) => {
  const classes = useStyles();

  const statusClass = `${classes.status} ${
    el.isOnHoliday
      ? classes.holiday
      : el.status === timesheetStatuses["Clocked In"]
      ? classes.in
      : el.status === timesheetStatuses["On Break"]
      ? classes.break
      : ""
  }`;

  return (
    <>
      <span className={statusClass} />
      {el.isOnHoliday
        ? "On Holiday"
        : getKeyByValue(timesheetStatuses, el.status)}
    </>
  );
};

const TimesheetAttendance = () => {
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [auctionHouseName, setAuctionHouseName] = useState("");
  const [isNightShift, setIsNightShift] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const [debouncedSearch] = useDebounce(search, 500);

  const { data, loading, error, refetch } = useQuery(
    [
      "timesheets",
      page,
      maximumRows,
      startDate,
      selectedEmployee,
      status,
      currentAuctionHouse?.id,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/timesheet/attendance?startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&startDate=${startDate}&endDate=${endDate}&employeeId=${
            selectedEmployee?.id || ""
          }&status=${status}&auctionHouse=${auctionHouseName || ""}`
        )
        .then((res) => res.data)
  );

  const employeesQuery = useQuery(["asEmployees", debouncedSearch], () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employees?search=${debouncedSearch}`
      )
      .then((res) => res.data)
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const managerClockOutMutation = useMutation(
    (props) =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/clock-out/manager`,
          {
            employeeId: props.employeeId,
            timesheetId: props.timesheetId,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        refetch();
        setOpenSuccess(true);
      },
      onError: () => {
        setOpenError(true);
      },
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    const name = house ? house.name.split("Simon Charles ")[1] : "";
    setAuctionHouseName(name);
    setCurrentAuctionHouse(house);
  };

  const handleSetShift = () => {
    setIsNightShift(!isNightShift);
    if (!isNightShift) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 1);
      setStartDate(newDate);
    } else {
      const newDate = new Date();
      setStartDate(newDate);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Typography color="textPrimary">Attendance</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <AutoComplete
                id="search"
                options={employeesQuery.data?.results || []}
                getOptionLabel={(option) => option.name || ""}
                inputValue={search}
                onChange={(e, value) => setSelectedEmployee(value)}
                onInputChange={(e, value) => setSearch(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search user" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse?.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                    variant="outlined"
                  >
                    <MenuItem value="">All</MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">All</MenuItem>
                  {Object.keys(timesheetStatuses).map((key) => {
                    return (
                      <MenuItem value={timesheetStatuses[key]} key={key}>
                        {key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {loading && <span>Loading...</span>}
          {error && <span>Error: {error.message}</span>}
          <Grid item xs={12} className={classes.dateContainer}>
            <div className={classes.dates}>
              <Typography variant="h6" gutterBottom>
                {formatDate(startDate)}
              </Typography>
              {isNightShift && (
                <Typography variant="h6" gutterBottom>
                  {formatDate(endDate)}
                </Typography>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSetShift}
            >
              {isNightShift ? "Day Shift" : "Night Shift"}
            </Button>
          </Grid>
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Clock In</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el, i) => (
                    <TableRow key={el.id || i}>
                      <TableCell>
                        <div className={classes.imageContainer}>
                          <img
                            src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheet-profile/${el.profileImage}`}
                            alt="profile"
                            className={classes.image}
                          />
                        </div>
                      </TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.auctionHouse}</TableCell>
                      <TableCell>
                        {el.clockIn && formatTime(el.clockIn)}
                      </TableCell>
                      <TableCell>
                        <StatusComponent el={el} />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          component={Link}
                          to={`/timesheets/employee/${el.employeeId}`}
                        >
                          View
                        </Button>
                        {el.status === timesheetStatuses["Clocked In"] && (
                          <Button
                            style={{ marginLeft: "1rem" }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() =>
                              managerClockOutMutation.mutate({
                                employeeId: el.employeeId,
                                timesheetId: el.id,
                              })
                            }
                          >
                            Clock Out
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={data.totalRows}
                page={page}
                rowsPerPage={maximumRows}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Clocked out successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={
          managerClockOutMutation?.error?.message || "Error! Please try again"
        }
      />
    </Grid>
  );
};

export default TimesheetAttendance;
