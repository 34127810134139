import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TextField,
  FormControl,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
//import { ServiceBusClient } from "@azure/service-bus";
import ErrorAlert from "../components/misc/ErrorAlert";

const APPLICATION_IDS = Object.freeze({
  user: 20,
});

const ROLE_IDS = Object.freeze({
  idVerified: 31,
});

const useStyles = makeStyles(() => ({
  fullwidthContent: {
    width: "100%",
    maxWidth: "720px",
  },
}));

const VerificationId = (props) => {
  const classes = useStyles();

  const { id, userId } = useParams();

  const [declineReason, setDeclineReason] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verificationIdQuery = useQuery([`verificationId-${id}`], () =>
    axios
      .get(
        `${process.env.REACT_APP_AUTH_API_URL}/account/verification-ids/${id}`
      )
      .then((res) => {
        return res.data;
      })
  );

  const verificationImageId = verificationIdQuery?.data?.verificationImageId;
  const verificationUserId = verificationIdQuery?.data?.userId;

  const userSummaryQuery = useQuery(
    [`userSummary:${userId}`, verificationUserId],
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/buyers/${userId}`)
        .then((res) => res.data)
  );

  const verificationImageQuery = useQuery(
    [`verificationImage-${id}`, verificationImageId],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_IMAGE_UPLOAD_API}/verification-id/${verificationImageId}`
        )
        .then((res) => res.data)
  );

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "declineReason":
        setDeclineReason(value);
        break;
      default:
        break;
    }
  };

  const handleVerify = async () => {
    try {
      // Add user application to verified users
      await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/applications`,
        {
          applicationId: APPLICATION_IDS.user,
        }
      );

      // Add idVerified role to verified users
      await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/roles`,
        {
          roleId: ROLE_IDS.idVerified,
        }
      );

      // // Email verified user
      // const sbClient = new ServiceBusClient(
      //   process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING
      // );
      // const sender = sbClient.createSender(
      //   process.env.REACT_APP_AUTH_EMAIL_QUEUE
      // );
      // await sender.sendMessages({
      //   subject: "user-id-verified",
      //   body: {
      //     userId,
      //     first_name: userSummaryQuery.data.firstName,
      //     url: "https://simoncharles-auctioneers.co.uk",
      //     current_year: new Date().getFullYear().toString(),
      //   },
      // });
      // await sender.close();

      // Delete verification Image
      await axios.delete(
        `${process.env.REACT_APP_IMAGE_UPLOAD_API}/verification-id/${verificationImageId}`
      );

      // Delete verification Image ID
      await axios.delete(
        `${process.env.REACT_APP_AUTH_API_URL}/account/verification-ids/${id}`
      );

      setSuccessMessage("ID verified successfully");
      setOpenSuccess(true);
      props.history.replace("/verification-ids");
    } catch (error) {
      console.error("There was an error verifying the ID:" + error);
      setErrorMessage(`Error verifying the ID: ${error}`);
      setOpenError(true);
    }
  };

  const handleDecline = async (e) => {
    e.preventDefault();

    try {
      // // Email declined user
      // const sbClient = new ServiceBusClient(
      //   process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING
      // );
      // const sender = sbClient.createSender(
      //   process.env.REACT_APP_AUTH_EMAIL_QUEUE
      // );
      // await sender.sendMessages({
      //   subject: "user-declined",
      //   body: {
      //     userId,
      //     first_name: userSummaryQuery.data.firstName,
      //     decline_reason: declineReason,
      //     url: "https://simoncharles-auctioneers.co.uk/account/account-security",
      //     current_year: new Date().getFullYear().toString(),
      //   },
      // });
      // await sender.close();

      // Delete verification Image
      await axios.delete(
        `${process.env.REACT_APP_IMAGE_UPLOAD_API}/verification-id/${verificationImageId}`
      );

      // Delete verification Image ID
      await axios.delete(
        `${process.env.REACT_APP_AUTH_API_URL}/account/verification-ids/${id}`
      );

      setSuccessMessage("ID declined successfully");
      setOpenSuccess(true);
      props.history.replace("/verification-ids");
    } catch (error) {
      console.error("There was an error declining the ID:" + error);
      setErrorMessage(`Error declining the ID: ${error}`);
      setOpenError(true);
    }
  };

  return <>
    {verificationIdQuery.isLoading ? (
      <CircularProgress />
    ) : verificationIdQuery.error ? (
      "An error has occurred: " + verificationIdQuery.error.message
    ) : (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/verification-ids`}>IDs Requiring Verification</Link>
            <Typography color="textPrimary">User's ID</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6">User's Uploaded ID</Typography>
            {verificationImageQuery.isLoading ? (
              <CircularProgress />
            ) : verificationImageQuery.error ? (
              <Alert severity="error">
                {`An error has occurred: ${verificationImageQuery.error.message}`}
              </Alert>
            ) : (
              !verificationImageQuery.isIdle && (
                <img
                  src={
                    "data:image/png;base64," +
                    verificationImageQuery.data.verificationImage
                  }
                  className={classes.fullwidthContent}
                  alt="User's verification ID"
                />
              )
            )}
            <Alert severity="info">
              Requirements:
              <ul>
                <li>Photographic ID</li>
                <li>Name matches the name on the account</li>
                <li>
                  If an address is present, the address matches the user's
                  registered address
                </li>
              </ul>
              This image is deleted when either "Verify" or "Decline" are
              clicked.
            </Alert>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid>
              <Typography variant="h6" gutterBottom>
                Addresses
              </Typography>
            </Grid>
            {userSummaryQuery.isLoading ? (
              <CircularProgress />
            ) : userSummaryQuery.error ? (
              <Alert severity="error">
                {`An error has occurred: ${userSummaryQuery.error.message}`}
              </Alert>
            ) : !userSummaryQuery.isIdle &&
              userSummaryQuery.data?.addresses.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Street</TableCell>
                      <TableCell>Address 2</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>County</TableCell>
                      <TableCell>Post Code</TableCell>
                      <TableCell>Primary</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userSummaryQuery.data.addresses.map((address) => {
                      return (
                        <TableRow key={address.id} hover>
                          <TableCell>
                            <Link to={`/users/${userId}`}>{userId}</Link>
                          </TableCell>
                          <TableCell>{address.name || "-"}</TableCell>
                          <TableCell>{address.street || "-"}</TableCell>
                          <TableCell>{address.address2 || "-"}</TableCell>
                          <TableCell>{address.city || "-"}</TableCell>
                          <TableCell>{address.county || "-"}</TableCell>
                          <TableCell>{address.postCode || "-"}</TableCell>
                          <TableCell>
                            <Checkbox
                              checked={
                                userSummaryQuery.data.billingAddressId ===
                                address.id
                              }
                              readOnly
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid container item>
                <Typography varaint="body1">
                  No addresses for this user.
                </Typography>
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item component="form" onSubmit={handleDecline} xs={12}>
            <Box p={2} mb={3} component={Paper}>
              <FormControl variant="outlined" fullWidth size="small">
                <TextField
                  label="Decline Reason"
                  name="declineReason"
                  value={declineReason}
                  onChange={handleInput}
                  variant="outlined"
                  fullWidth
                  size="small"
                  required
                />
                <FormHelperText>
                  This will be included in the email send to the user.
                </FormHelperText>
                <Box mt={2}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    fullWidth
                  >
                    Decline
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              onClick={handleVerify}
              color="primary"
              fullWidth
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )}
    <SuccessAlert
      openSuccess={openSuccess}
      setOpenSuccess={setOpenSuccess}
      message={successMessage}
    />
    <ErrorAlert
      openError={openError}
      setOpenError={setOpenError}
      message={errorMessage}
    />
  </>;
};

export default VerificationId;
