import { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EnhancedTableToolbar from "../../components/misc/EnhancedTableToolbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { utils, writeFileXLSX } from "xlsx";
import { formatCurrency } from "../../utils/formatCurrency";

const deductDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const VendorTopTen = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const minDate = deductDays(new Date(), 40);
  const [results, setResults] = useState([]);
  const limit = 10;

  useEffect(() => {
    if (!startDate || !endDate) return;
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stats/vendors/tophammer?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&limit=${limit}`
      )
      .then((res) => {
        setResults(res.data.results);
      });
  }, [startDate, endDate]);

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999);
    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    setStartDate(date);
  };

  const downloadXlsx = () => {
    const data = results.map((result) => ({
      VendorName: result.vendorName,
      TotalHammerPrice: result.totalHammerPrice,
    }));
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, `Vendor Top ${limit}`);
    writeFileXLSX(
      wb,
      `Top ${limit} Vendors ${startDate.toDateString()} - ${endDate.toDateString()}.xlsx`
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color={"textPrimary"}>Vendor Top Ten</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <EnhancedTableToolbar title={"Select Filter Dates"} />
            <Grid container justifyContent="space-around">
              <DatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label="Auction End Date From:"
                minDate={minDate}
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <DatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label="Auction End Date Till:"
                minDate={minDate}
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <Button variant="outlined" onClick={downloadXlsx}>
                Download XLSX
              </Button>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Total Hammer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={`${result.vendorId}`}>
                    <TableCell>{result.vendorName}</TableCell>
                    <TableCell>
                      {formatCurrency(result.totalHammerPrice)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VendorTopTen;
