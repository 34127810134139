import { useState } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutoComplete from "@mui/lab/Autocomplete";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { getDayAndMonth } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import useDebounce from "../utils/useDebounce";

const holidaysStatuses = {
  Requested: 0,
  Approved: 1,
};

const TimesheetHolidays = () => {
  const [search, setSearch] = useState("");
  const [startDate, setStartTime] = useState("");
  const [endDate, setEndTime] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const queryClient = useQueryClient();
  const [debouncedSearch] = useDebounce(search, 500);

  const { data, isLoading, error } = useQuery(
    ["asHolidays", startDate, endDate, selectedEmployee],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/timesheet-holidays?startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&startDate=${startDate}&endDate=${endDate}&employeeId=${
            selectedEmployee?.id || ""
          } `
        )
        .then((res) => res.data)
  );

  const employeesQuery = useQuery(["asEmployees", debouncedSearch], () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/employees?search=${debouncedSearch}`
      )
      .then((res) => res.data)
  );

  const deleteMutation = useMutation(
    (id) =>
      axios
        .delete(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet-holidays/${id}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setOpenSuccess(true);
        queryClient.setQueryData(
          ["asHolidays", startDate, endDate, selectedEmployee],
          (old) => {
            return {
              ...old,
              results: old.results.filter((item) => item.id !== res.id),
            };
          }
        );
      },
      onError: (err) => setOpenError(true),
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Holidays</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <AutoComplete
                  id="search"
                  options={employeesQuery.data?.results || []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={search}
                  onChange={(e, value) => setSelectedEmployee(value)}
                  onInputChange={(e, value) => setSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartTime(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndTime(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box component={Paper} p={3}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <ErrorAlert error={error} />}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{getDayAndMonth(row.startDate)}</TableCell>
                      <TableCell>{getDayAndMonth(row.endDate)}</TableCell>
                      <TableCell>{row.hours}h</TableCell>
                      <TableCell>
                        {getKeyByValue(holidaysStatuses, row.status)}
                      </TableCell>
                      <TableCell size="small">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => deleteMutation.mutate(row.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 50]}
                component="div"
                count={data.totalRows}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Successfully deleted holiday"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error deleting holiday"
      />
    </Grid>
  );
};

export default TimesheetHolidays;
