import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import Articles from "../components/B2B/Articles";

const B2BAddBlogArticle = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const addBlogEntryMutation = useMutation(
    ({ title, summary, articleDate, mainImage, content, showContactUs }) =>
      axios
        .put(`${process.env.REACT_APP_CONTENT_API_URL}/b2b-blog-articles`, {
          title,
          summary,
          articleDate,
          mainImage,
          content,
          showContactUs,
        })
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/b2b/blog-articles">Blog Articles</Link>
          <Typography color="textPrimary">Add</Typography>
        </Breadcrumbs>
      </Grid>
      <Articles submitMutation={addBlogEntryMutation} />

      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Article Created"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error Creating Article"
      />
    </Grid>
  );
};

export default B2BAddBlogArticle;
