import {
  IconButton,
  lighten,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import clsx from "clsx";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    title,
    numSelected,
    handleDelete,
    handleRefresh,
    handleCreate,
    createTitle,
    printComponent,
    handleCancel,
    disabled,
  } = props;

  return (
    <Toolbar
      disabled={disabled}
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          disabled={disabled}
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          disabled={disabled}
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            disabled={disabled}
            aria-label="delete"
            onClick={handleDelete}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {handleRefresh ? (
        <Tooltip title="Refresh">
          <IconButton
            disabled={disabled}
            aria-label="refresh"
            onClick={handleRefresh}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {handleCancel ? (
        <Tooltip title="Cancel Booking">
          <IconButton
            disabled={disabled}
            aria-label="cancel"
            onClick={handleCancel}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {handleCreate ? (
        <Tooltip title={createTitle}>
          <IconButton
            disabled={disabled}
            aria-label="refresh"
            onClick={handleCreate}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {printComponent ? (
        <ReactToPrint content={() => printComponent}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <IconButton
                disabled={disabled}
                onClick={handlePrint}
                size="large"
              >
                <PrintIcon />
              </IconButton>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      ) : (
        ""
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
