import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import SuccessAlert from "../components/misc/SuccessAlert";

const HelpArticleTopics = () => {
  const helpArticleTopicsQuery = useQuery(
    "scHelpArticleTopics",
    () =>
      axios
        .get(`${process.env.REACT_APP_CONTENT_API_URL}/help-article-topics`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selected, setSelected] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const data = {
      title,
      description,
    };

    axios
      .post(
        `${process.env.REACT_APP_CONTENT_API_URL}/help-article-topics`,
        data
      )
      .then((res) => {
        setOpenSuccess(true);
        setTitle("");
        setDescription("");
        helpArticleTopicsQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDelete = () => {
    selected.forEach((id) => {
      axios
        .delete(
          `${process.env.REACT_APP_CONTENT_API_URL}/help-article-topics/${id}/delete`
        )
        .then((res) => {
          helpArticleTopicsQuery.refetch();
          setOpenSuccess(true);
          setSelected([]);
        })
        .catch((err) =>
          console.error(`Error deleting help article topics ${id}: ${err}`)
        );
    });
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      {helpArticleTopicsQuery.isLoading ? (
        <CircularProgress />
      ) : helpArticleTopicsQuery.error ? (
        "An error has occurred: " + helpArticleTopicsQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/help-articles">Manage Help Articles</Link>
              <Typography color="textPrimary">
                Manage Help Article Topics
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={"Manage Help Article Topics"}
                numSelected={selected.length}
                handleDelete={handleDelete}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {helpArticleTopicsQuery.data.map((helpArticleTopic) => {
                      const isItemSelected = isSelected(helpArticleTopic.id);

                      return (
                        <TableRow
                          key={helpArticleTopic.id}
                          hover
                          onClick={(event) =>
                            handleSelect(event, helpArticleTopic.id)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                          <TableCell>{helpArticleTopic.title}</TableCell>
                          <TableCell>{helpArticleTopic.description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                spacing={4}
                component="form"
                onSubmit={handleCreate}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Create New Help Article Topic
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    name="title"
                    value={title}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Description"
                    name="description"
                    value={description}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    disabled={title === ""}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Delete Successful"
      />
    </Fragment>
  );
};

export default HelpArticleTopics;
