import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RefundList from "../components/Refunds/RefundList/RefundList";

const UserRefunds = (props) => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const userRefundsQuery = useQuery(
    [`userRefunds-${id}`, page],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_QMS_API_URL
          }/refunds/users/${id}?maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          return res.data;
        }),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/users">Users</Link>
          <Link to={`/users/${id}`}>{id}</Link>
          <Typography color="textPrimary">Refunds</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">User Refunds</Typography>
            </Grid>
            <Grid item>
              <Link to={`/qms/refunds/new`}>
                <Button variant="outlined" startIcon={<AddIcon />}>
                  Add new
                </Button>
              </Link>
            </Grid>
          </Grid>
          {userRefundsQuery.isLoading ? (
            <CircularProgress />
          ) : userRefundsQuery.error ? (
            <Alert severity="warning">
              {"An error has occurred: " + userRefundsQuery.error.message}
            </Alert>
          ) : userRefundsQuery?.data.refunds.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Deducted From Invoice</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Invoice ID</TableCell>
                    <TableCell>Ticket ID</TableCell>
                    <TableCell>Caused By</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Comments</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {userRefundsQuery.data && (
                  <RefundList refunds={userRefundsQuery.data.refunds} />
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={userRefundsQuery?.data.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          ) : (
            <Alert severity="info">User has no associated refunds</Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserRefunds;
