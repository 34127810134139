import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Label } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";

const getStartOfYear = (year) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(0);
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  vendorStart: {
    "border-left": "1px solid #cbcbcb",
  },
});

const ReportsMonthlyTotals = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState(
    getStartOfYear(new Date().getFullYear())
  );
  const [endTime, setEndTime] = useState(
    getStartOfYear(new Date().getFullYear() + 1)
  );
  const [loading, setLoading] = useState(false);
  const [invoiceHeadings, setInvoiceHeadings] = useState([]);
  const [sbiHeadings, setSbiHeadings] = useState([]);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(new Date().getFullYear());

  const onUpdateClick = () => {
    setStartTime(getStartOfYear(parseInt(startYear)));
    setEndTime(getStartOfYear(parseInt(endYear) + 1));
  };

  //   const handleYearChange = (e) => {
  //     setYear(e.target.value);
  //     setStartTime(getStartOfYear(e.target.value));
  //     setEndTime(getStartOfYear(e.target.value + 1));
  //   };

  const currencyFormatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/reports/monthlytotals?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`
      )
      .then((res) => {
        const results = res.data.map((week) => {
          return {
            ...week,
            turnover: week.selfBillingInvoiceHammerPrice + week.buyersPremium,
          };
        });

        const invoiceHeadings = [];
        const sbiHeadings = [];
        for (const month of results) {
          for (const invoiceCharge of month.invoiceCharges) {
            if (
              invoiceHeadings.findIndex(
                (h) => h.id === invoiceCharge.chargeTypeId
              ) === -1
            ) {
              invoiceHeadings.push({
                id: invoiceCharge.chargeTypeId,
                name: invoiceCharge.chargeType,
              });
            }
            if (
              invoiceCharge.chargeType === "Courier Handling Charge" ||
              invoiceCharge.chargeType === "Fly Tipping Fee" ||
              invoiceCharge.chargeType === "Internet Charge" ||
              invoiceCharge.chargeType === "Lot Processing Fee" ||
              invoiceCharge.chargeType === "Pallet Charge" ||
              invoiceCharge.chargeType === "Postage" ||
              invoiceCharge.chargeType === "Postage (Manual)" ||
              invoiceCharge.chargeType === "Storage Charge"
            ) {
              month.turnover += invoiceCharge.value;
            }
          }
          for (const sbiCharge of month.selfBillingInvoiceCharges) {
            if (
              sbiHeadings.findIndex((h) => h.id === sbiCharge.chargeTypeId) ===
              -1
            ) {
              sbiHeadings.push({
                id: sbiCharge.chargeTypeId,
                name: sbiCharge.chargeType,
              });
            }
            if (
              sbiCharge.chargeType === "Advertising" ||
              sbiCharge.chargeType === "Collection Charge EXC VAT" ||
              sbiCharge.chargeType === "Lotting Fee"
            ) {
              month.turnover += sbiCharge.value;
            }
          }
        }
        setData(results);
        setInvoiceHeadings(
          invoiceHeadings.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        setSbiHeadings(
          sbiHeadings.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [startTime, endTime]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Monthly Totals</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label>Start Year:</Label>
              <Input
                type="number"
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
              />

              <Label>End Year:</Label>
              <Input
                type="number"
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={onUpdateClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {loading && (
        <Grid item xs={12}>
          Loading...
        </Grid>
      )}
      {!loading && data.length === 0 && (
        <Grid item xs={12}>
          No data
        </Grid>
      )}
      {!loading && data.length > 0 && (
        <Grid item xs={12}>
          <Box component={Paper}>
            <Grid container p={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Month</TableCell>
                        <TableCell className={classes.vendorStart}>
                          Hammer Price
                        </TableCell>
                        <TableCell className={classes.vendorStart}>
                          Buyers Premium
                        </TableCell>
                        {invoiceHeadings.map((heading) => (
                          <TableCell key={heading.id}>{heading.name}</TableCell>
                        ))}
                        <TableCell className={classes.vendorStart}>
                          Vendor Commission
                        </TableCell>
                        {sbiHeadings.map((heading) => (
                          <TableCell key={heading.id}>{heading.name}</TableCell>
                        ))}
                        <TableCell className={classes.vendorStart}>
                          Total Fee Income
                        </TableCell>
                        <TableCell className={classes.vendorStart}>
                          Turnover
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((month) => (
                        <TableRow key={`${month.year}-${month.month}`} hover>
                          <TableCell>{month.year}</TableCell>
                          <TableCell>{month.month}</TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(
                              month.selfBillingInvoiceHammerPrice
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(month.buyersPremium)}
                          </TableCell>
                          {invoiceHeadings.map((heading) => {
                            const charge = month.invoiceCharges.find(
                              (c) => c.chargeTypeId === heading.id
                            );
                            return (
                              <TableCell key={heading.id} align="right">
                                {currencyFormatter.format(
                                  charge ? charge.value : 0
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(month.commission)}
                          </TableCell>
                          {sbiHeadings.map((heading) => {
                            const charge = month.selfBillingInvoiceCharges.find(
                              (c) => c.chargeTypeId === heading.id
                            );
                            return (
                              <TableCell key={heading.id} align="right">
                                {currencyFormatter.format(
                                  charge ? charge.value : 0
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            <b>
                              {currencyFormatter.format(month.totalFeeIncome)}
                            </b>
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            <b>{currencyFormatter.format(month.turnover)}</b>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={2}>
                          <b>Total</b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, month) =>
                                  total + month.selfBillingInvoiceHammerPrice,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, month) => total + month.buyersPremium,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        {invoiceHeadings.map((heading) => {
                          const total = data.reduce(
                            (total, month) =>
                              total +
                              (month.invoiceCharges.find(
                                (c) => c.chargeTypeId === heading.id
                              )?.value || 0),
                            0
                          );
                          return (
                            <TableCell key={heading.id} align="right">
                              <b>{currencyFormatter.format(total)}</b>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, month) => total + month.commission,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        {sbiHeadings.map((heading) => {
                          const total = data.reduce(
                            (total, month) =>
                              total +
                              (month.selfBillingInvoiceCharges.find(
                                (c) => c.chargeTypeId === heading.id
                              )?.value || 0),
                            0
                          );
                          return (
                            <TableCell key={heading.id} align="right">
                              <b>{currencyFormatter.format(total)}</b>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, month) => total + month.totalFeeIncome,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) => total + week.turnover,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportsMonthlyTotals;
