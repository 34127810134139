import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";
import { formatCurrency } from "../../../utils/formatCurrency";
import { getKeyByValue } from "../../../utils/getKeyByValue";

const refundStatuses = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  CANCELLED: 3,
});

const Refunds = ({ currentAuctionHouse, showTable }) => {
  const [status, setStatus] = useState("");

  const { data, isLoading, error } = useQuery(
    ["refunds", currentAuctionHouse, showTable, status],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_QMS_API_URL
          }/dashboard/refunds?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&status=${status}&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Refunds</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Pending"
                    value={data?.pending}
                    onClick={() => setStatus(0)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Processing"
                    value={data?.processing}
                    onClick={() => setStatus(1)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Week to Date"
                    value={formatCurrency(data?.weekStat || 0)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Month to Date"
                    value={formatCurrency(data?.monthStat || 0)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Year to Date"
                    value={formatCurrency(data?.yearStat || 0)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Refund Types</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Pending Count</TableCell>
                          <TableCell>Pending Amount</TableCell>
                          <TableCell>Year Count</TableCell>
                          <TableCell>Year Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.refundTypes.map((r) => (
                          <TableRow key={r.name}>
                            <TableCell>{r.name}</TableCell>
                            <TableCell>{r.count}</TableCell>
                            <TableCell>
                              {formatCurrency(r.totalAmount)}
                            </TableCell>
                            <TableCell>{r.yearCount}</TableCell>
                            <TableCell>
                              {formatCurrency(r.yearTotalAmount)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data?.refunds && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Total Amount</TableCell>
                        <TableCell>Invoice</TableCell>
                        <TableCell>User Id</TableCell>
                        <TableCell>Ticket Id</TableCell>
                        <TableCell>Refund</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.refunds.map((r) => (
                        <TableRow key={r.id}>
                          <TableCell>{formatTime(r.creationDate)}</TableCell>
                          <TableCell>
                            {getKeyByValue(refundStatuses, r.status)}
                          </TableCell>
                          <TableCell>{formatCurrency(r.amount)}</TableCell>
                          <TableCell>{formatCurrency(r.totalAmount)}</TableCell>
                          <TableCell>
                            <Link
                              to={`https://portal.auctionoms.com/accounts/invoices/${r.invoice}`}
                              target="_blank"
                            >
                              {r.invoiceId}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/users/${r.refundUserId}`}>
                              {r.refundUserId}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/qms/tickets/${r.ticketId}`}>
                              {r.ticketId}
                            </Link>
                          </TableCell>
                          <TableCell>{r.refundType}</TableCell>
                          <TableCell>
                            <Link to={`/qms/refunds/${r.id}/edit`}>View</Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Refunds;
