import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import ErrorAlert from "../components/misc/ErrorAlert";
import SuccessAlert from "../components/misc/SuccessAlert";
import { getLocationString } from "../utils/formatStockLocation";
import { formatTime } from "../utils/formatTime";
import { urlify } from "../utils/urls";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

const ManageBooking = () => {
  const { id } = useParams();
  const [userId, setUserId] = useState(-1);
  const [printComponent, setPrintComponent] = useState({});
  const [lotIds, setLotIds] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );
  const bookingsQuery = useQuery(
    ["scBooking", id, refresh],
    () =>
      axios
        .get(`${process.env.REACT_APP_BOOKING_API_URL}/bookings-internal/${id}`)
        .then((res) => {
          setUserId(res.data.user);
          setLotIds(res.data.lots);
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );
  const userQuery = useQuery(
    ["scUser", userId],
    () => {
      return axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/users/${userId}`)
        .then((res) => {
          return res.data;
        });
    },
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );
  const lotsQuery = useQuery(
    ["scLot", lotIds],
    async () => {
      let lots = [];
      for (const lotId of lotIds) {
        let lot = await axios
          .get(`${process.env.REACT_APP_AUCTION_API_URL}/lots/${lotId}`)
          .then((res) => {
            return res.data;
          });
        lot.stock = await axios
          .get(
            `${process.env.REACT_APP_AOMS_API_URL}/stocks/${lot.aomsStockId}/container`
          )
          .then((response) => {
            return response.data;
          });
        lots.push(lot);
      }
      return lots;
    },
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );

  const cancelBooking = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BOOKING_API_URL}/bookings-internal/${id}/cancel`
      )
      .then((res) => {
        bookingsQuery.data.cancelled = true;
        setSuccessMessage("Booking Cancelled Successfully");
        setOpenSuccess(true);
        setRefresh(!refresh);
        handleClose();
      })
      .catch((err) => {
        setErrorMessage(
          "Booking Cancellation Failed: " + err.response
            ? err.response.data.message
            : err.message
        );
        setOpenError(true);
      });
  };
  const handleMarkVisited = () => {
    axios
      .put(
        `${process.env.REACT_APP_BOOKING_API_URL}/bookings-internal/${id}/visited`,
        { visited: true }
      )
      .then((res) => {
        bookingsQuery.data.visited = true;
        setSuccessMessage("Marked as visited successfully");
        setOpenSuccess(true);
        setRefresh(!refresh);
      });
  };

  return (
    <Fragment>
      {auctionHousesQuery.isLoading ||
      bookingsQuery.isLoading ||
      userQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <Grid>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/lot-view-booking">Manage Bookings</Link>
                <Typography color="textPrimary">Booking {id}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={"Booking Details"}
                  printComponent={printComponent}
                />
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Auction House"
                      disabled={bookingsQuery.data.cancelled}
                      value={
                        auctionHousesQuery.data.filter(
                          (auctionHouse) =>
                            auctionHouse.id ===
                            bookingsQuery.data.viewing.auctionHouseId
                        )[0].name
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputLabel>User</InputLabel>
                    <Button
                      href={`/users/${userQuery.data.id}`}
                      variant="outlined"
                      disabled={bookingsQuery.data.cancelled}
                    >
                      {`${userQuery.data.firstName} ${userQuery.data.lastName}`}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Start Time"
                      disabled={bookingsQuery.data.cancelled}
                      value={formatTime(bookingsQuery.data.startTime)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="End Time"
                      disabled={bookingsQuery.data.cancelled}
                      value={formatTime(bookingsQuery.data.endTime)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Status"
                      disabled={bookingsQuery.data.cancelled}
                      value={
                        bookingsQuery.data.cancelled ? "Cancelled" : "Active"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      onClick={handleMarkVisited}
                      variant="outlined"
                      disabled={
                        bookingsQuery.data.visited ||
                        bookingsQuery.data.cancelled
                      }
                      startIcon={<EmojiPeopleIcon />}
                    >{`${
                      bookingsQuery.data.visited ? `` : `Mark `
                    }Visited`}</Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      onClick={handleClickOpen}
                      variant="outlined"
                      disabled={bookingsQuery.data.cancelled}
                      startIcon={<CancelIcon />}
                    >
                      Cancel Booking
                    </Button>
                  </Grid>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {"Cancel Booking?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to cancel this booking?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Disagree
                      </Button>
                      <Button onClick={cancelBooking} color="primary">
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  disabled={bookingsQuery.data.cancelled}
                  title={"Lots"}
                  // handleCreate={handleClickOpen}
                  createTitle="Add New Lot to Booking"
                />
                <TableContainer disabled={bookingsQuery.data.cancelled}>
                  <Table disabled={bookingsQuery.data.cancelled}>
                    <TableHead>
                      <TableRow disabled={bookingsQuery.data.cancelled}>
                        <TableCell disabled={bookingsQuery.data.cancelled}>
                          Lot No
                        </TableCell>
                        <TableCell disabled={bookingsQuery.data.cancelled}>
                          Title
                        </TableCell>
                        <TableCell disabled={bookingsQuery.data.cancelled}>
                          Location
                        </TableCell>
                        <TableCell disabled={bookingsQuery.data.cancelled}>
                          View
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody disabled={bookingsQuery.data.cancelled}>
                      {lotsQuery.data.map((lot) => {
                        return (
                          <TableRow
                            disabled={bookingsQuery.data.cancelled}
                            key={lot.id}
                            hover
                            tabIndex={-1}
                          >
                            <TableCell disabled={bookingsQuery.data.cancelled}>
                              {lot.lotNo + (lot.a != null ? lot.a : "")}
                            </TableCell>
                            <TableCell disabled={bookingsQuery.data.cancelled}>
                              {lot.title}
                            </TableCell>
                            <TableCell disabled={bookingsQuery.data.cancelled}>
                              {`${getLocationString(lot.stock.location)} - ${
                                lot.stock.name
                              }`}
                            </TableCell>
                            <TableCell disabled={bookingsQuery.data.cancelled}>
                              <a
                                href={`${process.env.REACT_APP_SITE_URL}/lots/${
                                  lot.id
                                }/${urlify(lot.title)}`}
                                target="_blank"
                                rel="noreferrer"
                                disabled={bookingsQuery.data.cancelled}
                              >
                                <Button
                                  variant="contained"
                                  size="large"
                                  disabled={bookingsQuery.data.cancelled}
                                >
                                  Go to Lot
                                </Button>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <div style={{ display: "none" }}>
            <Grid container spacing={6} ref={(el) => setPrintComponent(el)}>
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/lot-view-booking">Manage Bookings</Link>
                  <Typography color="textPrimary">Booking {id}</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12}>
                <Box p={2} component={Paper}>
                  <EnhancedTableToolbar title={"Booking Details"} />
                  <Grid container spacing={4}>
                    <Grid item>
                      <TextField
                        label="Auction House"
                        disabled={bookingsQuery.data.cancelled}
                        value={
                          auctionHousesQuery.data.filter(
                            (auctionHouse) =>
                              auctionHouse.id ===
                              bookingsQuery.data.viewing.auctionHouseId
                          )[0].name
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <InputLabel>User</InputLabel>
                      <Button
                        href={`/users/${userQuery.data.id}`}
                        variant="outlined"
                        disabled={bookingsQuery.data.cancelled}
                      >
                        {`${userQuery.data.firstName} ${userQuery.data.lastName}`}
                      </Button>
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Start Time"
                        disabled={bookingsQuery.data.cancelled}
                        value={formatTime(bookingsQuery.data.startTime)}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="End Time"
                        disabled={bookingsQuery.data.cancelled}
                        value={formatTime(bookingsQuery.data.endTime)}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Status"
                        disabled={bookingsQuery.data.cancelled}
                        value={
                          bookingsQuery.data.cancelled ? "Cancelled" : "Active"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box p={2} component={Paper}>
                  <EnhancedTableToolbar
                    disabled={bookingsQuery.data.cancelled}
                    title={"Lots"}
                  />
                  <TableContainer disabled={bookingsQuery.data.cancelled}>
                    <Table disabled={bookingsQuery.data.cancelled}>
                      <TableHead>
                        <TableRow disabled={bookingsQuery.data.cancelled}>
                          <TableCell disabled={bookingsQuery.data.cancelled}>
                            Lot No
                          </TableCell>
                          <TableCell disabled={bookingsQuery.data.cancelled}>
                            Title
                          </TableCell>
                          <TableCell disabled={bookingsQuery.data.cancelled}>
                            Location
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody disabled={bookingsQuery.data.cancelled}>
                        {lotsQuery.data.map((lot) => {
                          return (
                            <TableRow
                              disabled={bookingsQuery.data.cancelled}
                              key={lot.id}
                              hover
                              tabIndex={-1}
                            >
                              <TableCell
                                disabled={bookingsQuery.data.cancelled}
                              >
                                {lot.lotNo + (lot.a != null ? lot.a : "")}
                              </TableCell>
                              <TableCell
                                disabled={bookingsQuery.data.cancelled}
                              >
                                {lot.title}
                              </TableCell>
                              <TableCell
                                disabled={bookingsQuery.data.cancelled}
                              >
                                {`${getLocationString(lot.stock.location)} - ${
                                  lot.stock.name
                                }`}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default ManageBooking;
