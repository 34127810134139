import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RefundForm from "../components/Refunds/RefundForm/RefundForm";

const CreateRefund = (props) => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`/qms/refunds`}>Refunds</Link>
          <Typography color="textPrimary">Create Refund</Typography>
        </Breadcrumbs>
      </Grid>
      <RefundForm />
    </Grid>
  );
};

export default CreateRefund;
