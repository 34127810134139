import {
  Grid,
  Box,
  Breadcrumbs,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const membershipStatuses = {
  PENDING_PAYMENT: 0,
  PAID_PENDING_PROCESSING: 1,
  PAID_COMPLETE: 2,
  CANCELLED: 3,
};

const Memberships = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [membershipStatus, setMembershipStatus] = useState(
    membershipStatuses.PAID_PENDING_PROCESSING
  );

  const { isLoading, error, data } = useQuery(
    ["scMemberships", page, membershipStatus],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_PAYMENTS_API_URL
          }/users/memberships?status=${membershipStatus}&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "membershipStatus":
        setMembershipStatus(value);

        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Typography color="textPrimary">Memberships</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="membershipStatus-label">
                    Membership Status
                  </InputLabel>
                  <Select
                    labelId="membershipStatus-label"
                    label="Membership Status"
                    name="membershipStatus"
                    value={membershipStatus}
                    onChange={handleInput}
                    required
                  >
                    <MenuItem value={0}>Pending Payment</MenuItem>
                    <MenuItem value={1}>Paid Pending Processing</MenuItem>
                    <MenuItem value={2}>Paid Complete</MenuItem>
                    <MenuItem value={3}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Renewal Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.memberships.map((membership) => {
                      return (
                        <TableRow key={membership.id} hover>
                          <TableCell>{membership.id}</TableCell>
                          <TableCell>
                            <Link to={`/users/${membership.userId}`}>
                              {membership.userId}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {getKeyByValue(
                              membershipStatuses,
                              membership.status
                            )}
                          </TableCell>
                          <TableCell>
                            {formatTime(membership.renewalDate)}
                          </TableCell>
                          <TableCell>
                            <Link to={`/memberships/${membership.id}`}>
                              View
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={data.totalResults || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Memberships;
