import {
  TableBody,
  TableCell,
  TableRow,
  Button,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { formatCurrency } from "../../../utils/formatCurrency";
import { getKeyByValue } from "../../../utils/getKeyByValue";
import { formatTime } from "../../../utils/formatTime";

const refundStatuses = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  CANCELLED: 3,
});

const useStyles = makeStyles(() => ({
  PENDING: {
    background: "#f8d7da",
  },
  PROCESSING: {
    background: "#fff3cd",
  },
  COMPLETE: {
    background: "#d4edda",
  },
  CANCELLED: {
    background: "#e2e3e5",
  },
  TEST: {
    background: "#e8f4fd",
  },
}));

const RefundList = ({ refunds }) => {
  const classes = useStyles();

  const refundTypesQuery = useQuery(
    "refundTypes",
    () =>
      axios
        .get(`${process.env.REACT_APP_QMS_API_URL}/refunds/types`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  return (
    <TableBody>
      {refunds.map((refund) => {
        const status = getKeyByValue(refundStatuses, refund.status);
        return (
          <TableRow key={refund.id} hover>
            <TableCell>{formatTime(refund.creationDate)}</TableCell>
            <TableCell className={classes[status]}>{status}</TableCell>
            <TableCell>
              <Checkbox checked={refund.deductedFromInvoice} disabled />
            </TableCell>
            <TableCell>{formatCurrency(refund.amount)}</TableCell>
            <TableCell>
              {refund.totalAmount ? formatCurrency(refund.totalAmount) : "-"}
            </TableCell>
            <TableCell>
              <Link to={`/users/${refund.refundUserId}`}>
                {refund.refundUserId}
              </Link>
            </TableCell>
            <TableCell>
              {refund.invoiceId ? (
                <a
                  href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${refund.invoiceId}`}
                >
                  {refund.invoiceId}
                </a>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {refund.ticketId ? (
                <Link to={`/qms/tickets/${refund.ticketId}`}>
                  {refund.ticketId}
                </Link>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>{refund.auctionHouseId}</TableCell>
            <TableCell>
              {refund.faultUserId ? (
                <Link to={`/users/${refund.faultUserId}`}>
                  {refund.faultUserId}
                </Link>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              {refundTypesQuery.data &&
                refundTypesQuery.data.filter(
                  (refundType) => refundType.id === refund.refundTypeId
                )[0].name}
            </TableCell>
            <TableCell>{refund.reason}</TableCell>
            <TableCell>{refund.comment ? refund.comment : "-"}</TableCell>
            <TableCell>
              <Link to={`/qms/refunds/${refund.id}/edit`}>
                <Button variant="outlined" startIcon={<EditIcon />}>
                  Edit
                </Button>
              </Link>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default RefundList;
