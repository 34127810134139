import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatDate, formatTime } from "../../../utils/formatTime";

const Viewings = ({ currentAuctionHouse, showTable }) => {
  const [period, setPeriod] = useState("today");

  const { data, isLoading, error } = useQuery(
    ["viewings", currentAuctionHouse, showTable, period],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/dashboard/viewings?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&getTable=${showTable}&date=${new Date()}&period=${period}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6">Viewings</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Todays Viewings"
                    value={data.today}
                    onClick={() => setPeriod("today")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Weeks Viewings"
                    value={data.week}
                    onClick={() => setPeriod("week")}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data.viewings && (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Bookings</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.viewings.map((viewing) => (
                        <TableRow key={viewing.id}>
                          <TableCell>
                            {formatDate(viewing.viewingDate)}
                          </TableCell>
                          <TableCell>
                            {formatTime(viewing.startTime).slice(12, 17)}
                          </TableCell>
                          <TableCell>
                            {formatTime(viewing.endTime).slice(12, 17)}
                          </TableCell>
                          <TableCell>{viewing.bookingCount}</TableCell>
                          <TableCell>{viewing.duration}</TableCell>
                          <TableCell>
                            <Link to={`/lot-viewings/${viewing.id}`}>View</Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Viewings;
