import axios from "axios";
import { useQuery } from "react-query";
import { Grid, Box, Paper, Typography } from "@mui/material";
import SmallStat from "./SmallStat";

const Collections = () => {
  const { data, isLoading, error } = useQuery(["collections"], () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/dashboard/collections`)
      .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <Grid item xs={12}>
          <Box p={3} component={Paper}>
            <Grid item xs={12}>
              <Typography variant="h5">Collections</Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <SmallStat title="Created" value={data?.created} />
              </Grid>
              <Grid item xs={12} md={3}>
                <SmallStat title="Pending" value={data?.pending} />
              </Grid>
              <Grid item xs={12} md={3}>
                <SmallStat title="Pick Issues" value={data?.issues} />
              </Grid>
              <Grid item xs={12} md={3}>
                <SmallStat title="Hand Over" value={data?.handover} />
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 5 }}>
              <Grid item xs={12} md={6}>
                <SmallStat
                  title="Confirmed bookings for today"
                  value={data?.confirmedBookings}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SmallStat
                  title="Queued bookings for today"
                  value={data?.queuedBookings}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default Collections;
