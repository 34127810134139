import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EnhancedTableToolbar from "../../components/misc/EnhancedTableToolbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { utils, writeFileXLSX } from "xlsx";
const { useEffect, useState } = require("react");

const TotalInbounds = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stats/sales/estates?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      )
      .then((res) => {
        setResults(res.data.results);
      });
  }, [startDate, endDate]);

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999);
    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    setStartDate(date);
  };

  const downloadXlsx = () => {
    const data = results.map((result) => ({
      Year: result.year,
      Week: result.week,
      Count: result.count,
    }));
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Inbound Stats");
    writeFileXLSX(wb, "InboundStats.xlsx");
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color={"textPrimary"}>Inbound Stats</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <EnhancedTableToolbar title={"Select Filter Dates"} />
            <Grid container justifyContent="space-around">
              <DatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label="Auction End Date From:"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <DatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label="Auction End Date Till:"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <Button variant="outlined" onClick={downloadXlsx}>
                Download XLSX
              </Button>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={`${result.year}-${result.week}`}>
                    <TableCell>{result.year}</TableCell>
                    <TableCell>{result.week}</TableCell>
                    <TableCell>{result.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TotalInbounds;
