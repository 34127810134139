import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import useDebounce from "../components/HOCs/UseDebounce";

const ContainerAutoCompleter = ({
  setContainerId,
  currentAuctionHouse,
  disabled,
}) => {
  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const debounce = useDebounce(value, 500);

  const search = useCallback(
    (value) => {
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/containers/search?query=${value}&auctionHouseId=${currentAuctionHouse.id}&startRowIndex=0&maximumRows=10`
        )
        .then((res) => {
          setSearchResults(res.data.containers);
        })
        .catch((err) => {
          console.error("Container search error:", err);
          setSearchResults([]);
        });
    },
    [currentAuctionHouse]
  );

  useEffect(() => {
    if (debounce) {
      search(debounce);
    }
  }, [debounce, search]);

  const handleSearch = (e) => {
    if (e.key === "Enter" && value) {
      search(value);
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      label={"Search Containers"}
      type="text"
      options={searchResults}
      inputValue={value}
      isOptionEqualToValue={(option, value) => {
        if (option && value) return option.id === value.id;
      }}
      onChange={(event, value) => {
        if (value) setContainerId(value.id);
      }}
      onInputChange={(event, newInputValue) => {
        if (event) setValue(newInputValue);
      }}
      variant="outlined"
      onKeyPress={handleSearch}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Containers"
          name="searchQuery"
          variant="outlined"
          margin="normal"
          fullWidth
        />
      )}
    />
  );
};

const ReportStockIntegrity = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({ name: "" });
  const [containerId, setContainerId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    if (currentAuctionHouse.name === "") {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stockintegritychecks?auctionHouseId=${
          currentAuctionHouse.id
        }&startRowIndex=${page * rowsPerPage}&maximumRows=${rowsPerPage}`
      )
      .then((res) => {
        setTotalPages(Math.ceil(res.data.totalResults / rowsPerPage));
        setChecks(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Stock Integrity Check search error:", err);
        setError(
          "An error occurred while searching for Stock Integrity Checks"
        );
        setLoading(false);
        setTotalPages(1);
      });
  }, [currentAuctionHouse, page, rowsPerPage]);

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_AOMS_API_URL}/stockintegritychecks`, {
        containerId,
        auctionHouseId: currentAuctionHouse.id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "auctionHouse":
        const auctionHouse = auctionHousesQuery.data.find(
          (ah) => ah.id === e.target.value
        );
        setCurrentAuctionHouse(auctionHouse);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Stocks Integriy Report</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={7}>
            <Grid item xs={12}>
              <Typography variant="h6">Create Report</Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={7}>
                  <Grid item md={4}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel>Auction House</InputLabel>
                      {auctionHousesQuery.data && (
                        <Select
                          value={currentAuctionHouse.id}
                          label="Auction Houses"
                          name="auctionHouse"
                          onChange={handleChange}
                        >
                          <MenuItem value=""></MenuItem>
                          {auctionHousesQuery.data.map((house) => {
                            return (
                              <MenuItem value={house.id} key={house.id}>
                                {house.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth variant="standard">
                      <ContainerAutoCompleter
                        setContainerId={setContainerId}
                        currentAuctionHouse={currentAuctionHouse}
                        disabled={!currentAuctionHouse}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!containerId}
                    >
                      Create Report
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={7}>
            <Grid item xs={12}>
              <Typography variant="h6">Report</Typography>
              {loading && <CircularProgress />}
              {error && <Typography color="error">{error}</Typography>}
              {!loading && (
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Container</TableCell>
                        <TableCell>Processed</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checks.map((check, i) => (
                        <TableRow key={i}>
                          <TableCell>{check.id}</TableCell>
                          <TableCell>{check.containerName}</TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={check.processed}
                              readOnly
                            />
                          </TableCell>
                          <TableCell>
                            <Link to={`/reports/stockintegrity/${check.id}`}>
                              View
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={totalPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportStockIntegrity;
