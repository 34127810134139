const websocketStates = Object.freeze({
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
});

let socket = null;

let observerIds = [];
let observers = {};
let wsConnectionLost = null;

let tryWebsocketConnectionCount = 3; // Attempt to reconnect 3 times

const getWebsocketConnection = (token) => {
  console.log("Getting ws connection", new Date());
  const accessToken = token;

  if (
    accessToken &&
    (socket === null || socket.readyState >= websocketStates.CLOSING)
  ) {
    socket = new WebSocket(
      `${process.env.REACT_APP_SITE_URL_WS_URL}/?token=${accessToken}`
    );
    socket.addEventListener("error", function (event) {
      console.error(
        "WS has failed to connect on, please refresh the page to receive live updates"
      );
      wsConnectionLost(true);
    });
  }

  return socket;
};

export function websocketConnect(token, setWsConnectionLost) {
  wsConnectionLost = setWsConnectionLost;
  socket = getWebsocketConnection(token);

  if (socket) {
    socket.addEventListener("close", function (event) {
      // console.log("WS connection closed: " + new Date());
      if (event.code === 1006) {
        console.log(
          "Error connecting to ws, getting new access token: " + event.code
        );

        async function retryWebsocketConnection() {
          console.log("attempting reconnect");
          if (tryWebsocketConnectionCount <= 0) {
            return;
          }
          tryWebsocketConnectionCount--;
          //Token is valid for 2 days. We can ignore this step
          // await getRenewedAccessToken();
          //await wait();
          wsConnectionLost(false);
          websocketConnect(token, wsConnectionLost);
        }

        retryWebsocketConnection();
      }
    });

    socket.addEventListener("open", function (event) {
      console.log("WS connection opened: " + new Date());
      if (observerIds.length > 0) {
        const msg = { type: "addCustomers", ids: observerIds };
        socket.send(JSON.stringify(msg));
      }
    });

    socket.addEventListener("message", function (event) {
      console.log("WS message received: " + new Date());
      const data = JSON.parse(event.data);

      observerIds.forEach((el) => {
        for (const key in observers[el + "-admin"]) {
          const updateFunction = observers[el + "-admin"][key];
          updateFunction(data);
        }
      });
      // const admin = observerIds.find((el) => el.toString().includes("admin"));
      // if (admin) {
      // for (const [key] of Object.entries(observers[admin])) {
      //   const updateFunction = observers[admin][key];
      //   updateFunction(data);
      // }
      // }
    });
  }
}

export function websocketAddCustomersQueListeners(userId) {
  observerIds = userId;
  socket = getWebsocketConnection();

  if (socket?.readyState === websocketStates.OPEN) {
    const msg = { type: "addCustomers", ids: observerIds };
    socket.send(JSON.stringify(msg));
    console.log("Add customers message sent: " + new Date());
  }
}

export function websocketRemoveCustomersQueListeners() {
  socket = getWebsocketConnection();

  if (socket?.readyState === websocketStates.OPEN) {
    const msg = { type: "removeCustomers", ids: observerIds };
    socket.send(JSON.stringify(msg));
    // console.log("Remove listeners message sent: " + new Date());
  }
}

export function websocketAddObserver(userId, updateFunction, uuid) {
  if (observers.hasOwnProperty(userId)) {
    observers[userId][uuid] = updateFunction;
  } else {
    const observerView = {};
    observerView[uuid] = updateFunction;
    observers[userId] = observerView;
  }
}

export function websocketRemoveObserver(userId, uuid) {
  if (
    observers.hasOwnProperty(userId) &&
    Object.keys(observers[userId]).length > 1
  ) {
    delete observers[userId][uuid];
  } else {
    delete observers[userId];
  }
}
