import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import LockIcon from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";

const EditUser = (props) => {
  const { userId, firstName, lastName, email, accountStatus, emailStatus } =
    props.location.state;

  const [newFirstName, setNewFirstName] = useState(firstName);
  const [newLastName, setNewLastName] = useState(lastName);
  const [newEmail, setNewEmail] = useState(email);
  const [newAccountStatus, setNewAccountStatus] = useState(accountStatus);
  const [newEmailStatus, setNewEmailStatus] = useState(emailStatus);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleSave = () => {
    axios
      .put(`${process.env.REACT_APP_AUTH_API_URL}/users/${userId}`, {
        firstName: newFirstName,
        lastName: newLastName,
        email: newEmail,
        emailVerified: newEmailStatus,
      })
      .then(() => setOpenSuccess(true))
      .catch((err) => console.error(err));
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "firstName":
        setNewFirstName(value);
        break;
      case "lastName":
        setNewLastName(value);
        break;
      case "email":
        setNewEmail(value);
        break;
      case "accountStatus":
        setNewAccountStatus(value);
        break;
      case "emailStatus":
        setNewEmailStatus(value);
        break;
      default:
        break;
    }
  };

  const handleLock = () => {
    if (newAccountStatus) {
      axios
        .put(`${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/unlock`)
        .then(() => setOpenSuccess(true))
        .catch((err) => console.log(err));

      setNewAccountStatus(0);
    } else {
      axios
        .put(`${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/lock`)
        .then(() => setOpenSuccess(true))
        .catch((err) => console.log(err));

      setNewAccountStatus(1);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/users">Users</Link>
            <Link to={`/users/${userId}`}>Manage User</Link>
            <Typography color="textPrimary">Edit User</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid
              container
              item
              xs={12}
              spacing={4}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link
                  to={{
                    pathname: `/users/${userId}/change-password`,
                    state: {
                      userId,
                    },
                  }}
                >
                  Change Password
                </Link>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleLock}
                  startIcon={newAccountStatus ? <LockOpen /> : <LockIcon />}
                >
                  {newAccountStatus ? "Unlock Account" : "Lock Account"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              Edit Account Details
            </Typography>
            <Grid container spacing={4}>
              <Grid container item spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    value={newFirstName}
                    fullWidth
                    variant="outlined"
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={newEmail}
                    fullWidth
                    variant="outlined"
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    value={newLastName}
                    fullWidth
                    variant="outlined"
                    onChange={handleInput}
                  />
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Account Status"
                      name="accountStatus"
                      value={newAccountStatus ? "Locked" : "Active"}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                      onChange={handleInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="email-status-label">
                        Email Status
                      </InputLabel>
                      <Select
                        labelId="email-status-label"
                        label="Email Status"
                        name="emailStatus"
                        value={newEmailStatus}
                        onChange={handleInput}
                      >
                        <MenuItem value={0}>Unapproved</MenuItem>
                        <MenuItem value={1}>Approved</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={handleSave}>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Changes Saved"
      />
    </Fragment>
  );
};

export default EditUser;
