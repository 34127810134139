import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RefundForm from "../components/Refunds/RefundForm/RefundForm";

const EditRefund = (props) => {
  const { id } = useParams();

  const refundQuery = useQuery(`refundTypes-${id}`, () =>
    axios
      .get(`${process.env.REACT_APP_QMS_API_URL}/refunds/${id}`)
      .then((res) => res.data)
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`/qms/refunds`}>Refunds</Link>
          <Typography>{id}</Typography>
          <Typography color="textPrimary">Edit Refund</Typography>
        </Breadcrumbs>
      </Grid>
      {refundQuery.isLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : refundQuery.error ? (
        <Grid item xs={12}>
          <Alert severity="error">
            {"An error has occurred: " + refundQuery.error.message}
          </Alert>
        </Grid>
      ) : (
        <RefundForm refund={refundQuery.data} />
      )}
    </Grid>
  );
};

export default EditRefund;
