import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RefundList from "../components/Refunds/RefundList/RefundList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BuildIcon from "@mui/icons-material/Build";
import { formatCurrency } from "../utils/formatCurrency";
import { useCSVDownloader } from "react-papaparse";
import { formatTime } from "../utils/formatTime";

const refundStatuses = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  CANCELLED: 3,
});

const Refunds = (props) => {
  const { CSVDownloader, Type } = useCSVDownloader();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [startTime, setStartTime] = useState(
    new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)
  );
  const [endTime, setEndTime] = useState(new Date().setHours(23, 59, 59, 999));
  const [status, setStatus] = useState(null);
  const [refundTypeId, setRefundTypeId] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(null);

  const refundsQuery = useQuery(
    [
      "refunds",
      page,
      rowsPerPage,
      startTime,
      endTime,
      status,
      refundTypeId,
      currentAuctionHouse,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_QMS_API_URL
          }/refunds?maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }&startTime=${new Date(startTime).toISOString()}${
            endTime ? `&endTime=${new Date(endTime).toISOString()}` : ""
          }${status || status === 0 ? `&status=${status}` : ""}${
            refundTypeId ? `&refundTypeId=${refundTypeId}` : ""
          }${
            currentAuctionHouse
              ? `&auctionHouseId=${currentAuctionHouse.id}`
              : ""
          }
          `
        )
        .then((res) => {
          return res.data;
        }),
    { keepPreviousData: true }
  );

  const refundTypesQuery = useQuery(
    "refundTypes",
    () =>
      axios
        .get(`${process.env.REACT_APP_QMS_API_URL}/refunds/types`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "status":
        setPage(0);
        setStatus(value);
        break;
      case "refundTypeId":
        setPage(0);
        setRefundTypeId(value);
        break;
      default:
        break;
    }
  };

  const handleStartDateChange = (time) => {
    setStartTime(time);
  };
  const handleEndDateChange = (time) => {
    setEndTime(time);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CSVButton = ({ data }) => {
    const getCsvData = async () => {
      try {
        setIsLoading(true);

        const res = await axios.get(
          `${
            process.env.REACT_APP_QMS_API_URL
          }/refunds/download?startTime=${new Date(startTime).toISOString()}${
            endTime ? `&endTime=${new Date(endTime).toISOString()}` : ""
          }${
            currentAuctionHouse
              ? `&auctionHouseId=${currentAuctionHouse.id}`
              : ""
          }`
        );
        const invoiceIds = res.data.map((el) => el.InvoiceID);

        let start = 0;
        let invoicesAomsData = [];
        while (start < invoiceIds.length) {
          const ids = invoiceIds
            .slice(start, start + Math.min(invoiceIds.length, 1000))
            .filter((el) => el !== null);

          const invoicesData = await axios.post(
            `${process.env.REACT_APP_AOMS_API_URL}/reports/invoices-date`,
            {
              invoiceIds: ids,
            }
          );
          invoicesAomsData.push(invoicesData.data.results);
          start += 1000;
        }
        const merged = res.data.map((el) => {
          const invoice = invoicesAomsData
            .flat()
            .find((invoice) => invoice.id === el.InvoiceID);
          if (invoice) {
            return { ...el, InvoiceDate: formatTime(invoice.invoiceDate) };
          }
          return el;
        });

        setCsvData(merged);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    if (data) {
      return (
        <CSVDownloader
          type={Type.Button}
          filename={`Refunds: ${formatTime(startTime).replace(
            "_",
            ":"
          )} - ${formatTime(endTime).replace("_", ":")}`}
          bom={true}
          data={() => {
            setCsvData(null);
            return data;
          }}
        >
          Download
        </CSVDownloader>
      );
    } else {
      return (
        <>
          {isLoading && <CircularProgress size={20} />}
          <Button variant="outlined" onClick={getCsvData} disabled={isLoading}>
            Prepare Download
          </Button>
        </>
      );
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Refunds</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={6} style={{ marginBottom: "10px" }}>
              <Typography variant="h6">Select Dates</Typography>
            </Grid>
            <Grid item>
              <CSVButton data={csvData} />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Refunds from:"
                  name="startTime"
                  value={startTime}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Refunds to:"
                  name="endTime"
                  value={endTime}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box mt={2} mb={1}>
                  <TextField
                    label="Selected Dates Total"
                    name="totalAmount"
                    value={
                      refundsQuery.data
                        ? formatCurrency(
                            refundsQuery.data.selectedDatesRefunds.totalAmount
                          )
                        : "-"
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box mt={2} mb={1}>
                  <TextField
                    label="Year Total"
                    name="totalAmount"
                    value={
                      refundsQuery.data
                        ? formatCurrency(refundsQuery.data.totalAmount)
                        : "-"
                    }
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid container item xs={12} md={8} spacing={2}>
              <Grid item>
                <Typography variant="h6">Refunds</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="status-label">Filter status</InputLabel>
                  <Select
                    labelId="status-label"
                    label="Filter Status"
                    name="status"
                    value={status}
                    onChange={handleInput}
                  >
                    <MenuItem value={null}>All statuses</MenuItem>
                    {Object.entries(refundStatuses).map((refundStatus) => {
                      return (
                        <MenuItem key={refundStatus[1]} value={refundStatus[1]}>
                          {refundStatus[0]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="refund-type-id-label">
                    Filter refund type
                  </InputLabel>
                  <Select
                    labelId="refund-type-id-label"
                    label="Filter Refund Type"
                    name="refundTypeId"
                    value={refundTypeId}
                    onChange={handleInput}
                  >
                    <MenuItem value={null}>All types</MenuItem>
                    {refundTypesQuery.data?.length > 0 &&
                      refundTypesQuery.data.map((refundType) => {
                        return (
                          <MenuItem key={refundType.id} value={refundType.id}>
                            {refundType.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                {auctionHousesQuery.data && (
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="auction_house">
                      Filter Auction House
                    </InputLabel>
                    <Select
                      labelId="auction_house"
                      label="Auction House"
                      value={
                        currentAuctionHouse ? currentAuctionHouse.name : ""
                      }
                      onChange={handleSelect}
                    >
                      <MenuItem value={null}>All Auction Houses</MenuItem>
                      {auctionHousesQuery.data.map((house) => {
                        return (
                          <MenuItem value={house.name} key={house.id}>
                            {house.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} md={3}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Link to={`/qms/refunds/types`}>
                    <Button variant="outlined" startIcon={<BuildIcon />}>
                      Manage Refund Types
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={`/qms/refunds/new`}>
                    <Button variant="outlined" startIcon={<AddIcon />}>
                      Add new
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {refundsQuery.isLoading ? (
            <CircularProgress />
          ) : refundsQuery.error ? (
            <Alert severity="warning">
              {"An error has occurred: " + refundsQuery.error.message}
            </Alert>
          ) : refundsQuery?.data.selectedDatesRefunds.refunds.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Deducted From Invoice</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Invoice ID</TableCell>
                    <TableCell>Ticket ID</TableCell>
                    <TableCell>Auction House</TableCell>
                    <TableCell>Caused By</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Comments</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {refundsQuery.data && (
                  <RefundList
                    refunds={refundsQuery.data.selectedDatesRefunds.refunds}
                  />
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={refundsQuery?.data.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          ) : (
            <Alert severity="info">No refunds to see here</Alert>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Refunds;
