import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const CreateTopic = (props) => {
  const [name, setName] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const topics = props.topics;

  useEffect(() => {
    async function getRoles() {
      const rolesResponse = await axios.get(
        `${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`
      );
      const roles = rolesResponse.data
        .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
        .map((r) => r.name);
      setRoles(roles);
    }
    getRoles();
  }, []);

  const handleInput = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "role":
        setSelectedRole(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await axios.post(`${process.env.REACT_APP_QMS_API_URL}/topics`, {
      name,
      defaultRole: selectedRole,
    });
    topics.refetch();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" color="inherit">
            Create New Topic
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            onChange={handleInput}
            variant="outlined"
            value={name}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="lbl-role">Dept</InputLabel>
            <Select
              labelId="lbl-role"
              value={selectedRole}
              name="role"
              onChange={handleInput}
            >
              {roles.map((r) => (
                <MenuItem key={`role-${r}`} value={r}>
                  {r}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button type="submit" variant="contained" color="primary">
            Create Topic
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ManageTopics = (props) => {
  const topics = props.topics;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h6" color="inherit">
          Manage Topics
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {topics.isLoading ? (
          <CircularProgress />
        ) : topics.error ? (
          "An error has occurred: " + topics.error.message
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Dept</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topics.data.map((r) => {
                  return (
                    <TableRow key={`topic-${r.id}`}>
                      <TableCell>{r.id}</TableCell>
                      <TableCell>{r.name}</TableCell>
                      <TableCell>{r.defaultRole}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

const QmsTopics = (props) => {
  const topics = useQuery("getTopics", async () => {
    return (await axios.get(`${process.env.REACT_APP_QMS_API_URL}/topics`))
      .data;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/qms/tickets">Tickets</Link>
          <Typography color="textPrimary">Manage Topics</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <CreateTopic topics={topics} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <ManageTopics topics={topics} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default QmsTopics;
