import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  btnContainer: { display: "flex", alignItems: "center" },
}));

const CustomerQueueVariables = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const queryClient = useQueryClient();

  const pickAveragesData = useQuery(
    ["asPickAverages", currentAuctionHouse.id],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/pick-average/${currentAuctionHouse.id}`
        )
        .then((res) => res.data),
    {
      enabled: !!currentAuctionHouse.id,
    }
  );

  const delayData = useQuery(
    ["asAuctionHouseDelay", currentAuctionHouse.id],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/delay/${currentAuctionHouse.id}`
        )
        .then((res) => res.data),
    {
      enabled: !!currentAuctionHouse.id,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const updatePickAverageMutation = useMutation(
    (params) =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/pick-average/${params.id}`,
          {
            totalPickers: params.totalPickers,
            averagePerHour: params.avgPerHour,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  const updateDelayMutation = useMutation(
    () =>
      axios
        .post(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/delay/${currentAuctionHouse.id}`,
          {
            delay: parseInt(delayData.data.result),
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangeAverages = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    queryClient.setQueryData(
      ["asPickAverages", currentAuctionHouse.id],
      (oldData) => {
        oldData.result.map((el) => {
          el[name] = value;
          return el;
        });
        return { result: oldData.result };
      }
    );
  };

  const handleChangeDelay = (e) => {
    const value = e.target.value;

    queryClient.setQueryData(
      ["asAuctionHouseDelay", currentAuctionHouse.id],
      (oldData) => {
        return { result: value };
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Variables</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Auction House</InputLabel>
            {auctionHousesQuery.data && (
              <Select
                value={currentAuctionHouse.name}
                label="Auction Houses"
                onChange={handleSelect}
              >
                <MenuItem value=""></MenuItem>
                {auctionHousesQuery.data.map((house) => {
                  return (
                    <MenuItem value={house.name} key={house.id}>
                      {house.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Typography variant="h6">Pick Averages</Typography>

          {pickAveragesData.data &&
            pickAveragesData.data?.result.map((el) => (
              <Grid container spacing={3} key={el.id}>
                <Grid item xs={4}>
                  <TextField
                    label="Total Pickers"
                    name="totalPickers"
                    type="number"
                    value={el.totalPickers}
                    onChange={handleChangeAverages}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Average/Hour"
                    name="avgPerHour"
                    type="number"
                    value={el.avgPerHour}
                    onChange={handleChangeAverages}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} className={classes.btnContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      updatePickAverageMutation.mutate({
                        id: el.id,
                        totalPickers: el.totalPickers,
                        avgPerHour: el.avgPerHour,
                      })
                    }
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Typography variant="h6">Booking Slot Delay (hours)</Typography>
          {delayData.data && (
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  name="delay"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={
                    delayData.data.result ? parseInt(delayData.data.result) : 0
                  }
                  onChange={handleChangeDelay}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      updateDelayMutation.mutate();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4} className={classes.btnContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateDelayMutation.mutate}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Success!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default CustomerQueueVariables;
