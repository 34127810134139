import { useState, createContext, useContext } from "react";
import { useQuery } from "react-query";
import axios from "axios";

const AuctionHouseContext = createContext();

const AuctionHouseProvider = ({ children }) => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [currentAuctionHouseName, setCurrentAuctionHouseName] = useState("");

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSetCurrentAuctionHouse = (auctionHouse) => {
    setCurrentAuctionHouse(auctionHouse);
    setCurrentAuctionHouseName(auctionHouse.name.split("Simon Charles")[1]);
    localStorage.setItem("auctionHouse", JSON.stringify(auctionHouse));
    localStorage.setItem("auctionHouseName", auctionHouse.name);
  };

  const handleClear = () => {
    setCurrentAuctionHouse("");
    setCurrentAuctionHouseName("");
    localStorage.removeItem("auctionHouse");
    localStorage.removeItem("auctionHouseName");
  };

  const state = {
    auctionHouses: auctionHousesQuery.data,
    currentAuctionHouse,
    setCurrentAuctionHouse,
    currentAuctionHouseName,
    setCurrentAuctionHouseName,
    handleSetCurrentAuctionHouse,
    handleClear,
  };

  return (
    <AuctionHouseContext.Provider value={state}>
      {children}
    </AuctionHouseContext.Provider>
  );
};

const useAuctionHouse = () => useContext(AuctionHouseContext);

export { AuctionHouseContext, AuctionHouseProvider, useAuctionHouse };
