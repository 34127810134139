import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";
import { getKeyByValue } from "../../../utils/getKeyByValue";

const ticketsStatuses = {
  OPEN: 0,
  PENDING: 1,
  CLOSED: 2,
};

const Tickets = ({ showTable }) => {
  const [status, setStatus] = useState(ticketsStatuses.OPEN);

  const { data, isLoading, error } = useQuery(
    ["myTickets", showTable, status],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_QMS_API_URL}/dashboard/my-tickets?getTable=${showTable}&status=${status}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">My Tickets</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Open"
                    value={data.open}
                    onClick={() => setStatus(ticketsStatuses.OPEN)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Pending"
                    value={data.pending}
                    onClick={() => setStatus(ticketsStatuses.PENDING)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data.tickets && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.tickets.map((ticket) => (
                        <TableRow key={ticket.id}>
                          <TableCell>{ticket.title}</TableCell>
                          <TableCell>
                            {getKeyByValue(ticketsStatuses, ticket.status)}
                          </TableCell>
                          <TableCell>
                            {formatTime(ticket.creationDate)}
                          </TableCell>
                          <TableCell>
                            {formatTime(ticket.modificationDate)}
                          </TableCell>
                          <TableCell>
                            <Link to={`/qms/tickets/${ticket.id}`}>View</Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Tickets;
