import React, { useReducer, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
  Modal,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import imageArray from "../utils/notificationsImageArray";

const reducer = (state, action) => {
  switch (action.type) {
    case "title":
      return { ...state, title: action.payload };
    case "body":
      return { ...state, body: action.payload };
    case "button_name":
      return { ...state, buttonName: action.payload };
    case "button_url":
      return { ...state, buttonURL: action.payload };
    case "size":
      return { ...state, size: action.payload };
    case "type":
      return { ...state, type: action.payload };
    case "image":
      return { ...state, image: action.payload };
    case "scheduleAt":
      return { ...state, scheduleAt: action.payload };
    case "showModal":
      return { ...state, showModal: action.payload };
    case "closeModal":
      return { ...state, showModal: action.payload };
    default:
      return state;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NotificationsCreate = () => {
  const dateTime = new Date();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [checkboxCheck, setCheckboxCheck] = useState(false);
  const [customImageCheckbox, setCustomImageCheckbox] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    title: "",
    body: "",
    buttonName: "Submit",
    buttonURL: "",
    size: "small_text",
    image: "",
    type: "auctionEndingSoon",
    scheduleAt: dateTime,
  });

  const mutation = useMutation(
    (notification) =>
      axios.post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications`,
        notification
      ),
    {
      onSettled: (data, error, variables, context) => {
        if (error) setOpenError(true);
        if (data) setOpenSuccess(true);
      },
    }
  );
  const handleSubmitNotification = (event) => {
    event.preventDefault();

    mutation.mutate(
      {
        title: state.title,
        body: state.body,
        actionTitle: state.buttonName,
        actionURL: state.buttonURL,
        type: "auctionEndingSoon",
        size: state.size,
        image: state.image,
      },
      { onSuccess: (data, error, variables, context) => console.log(data.data) }
    );
  };

  const submitToSelfMutation = useMutation(
    (notification) =>
      axios.post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/send-notification`,
        notification
      ),
    {
      onSettled: (data, error, variables, context) => {
        if (error) setOpenError(true);
        if (data) setOpenSuccess(true);
      },
    }
  );

  const handelSubmitToSelf = (event) => {
    event.preventDefault();
    if (state.size === "big_image" && !state.image) return setOpenError(true);

    submitToSelfMutation.mutate({
      title: state.title,
      body: state.body,
      actionTitle: state.buttonName,
      actionURL: state.buttonURL,
      type: "auctionEndingSoon",
      size: state.size,
      image: state.image,
    });
  };

  const handleCheckBox = () => {
    setCheckboxCheck(!checkboxCheck);
  };
  const handleCustomImageCheckBox = () => {
    setCustomImageCheckbox(!customImageCheckbox);
  };

  const handleSaveNotification = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications`,
        {
          type: state.type,
          title: state.title,
          body: state.body,
          size: state.size,
          scheduleAt: state.scheduleAt,
          image: state.image,
          buttonName: state.buttonName,
          buttonURL: state.buttonURL,
        }
      );

      setOpenSuccess(true);
    } catch (error) {
      console.log(error);
      setOpenError(true);
    }
  };

  return (
    <Grid container spacing={3}>
      <Modal
        open={state.showModal}
        onClose={() => dispatch({ type: "closeModal", payload: false })}
        aria-labelledby="modal-notification-submit-check"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to submit this notification?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmitNotification(e)}
          >
            Submit Notification
          </Button>
        </Box>
      </Modal>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/notifications">Notifications</Link>
          <Typography color="textPrimary">Create New Notification</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxCheck}
                onChange={handleCheckBox}
                name="Save Notification"
              />
            }
            label="Save Notification"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (!checkboxCheck) dispatch({ type: "showModal", payload: true });
            else handleSaveNotification(event);
          }}
        >
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="textPrimary">
                  Notification Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  name="title"
                  variant="outlined"
                  fullWidth
                  required
                  value={state.title}
                  onChange={(event) =>
                    dispatch({ type: "title", payload: event.target.value })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Body"
                  name="body"
                  variant="outlined"
                  fullWidth
                  multiline
                  required
                  value={state.body}
                  onChange={(event) =>
                    dispatch({ type: "body", payload: event.target.value })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Button name"
                  name="button name"
                  variant="outlined"
                  fullWidth
                  required
                  value={state.buttonName}
                  onChange={(event) =>
                    dispatch({
                      type: "button_name",
                      payload: event.target.value,
                    })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Button URL"
                  name="button url"
                  variant="outlined"
                  fullWidth
                  required
                  value={state.buttonURL}
                  onChange={(event) =>
                    dispatch({
                      type: "button_url",
                      payload: event.target.value,
                    })
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-size">Notification Size</InputLabel>
                  <Select
                    labelId="lbl-size"
                    id="notification_size"
                    label="Notification Type"
                    name="notification_size"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.size}
                    onChange={(event) =>
                      dispatch({
                        type: "size",
                        payload: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"small_text"}>Small Text</MenuItem>
                    <MenuItem value={"big_text"}>Big Text</MenuItem>
                    <MenuItem value={"big_image"}>Big Image</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-type">Notification Type</InputLabel>
                  <Select
                    labelId="lbl-type"
                    id="notification_type"
                    label="Notification Type"
                    name="notification_type"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.type}
                    onChange={(event) =>
                      dispatch({
                        type: "type",
                        payload: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"auctionEndingSoon"}>
                      Action Ending Soon
                    </MenuItem>
                    <MenuItem value={"outbid"}>Outbid</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customImageCheckbox}
                      onChange={handleCustomImageCheckBox}
                      name="Custom Image"
                    />
                  }
                  label="Custom Image"
                />
                {customImageCheckbox ? (
                  <TextField
                    label="Image Url"
                    name="image"
                    variant="outlined"
                    fullWidth
                    required={state.type === "big_image"}
                    value={state.image}
                    onChange={(event) =>
                      dispatch({ type: "image", payload: event.target.value })
                    }
                  ></TextField>
                ) : (
                  <Select
                    labelId="lb2-type"
                    id="image_type"
                    label="Image Type"
                    name="image_type"
                    variant="outlined"
                    fullWidth
                    required={state.type === "big_image"}
                    value={state.image}
                    onChange={(event) =>
                      dispatch({ type: "image", payload: event.target.value })
                    }
                  >
                    {imageArray.map((image, index) => (
                      <MenuItem value={image} key={index}>
                        <img
                          src={image}
                          alt={`Notification-${index}`}
                          style={{ width: "350px" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Grid>
              {checkboxCheck && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label="Schedule At:"
                        value={state.scheduleAt}
                        onChange={(event) =>
                          dispatch({
                            type: "scheduleAt",
                            payload: event,
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        variant="inline"
                        margin="normal"
                        label="Schedule At:"
                        value={state.scheduleAt}
                        onChange={(event) =>
                          dispatch({ type: "scheduleAt", payload: event })
                        }
                      ></TimePicker>
                    </Grid>
                  </LocalizationProvider>
                </>
              )}
              <Grid item xs={12} md={6}>
                <Button type="submit" variant="contained" color="primary">
                  {!checkboxCheck ? "Submit Notification" : "Save Notification"}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" onClick={handelSubmitToSelf}>
                  Submit to self
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Notification sent!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default NotificationsCreate;
