import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";

const membershipStatuses = {
  PENDING_PAYMENT: 0,
  PAID_PENDING_PROCESSING: 1,
  PAID_COMPLETE: 2,
  CANCELLED: 3,
};

const Membership = () => {
  const { id } = useParams();

  const { isLoading, error, data } = useQuery("scMembership", () =>
    axios
      .get(`${process.env.REACT_APP_PAYMENTS_API_URL}/users/memberships/${id}`)
      .then((res) => res.data)
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to="/memberships">Memberships</Link>
              <Typography color="textPrimary">{data.membership.id}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container item xs={12} spacing={4}>
                <Grid item>
                  <Link to={`/users/${data.membership.userId}`}>View User</Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Membership Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Membership"
                    value={
                      data.membership.renewalDate
                        ? "Premium"
                        : data.membership.trialExpirationDate
                        ? "Trial"
                        : "-"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label="Status"
                    value={getKeyByValue(
                      membershipStatuses,
                      data.membership.status
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    label={
                      data.membership.renewalDate
                        ? "Renewal Date"
                        : data.membership.trialExpirationDate
                        ? "Trial Expiration Date"
                        : "-"
                    }
                    value={formatTime(
                      data.membership.renewalDate ||
                        data.membership.trialExpirationDate
                    )}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="User ID"
                    value={data.membership.userId}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                {data.membershipInvoice && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        value={data.membershipInvoice.name}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Street"
                        value={data.membershipInvoice.street}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Address 2"
                        value={data.membershipInvoice.address2}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City"
                        value={data.membershipInvoice.city}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Country"
                        value={data.membershipInvoice.country}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Post Code"
                        value={data.membershipInvoice.postCode}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
          {data.membershipInvoice && (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Membership Invoice Details
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>VAT</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover>
                        <TableCell>{data.membershipInvoice.id}</TableCell>
                        <TableCell>
                          {formatTime(data.membershipInvoice.creationDate)}
                        </TableCell>
                        <TableCell>{data.membershipInvoice.amount}</TableCell>
                        <TableCell>{data.membershipInvoice.vat}</TableCell>
                        <TableCell>{data.membershipInvoice.total}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Membership;
