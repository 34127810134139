import React, { useState, useRef } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    gap: "1rem",
    marginBottom: "1rem",
  },
  input: {
    display: "none",
  },
}));

const TimesheetAddEmployee = () => {
  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [clockNumber, setClockNumber] = useState("");
  const [payrollNo, setPayrollNo] = useState("");
  const [holidaysAllowance] = useState("");
  const [shiftPattern, setShiftPattern] = useState("");
  const [image, setImage] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const classes = useStyles();
  const imageRef = useRef(null);

  const { data: shiftPatterns } = useQuery(
    ["shiftPatterns"],
    () =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-patterns`)
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (data.results[0]) {
          setShiftPattern(data.results[0]);
        }
      },
    }
  );

  const handleAdd = async () => {
    if (!name) {
      setOpenError(true);
      setError({ message: "Name is required" });
      return;
    }
    if (!shiftPattern) {
      setOpenError(true);
      setError({ message: "Shift pattern is required" });
      return;
    }
    setData(null);
    setError(null);
    try {
      const formData = new FormData();
      formData.append("file", image);
      const response = await axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/add-employee?name=${name}&authUserId=${userId}&clockNo=${clockNumber}&payrollNo=${payrollNo}&holidaysAllowance=${holidaysAllowance}&shiftPatternId=${shiftPattern.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setData(response.data);
      setName("");
      setClockNumber("");
      setUserId("");
      setImage(null);
      setPayrollNo("");
      setOpenSuccess(true);
    } catch (err) {
      setError(err.response?.data || err);
      setOpenError(true);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Typography color="textPrimary">Add Employee</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              id="outlined-basic"
              label="Employee Name"
              variant="outlined"
              required
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="User Id"
              variant="outlined"
              value={userId}
              fullWidth
              onChange={(e) => setUserId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              id="outlined-basic"
              label="Clock Number"
              variant="outlined"
              inputProps={{ maxLength: 6, pattern: "[0-9]*" }}
              value={clockNumber}
              fullWidth
              onChange={(e) => setClockNumber(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Payroll Number"
              variant="outlined"
              value={payrollNo}
              fullWidth
              onChange={(e) => setPayrollNo(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ paddingRight: "0.5rem", marginBottom: "1rem" }}
          >
            {/* <TextField
              id="outlined-basic"
              label="Holidays Yearly Allowance"
              variant="outlined"
              value={holidaysAllowance}
              fullWidth
              onChange={(e) => setHolidaysAllowance(e.target.value)}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="shift-pattern-label">Shift Pattern</InputLabel>
              <Select
                defaultValue={""}
                value={shiftPattern}
                labelId="shift-pattern-label"
                fullWidth
                onChange={(e) => setShiftPattern(e.target.value)}
              >
                {shiftPatterns?.results.map((el) => (
                  <MenuItem key={el.id} value={el}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "1rem" }}
            onClick={handleAdd}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => imageRef?.current.click()}
          >
            Add Photo
          </Button>
          <input
            ref={imageRef}
            className={classes.input}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box color="text.secondary">
          <b>Notes:</b>
          <ul>
            <li>Employee name is required</li>
            <li>User id is optional</li>
            <li>
              Clock number is optional. A value will be generated on the server
              if one hasn't been provided.
            </li>
            <li>Clock number min length is 4 and max is 6</li>
            <li>Payroll is optional</li>
            <li>
              Holiday yearly allowance is optional. If no value is provided the
              default will be used.
            </li>
            <li>Image is optional</li>
            <li>The clock number will be returned on success.</li>
          </ul>
        </Box>
      </Grid>
      {data && (
        <Grid item xs={12}>
          <Box component={Paper} p={2}>
            <Typography gutterBottom>Employee Clock Number</Typography>
            <TextField
              value={data?.clockNo}
              variant="outlined"
              fullWidth={false}
              disabled
            />
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Successfully added!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={error?.message || "Error! Please try again"}
      />
    </Grid>
  );
};

export default TimesheetAddEmployee;
