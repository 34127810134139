import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { marked } from "marked";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const types = Object.freeze({
  BUTTON: 0,
  ONLY_TEXT: 1,
});

const positions = Object.freeze({
  TOP: 0,
  BOTTOM: 1,
});

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    minHeight: "200px",
    maxHeight: "260px",
    maxWidth: "100%",
    minWidth: "100%",
    padding: theme.spacing(2),
    fontSize: "14px",
  },
  preview: {
    width: "100%",
    border: "1px solid #ccc",
    paddingLeft: theme.spacing(2),
  },
}));

const TextAreaComp = ({ value, handleChange, name, placeholder }) => {
  const [view, setView] = useState(false);
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {view ? (
        <div
          className={classes.preview}
          dangerouslySetInnerHTML={{
            __html: marked(value),
          }}
        />
      ) : (
        <textarea
          className={classes.textArea}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setView(!view)}
        style={{ marginTop: "10px" }}
      >
        {view ? "Edit" : "Preview"}
      </Button>
    </Grid>
  );
};

const ContentSeoBannersCreate = () => {
  const [type, setType] = useState(0);
  const [position, setPosition] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const createMutation = useMutation(
    () =>
      axios
        .put(`${process.env.REACT_APP_CONTENT_API_URL}/seo-banners`, {
          title: title ? title : null,
          content,
          url: url ? url : null,
          type,
          position,
          base64Image: image ? image : null,
        })
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  const handleSubmit = () => {
    if (position === positions.TOP && title.trim === "") {
      return setOpenError(true);
    }
    createMutation.mutate();
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(reader.result);
      reader.onerror = () =>
        console.error(`Error reading file: ${reader.error}`);
    } else {
      setImage("");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/content/seo-banners">
            <Typography color="textPrimary">SEO Banners</Typography>
          </Link>
          <Typography color="textPrimary">Create</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container item spacing={4}>
            <Grid container item spacing={4}>
              <Grid item xs={12} md={6}>
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={types.BUTTON}>Button</MenuItem>
                  <MenuItem value={types.ONLY_TEXT}>Only Text</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={positions.TOP}>Top</MenuItem>
                  <MenuItem value={positions.BOTTOM}>Bottom</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                required={position === positions.TOP}
              />
            </Grid>
            <Grid item xs={12}>
              {position === positions.TOP && (
                <Typography>
                  *Limit the content to less than 340 characters for the best
                  design appearance. 340 / {content.length}
                </Typography>
              )}
              <TextAreaComp
                value={content}
                handleChange={(e) => setContent(e.target.value)}
                name="content"
                placeholder="*Content"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="url"
                variant="outlined"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>*Image recommended size 660px X 580px </Typography>
              <input accept="image/*" type="file" onChange={handleImage} />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Box>
      </Grid>
      {image && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <img src={`data:image/png;base64${image}`} alt="preview" />
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Banner created successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ContentSeoBannersCreate;
