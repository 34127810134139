import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import SuccessAlert from "../components/misc/SuccessAlert";

const QmsCreate = (props) => {
  const userId = props.location.state?.userId;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [topics, setTopics] = useState([]);
  const [roles, setRoles] = useState([]);
  const [agents, setAgents] = useState([]);
  const [topicId, setTopicId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [role, setRole] = useState("");
  const [readOnlyRequester, setReadOnlyRequester] = useState(false);
  const [title, setTitle] = useState("");
  const [invoiceId, setInvoiceId] = useState(
    props.location.state?.invoiceId ? props.location.state?.invoiceId : ""
  );
  const [conversation, setConversation] = useState(
    props.location.state?.feedback ? props.location.state?.feedback : ""
  );
  const [internal, setInternal] = useState("");
  const [ticketCreated, setTicketCreated] = useState(false);

  const resetForm = () => {
    setTitle("");
    setInvoiceId("");
    setAgentId("");
    setRole("");
    setTopicId("");
    setConversation("");
    setReadOnlyRequester(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setInternal("");
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "invoiceId":
        setInvoiceId(value);
        break;
      case "agentId":
        setAgentId(value);
        break;
      case "role":
        setRole(value);
        break;
      case "topicId":
        setTopicId(value);
        break;
      case "conversation":
        setConversation(value);
        break;
      case "internal":
        setInternal(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const asBindData = async () => {
    const rolesResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`
    );
    const roles = rolesResponse.data
      .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
      .map((r) => r.name);
    setRoles(roles);

    const topicsResponse = await axios.get(
      `${process.env.REACT_APP_QMS_API_URL}/topics`
    );
    const topics = topicsResponse.data.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setTopics(topics);

    const usersResponse = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/applications/12/users`
    );
    const agents = usersResponse.data.map((u) => {
      return {
        name: u.firstName + " " + u.lastName,
        id: u.id,
      };
    });
    setAgents(agents);

    if (userId !== undefined) {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}`
        );
        const user = userResponse.data;
        setEmail(user.email);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setReadOnlyRequester(true);
      } catch (err) {}
    }
  };

  const bindData = useCallback(asBindData, [userId]);

  useEffect(() => {
    bindData();
  }, [userId, bindData]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await axios.post(`${process.env.REACT_APP_QMS_API_URL}/tickets`, {
      title: title,
      invoiceId: invoiceId.length > 0 ? invoiceId : undefined,
      topicId: topicId,
      status: 0,
      requester: {
        authUserId: userId ? parseInt(userId) : undefined,
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
      refundRequired: false,
      assignedAgentId: agentId > 0 ? agentId : undefined,
      assignedRole: role.length > 0 ? role : undefined,
      conversation: {
        type: 0,
        message: conversation,
      },
      internalConversation: internal.length > 0 ? internal : undefined,
    });
    setTicketCreated(true);
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/qms/tickets">Tickets</Link>
            <Typography color="textPrimary">Create New Ticket</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="textPrimary">Requester</Typography>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={firstName}
                    fullWidth
                    InputProps={{
                      readOnly: readOnlyRequester,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={lastName}
                    fullWidth
                    InputProps={{
                      readOnly: readOnlyRequester,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      readOnly: readOnlyRequester,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography color="textPrimary">Ticket Details</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Title"
                  name="title"
                  value={title}
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Invoice ID"
                  name="invoiceId"
                  value={invoiceId}
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-assigned-topic">Topic</InputLabel>
                  <Select
                    labelId="lbl-assigned-topic"
                    label="Topic"
                    value={topicId}
                    name="topicId"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth={true}
                  >
                    {topics.map((r) => {
                      return (
                        <MenuItem key={`topic-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-assigned-role">Dept</InputLabel>
                  <Select
                    labelId="lbl-assigned-role"
                    label="Dept"
                    value={role}
                    name="role"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth={true}
                  >
                    {roles.map((r) => {
                      return (
                        <MenuItem key={`role-${r}`} value={r}>
                          {r}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lbl-assigned-agent">Agent</InputLabel>
                  <Select
                    labelId="lbl-assigned-agent"
                    label="Agent"
                    name="agentId"
                    value={agentId}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth={true}
                  >
                    {agents.map((r) => {
                      return (
                        <MenuItem key={`agent-${r.id}`} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Conversation"
                  name="conversation"
                  multiline
                  variant="outlined"
                  fullWidth={true}
                  value={conversation}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="Internal Comments"
                  name="internal"
                  multiline
                  variant="outlined"
                  fullWidth={true}
                  value={internal}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={ticketCreated}
        setOpenSuccess={setTicketCreated}
        message="New Ticket Created"
      />
    </form>
  );
};

export default QmsCreate;
