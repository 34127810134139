import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import { marked } from "marked";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end",
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grayBorder: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "4px 8px 5px 8px!important",
    width: "100%",
    height: "100%",
    "&:hover": {
      border: "1px solid black",
    },
  },
  textArea: {
    width: "100%",
    minHeight: "200px",
    maxHeight: "260px",
    maxWidth: "100%",
    minWidth: "100%",
    padding: theme.spacing(2),
    fontSize: "14px",
  },
  preview: {
    width: "100%",
    minHeight: "200px",
    border: "1px solid #ccc",
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  imagePreview: {
    width: "100%",
    borderRadius: "10px",
  },
  deleteBtn: {
    color: "red",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },
  mainView: {
    width: "30%",
    height: "100%",
  },
  imageView: {
    width: "80px",
    height: "80px",
  },
}));

const ShowImage = ({ image, alt, style }) => {
  if (image.includes("data:image")) {
    return <img src={image} alt={alt} className={style} />;
  }
  return (
    <img
      src={`${process.env.REACT_APP_IMAGE_CDN_URL}/content/${image}`}
      alt={alt}
      className={style}
    />
  );
};

const Preview = ({ content }) => {
  const classes = useStyles();
  const arr = [];

  for (let i = 0; i < content.length; i++) {
    const { type, alt, value, size } = content[i];
    if (type === "text" && value && size) {
      arr.push(
        <Grid item xs={size * 1}>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(content[i].value),
            }}
          />
        </Grid>
      );
    } else if (type === "image" && value && alt && size) {
      arr.push(
        <Grid item xs={size * 1}>
          <ShowImage image={value} alt={alt} style={classes.imagePreview} />
        </Grid>
      );
    }
  }

  return (
    <Grid container spacing={3}>
      {arr}
    </Grid>
  );
};

const TextAreaInput = ({
  index,
  lastIndex,
  content,
  setContent,
  handleRemove,
  handleMoveContent,
}) => {
  const [view, setView] = useState(false);
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingLeft: "40px" }}
      >
        <Typography color="textSecondary">Content*</Typography>
        <ClearIcon
          size="small"
          className={classes.deleteBtn}
          onClick={handleRemove}
        />
      </Grid>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginRight: "10px",
          }}
        >
          {index !== 0 && (
            <Button
              style={{ padding: "2px 6px 2px 6px", minWidth: "0px" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleMoveContent(index, -1)}
            >
              ▲
            </Button>
          )}
          {index !== lastIndex && (
            <Button
              style={{ padding: "2px 6px 2px 6px", minWidth: "0px" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleMoveContent(index, 1)}
            >
              ▼
            </Button>
          )}
        </div>
        <div style={{ width: "100%" }}>
          {view ? (
            <div
              className={classes.preview}
              dangerouslySetInnerHTML={{
                __html: marked(content.value),
              }}
            />
          ) : (
            <textarea
              className={classes.textArea}
              name="content"
              value={content.value}
              onChange={(e) =>
                setContent((prev) => {
                  prev[index].value = e.target.value;
                  return [...prev];
                })
              }
            />
          )}
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  value={content.size}
                  onChange={(e) =>
                    setContent((prev) => {
                      prev[index].size = e.target.value;
                      return [...prev];
                    })
                  }
                >
                  <MenuItem value="12">100%</MenuItem>
                  <MenuItem value="6">50%</MenuItem>
                  <MenuItem value="4">33%</MenuItem>
                  <MenuItem value="3">25%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setView(!view)}
              style={{ marginTop: "10px" }}
            >
              {view ? "Edit" : "Preview"}
            </Button>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

const ImageInput = ({
  index,
  lastIndex,
  content,
  setContent,
  handleImage,
  handleRemove,
  handleMoveContent,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingLeft: "40px" }}
      >
        <Typography color="textSecondary">Image {index + 1}</Typography>
        <ClearIcon
          size="small"
          className={classes.deleteBtn}
          onClick={handleRemove}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginRight: "10px",
            }}
          >
            {index !== 0 && (
              <Button
                style={{ padding: "2px 6px 2px 6px", minWidth: "0px" }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleMoveContent(index, -1)}
              >
                ▲
              </Button>
            )}
            {index !== lastIndex && (
              <Button
                style={{ padding: "2px 6px 2px 6px", minWidth: "0px" }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleMoveContent(index, 1)}
              >
                ▼
              </Button>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <TextField
              label="Alt"
              variant="outlined"
              fullWidth
              required
              value={content.alt}
              onChange={(e) =>
                setContent((prev) => {
                  prev[index].alt = e.target.value;
                  return [...prev];
                })
              }
            />
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginTop: 5 }}
            >
              <Select
                value={content.size}
                onChange={(e) =>
                  setContent((prev) => {
                    prev[index].size = e.target.value;
                    return [...prev];
                  })
                }
              >
                <MenuItem value="12">100%</MenuItem>
                <MenuItem value="6">50%</MenuItem>
                <MenuItem value="4">33%</MenuItem>
                <MenuItem value="3">25%</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.grayBorder}>
            <Grid container justifyContent="space-between">
              <div>
                <Typography color="textSecondary">
                  {content.value ? "Change" : "Add"} Image
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  name={content.alt}
                  onChange={(e) =>
                    handleImage(e, (imageData) => {
                      setContent((prev) => {
                        prev[index].value = imageData;
                        return [...prev];
                      });
                    })
                  }
                />
              </div>
              {content.value && (
                <ShowImage
                  image={content.value}
                  alt={content.alt}
                  style={classes.imageView}
                />
              )}
            </Grid>
            {content.value.length > 1000000 && (
              <Typography color="error">Image size is too big!</Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Articles = ({ data, submitMutation }) => {
  const [title, setTitle] = useState("");
  const [articleDate, setArticleDate] = useState(new Date());
  const [summary, setSummary] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [content, setContent] = useState([]);
  const [preview, setPreview] = useState(false);
  const [showContactUs, setShowContactUs] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      console.log("data.articleDate", data.articleDate);
      setArticleDate(new Date(data.articleDate));
      setSummary(data.summary);
      setMainImage(data.mainImage);
      setContent(JSON.parse(data.content));
      setPreview(data.preview);
      setShowContactUs(data.showContactUs);
    }
  }, [data]);

  const uploadImageMutation = useMutation((base64Image) =>
    axios
      .put(`${process.env.REACT_APP_CONTENT_API_URL}/upload-image`, {
        base64Image,
      })
      .then((res) => res.data)
  );

  const handleImage = async (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(reader.result);
      reader.onerror = () =>
        console.error(`Error reading file: ${reader.error}`);
    } else {
      setImage("");
    }
  };

  const handleSubmit = async () => {
    try {
      //Upload the main image and replace the base64 with the url
      let image = mainImage;
      if (mainImage.includes("data:image")) {
        const result = await uploadImageMutation.mutateAsync(mainImage);
        image = result.result;
        setMainImage(image);
      }

      //Upload the content images and replace the base64 with the url
      let articleContent = [...content];
      for await (const el of articleContent) {
        if (el.type === "image" && el.value.includes("data:image")) {
          const image = await uploadImageMutation.mutateAsync(el.value);
          el.value = image.result;
        }
      }

      //Save the article
      await submitMutation.mutateAsync({
        title,
        articleDate,
        summary,
        mainImage: image,
        content: JSON.stringify(articleContent),
        showContactUs,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSetContent = (type, size, alt) => {
    setContent((prev) => [...prev, { type, value: "", alt, size }]);
  };

  const handleRemove = (index) => {
    setContent((prev) => prev.filter((el, i) => i !== index));
  };

  const handleMoveContent = (index, direction) => {
    setContent((prev) => {
      const temp = prev[index];
      prev[index] = prev[index + direction];
      prev[index + direction] = temp;
      return [...prev];
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Box component={Paper} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Summary"
                variant="outlined"
                fullWidth
                required
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  style={{ marginTop: 0 }}
                  disableToolbar
                  inputVariant="outlined"
                  margin="normal"
                  autoOk={true}
                  label="Article Date:"
                  value={articleDate}
                  onChange={(e) => setArticleDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.grayBorder}>
                <Grid container justifyContent="space-between">
                  <div>
                    <Typography color="textSecondary">Main Image*</Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImage(e, setMainImage)}
                    />
                  </div>
                  {mainImage && (
                    <ShowImage
                      image={mainImage}
                      alt="main"
                      style={classes.mainView}
                    />
                  )}
                </Grid>
                {mainImage.length > 1000000 && (
                  <Typography color="error">Image size is too big!</Typography>
                )}
              </div>
            </Grid>
            {!preview &&
              content.map((el, index) => {
                if (el.type === "text") {
                  return (
                    <TextAreaInput
                      key={index}
                      index={index}
                      lastIndex={content.length - 1}
                      content={el}
                      setContent={setContent}
                      handleRemove={() => handleRemove(index)}
                      handleMoveContent={handleMoveContent}
                    />
                  );
                }
                return (
                  <ImageInput
                    key={index}
                    index={index}
                    lastIndex={content.length - 1}
                    content={el}
                    setContent={setContent}
                    handleImage={handleImage}
                    handleRemove={() => handleRemove(index)}
                    handleMoveContent={handleMoveContent}
                  />
                );
              })}
          </Grid>
        </Box>
      </Grid>
      {preview && (
        <Grid item xs={12}>
          <Box component={Paper} p={2}>
            <Preview content={content} />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box component={Paper} p={2} className={classes.rightAlign}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showContactUs}
                onChange={(e) => setShowContactUs(e.target.checked)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={`${showContactUs ? "Hide" : "Show"} Contact Us`}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.marginRight}
            onClick={() => handleSetContent("text", "12", "text")}
          >
            Add Text
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.marginRight}
            onClick={() => handleSetContent("image", "6")}
          >
            Add Image
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.marginRight}
            onClick={() => setPreview((prev) => !prev)}
          >
            {preview ? "Edit" : "Preview"}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default Articles;
