import { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { formatTime } from "../utils/formatTime";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ReinstateInvoice = () => {
  const [invoiceId, setInvoiceId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const reinstateInvoiceMutation = useMutation(
    () =>
      axios
        .put(
          `${process.env.REACT_APP_AOMS_API_URL}/invoices/${invoiceId}/reinstate`
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setOpenSuccess(true);
      },
      onError: (err) => {
        setOpenError(true);
        setErrorMessage(err.response.data.message);
      },
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Reinstate Invoice</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                id="invoice-id"
                label="Invoice Id"
                variant="outlined"
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    reinstateInvoiceMutation.mutate();
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => reinstateInvoiceMutation.mutate()}
                fullWidth
                disabled={!invoiceId || reinstateInvoiceMutation.isLoading}
              >
                {reinstateInvoiceMutation.isLoading ? "Loading" : "Reinstate"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {reinstateInvoiceMutation?.data && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid item xs={12}>
              <Typography variant="h6">
                <u>Current Invoice</u>
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>InvoiceId</TableCell>
                      <TableCell>Deadline</TableCell>
                      <TableCell>TotalDue</TableCell>
                      <TableCell>HammerPrice</TableCell>
                      <TableCell>HammerVAT</TableCell>
                      <TableCell>BuyersPremium</TableCell>
                      <TableCell>BuyersPremiumVAT</TableCell>
                      <TableCell>Charges</TableCell>
                      <TableCell>ChargesVAT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reinstateInvoiceMutation?.data?.invoice?.map((el) => (
                      <TableRow key={el.id}>
                        <TableCell>{el.id}</TableCell>
                        <TableCell>
                          {formatTime(el.collectionDeadline)}
                        </TableCell>
                        <TableCell>{el.totalDue}</TableCell>
                        <TableCell>{el.hammerPrice}</TableCell>
                        <TableCell>{el.hammerVat}</TableCell>
                        <TableCell>{el.buyersPremium}</TableCell>
                        <TableCell>{el.buyersPremiumVat}</TableCell>
                        <TableCell>{el.charges}</TableCell>
                        <TableCell>{el.chargesVat}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <Typography variant="h6" gutterBottom>
                <u>New Invoice</u>
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>InvoiceId</TableCell>
                      <TableCell>Deadline</TableCell>
                      <TableCell>TotalDue</TableCell>
                      <TableCell>HammerPrice</TableCell>
                      <TableCell>HammerVAT</TableCell>
                      <TableCell>BuyersPremium</TableCell>
                      <TableCell>BuyersPremiumVAT</TableCell>
                      <TableCell>Charges</TableCell>
                      <TableCell>ChargesVAT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reinstateInvoiceMutation?.data?.newInvoice?.map((el) => {
                      if (!el) return null;
                      return (
                        <TableRow key={el.id}>
                          <TableCell>{el.id}</TableCell>
                          <TableCell>
                            {formatTime(el.collectionDeadline)}
                          </TableCell>
                          <TableCell>{el.totalDue}</TableCell>
                          <TableCell>{el.hammerPrice}</TableCell>
                          <TableCell>{el.hammerVat}</TableCell>
                          <TableCell>{el.buyersPremium}</TableCell>
                          <TableCell>{el.buyersPremiumVat}</TableCell>
                          <TableCell>{el.charges}</TableCell>
                          <TableCell>{el.chargesVat}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Grid>
      )}
      {reinstateInvoiceMutation?.data?.errors && (
        <Grid item xs={12}>
          <Typography variant="h6">Errors</Typography>
          <Box p={2} component={Paper}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Lot No</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reinstateInvoiceMutation?.data?.errors?.map((error) => {
                    if (!error) return null;
                    return (
                      <TableRow key={error.lotNo}>
                        <TableCell>{error.lotNo}</TableCell>
                        <TableCell>{error.message}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Lot has been reset!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Grid>
  );
};

export default ReinstateInvoice;
