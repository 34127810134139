import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { utils, writeFileXLSX } from "xlsx";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import AutoComplete from "@mui/lab/Autocomplete";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useDebounce from "../utils/useDebounce";
import { formatTime } from "../utils/formatTime";

const ReportStatsUsers = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().toISOString().split("T")[0] + "T00:00:00")
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().toISOString().split("T")[0] + "T23:59:59")
  );
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [userSearch, setUserSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const [debouncedUserSearch] = useDebounce(userSearch, 500);

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const usersData = useQuery(
    ["users", debouncedUserSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/users?search=${debouncedUserSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedUserSearch,
    }
  );

  const { data, isLoading, error } = useQuery(
    [
      "asStatsUsers",
      startDate,
      endDate,
      currentAuctionHouse,
      selectedUser,
      page,
      maximumRows,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/stats/users?startDate=${startDate}&endDate=${endDate}&auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&userId=${selectedUser?.id || ""}`
        )
        .then((res) => res.data)
  );

  const getUserData = (userId) => {
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stats/users/${userId}?startDate=${startDate}&endDate=${endDate}${
          currentAuctionHouse ? `&auctionHouseId=${currentAuctionHouse.id}` : ""
        }`
      )
      .then((res) => {
        const data = res.data;

        const filename = `user-stats-${data.name}-${formatTime(
          data.startDate
        )}-${formatTime(data.endDate)}.xlsx`;

        const wb = utils.book_new();
        const pickedWs = utils.json_to_sheet(data.picked, {
          header: [
            "pickBatchId",
            "lotId",
            "lotNo",
            "a",
            "location",
            "invoiceId",
            "productTitle",
          ],
        });
        const putawayWs = utils.json_to_sheet(data.putaway, {
          header: [
            "creationDate",
            "stockId",
            "container",
            "location",
            "productTitle",
          ],
        });

        const sortedWs = utils.json_to_sheet(data.sorted, {
          header: [
            "creationDate",
            "stockId",
            "container",
            "location",
            "productTitle",
          ],
        });

        const dispatchedWs = utils.json_to_sheet(data.dispatched, {
          header: [
            "dispatchNoteId",
            "processedDate",
            "invoiceId",
            "totalLots",
            "consignor",
            "trackingNotes",
          ],
        });

        utils.book_append_sheet(wb, pickedWs, "Picked");
        utils.book_append_sheet(wb, putawayWs, "Putaway");
        utils.book_append_sheet(wb, sortedWs, "Sorted");
        utils.book_append_sheet(wb, dispatchedWs, "Dispatched");
        writeFileXLSX(wb, filename);
      });
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Stats Users Report</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <AutoComplete
                  id="user-search"
                  options={usersData.data ? usersData.data?.users : []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={userSearch}
                  onChange={(e, value) => setSelectedUser(value)}
                  onInputChange={(e, value) => setUserSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  {auctionHousesQuery.data && (
                    <Select
                      value={currentAuctionHouse?.name}
                      label="Auction Houses"
                      onChange={handleSelect}
                      variant="outlined"
                    >
                      <MenuItem value="">All</MenuItem>
                      {auctionHousesQuery.data.map((house) => {
                        return (
                          <MenuItem value={house.name} key={house.id}>
                            {house.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <MobileTimePicker
                  margin="normal"
                  label="Start Time From:"
                  value={startDate}
                  defaultValue="00:00"
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  ampm={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <MobileTimePicker
                  margin="normal"
                  label="End Time From:"
                  value={endDate}
                  defaultValue="23:59:59"
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  ampm={false}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <p>Loading...</p>}
          {error && `An error has occurred: ${error.message}`}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Picked</TableCell>
                    <TableCell>Sorted</TableCell>
                    <TableCell>Lots Dispatched</TableCell>
                    <TableCell>PutAway</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.totalPicked}</TableCell>
                      <TableCell>{row.totalSorted}</TableCell>
                      <TableCell>{row.totalLotsDispatched || 0}</TableCell>
                      <TableCell>{row.totalPutAway}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => getUserData(row.userId)}
                        >
                          Download XLSX
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.totalRows || 0}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportStatsUsers;
