import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Grid, Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SmallStat from "./SmallStat";

const useStyles = makeStyles((theme) => ({
  employeeContainer: {
    display: "flex",
    gap: "10px",
    margin: "5px 0",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  employeeName: {
    flex: 1,
  },
  employeeHoliday: {
    fontSize: "0.8rem",
    color: "#646c78",
  },
  employeeImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "gray",
    objectFit: "cover",
    overflow: "hidden",
  },
}));

const EmployeeList = ({ title, list }) => {
  const classes = useStyles();

  const formatEndDate = (date) => {
    if (!date) return "";
    const d = new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
    return `Until ${d}`;
  };

  return (
    <div className={classes.stat}>
      <span>{title}</span>
      {list && list.length > 0 ? (
        <>
          {list.map((employee) => (
            <div className={classes.employeeContainer} key={employee.id}>
              <img
                src={`${process.env.REACT_APP_IMAGE_CDN_URL}/timesheet-profile/${employee.profileImage}`}
                alt="name"
                className={classes.employeeImage}
              />
              <span className={classes.employeeName}>{employee.name}</span>
              <span className={classes.employeeHoliday}>
                {formatEndDate(employee.endDate)}
              </span>
            </div>
          ))}
        </>
      ) : (
        <div>
          <span className={classes.employeeHoliday}>No employees</span>
        </div>
      )}
    </div>
  );
};

const Attendance = ({ auctionHouseName, showTable }) => {
  const [attendanceType, setAttendanceType] = useState("present");

  const { data, isLoading, error } = useQuery(
    ["attendance", auctionHouseName, attendanceType],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/attendance?startRowIndex=0&maximumRows=5&auctionHouse=${
            auctionHouseName || ""
          }&type=${attendanceType}&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && data.attendance && data.attendance.present && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Attendance</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Present"
                    value={data.attendance.present}
                    onClick={() => setAttendanceType("present")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Absent"
                    value={data.attendance.absent}
                    onClick={() => setAttendanceType("absent")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat title="Total" value={data.attendance.total} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && (
            <Grid
              item
              xs={12}
              style={{ padding: attendanceType !== "" ? "24px" : 0 }}
            >
              {attendanceType === "present" && (
                <Box p={3} component={Paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <EmployeeList title="Clocked In" list={data?.optionOne} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <EmployeeList title="On Break" list={data?.optionTwo} />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {attendanceType === "absent" && (
                <Box p={3} component={Paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <EmployeeList title="Absent" list={data?.optionOne} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <EmployeeList title="Holiday" list={data?.optionTwo} />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Attendance;
