import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";

const getStartOfYear = (year) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(0);
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const getYears = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = 2016; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  vendorStart: {
    "border-left": "1px solid #cbcbcb",
  },
});

const ReportsWeeklyTotals = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState(
    getStartOfYear(new Date().getFullYear())
  );
  const [endTime, setEndTime] = useState(
    getStartOfYear(new Date().getFullYear() + 1)
  );
  const [loading, setLoading] = useState(false);
  const [invoiceHeadings, setInvoiceHeadings] = useState([]);
  const [sbiHeadings, setSbiHeadings] = useState([]);
  const years = getYears();
  const [year, setYear] = useState(new Date().getFullYear());

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setStartTime(getStartOfYear(e.target.value));
    setEndTime(getStartOfYear(e.target.value + 1));
  };

  const currencyFormatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/reports/weeklytotals?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`
      )
      .then((res) => {
        const results = res.data.map((week) => {
          return {
            ...week,
            turnover: week.selfBillingInvoiceHammerPrice + week.buyersPremium,
          };
        });
        const invoiceHeadings = [];
        const sbiHeadings = [];
        for (const week of results) {
          for (const invoiceCharge of week.invoiceCharges) {
            if (
              invoiceHeadings.findIndex(
                (h) => h.id === invoiceCharge.chargeTypeId
              ) === -1
            ) {
              invoiceHeadings.push({
                id: invoiceCharge.chargeTypeId,
                name: invoiceCharge.chargeType,
              });
            }
            if (
              invoiceCharge.chargeType === "Courier Handling Charge" ||
              invoiceCharge.chargeType === "Fly Tipping Fee" ||
              invoiceCharge.chargeType === "Internet Charge" ||
              invoiceCharge.chargeType === "Lot Processing Fee" ||
              invoiceCharge.chargeType === "Pallet Charge" ||
              invoiceCharge.chargeType === "Postage" ||
              invoiceCharge.chargeType === "Postage (Manual)" ||
              invoiceCharge.chargeType === "Storage Charge"
            ) {
              week.turnover += invoiceCharge.value;
            }
          }
          for (const sbiCharge of week.selfBillingInvoiceCharges) {
            if (
              sbiHeadings.findIndex((h) => h.id === sbiCharge.chargeTypeId) ===
              -1
            ) {
              sbiHeadings.push({
                id: sbiCharge.chargeTypeId,
                name: sbiCharge.chargeType,
              });
            }
            if (
              sbiCharge.chargeType === "Advertising" ||
              sbiCharge.chargeType === "Collection Charge EXC VAT" ||
              sbiCharge.chargeType === "Lotting Fee"
            ) {
              week.turnover += sbiCharge.value;
            }
          }
        }
        setData(results);
        setInvoiceHeadings(
          invoiceHeadings.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        setSbiHeadings(
          sbiHeadings.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [startTime, endTime]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Weekly Totals</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Select
                value={year}
                onChange={handleYearChange}
                label="Select Year"
                disabled={loading}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {loading && (
        <Grid item xs={12}>
          Loading...
        </Grid>
      )}
      {!loading && data.length === 0 && (
        <Grid item xs={12}>
          No data
        </Grid>
      )}
      {!loading && data.length > 0 && (
        <Grid item xs={12}>
          <Box component={Paper}>
            <Grid container p={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Week/Year</TableCell>
                        <TableCell className={classes.vendorStart}>
                          Hammer Price
                        </TableCell>
                        <TableCell className={classes.vendorStart}>
                          Buyers Premium
                        </TableCell>
                        {invoiceHeadings.map((heading) => (
                          <TableCell key={heading.id}>{heading.name}</TableCell>
                        ))}
                        <TableCell className={classes.vendorStart}>
                          Vendor Commission
                        </TableCell>
                        {sbiHeadings.map((heading) => (
                          <TableCell key={heading.id}>{heading.name}</TableCell>
                        ))}
                        <TableCell className={classes.vendorStart}>
                          Total Fee Income
                        </TableCell>
                        <TableCell className={classes.vendorStart}>
                          Turnover
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((week) => (
                        <TableRow key={week.weekNo} hover>
                          <TableCell>{week.weekNo}</TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(
                              week.selfBillingInvoiceHammerPrice
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(week.buyersPremium)}
                          </TableCell>
                          {invoiceHeadings.map((heading) => {
                            const charge = week.invoiceCharges.find(
                              (c) => c.chargeTypeId === heading.id
                            );
                            return (
                              <TableCell key={heading.id} align="right">
                                {currencyFormatter.format(
                                  charge ? charge.value : 0
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            {currencyFormatter.format(week.commission)}
                          </TableCell>
                          {sbiHeadings.map((heading) => {
                            const charge = week.selfBillingInvoiceCharges.find(
                              (c) => c.chargeTypeId === heading.id
                            );
                            return (
                              <TableCell key={heading.id} align="right">
                                {currencyFormatter.format(
                                  charge ? charge.value : 0
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            <b>
                              {currencyFormatter.format(week.totalFeeIncome)}
                            </b>
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.vendorStart}
                          >
                            <b>{currencyFormatter.format(week.turnover)}</b>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) =>
                                  total + week.selfBillingInvoiceHammerPrice,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) => total + week.buyersPremium,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        {invoiceHeadings.map((heading) => {
                          const total = data.reduce(
                            (total, week) =>
                              total +
                              (week.invoiceCharges.find(
                                (c) => c.chargeTypeId === heading.id
                              )?.value || 0),
                            0
                          );
                          return (
                            <TableCell key={heading.id} align="right">
                              <b>{currencyFormatter.format(total)}</b>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) => total + week.commission,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        {sbiHeadings.map((heading) => {
                          const total = data.reduce(
                            (total, week) =>
                              total +
                              (week.selfBillingInvoiceCharges.find(
                                (c) => c.chargeTypeId === heading.id
                              )?.value || 0),
                            0
                          );
                          return (
                            <TableCell key={heading.id} align="right">
                              <b>{currencyFormatter.format(total)}</b>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) => total + week.totalFeeIncome,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.vendorStart}
                        >
                          <b>
                            {currencyFormatter.format(
                              data.reduce(
                                (total, week) => total + week.turnover,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportsWeeklyTotals;
