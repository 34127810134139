import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AutoComplete from "@mui/lab/Autocomplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

import useDebounce from "../utils/useDebounce";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  marginZero: {
    margin: 0,
  },
  waiting: {
    backgroundColor: "#f5f5f5",
  },
}));

const ReportDispatch = () => {
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [generate, setGenerate] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const csvRef = useRef();
  const [debouncedSearch] = useDebounce(search, 500);

  const usersData = useQuery(
    ["users", debouncedSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/users?search=${debouncedSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedSearch,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const { data, loading, error } = useQuery(
    ["dispatch", page, rowsPerPage, generate],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/dispatch?auctionHouseId=${currentAuctionHouse.id}&userId=${
            selectedUser ? selectedUser.id : ""
          }&startDate=${startDate}&endDate=${endDate}&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    {
      enabled: generate,
    }
  );

  const cvsDataMutation = useMutation(
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/reports/dispatch?auctionHouseId=${currentAuctionHouse.id}&userId=${
            selectedUser ? selectedUser.id : ""
          }&startDate=${startDate}&endDate=${endDate}&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        if (res.results) {
          setCsvData(res.results);
          csvRef.current.link.click();
          setOpenSuccess(true);
        }
      },
      onError: () => setOpenError(true),
    }
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
    setGenerate(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Inbound Dispatch</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <AutoComplete
                id="search"
                options={usersData.data ? usersData.data?.users : []}
                getOptionLabel={(option) => option.name || ""}
                inputValue={search}
                onChange={(e, value) => {
                  setSelectedUser(value);
                  setGenerate(false);
                }}
                onInputChange={(e, value) => setSearch(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search user" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e);
                    setGenerate(false);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e);
                    setGenerate(false);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item className={classes.center}>
            {!generate ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setGenerate(true)}
              >
                Generate Report
              </Button>
            ) : (
              <>
                <CSVLink
                  ref={csvRef}
                  data={csvData}
                  filename={`dispatch-report-${
                    selectedUser ? selectedUser.name : "all"
                  }-${startDate}-${endDate}.csv`}
                  target="_blank"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => cvsDataMutation.mutateAsync()}
                >
                  Download Report
                </Button>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
      {loading && <CircularProgress />}
      {error && "An error has occurred: " + error.message}
      {!data && "No dispatch data for this period or auction house"}
      {data && data.results && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Processed Date</TableCell>
                    <TableCell>Last Processed</TableCell>
                    <TableCell>Total Lots</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.processedDate}</TableCell>
                      <TableCell>{el.lastProcessed}</TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ReportDispatch;
