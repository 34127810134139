import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import axios from "axios";

const ReturnsCreate = (props) => {
  const [printServiceId, setPrintServiceId] = useState("");
  const [nameOfPrinter, setNameOfPrinter] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [nameOfPrinterService, setNameOfPrinterService] = useState("");
  const [printServices, setPrintServices] = useState([]);

  const resetForm = () => {
    setPrintServiceId("");
    setNameOfPrinter("");
    setIpAddress("");
    setNameOfPrinterService("");
  };

  const handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    switch (name) {
      case "nameOfPrinter":
        setNameOfPrinter(value);
        break;
      case "ipAddress":
        setIpAddress(value);
        break;
      case "location":
        setPrintServiceId(value);
        break;
      case "nameOfPrinterService":
        setNameOfPrinterService(value);
        break;
      default:
        break;
    }
  };
  const asBindOtherData = async () => {
    const ps = await axios.get(
      `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
    );
    const printServices = ps.data.printerService.map((t) => {
      return {
        id: t.id,
        name: t.name,
      };
    });
    setPrintServices(printServices);
  };

  const bindOtherData = useCallback(asBindOtherData, []);

  useEffect(() => {
    bindOtherData();
  }, [bindOtherData]);

  const doSubmit = (evt) => {
    evt.preventDefault();

    async function call() {
      await handleSubmit();
    }
    call();
  };

  const handleSubmit = async () => {
    if (nameOfPrinterService != null || nameOfPrinterService !== "") {
      try {
        await axios.post(`${process.env.REACT_APP_PRINTERS_API_URL}/printers`, {
          printServiceId: printServiceId,
          ipAddress: ipAddress,
          nameOfPrinter: nameOfPrinter,
        });
      } catch (err) {
        console.log("Error creating printer service with: ", err.response);
      }
    }
    resetForm();
  };

  const handlePrinterServiceSubmit = async (evt) => {
    evt.preventDefault();

    if (nameOfPrinterService !== null || nameOfPrinterService !== "") {
      try {
        await axios.post(
          `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`,
          {
            nameOfPrinterService: nameOfPrinterService,
          }
        );
      } catch (err) {
        console.log("Error creating printer service with: ", err.response);
      }
    }
    resetForm();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/printers">Printers</Link>
          <Typography color="textPrimary">Create New Printers</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handlePrinterServiceSubmit}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="textPrimary">
                  Printer Service Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Name"
                  name="nameOfPrinterService"
                  onChange={handleChange}
                  value={nameOfPrinterService}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit Printer Service
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={doSubmit}>
          <Box p={2} component={Paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography color="textPrimary">Printer Details</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Name"
                  name="nameOfPrinter"
                  onChange={handleChange}
                  value={nameOfPrinter}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="IP Address"
                  name="ipAddress"
                  onChange={handleChange}
                  value={ipAddress}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel id="lbl-status">Location:</InputLabel>
                <Select
                  labelId="lbl-status"
                  id="location"
                  value={printServiceId}
                  name="location"
                  fullWidth
                  onChange={handleChange}
                >
                  {printServices.map((r) => {
                    return (
                      <MenuItem key={`printservice-${r.id}`} value={r.id}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit Printer
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default ReturnsCreate;
