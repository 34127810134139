import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";

const ChangePassword = (props) => {
  const { userId } = props.location.state;

  const [newPassword, setNewPassword] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleSave = () => {
    axios
      .put(
        `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/change-password`,
        {
          newPassword: newPassword,
        }
      )
      .then((res) => {
        setOpenSuccess(true);
        setNewPassword("");
      })
      .catch((err) => console.error(err));
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "newPassword":
        setNewPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/users">Users</Link>
            <Link to={`/users/${userId}`}>Manage User</Link>
            <Typography color="textPrimary">Change Password</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="New Password"
                  name="newPassword"
                  value={newPassword}
                  fullWidth
                  variant="outlined"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" onClick={handleSave}>
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Password Updated"
      />
    </Fragment>
  );
};

export default ChangePassword;
