import React, { useState, useReducer, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";

import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import imageArray from "../utils/notificationsImageArray";

const reducer = (state, action) => {
  switch (action.type) {
    case "getInfo":
      return { ...action.payload };
    case "title":
      return { ...state, title: action.payload };
    case "body":
      return { ...state, body: action.payload };
    case "button_name":
      return { ...state, buttonName: action.payload };
    case "button_url":
      return { ...state, buttonURL: action.payload };
    case "size":
      return { ...state, size: action.payload };
    case "type":
      return { ...state, type: action.payload };
    case "image":
      return { ...state, image: action.payload };
    case "scheduleAt":
      return { ...state, scheduleAt: action.payload };
    default:
      return state;
  }
};

const NotificationUpdate = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [customImageCheckbox, setCustomImageCheckbox] = useState(true);

  const { id } = useParams();
  const dateTime = new Date();

  const notificationDetails = useQuery("getNotification", async () => {
    return (
      await axios.get(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications/${id}`
      )
    ).data;
  });

  const [state, dispatch] = useReducer(reducer, {
    title: "",
    body: "",
    buttonName: "",
    buttonURL: "",
    size: "small_text",
    type: "auctionEndingSoon",
    image: "",
    scheduleAt: dateTime,
  });

  useEffect(() => {
    if (notificationDetails.data) {
      const data = { ...notificationDetails.data.response };
      for (let key in data) {
        if (data[key] === null || data[key] === undefined) data[key] = "";
      }
      dispatch({
        type: "getInfo",
        payload: { ...data, scheduleAt: new Date(data.scheduleAt) },
      });
    }
  }, [notificationDetails]);

  const handelSubmitToSelf = async () => {
    if (state.size === "big_image" && !state.image) return setOpenError(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/send-notification`,
        {
          title: state.title,
          body: state.body,
          actionTitle: state.buttonName,
          actionURL: state.buttonURL,
          type: state.type,
          size: state.size,
          image: state.image,
        }
      );
      setOpenSuccess(true);
    } catch (err) {
      console.log(err);
      setOpenError(true);
    }
  };

  const updateMutation = useMutation(
    (notification) =>
      axios.put(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/manage/notifications/${id}`,
        notification
      ),
    {
      onSettled: (data, error, variables, context) => {
        if (error) setOpenError(true);
        if (data) setOpenSuccess(true);
      },
    }
  );

  const handleSaveNotification = async () => {
    updateMutation.mutate({
      title: state.title,
      body: state.body,
      size: state.size,
      scheduleAt: state.scheduleAt,
      image: state.image,
      type: state.type,
      buttonName: state.buttonName,
      buttonURL: state.buttonURL,
    });
  };

  const handleCustomImageCheckBox = () => {
    setCustomImageCheckbox(!customImageCheckbox);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/notifications">Notifications</Link>
          <Typography color="textPrimary">Update Notification</Typography>
        </Breadcrumbs>
      </Grid>

      {notificationDetails.isLoading ? (
        <CircularProgress />
      ) : notificationDetails.error ? (
        "An error has occurred: " + notificationDetails.error.message
      ) : (
        <Grid item xs={12}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSaveNotification(event);
            }}
          >
            <Box p={2} component={Paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="textPrimary">
                    Notification Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    name="title"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.title || ""}
                    onChange={(event) =>
                      dispatch({ type: "title", payload: event.target.value })
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Body"
                    name="body"
                    variant="outlined"
                    fullWidth
                    multiline
                    required
                    value={state.body || ""}
                    onChange={(event) =>
                      dispatch({ type: "body", payload: event.target.value })
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Button name"
                    name="button name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.buttonName || ""}
                    onChange={(event) =>
                      dispatch({
                        type: "button_name",
                        payload: event.target.value,
                      })
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Button URL"
                    name="button url"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.buttonURL || ""}
                    onChange={(event) =>
                      dispatch({
                        type: "button_url",
                        payload: event.target.value,
                      })
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="lbl-size">Notification Size</InputLabel>
                    <Select
                      labelId="lbl-size"
                      id="notification_size"
                      label="Notification Size"
                      name="notification_size"
                      variant="outlined"
                      fullWidth
                      required
                      value={state.size || ""}
                      onChange={(event) =>
                        dispatch({
                          type: "size",
                          payload: event.target.value,
                        })
                      }
                    >
                      <MenuItem value={"small_text"}>Small Text</MenuItem>
                      <MenuItem value={"big_text"}>Big Text</MenuItem>
                      <MenuItem value={"big_image"}>Big Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="lbl-type">Notification Type</InputLabel>
                    <Select
                      labelId="lbl-type"
                      id="notification_type"
                      label="Notification Type"
                      name="notification_type"
                      variant="outlined"
                      fullWidth
                      required
                      value={state.type || ""}
                      onChange={(event) =>
                        dispatch({
                          type: "type",
                          payload: event.target.value,
                        })
                      }
                    >
                      <MenuItem value={"auctionEndingSoon"}>
                        Action Ending Soon
                      </MenuItem>
                      <MenuItem value={"outbid"}>Outbid</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customImageCheckbox}
                        onChange={handleCustomImageCheckBox}
                        name="Custom Image"
                      />
                    }
                    label="Custom Image"
                  />
                  {customImageCheckbox ? (
                    <TextField
                      label="Image Url"
                      name="image"
                      variant="outlined"
                      fullWidth
                      required={state.type === "big_image"}
                      value={state.image}
                      onChange={(event) =>
                        dispatch({ type: "image", payload: event.target.value })
                      }
                    ></TextField>
                  ) : (
                    <Select
                      labelId="lb2-type"
                      id="image_type"
                      label="Image Type"
                      name="image_type"
                      variant="outlined"
                      fullWidth
                      required={state.type === "big_image"}
                      value={state.image}
                      onChange={(event) =>
                        dispatch({ type: "image", payload: event.target.value })
                      }
                    >
                      {imageArray.map((image, index) => (
                        <MenuItem value={image}>
                          <img
                            src={image}
                            alt={`Notification-${index}`}
                            style={{ width: "350px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      label="Schedule At:"
                      value={state.scheduleAt || ""}
                      onChange={(event) =>
                        dispatch({
                          type: "scheduleAt",
                          payload: event,
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      variant="inline"
                      margin="normal"
                      label="Schedule At:"
                      value={state.scheduleAt || ""}
                      onChange={(event) =>
                        dispatch({ type: "scheduleAt", payload: event })
                      }
                    ></TimePicker>
                  </Grid>
                </LocalizationProvider>

                <Grid item xs={12} md={6}>
                  <Button type="submit" variant="contained" color="primary">
                    Save Notification
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="contained" onClick={handelSubmitToSelf}>
                    Submit to self
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Notification sent!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default NotificationUpdate;
