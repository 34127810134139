import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import axios from "axios";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import AddIcon from "@mui/icons-material/Add";

const AddNewRefundTypeDialog = (props) => {
  return (
    <Dialog
      open={props.openDialog}
      onClose={props.toggleAddNewRefundTypeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add New</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          value={props.refundTypeName}
          name="refundTypeName"
          onChange={props.handleInput}
          label="New refund type"
          fullWidth
          required
        />
        {props.errorMessage && (
          <Alert severity="warning">{props.errorMessage}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleAddNewRefundTypeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={props.handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RefundTypes = () => {
  const refundTypesQuery = useQuery("refundTypes", () =>
    axios
      .get(`${process.env.REACT_APP_QMS_API_URL}/refunds/types`)
      .then((res) => res.data)
  );

  const [refundTypeName, setRefundTypeName] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const useAddRefundType = () => {
    const queryClient = useQueryClient();

    return useMutation(
      (newRefundType) =>
        axios.post(
          `${process.env.REACT_APP_QMS_API_URL}/refunds/types`,
          newRefundType
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("refundTypes");
          setOpenSuccess(true);
          setOpenDialog(false);
          setRefundTypeName("");
        },
        onError: () => {
          setErrorMessage("There was an error adding a new refund type");
        },
      }
    );
  };

  const refundTypeMutation = useAddRefundType();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "refundTypeName":
        setRefundTypeName(value);
        break;
      default:
        break;
    }
  };

  const toggleAddNewRefundTypeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!refundTypeName) return;

    const newRefundType = {
      refundTypeName,
    };
    refundTypeMutation.mutate(newRefundType);
  };

  return <>
    {refundTypesQuery.isLoading || refundTypeMutation.isLoading ? (
      <CircularProgress />
    ) : refundTypesQuery.error ? (
      <Alert severity="warning">
        {`An error has occurred: ${refundTypesQuery.error.message}`}
      </Alert>
    ) : refundTypeMutation.isError ? (
      <Alert severity="warning">
        {`An error has occurred: ${refundTypeMutation.error.message}`}
      </Alert>
    ) : (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/qms/refunds`}>Refunds</Link>
            <Typography color="textPrimary">Refund Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Refund Types</Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={toggleAddNewRefundTypeDialog}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add new
                </Button>
                <AddNewRefundTypeDialog
                  openDialog={openDialog}
                  toggleAddNewRefundTypeDialog={toggleAddNewRefundTypeDialog}
                  refundTypeName={refundTypeName}
                  handleInput={handleInput}
                  handleSave={handleSave}
                  errorMessage={errorMessage}
                />
              </Grid>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refundTypesQuery?.data.map((refundType) => {
                    return (
                      <TableRow key={refundType.id} hover>
                        <TableCell>{refundType.id}</TableCell>
                        <TableCell>{refundType.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    )}
    <SuccessAlert
      openSuccess={openSuccess}
      setOpenSuccess={setOpenSuccess}
      message="New refund type added successfully"
    />
  </>;
};

export default RefundTypes;
