import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";

const Returns = ({ currentAuctionHouse, showTable }) => {
  const [returnsStatus, setReturnsStatus] = useState(0);
  const returnsQuery = useQuery(
    ["returns", currentAuctionHouse, returnsStatus],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_RETURNS_API_URL
          }/returns/dashboard?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&status=${returnsStatus}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      <Grid item xs={12}>
        <Box p={3} component={Paper}>
          <Grid item xs={12}>
            <Typography variant="h5">Returns</Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SmallStat
                title="Open"
                value={returnsQuery.data?.open}
                onClick={() => setReturnsStatus(0)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SmallStat
                title="Pending"
                value={returnsQuery.data?.pending}
                onClick={() => setReturnsStatus(1)}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {showTable && (
        <Grid item xs={12}>
          <Box p={3} component={Paper}>
            {returnsQuery.isLoading && <p>Loading...</p>}
            {returnsQuery.error && (
              <div>Error: {returnsQuery.error.message}</div>
            )}
            {returnsQuery.data && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Ticket Id</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Inbound Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {returnsQuery.data?.results.map((r) => (
                      <TableRow key={r.id}>
                        <TableCell>{r.id}</TableCell>
                        <TableCell>
                          <Link to={`/qms/tickets/${r.ticketId}`}>
                            {r.ticketId}
                          </Link>
                        </TableCell>
                        <TableCell>{r.location}</TableCell>
                        <TableCell>{formatTime(r.creationDate)}</TableCell>
                        <TableCell>{formatTime(r.inBoundDate)}</TableCell>
                        <TableCell>
                          <Link to={`/returns/view/${r.id}`}>View</Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default Returns;
