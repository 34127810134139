import { makeStyles } from "@mui/styles";
import { CardActionArea } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  stat: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    border: "1px solid gray",
    overflow: "hidden",
  },
  emoji: {
    fontSize: "2.5rem",
    padding: "10px",
  },
  value: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px",
  },
  statBody: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
}));

const SmallStat = ({ title, value, emoji, onClick }) => {
  const classes = useStyles();
  return (
    <CardActionArea onClick={onClick}>
      <div className={classes.stat}>
        {emoji && <span className={classes.emoji}>{emoji}</span>}
        <div className={classes.value}>
          <span>{title}</span>
          <span className={classes.statBody}>
            {value !== null && value !== undefined ? value : "-"}
          </span>
        </div>
      </div>
    </CardActionArea>
  );
};

export default SmallStat;
