import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";

const ErrorAlert = ({ openError, setOpenError, message }) => {
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  return (
    <Snackbar
      open={openError}
      onClose={handleCloseError}
      autoHideDuration={4000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleCloseError}
        severity="error"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorAlert;
