import { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Grid, Box, Paper, Typography } from "@mui/material";
import SmallStat from "./SmallStat";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatTime";

const AuctionStatistics = ({ currentAuctionHouse }) => {
  const [currentWeekStart, setCurrentWeekStart] = useState("");
  const [currentWeekEnd, setCurrentWeekEnd] = useState("");
  const [previousStartTime, setPreviousStartTime] = useState("");
  const [previousEndTime, setPreviousEndTime] = useState("");

  useEffect(() => {
    const currentStart = new Date();

    currentStart.setDate(currentStart.getDate() - currentStart.getDay());
    currentStart.setHours(0, 0, 0, 0);

    const currentEnd = new Date(currentStart);
    currentEnd.setDate(currentEnd.getDate() + 6);
    currentEnd.setHours(23, 59, 59, 999);

    const previousStart = new Date(currentStart);
    previousStart.setDate(previousStart.getDate() - 7);

    const previousEnd = new Date(previousStart);
    previousEnd.setDate(previousEnd.getDate() + 6);
    previousEnd.setHours(23, 59, 59, 999);

    setCurrentWeekStart(currentStart);
    setCurrentWeekEnd(currentEnd);
    setPreviousStartTime(previousStart);
    setPreviousEndTime(previousEnd);
  }, []);

  const { data, isLoading, error } = useQuery(
    ["currentStats", currentAuctionHouse],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/auctionstats?currentStartTime=${currentWeekStart}&currentEndTime=${currentWeekEnd}&previousStartTime=${previousStartTime}&previousEndTime=${previousEndTime}&auctionHouseId=${
            currentAuctionHouse?.id || ""
          }`
        )
        .then((res) => res.data),
    {
      enabled: !!currentWeekStart && !!previousStartTime && !!previousEndTime,
    }
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Current Week Auctions Stats {formatDate(currentWeekStart)} -{" "}
                  {formatDate(currentWeekEnd)}
                </Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Total Auctions"
                    value={data?.current?.totalAuctions}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Total Lots"
                    value={data?.current?.totalLots}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Lots With Bids"
                    value={data?.current?.totalLotBids}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Registrations"
                    value={data?.current?.totalRegisteredUsers}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Total Hammer"
                    value={formatCurrency(data?.current?.totalHammer || 0)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Previous Week Auctions Stats {formatDate(previousStartTime)} -{" "}
                  {formatDate(previousEndTime)}
                </Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Total Auctions"
                    value={data?.previous?.totalAuctions}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Total Lots"
                    value={data?.previous?.totalLots}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Lots With Bids"
                    value={data?.previous?.totalLotBids}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Registrations"
                    value={data?.previous?.totalRegisteredUsers}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SmallStat
                    title="Total Hammer"
                    value={formatCurrency(data?.previous?.totalHammer || 0)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default AuctionStatistics;
