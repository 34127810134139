import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const ReportStockIntegrityView = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [check, setCheck] = useState(null);
  const [scan, setScan] = useState("");
  const [stocks, setStocks] = useState([]);
  const [readonly, setReadonly] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "scan":
        setScan(value);
        break;
      default:
        break;
    }
  };

  const getStock = () => {
    if (isNaN(parseInt(scan))) {
      setError("Invalid scan");
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_AOMS_API_URL}/stockintegritychecks/${id}/stocks`,
        {
          stockId: scan,
        }
      )
      .then((res) => {
        const arr = [...stocks, res.data.stock];
        setStocks(arr);
        setScan("");
      })
      .catch((err) => {
        setError(err.response.data.message);
        setScan("");
      });
  };

  const removeStock = (stockId) => {
    const arr = stocks.filter((stock) => stock.id !== stockId);
    setStocks(arr);
    axios.delete(
      `${process.env.REACT_APP_AOMS_API_URL}/stockintegritychecks/${id}/stocks/${stockId}`
    );
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      getStock();
    }
  };

  const processCheck = () => {
    axios
      .put(`${process.env.REACT_APP_AOMS_API_URL}/stockintegritychecks/${id}`, {
        processed: true,
      })
      .then((res) => {
        console.log(res);
        setReadonly(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setError(null);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/stockintegritychecks/${id}`)
      .then((res) => {
        console.log("check", res.data);
        setCheck(res.data);
        setStocks(res.data.stocks);
        if (res.data.processed) {
          setReadonly(true);
        }
        setLoading(false);
      })

      .catch((err) => {
        console.log("get check error:", err);
        setError("An error occurred while getting the Stock Integrity Check");
        setLoading(false);
      });
  }, [id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/reports/stockintegrity">Stock Integrity Checks</Link>
          <Link to={`/reports/stockintegrity/${id}`}>Check {id}</Link>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        {!error && loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Box p={2} component={Paper}>
                    <TextField
                      readonly
                      value={check.id}
                      label="Check ID"
                      fullWidth
                    />
                    <TextField
                      readonly
                      value={check.container.name}
                      label="Container/Location"
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box p={2} component={Paper}>
                    <TextField
                      value={scan}
                      label="Scan Label"
                      fullWidth
                      name="scan"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      readonly={readonly}
                    />
                  </Box>
                  {error && (
                    <Box p={2} component={Paper}>
                      <Typography variant="body1" color="error">
                        {error}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {stocks.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stock ID</TableCell>
                  <TableCell>Product Title</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stocks.map((stock) => (
                  <TableRow key={stock.id}>
                    <TableCell>{stock.id}</TableCell>
                    <TableCell>{stock.productTitle}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => removeStock(stock.id)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      {!readonly && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => processCheck()}
          >
            Process
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportStockIntegrityView;
