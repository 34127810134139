import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ReportLotsCondition = () => {
  const [status, setStatus] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [estateNo, setEstateNo] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [lotsData, setLotsData] = useState([]);
  const [CVSFilename, setCVSFilename] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const lotsConditionMutation = useMutation(
    ({ maxRows }) => {
      let extraQuery = "";
      if (estateNo !== "") extraQuery += `&estateNo=${estateNo}`;
      if (vendorId !== "") extraQuery += `&vendorId=${vendorId}`;

      return axios.get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/reports/lots-condition?status=${status}&startDate=${startDate}&endDate=${endDate}&page=${
          page * rowsPerPage
        }&maximumRows=${maxRows ? maxRows : rowsPerPage}${extraQuery}`
      );
    },
    {
      onSuccess: (data) => {
        setLotsData(data.data.lots);
        setOpenSuccess(true);
      },
      onError: (error) => setOpenError(true),
    }
  );

  const getVendorsMutation = useMutation(
    () =>
      axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/lots/get-vendors?startDate=${startDate}&endDate=${endDate}&status=0`
      ),
    {
      onSettled: (data, error) => {
        if (data) setOpenSuccess(true);
        if (error) setOpenError(true);
      },
    }
  );

  useEffect(() => {
    if (startDate && endDate) {
      const start = startDate.toISOString().split("T")[0];
      const end = endDate.toISOString().split("T")[0];
      setCVSFilename(`lots-report-${start}_${end}.csv`);
      getVendorsMutation.reset();
      setVendorId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    lotsConditionMutation.mutate();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    lotsConditionMutation.mutate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Lots Condition</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {!getVendorsMutation.data ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getVendorsMutation.mutate()}
                >
                  Get Vendors
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={lotsConditionMutation.mutate}
                >
                  Generate Report
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <TextField
                id="estateNo"
                name="estateNo"
                label="Estate No..."
                value={estateNo}
                onChange={(e) => setEstateNo(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="vendorId">Vendor</InputLabel>
                <Select
                  labelId="vendorId"
                  id="vendorId"
                  name="vendorId"
                  label="Vendor Id"
                  value={vendorId}
                  fullWidth
                  onChange={(e) => setVendorId(e.target.value)}
                >
                  {getVendorsMutation?.data?.data?.vendors.map((vendor) => {
                    return (
                      <MenuItem value={vendor.id} key={vendor.id}>
                        {vendor.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="status">Status</InputLabel>
                <Select
                  label="Status"
                  labelId="status"
                  id="status"
                  fullWidth
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={0}>Pre Sale</MenuItem>
                  <MenuItem value={1}>Post Sale</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {lotsData.length <= 10 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!lotsConditionMutation.data?.data?.count}
                  onClick={() =>
                    lotsConditionMutation.mutate({
                      maxRows: lotsConditionMutation.data.data.count,
                    })
                  }
                >
                  Request Download
                </Button>
              ) : (
                <CSVLink data={lotsData} filename={CVSFilename}>
                  <Button variant="contained" color="primary">
                    Download Ready
                  </Button>
                </CSVLink>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {lotsConditionMutation.isLoading ? (
            <CircularProgress />
          ) : lotsConditionMutation.error ? (
            "An error has occurred:" + lotsConditionMutation.error
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Lot No</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Product Title</TableCell>
                    <TableCell>Product Description</TableCell>
                    <TableCell>Item Description</TableCell>
                    <TableCell>Item Reference</TableCell>
                    <TableCell>Estate No</TableCell>
                    <TableCell>Vendor Name</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lotsConditionMutation.data?.data?.lots?.map((lot) => (
                    <TableRow key={lot.id}>
                      <TableCell>{lot.lotNo}</TableCell>
                      <TableCell>{lot.productCode}</TableCell>
                      <TableCell>{lot.productTitle}</TableCell>
                      <TableCell>{lot.productDescription}</TableCell>
                      <TableCell>{lot.itemDescription}</TableCell>
                      <TableCell>{lot.itemReference}</TableCell>
                      <TableCell>{lot.estateNo}</TableCell>
                      <TableCell>{lot.vendorName}</TableCell>
                      <TableCell>{lot.invoiceDate}</TableCell>
                      <TableCell>{lot.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={
                  lotsConditionMutation.data?.data?.count
                    ? lotsConditionMutation.data.data.count
                    : 0
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Lots loaded successfully!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="An error has occurred"
      />
    </Grid>
  );
};

export default ReportLotsCondition;
