import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";
import { marked } from "marked";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "100%",
    minHeight: "100px",
    maxHeight: "140px",
    maxWidth: "100%",
    minWidth: "100%",
    padding: theme.spacing(2),
    fontSize: "14px",
  },
  preview: {
    width: "100%",
    border: "1px solid #ccc",
    paddingLeft: theme.spacing(2),
  },
  auctionCategoryContainer: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    gap: "1rem",
  },
}));

const TextAreaComp = ({ value, handleChange, name, placeholder }) => {
  const [view, setView] = useState(false);

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      {view ? (
        <div
          className={classes.preview}
          dangerouslySetInnerHTML={{
            __html: marked(value),
          }}
        />
      ) : (
        <textarea
          className={classes.textArea}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setView(!view)}
      >
        {view ? "Edit" : "View"}
      </Button>
    </Grid>
  );
};

const ContentLandingPage = () => {
  const [type, setType] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [auctionId, setAuctionId] = useState("");
  const [auctionsOrCategoriesId, setAuctionsOrCategoriesId] = useState([]);
  const [image, setImage] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [title, setTitle] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [educationData, setEducationData] = useState("");
  const [add, setAdd] = useState([]);
  const [remove, setRemove] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { id } = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    "getLandingPage",
    async () =>
      await axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage/${id}`)
        .then((res) => {
          const education = {
            one: res.data?.result?.CEducationOne,
            two: res.data?.result?.CEducationTwo,
            three: res.data?.result?.CEducationThree,
          };
          setType(res.data?.result?.type);
          setAuctionsOrCategoriesId(res.data?.auctionCategoriesIds);
          setTitle(res.data?.result?.title);
          setSeoTitle(res.data?.result?.seoTitle);
          setDescription(res.data?.result?.CDescription);
          setUrl(res.data?.result?.url);
          setEducationData(education);
          return res.data;
        })
  );

  const categories = useQuery(
    "asCategories",
    async () =>
      await axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage/categories`)
        .then((res) => res.data)
  );

  const updatePageMutation = useMutation(
    () =>
      axios
        .patch(
          `${process.env.REACT_APP_AUCTION_API_URL}/landingPage/update/${id}`,
          {
            title: title,
            seoTitle: seoTitle,
            description: description,
            image: image ? image : data.result.image,
            url: url,
            educationOne: educationData.one,
            educationTwo: educationData.two,
            educationThree: educationData.three,
            add: add,
            remove: remove,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => setOpenSuccess(true),
      onError: (error) => setOpenError(true),
    }
  );

  useEffect(() => {
    if (data?.result) {
      setImageSrc(
        `https://scwebv7cdn.azureedge.net/content/${data.result.image}`
      );
    }
  }, [data]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let data = {};

    switch (name) {
      case "categoryId":
        setCategoryId(value);
        setAuctionsOrCategoriesId((prev) => [...prev, value]);
        setAdd((prev) => [...prev, value]);
        break;
      case "auctionId":
        setAuctionId(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "seoTitle":
        setSeoTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "image":
        queryClient.setQueryData("getLandingPage", {
          result: {
            ...data.result,
            image: value,
          },
        });
        break;
      case "url":
        setUrl(value);
        break;
      case "educationOne":
        data = { ...educationData, one: value };
        setEducationData(data);
        break;
      case "educationTwo":
        data = { ...educationData, two: value };
        setEducationData(data);
        break;
      case "educationThree":
        data = { ...educationData, three: value };
        setEducationData(data);
        break;
      default:
        break;
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.onerror = () =>
        console.error(`Error reading file: ${reader.error}`);
    } else {
      setImage("");
    }
  };

  const handleRemoveAuctionsCategories = (value) => {
    setRemove([...remove, value]);
    setAuctionsOrCategoriesId((val) => val.filter((el) => el !== value));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/content/landing-pages">Landing Pages</Link>
          <Typography color="textPrimary">Update Landing Page</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item spacing={3} xs={12}>
        <Box p={2} component={Paper}>
          <Grid item xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : error ? (
              "An error has occurred: " + error.message
            ) : !data ? (
              "No data"
            ) : (
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h6" color="inherit">
                      Update Landing Page
                    </Typography>
                  </Grid>
                  {type === 0 && (
                    <Grid item xs={12}>
                      <Typography color="inherit">Select Category</Typography>
                      <Select
                        labelId="category-label"
                        name="categoryId"
                        label="Category"
                        variant="outlined"
                        fullWidth
                        value={categoryId}
                        onChange={handleChange}
                      >
                        {categories.data &&
                          categories.data.result.map((el) => (
                            <MenuItem value={el.id}>{el.name}</MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  )}
                  {type === 1 && (
                    <Grid item xs={12}>
                      <Typography color="inherit">Add Auction Id</Typography>
                      <TextField
                        id="auctionId"
                        name="auctionId"
                        label="Auction Id"
                        variant="outlined"
                        fullWidth
                        value={auctionId}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setAuctionsOrCategoriesId((prev) => [
                              ...prev,
                              auctionId,
                            ]);
                            setAuctionId("");
                            setAdd((prev) => [...prev, auctionId]);
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {auctionsOrCategoriesId.length > 0 && (
                    <Grid item xs={12} className={classes.container}>
                      {auctionsOrCategoriesId.map((el, index) => (
                        <div
                          className={classes.auctionCategoryContainer}
                          key={index}
                        >
                          <TextField
                            id="auctionsOrCategoriesId"
                            name="auctionsOrCategoriesId"
                            label="Id"
                            variant="outlined"
                            value={el}
                            disabled
                          />
                          <HighlightOffIcon
                            onClick={() => handleRemoveAuctionsCategories(el)}
                            color="error"
                            fontSize="large"
                          />
                        </div>
                      ))}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      fullWidth
                      variant="outlined"
                      value={title}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="seoTitle"
                      name="seoTitle"
                      label="SEO Title"
                      fullWidth
                      variant="outlined"
                      value={seoTitle}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      aria-label="minimum height"
                      name="description"
                      className={classes.textArea}
                      variant="outlined"
                      value={description}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="url"
                      name="url"
                      label="View Lots Button Url"
                      fullWidth
                      variant="outlined"
                      value={url}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type="file"
                      onChange={handleImage}
                      accept="image/*"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={image ? image : imageSrc}
                      alt="landingPageImage"
                      style={{ maxHeight: "300px" }}
                    />
                  </Grid>
                  <TextAreaComp
                    key="educationOne"
                    value={educationData.one}
                    handleChange={handleChange}
                    name="educationOne"
                    placeholder="Education One"
                  />
                  <TextAreaComp
                    key="educationTwo"
                    value={educationData.two}
                    handleChange={handleChange}
                    name="educationTwo"
                    placeholder="Education Two"
                  />
                  <TextAreaComp
                    key="educationThree"
                    value={educationData.three}
                    handleChange={handleChange}
                    name="educationThree"
                    placeholder="Education Three"
                  />
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => updatePageMutation.mutate()}
                      disabled={isLoading}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Successfully updated landing page"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ContentLandingPage;
