import {
  Box,
  Breadcrumbs,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  FormControlLabel,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableCell,
} from "@mui/material";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import axios from "axios";
import { useState, Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { formatCurrency } from "../utils/formatCurrency";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";

const ViewAuction = () => {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [auction, setAuction] = useState({});
  const [lotsData, setLotsData] = useState({ lots: [] });
  const [isAuctionLoading, setIsAuctionLoading] = useState(true);
  const [isLotsLoading, setIsLotsLoading] = useState(true);
  const [loadLots, setLoadLots] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [lotRange, setLotRange] = useState({ startLot: null, endLot: null });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctions/${id}`)
        .then((response) => {
          setAuction(response.data);
          setIsAuctionLoading(false);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setIsLotsLoading(true);
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/lots?auctionId=${id}`)
      .then((response) => {
        setLotsData(response.data);
        setTotalRows(response.data.totalResults);
        setIsLotsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadLots]);
  useEffect(() => {
    setIsLotsLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AUCTION_API_URL
        }/lots?auctionId=${id}&startRowIndex=${
          page * rowsPerPage
        }&maximumRows=${rowsPerPage}`
      )
      .then((response) => {
        if (lotsData.lots.length === 0 || response.data.startRowIndex === 0) {
          setLotsData(response.data);
          setTotalRows(response.data.totalResults);
        } else {
          lotsData.lots.splice(
            response.data.startRowIndex,
            response.data.lots.length,
            ...response.data.lots
          );
        }
        setIsLotsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const onAOMSUpdateClick = () => {
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctions/${id}/updateaoms`)
      .then((response) => {
        setSuccessMessage("Update Triggered");
        setOpenSuccess(true);
      });
  };
  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleRefresh = () => {
    setLoadLots(!loadLots);
  };
  const onDeleteAuctionsByRangeClick = () => {
    axios
      .delete(
        `${process.env.REACT_APP_AUCTION_API_URL}/auctions/${id}/deletelotsrange?startLot=${lotRange.startLot}&endLot=${lotRange.endLot}`
      )
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage(
            `${response.data.totalResults} successfully deleted`
          );
          setOpenSuccess(true);
          setLoadLots(!loadLots);
          handleDialogClose();
        }
      })
      .catch((err) => {
        console.error(`Error deleting lots in auction ${id}: ${err}`);
        setErrorMessage(
          `Error deleting lots in range ${lotRange.startLot} to ${lotRange.endLot} from ${auction.title}`
        );
        setOpenError(true);
      });
  };

  const handleRangeChange = (e) => {
    switch (e.target.name) {
      case "startLot":
        setLotRange({ startLot: e.target.value, endLot: lotRange.endLot });
        break;
      case "endLot":
        setLotRange({ startLot: lotRange.startLot, endLot: e.target.value });
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      {isAuctionLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auctions">Auctions</Link>
              <Typography color="textPrimary">Auction {id}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Auction Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    value={auction.title}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Subtitle"
                    value={auction.subTitle}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Primary Category"
                    value={
                      auction.primaryCategoryId
                        ? auction.categories.filter(
                            (cat) => cat.id === auction.primaryCategoryId
                          )[0]?.name
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Auction House"
                    value={auction.auctionHouse.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Start Time"
                    value={formatTime(auction.startTime)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="End Time"
                    value={formatTime(auction.endTime)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <FormControlLabel
                    control={<Checkbox checked={auction.onlineOnly} />}
                    label="Online Only"
                  />

                  <FormControlLabel
                    control={<Checkbox checked={auction.openForBids} />}
                    label="Open for Bids"
                  />

                  <FormControlLabel
                    control={<Checkbox checked={auction.postalOnly} />}
                    label="Postal Only"
                  />

                  <FormControlLabel
                    control={<Checkbox checked={auction.premium} />}
                    label="Premium"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container justifyContent="space-around">
                <Button
                  onClick={onAOMSUpdateClick}
                  variant="outlined"
                  startIcon={<UpdateIcon />}
                >
                  Update Auction in OMS
                </Button>
                <Link to={{ pathname: `/auctions/${id}/edit` }}>
                  <Button variant="outlined" startIcon={<EditIcon />}>
                    Edit Auction
                  </Button>
                </Link>
                <Button
                  onClick={handleClickDialogOpen}
                  variant="outlined"
                  startIcon={<DeleteSweepIcon />}
                >
                  Delete Auction Lots By Range
                </Button>

                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Delete lots in a range
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      value={lotRange.startLot}
                      name="startLot"
                      onChange={handleRangeChange}
                      label="Lot Range Start"
                      type="number"
                      fullWidth
                    />
                    <TextField
                      margin="dense"
                      value={lotRange.endLot}
                      name="endLot"
                      onChange={handleRangeChange}
                      label="Lot Range End"
                      type="number"
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={onDeleteAuctionsByRangeClick}
                      color="primary"
                      startIcon={<DeleteSweepIcon />}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Box>
          </Grid>
          {isLotsLoading ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={`Lots in the Auction (${lotsData.totalResults})`}
                  handleRefresh={handleRefresh}
                />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Lot Number</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Hammer Price</TableCell>
                        <TableCell>Asking Price</TableCell>
                        <TableCell>Buy Now Price</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lotsData.lots
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((lot) => {
                          return (
                            <TableRow key={lot.id} hover tabIndex={-1}>
                              <TableCell>{lot.id}</TableCell>
                              <TableCell>{`${lot.lotNo}${lot.a}`}</TableCell>
                              <TableCell>{lot.title}</TableCell>
                              <TableCell>
                                {formatCurrency(lot.hammerPrice)}
                              </TableCell>
                              <TableCell>
                                {formatCurrency(lot.askingPrice)}
                              </TableCell>
                              <TableCell>
                                {formatCurrency(lot.buyNowPrice)}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/auctions/${id}/lots/${lot.id}/bidhistory`}
                                >
                                  View Bid History
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          )}
          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            message={successMessage}
          />
          <ErrorAlert
            openError={openError}
            setOpenError={setOpenError}
            message={errorMessage}
          />
        </Grid>
      )}
    </Fragment>
  );
};

export default ViewAuction;
