import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";

const useStyles = makeStyles(() => ({
  fullwidthContent: {
    width: "100%",
  },
}));

const CreateBanner = () => {
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState(3);
  const [size, setSize] = useState(0);
  const [position, setPosition] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [timeDelay, setTimeDelay] = useState(5000);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "priority":
        setPriority(value);
        break;
      case "size":
        setSize(value);
        break;
      case "position":
        setPosition(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "uploadImage":
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => setImageSrc(reader.result);
          reader.onerror = () =>
            console.error(`Error reading file: ${reader.error}`);
        } else {
          setImageSrc("");
        }
        break;
      case "timeDelay":
        setTimeDelay(value);
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();

    const data = {
      title: title,
      priority: priority,
      size: size,
      position: position,
      startDate: startDate,
      endDate: endDate,
      base64Image: imageSrc,
      timeDelay: timeDelay ? timeDelay : null,
    };

    axios
      .post(`${process.env.REACT_APP_CONTENT_API_URL}/banners`, data)
      .then((res) => {
        setOpenSuccess(true);
        setTitle("");
        setPriority(3);
        setSize(0);
        setPosition(0);
        setStartDate("");
        setEndDate("");
        setImageSrc("");
        setTimeDelay(5000);
        setErrorMessage("");
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 413) {
          setErrorMessage("Image must be smaller than 20mb");
        }
      });
  };

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/banners">Manage Banners</Link>
            <Typography color="textPrimary">Create New Banner</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <Grid
              container
              item
              spacing={4}
              component="form"
              onSubmit={handleCreate}
            >
              <Grid item>
                <Typography variant="h5">New Banner</Typography>
              </Grid>
              <Grid container item spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    name="title"
                    value={title}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="priority-label">Priority</InputLabel>
                    <Select
                      labelId="priority-label"
                      label="Priority"
                      name="priority"
                      value={priority}
                      onChange={handleInput}
                      required
                    >
                      <MenuItem value={1}>Critical</MenuItem>
                      <MenuItem value={2}>High</MenuItem>
                      <MenuItem value={3}>Normal</MenuItem>
                      <MenuItem value={4}>Low</MenuItem>
                      <MenuItem value={5}>Lowest</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="size-label">Size</InputLabel>
                    <Select
                      labelId="size-label"
                      label="Size"
                      name="size"
                      value={size}
                      onChange={handleInput}
                      required
                    >
                      <MenuItem value={0}>Desktop</MenuItem>
                      <MenuItem value={1}>Mobile</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="position-label">Position</InputLabel>
                    <Select
                      labelId="position-label"
                      label="Position"
                      name="position"
                      value={position}
                      onChange={handleInput}
                      required
                    >
                      <MenuItem value={0}>Top</MenuItem>
                      <MenuItem value={1}>Bottom</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Time Delay (ms)"
                    name="timeDelay"
                    value={timeDelay}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    name="startDate"
                    label="Start"
                    type="datetime-local"
                    value={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInput}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    name="endDate"
                    label="End"
                    type="datetime-local"
                    value={endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleInput}
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="file"
                    name="uploadImage"
                    accept="image/*"
                    onChange={handleInput}
                  />
                  {errorMessage && (
                    <Typography variant="body2" color="textSecondary">
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      className={classes.fullwidthContent}
                      alt=""
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="outlined"
                  size="large"
                  disabled={
                    title === "" ||
                    imageSrc === "" ||
                    startDate === "" ||
                    endDate === ""
                  }
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Banner Created"
      />
    </Fragment>
  );
};

export default CreateBanner;
