import {
  Box,
  Breadcrumbs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  TableCell,
  Button,
} from "@mui/material";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import axios from "axios";
import { useState, Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { formatCurrency } from "../utils/formatCurrency";
import { urlify } from "../utils/urls";

const LotBidHistory = () => {
  const { auctionId, lotId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [auction, setAuction] = useState({});
  const [isAuctionLoading, setIsAuctionLoading] = useState(false);
  const [lot, setLot] = useState({});
  const [bidsData, setBidsData] = useState({ bids: [] });
  const [isBidsoading, setIsBidsLoading] = useState(true);
  const [isLotLoading, setIsLotLoading] = useState(true);
  const [loadBids, setLoadBids] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const LOT_STATUS_MAP = ["Awaiting Sale", "Sold", "Not Sold"];
  const BID_TYPE_MAP = [
    "Commission Bid",
    "Quick Bid",
    "Maximum Bid",
    "Instant Win Bid",
    "Auto Bid",
  ];
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/lots/${lotId}`)
        .then((response) => {
          setLot(response.data);
          setIsLotLoading(false);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctions/${auctionId}`)
        .then((response) => {
          setAuction(response.data);
          setIsAuctionLoading(false);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setIsBidsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_AUCTION_API_URL}/lots/${lotId}/bidhistorydetailed`
      )
      .then((response) => {
        setBidsData(response.data);
        setTotalRows(response.data.totalResults);
        setIsBidsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadBids]);
  useEffect(() => {
    setIsBidsLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_AUCTION_API_URL
        }/lots/${lotId}/bidhistorydetailed?startRowIndex=${
          page * rowsPerPage
        }&maximumRows=${rowsPerPage}`
      )
      .then((response) => {
        if (bidsData.bids.length === 0 || response.data.startRowIndex === 0) {
          setBidsData(response.data);
          setTotalRows(response.data.totalResults);
        } else {
          bidsData.bids.splice(
            response.data.startRowIndex,
            response.data.bids.length,
            ...response.data.bids
          );
        }
        setIsBidsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  const handleRefresh = () => {
    setLoadBids(!loadBids);
  };
  return (
    <Fragment>
      {isLotLoading || isAuctionLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auctions">Auctions</Link>
              <Link to={`/auctions/${auctionId}`}>Auctions {auctionId}</Link>
              <Link to={`/auctions/${auctionId}`}>Lots</Link>
              <Link to={`/auctions/${auctionId}/lots/${lotId}`}>
                Lot {lotId}
              </Link>
              <Typography color="textPrimary">Bid History</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                Lot Details
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Auction"
                    value={auction.title}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Auction End Time"
                    value={formatTime(auction.endTime)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Lot ID"
                    value={lot.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Lot No"
                    value={lot.lotNo}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Lot Status"
                    value={LOT_STATUS_MAP[lot.status]}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Lot End Time"
                    value={formatTime(lot.endTime)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    value={lot.title}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    variant="outlined"
                    multiline
                  />
                </Grid>
                {lot.winningUserId ? (
                  <Grid item xs={12} sm={3}>
                    <Link to={`/users/${lot.winningUserId}`} target="_blank">
                      {`Winning User: ${lot.winningUserId}`}{" "}
                    </Link>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={3}>
                  <a
                    href={`${
                      process.env.REACT_APP_SITE_URL
                    }/lots/${lotId}/${urlify("")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="contained" size="large">
                      Go to Lot
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {isBidsoading ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={`Lots in the Auction (${bidsData.totalResults})`}
                  handleRefresh={handleRefresh}
                />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Creation Time</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>User ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bidsData.bids
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((bid) => {
                          return (
                            <TableRow key={lot.id} hover tabIndex={-1}>
                              <TableCell>{bid.id}</TableCell>
                              <TableCell>
                                {formatTime(bid.creationDate)}
                              </TableCell>
                              <TableCell>
                                {formatCurrency(bid.amount)}
                              </TableCell>
                              <TableCell>{BID_TYPE_MAP[bid.bidType]}</TableCell>
                              <TableCell>
                                <Link to={`/users/${bid.userId}`}>
                                  {bid.userId}
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default LotBidHistory;
