import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import MarkdownEditor from "../components/misc/MarkdownEditor";
import SuccessAlert from "../components/misc/SuccessAlert";

const EditHelpArticle = (props) => {
  const helpArticleTopicsQuery = useQuery(
    "scHelpArticleTopics",
    () =>
      axios
        .get(`${process.env.REACT_APP_CONTENT_API_URL}/help-article-topics`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const { helpArticle } = props.location.state;
  const { id } = useParams();

  const [title, setTitle] = useState(helpArticle.title);
  const [markdown, setMarkdown] = useState(helpArticle.markdown);
  const [featured, setFeatured] = useState(helpArticle.featured);
  const [topicId, setTopicId] = useState(helpArticle.topicId);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "markdown":
        setMarkdown(value);
        break;
      case "featured":
        setFeatured(e.target.checked);
        break;
      case "topicId":
        setTopicId(value);
        break;
      default:
        break;
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const data = {
      title: title,
      markdown: markdown,
      featured: featured,
      topicId: topicId,
    };

    axios
      .put(
        `${process.env.REACT_APP_CONTENT_API_URL}/help-articles/${id}/edit`,
        data
      )
      .then((res) => {
        setOpenSuccess(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      {helpArticleTopicsQuery.isLoading ? (
        <CircularProgress />
      ) : helpArticleTopicsQuery.error ? (
        "An error has occurred: " + helpArticleTopicsQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/help-articles">Manage Help Articles</Link>
              <Typography color="textPrimary">Edit</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                item
                spacing={4}
                component="form"
                onSubmit={handleEdit}
              >
                <Grid item>
                  <Typography variant="h5">Edit Help Article</Typography>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Title"
                      name="title"
                      value={title}
                      onChange={handleInput}
                      variant="outlined"
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="auction-house-id-label">Topic</InputLabel>
                      <Select
                        labelId="topic-id-label"
                        label="Topic"
                        name="topicId"
                        value={topicId}
                        onChange={handleInput}
                        required
                      >
                        <MenuItem value={0}>Select a topic...</MenuItem>
                        {helpArticleTopicsQuery.data.length > 0 &&
                          helpArticleTopicsQuery.data.map(
                            (helpArticleTopic) => {
                              return (
                                <MenuItem
                                  key={helpArticleTopic.id}
                                  value={helpArticleTopic.id}
                                >
                                  {helpArticleTopic.title}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <MarkdownEditor
                  markdown={markdown}
                  name="markdown"
                  handleInput={handleInput}
                />
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={featured}
                        onChange={handleInput}
                        name="featured"
                        color="primary"
                      />
                    }
                    label="Featured"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    disabled={title === "" || markdown === ""}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Help Article Updated"
      />
    </Fragment>
  );
};

export default EditHelpArticle;
