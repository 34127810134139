import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  FormHelperText,
  TablePagination,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useState, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { formatTime } from "../utils/formatTime";
import Autocomplete from "@mui/lab/Autocomplete";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import useDebounce from "../components/HOCs/UseDebounce";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function FormDialog(props) {
  const { inputValue, open, setOpen, auctionHousesQuery, handleCreateBooking } =
    props;
  const handleClose = () => {
    setOpen(false);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);

  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const [viewingDate, setViewingDate] = useState(new Date());
  const [timeError, setTimeError] = useState(false);
  const [user, setUser] = useState(-1);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const searchUsers = (searchQuery) => {
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/users?search=${searchQuery}`)
      .then((res) => {
        setUsers(res.data.matchingUsersResults);
      })
      .catch((err) => {
        console.error("User search error:", err);
        setUsers([]);
      });
  };
  const timeSlotsQuery = useQuery(
    ["scTimeSlots", selectedAuctionHouse, viewingDate],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/viewings/getslots?auctionHouseId=${selectedAuctionHouse}&viewingDate=${viewingDate
            .toISOString()
            .slice(0, 10)}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (debouncedSearchQuery) {
      searchUsers(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setSelectedTimeSlot({});
  }, [selectedAuctionHouse, viewingDate]);

  useEffect(
    () =>
      axios.get(`http://worldclockapi.com/api/json/utc/now`).then((res) => {
        var clientNow = new Date();
        var serverNow = new Date(res.data.currentDateTime);
        setTimeError(
          clientNow.toISOString().slice(0, 16) ===
            serverNow.toISOString().slice(0, 16)
            ? false
            : true
        );
      }),
    [timeError]
  );
  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      inputValue.auctionHouseId = id.props.value;
      setSelectedAuctionHouse(inputValue.auctionHouseId);
    }
  };

  useEffect(() => {
    inputValue.viewingId = selectedTimeSlot.viewingId;
    inputValue.startTime = selectedTimeSlot.startTime;
    inputValue.endTime = selectedTimeSlot.endTime;
    inputValue.user = user;
    inputValue.lots = [];
  }, [user, selectedTimeSlot, inputValue]);
  const handleSearch = (e) => {
    if (e.key === "Enter" && searchQuery) {
      searchUsers(searchQuery);
    }
  };

  const handleViewingDateChange = (date) => {
    inputValue.viewingDate = date;
    setViewingDate(date);
  };

  const handleSelectTimeSlot = (event, id) => {
    if (id.props.value) {
      setSelectedTimeSlot(id.props.value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Booking</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container justifyContent="space-around">
            <FormControl variant="outlined">
              <InputLabel>Auction House</InputLabel>

              <Select
                onChange={handleAuctionhouseChange}
                value={selectedAuctionHouse}
              >
                {auctionHousesQuery.data.map((auctionHouse) => {
                  return (
                    <MenuItem value={auctionHouse.id}>
                      {auctionHouse.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Select the Auction House</FormHelperText>
            </FormControl>
            <DatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              label="Select the Date:"
              value={viewingDate}
              onChange={handleViewingDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            {timeSlotsQuery.isLoading || timeSlotsQuery.isError ? (
              ""
            ) : (
              <FormControl
                variant="outlined"
                disabled={timeSlotsQuery.data.length === 0}
              >
                <Select
                  onChange={handleSelectTimeSlot}
                  value={selectedTimeSlot}
                >
                  {timeSlotsQuery.data.map((timeSlot) => {
                    return (
                      <MenuItem value={timeSlot}>
                        {formatTime(timeSlot.startTime).slice(11, 17)} -{" "}
                        {formatTime(timeSlot.endTime).slice(11, 17)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {timeSlotsQuery.data.length === 0 ? (
                  <FormHelperText>No time slots available</FormHelperText>
                ) : (
                  <FormHelperText>Please select a time slot</FormHelperText>
                )}
              </FormControl>
            )}
            <Autocomplete
              label="Search Users"
              type="text"
              options={users}
              inputValue={searchQuery}
              isOptionEqualToValue={(option, value) => {
                if (option && value) return option.id === value.id;
              }}
              onChange={(event, value) => {
                if (value) setUser(value.id);
              }}
              onInputChange={(event, newInputValue) => {
                if (event) setSearchQuery(newInputValue);
              }}
              variant="outlined"
              onKeyPress={handleSearch}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName} <${option.email}>`
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Users"
                  type="text"
                  name="searchQuery"
                />
              )}
            />
          </Grid>
        </LocalizationProvider>
        {timeError ? (
          <DialogContentText>
            The time/timezone is not correct. Please fix that.
          </DialogContentText>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleCreateBooking}
          disabled={
            timeError ||
            Object.keys(selectedTimeSlot).length === 0 ||
            user === -1
          }
          color="primary"
        >
          Create Booking
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const ManageBookings = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(1);
  const selectedUser = 0;
  const [viewingDate, setViewingDate] = useState(new Date());
  const [startTime, setStartTime] = useState(
    new Date(new Date().toISOString().slice(0, 11) + "06:30")
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().toISOString().slice(0, 11) + "16:30")
  );
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newBooking, setNewBooking] = useState({});
  const [printComponent, setPrintComponent] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          setRefresh(!refresh);
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );
  const bookingsQuery = useQuery(
    [
      "scBookings",
      page,
      rowsPerPage,
      selectedAuctionHouse,
      viewingDate,
      startTime,
      endTime,
      refresh,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_BOOKING_API_URL
          }/bookings-internal?auctionHouseId=${selectedAuctionHouse}&viewingDate=${viewingDate.toISOString()}&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}&maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }&user=${selectedUser}`
        )
        .then((res) => {
          setTotalRows(res.data.totalResults);
          return res.data.bookings;
        }),
    { keepPreviousData: true }
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      setSelectedAuctionHouse(id.props.value);
    }
  };
  const handleViewingDateChange = (date) => {
    setViewingDate(date);
  };
  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };
  const handleClickOpen = () => {
    setCreateDialogOpen(true);
  };
  const handleCreateBooking = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BOOKING_API_URL}/bookings-internal`, {
        viewingId: newBooking.viewingId,
        startTime: newBooking.startTime,
        endTime: newBooking.endTime,
        user: newBooking.user,
        lots: newBooking.lots,
      })
      .then((res) => {
        setNewBooking({});
        setSuccessMessage("View Created Successfully");
        setOpenSuccess(true);
        setCreateDialogOpen(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setErrorMessage(
          "Booking Creation Failed: " + err.response
            ? err.response.data.message
            : err.message
        );
        setOpenError(true);
      });
  };

  return (
    <Fragment>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Manage Bookings</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="space-around">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Auction House</InputLabel>

                    <Select
                      onChange={handleAuctionhouseChange}
                      value={selectedAuctionHouse}
                    >
                      {auctionHousesQuery.data.map((auctionHouse) => {
                        return (
                          <MenuItem value={auctionHouse.id}>
                            {auctionHouse.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Select the Auction House</FormHelperText>
                  </FormControl>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Viewing Date:"
                    value={viewingDate}
                    onChange={handleViewingDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <TimePicker
                    margin="normal"
                    label="Starting From Time:"
                    value={startTime}
                    defaultValue="08:30"
                    keyboardIcon={<ScheduleIcon />}
                    onChange={handleStartTimeChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                  <TimePicker
                    margin="normal"
                    label="Ending Before Time:"
                    value={endTime}
                    defaultValue="16:30"
                    onChange={handleEndTimeChange}
                    keyboardIcon={<ScheduleIcon />}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
          </Grid>
          {bookingsQuery.isLoading || bookingsQuery.data === undefined ? (
            <CircularProgress />
          ) : bookingsQuery.error ? (
            "An error has occurred: " + bookingsQuery.error.message
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar
                  title={"Bookings"}
                  handleCreate={handleClickOpen}
                  createTitle="Create New Booking"
                  printComponent={printComponent}
                />
                <FormDialog
                  inputValue={newBooking}
                  open={createDialogOpen}
                  auctionHousesQuery={auctionHousesQuery}
                  setOpen={setCreateDialogOpen}
                  handleCreateBooking={handleCreateBooking}
                />
                <TableContainer>
                  <Table ref={(el) => setPrintComponent(el)}>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Lots Count</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>View</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bookingsQuery.data.map((booking) => {
                        return (
                          <TableRow key={booking.id} hover tabIndex={-1}>
                            <TableCell>{booking.id}</TableCell>
                            <TableCell>
                              {formatTime(booking.startTime).slice(12, 17)}
                            </TableCell>
                            <TableCell>
                              {formatTime(booking.endTime).slice(12, 17)}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/users/${booking.user}`}
                                variant="outlined"
                              >
                                {booking.user}
                              </Link>
                            </TableCell>
                            <TableCell>{booking.lotsCount}</TableCell>
                            <TableCell>
                              {booking.cancelled ? "Cancelled" : "Active"}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/lot-view-booking/${booking.id}`}
                                variant="outlined"
                              >
                                Go to Booking
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default ManageBookings;
