import axios from "axios";
import { useQuery } from "react-query";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";
import useTimer from "../../../utils/useTimer";

const Remaining = ({ date }) => {
  const [time] = useTimer(date, "up");

  return (
    <span>
      <b>{time}</b>
    </span>
  );
};

const Picking = ({ currentAuctionHouse, showTable }) => {
  const { data, isLoading, error } = useQuery(
    ["picking", currentAuctionHouse, showTable],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/picking?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Picking</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Total lots to process"
                    value={data?.totalLotsToProcess}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallStat
                    title="Processes today"
                    value={data?.processedToday}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data?.pickerStats && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Picker</TableCell>
                        <TableCell>Lots Picked Today</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.pickerStats.map((picker) => (
                        <TableRow key={picker.user}>
                          <TableCell>{picker.user}</TableCell>
                          <TableCell>{picker.totalLots}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
          {showTable && data.openPickBatches && (
            <Grid item xs={12}>
              <Box p={3} component={Paper}>
                <Grid item xs={12}>
                  <Typography variant="h5">Open Pick Batches</Typography>
                </Grid>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>Time Open</TableCell>
                        <TableCell>Picker</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Remaining</TableCell>
                        <TableCell>Total Lots</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.openPickBatches.map((batch) => (
                        <TableRow key={batch.id}>
                          <TableCell>
                            {formatTime(batch.creationDate)}
                          </TableCell>
                          <TableCell>
                            <Remaining date={batch.creationDate} />
                          </TableCell>
                          <TableCell>{batch.user}</TableCell>
                          <TableCell>{batch.location}</TableCell>
                          <TableCell>{batch.remainingLots}</TableCell>
                          <TableCell>{batch.totalLots}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Picking;
