import React, { useState } from "react";
import { Box, Breadcrumbs, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    cursor: "pointer",
  },
  notPickedContainer: {
    backgroundColor: "#445a3e",
    borderRadius: "20px",
  },
  pickedContainer: {
    backgroundColor: "#c10c0c",
    borderRadius: "20px",
  },
}));

const RandomSearch = () => {
  const [isPicked, setIsPicked] = useState(0);
  const classes = useStyles();

  const handleClick = () => {
    const random = Math.random();
    if (random < 0.5) {
      setIsPicked(1);
    } else {
      setIsPicked(2);
    }
    setTimeout(() => {
      setIsPicked(0);
    }, 1000);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Random Search</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="inherit">
              Search
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <CircularProgress /> */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              role="button"
              className={
                isPicked === 0
                  ? ""
                  : isPicked === 1
                  ? classes.pickedContainer
                  : classes.notPickedContainer
              }
            >
              {isPicked < 2 ? (
                <img
                  src="./icons/button-random.svg"
                  alt="Random"
                  className={classes.button}
                  onClick={handleClick}
                />
              ) : (
                <img
                  src="./icons/button-random-green.svg"
                  alt="Random"
                  className={classes.button}
                  onClick={handleClick}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RandomSearch;
