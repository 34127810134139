import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#445a3e",
    },
    secondary: {
      main: "#f2a900",
    },
    danger: {
      main: "#FF0000",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },
  },
});
