import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { formatDate } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100vh",
    width: "80vw",
    height: "90vh",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    padding: theme.spacing(2, 4, 3),
    overflowY: "scroll",
  },
  image: {
    width: "80px",
    height: "80px",
  },
}));

const ReportModal = ({ open, handleClose, selectedInvoiceId }) => {
  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    ["asData"],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/stats/dispatch-list/${selectedInvoiceId}`
        )
        .then((res) => res.data),
    {
      enabled: !!selectedInvoiceId && open,
    }
  );

  const handleStatuses = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Picked";
      case 2:
        return "Issues";
      default:
        return "Other";
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="options-modal"
      disableScrollLock={false}
    >
      <Box className={classes.modal}>
        {isLoading && <p>Loading...</p>}
        {error && `An error has occurred: ${error.message}`}
        {data && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>StockId</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>LotNo</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>PickIssues</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_CDN_URL}/thumbnails/${row.imageId}-480.webp`}
                        className={classes.image}
                        alt="img"
                      />
                    </TableCell>
                    <TableCell>{row.stockId}</TableCell>
                    <TableCell>{handleStatuses(row.status)}</TableCell>
                    <TableCell>{row.lotNo}</TableCell>
                    <TableCell>{row.productTitle}</TableCell>
                    <TableCell>{row.pickIssues}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};

const ReportCollectionAndDispatch = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState("");
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const classes = useStyles();

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const { data, isLoading, error } = useQuery(
    [
      "asStatsUsers",
      startDate,
      endDate,
      currentAuctionHouse,
      page,
      maximumRows,
      status,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/stats/dispatch-list?startDate=${startDate}&endDate=${endDate}&auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}&status=${status}`
        )
        .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value));
    setPage(0);
  };

  const handleStatuses = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Picked";
      case 2:
        return "Processing";
      case 3:
        return "Processed";
      case 4:
        return "Cancelled";
      default:
        return "Other";
    }
  };

  return (
    <Grid container spacing={3}>
      <ReportModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedInvoiceId={selectedInvoiceId}
      />
      <Grid item xs={12}>
        <h1>Dispatch List</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  {auctionHousesQuery.data && (
                    <Select
                      value={currentAuctionHouse?.name}
                      label="Auction Houses"
                      onChange={handleSelect}
                      variant="outlined"
                    >
                      <MenuItem value="">All</MenuItem>
                      {auctionHousesQuery.data.map((house) => {
                        return (
                          <MenuItem value={house.name} key={house.id}>
                            {house.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={1}>Picked</MenuItem>
                    <MenuItem value={2}>Processing</MenuItem>
                    <MenuItem value={3}>Processed</MenuItem>
                    <MenuItem value={4}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <p>Loading...</p>}
          {error && `An error has occurred: ${error.message}`}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Invoice</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>StartProcess</TableCell>
                    <TableCell>StartBy</TableCell>
                    <TableCell>Proceed</TableCell>
                    <TableCell>ProcessedBy</TableCell>
                    <TableCell>TotalLots</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.results.map((row) => (
                    <TableRow
                      key={row.id}
                      className={classes.row}
                      onClick={() => {
                        setOpen(true);
                        setSelectedInvoiceId(row.invoiceId);
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.invoiceId}</TableCell>
                      <TableCell>{handleStatuses(row.status)}</TableCell>
                      <TableCell>{formatDate(row.startProcessDate)}</TableCell>
                      <TableCell>{row.startBy}</TableCell>
                      <TableCell>{formatDate(row.processedDate)}</TableCell>
                      <TableCell>{row.processedBy}</TableCell>
                      <TableCell>{row.totalLots}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.totalRows || 0}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportCollectionAndDispatch;
