import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import SuccessAlert from "../components/misc/SuccessAlert";

const HelpArticles = () => {
  const helpArticlesQuery = useQuery("scHelpArticles", () =>
    axios
      .get(`${process.env.REACT_APP_CONTENT_API_URL}/help-articles`)
      .then((res) => res.data)
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    selected.forEach((id) => {
      axios
        .delete(
          `${process.env.REACT_APP_CONTENT_API_URL}/help-articles/${id}/delete`
        )
        .then((res) => {
          helpArticlesQuery.refetch();
          setOpenSuccess(true);
          setSelected([]);
        })
        .catch((err) =>
          console.error(`Error deleting help articles ${id}: ${err}`)
        );
    });
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      {helpArticlesQuery.isLoading ? (
        <CircularProgress />
      ) : helpArticlesQuery.error ? (
        "An error has occurred: " + helpArticlesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Help Articles</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container item xs={12} spacing={4}>
                <Grid item>
                  <Link to="/help-articles/new">Create New Help Article</Link>
                </Grid>
                <Grid item>
                  <Link to="/help-article-topics">
                    Manage Help Article Topics
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={"Manage Help Articles"}
                numSelected={selected.length}
                handleDelete={handleDelete}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Featured</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {helpArticlesQuery.data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((helpArticle) => {
                        const isItemSelected = isSelected(helpArticle.id);

                        return (
                          <TableRow
                            key={helpArticle.id}
                            hover
                            onClick={(event) =>
                              handleSelect(event, helpArticle.id)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell>{helpArticle.id}</TableCell>
                            <TableCell>{helpArticle.title}</TableCell>
                            <TableCell>
                              <Checkbox
                                checked={helpArticle.featured}
                                readOnly
                                disabled
                                inputProps={{
                                  "aria-label": "featured checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>{helpArticle.topic}</TableCell>
                            <TableCell>
                              <Link
                                to={{
                                  pathname: `/help-articles/${helpArticle.id}/edit`,
                                  state: { helpArticle: helpArticle },
                                }}
                              >
                                Edit
                              </Link>
                            </TableCell>
                            <TableCell>
                              <a
                                onClick={(e) => e.stopPropagation()}
                                href={`${process.env.REACT_APP_SCWEBSITE_URL}/help`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={helpArticlesQuery.data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Delete Successful"
      />
    </Fragment>
  );
};

export default HelpArticles;
