import { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import {
  Box,
  Grid,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutoComplete from "@mui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import useDebounce from "../utils/useDebounce";
import { formatCurrency } from "../utils/formatCurrency";
import { formatTime } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1000px",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2, 4, 3),
  },
  hover: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const RefundsModal = ({ userId, startDate, endDate, open, handleClose }) => {
  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    ["refunds", userId],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_QMS_API_URL}/stats/user/${userId}/refunds?startDate=${startDate}&endDate=${endDate}&typeId=14`
        )
        .then((res) => res.data),
    {
      enabled: !!userId && open,
    }
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        {isLoading && <CircularProgress />}
        {error && `An error has occurred: ${error.message}`}
        {data && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Ticket</TableCell>
                  <TableCell>TotalAmount</TableCell>
                  {/* <TableCell>AuctionHouse</TableCell> */}
                  <TableCell>Reason</TableCell>
                  <TableCell>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.results?.map((el) => (
                  <TableRow key={el.id}>
                    <TableCell>{formatTime(el.creationDate)}</TableCell>
                    <TableCell>{el.status}</TableCell>
                    <TableCell>{formatCurrency(el.amount)}</TableCell>
                    <TableCell>{el.invoiceId}</TableCell>
                    <TableCell>{el.ticketId}</TableCell>
                    <TableCell>{formatCurrency(el.totalAmount)}</TableCell>
                    {/* <TableCell>{el.auctionHouseId}</TableCell> */}
                    <TableCell>{el.reason}</TableCell>
                    <TableCell>{el.comment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};

const LotterPerformance = () => {
  const [userSearch, setUserSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);
  const [open, setOpen] = useState(false);
  const [modalUserId, setModalUserId] = useState("");

  const [debouncedUserSearch] = useDebounce(userSearch, 500);
  const [debouncedVendorSearch] = useDebounce(vendorSearch, 500);
  const classes = useStyles();

  const usersData = useQuery(
    ["users", debouncedUserSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/users?search=${debouncedUserSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedUserSearch,
    }
  );

  const vendorsData = useQuery(
    ["vendors", debouncedVendorSearch],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/vendors?search=${debouncedVendorSearch}`
        )
        .then((res) => res.data),
    {
      enabled: !!debouncedVendorSearch,
    }
  );

  const handleGetLottingReport = useMutation(() =>
    axios
      .get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/stats/lotter-performance?startDate=${startDate}&endDate=${endDate}&startRowIndex=${
          page * maximumRows
        }&maximumRows=${maximumRows}&userId=${
          selectedUser?.id || ""
        }&vendorId=${selectedVendor?.id || ""}`
      )
      .then((res) => res.data)
  );

  const handleGetRefundsReport = useMutation((props) =>
    axios
      .get(
        `${process.env.REACT_APP_QMS_API_URL}/stats/users?startDate=${startDate}&endDate=${endDate}&userIds=${props.userIds}&typeId=14`
      )
      .then((res) => res.data)
  );

  useEffect(() => {
    const handleGetData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const res = await handleGetLottingReport.mutateAsync();
        let reportData = [];
        if (res && res.results.length > 0) {
          const userIds = res.results.map((el) => el.authUserId).join(",");
          const refunds = await handleGetRefundsReport.mutateAsync({ userIds });
          reportData = res.results.map((el) => {
            const refund = refunds?.results?.find(
              (r) => r.faultUserId === el.authUserId
            );
            return {
              ...el,
              ...refund,
              percentageLots: calculatePercentage(
                el.totalLots,
                refund?.totalLotsRefunded
              ),
              percentageValue: calculatePercentage(
                el.totalValue,
                refund?.totalValueRefunded
              ),
            };
          });
        }
        setData(reportData);
        setTotalRows(res.totalRows);
        setIsLoading(() => false);
      } catch (err) {
        console.log(err);
        setError(err);
      }
    };

    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, page, maximumRows, selectedUser, selectedVendor]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value));
    setPage(0);
  };

  const calculatePercentage = (total, value) => {
    if (!value) return 0;
    return parseFloat((value / total) * 100).toFixed(2);
  };

  return (
    <Grid container spacing={3}>
      <RefundsModal
        userId={modalUserId}
        startDate={startDate}
        endDate={endDate}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <Grid item xs={12}>
        <h1>Lotter Performance Report</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <AutoComplete
                  id="user-search"
                  options={usersData.data ? usersData.data?.users : []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={userSearch}
                  onChange={(e, value) => setSelectedUser(value)}
                  onInputChange={(e, value) => setUserSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search user" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoComplete
                  id="vendor-search"
                  options={vendorsData.data ? vendorsData.data?.vendors : []}
                  getOptionLabel={(option) => option.name || ""}
                  inputValue={vendorSearch}
                  onChange={(e, value) => setSelectedVendor(value)}
                  onInputChange={(e, value) => setVendorSearch(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search vendor" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="Start Date"
                  value={startDate}
                  onChange={(time) => setStartDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  label="End Date"
                  value={endDate}
                  onChange={(time) => setEndDate(time)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <p>Loading...</p>}
          {error && `An error has occurred: ${error.message}`}
          {data?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Lotter</TableCell>
                    <TableCell>Lots Created</TableCell>
                    <TableCell>Total Minutes</TableCell>
                    <TableCell>Avg Time</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Avg Value</TableCell>
                    <TableCell>Refunds</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Percentage Lots</TableCell>
                    <TableCell>Percentage Value</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.id}</TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                      <TableCell>{el.totalMinutes}</TableCell>
                      <TableCell>
                        {parseFloat(el.totalMinutes / el.totalLots).toFixed(2)}
                      </TableCell>
                      <TableCell>{formatCurrency(el.totalValue)}</TableCell>
                      <TableCell>
                        {formatCurrency(el.totalValue / el.totalLots)}
                      </TableCell>
                      <TableCell>
                        <p
                          onClick={() => {
                            setOpen(true);
                            setModalUserId(el.authUserId);
                          }}
                          className={classes.hover}
                        >
                          {el.totalLotsRefunded || 0}
                        </p>
                      </TableCell>
                      <TableCell>
                        {formatCurrency(el.totalValueRefunded || 0)}
                      </TableCell>
                      <TableCell>{el.percentageLots}%</TableCell>
                      <TableCell>{el.percentageValue}%</TableCell>
                      <TableCell>
                        <p
                          onClick={() => {
                            setOpen(true);
                            setModalUserId(el.authUserId);
                          }}
                          className={classes.hover}
                        >
                          View
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LotterPerformance;
