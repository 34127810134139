import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  FormHelperText,
  TablePagination,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ManageLotEnquiries = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState(
    localStorage.getItem("lot_enquiry_auction_house_id")
      ? localStorage.getItem("lot_enquiry_auction_house_id")
      : -1
  );
  const [selectedAssigned, setSelectedAssigned] = useState(true);
  const [openSubscriptionSuccess, setOpenSubscriptionSuccess] = useState(false);

  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          if (localStorage.getItem("lot_enquiry_auction_house_id")) {
            setSelectedAuctionHouse(
              localStorage.getItem("lot_enquiry_auction_house_id")
            );
          } else {
            setSelectedAuctionHouse(res.data[0].id);
          }
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const lotEnquiryQuery = useQuery(
    ["scAuctions", page, rowsPerPage, selectedAssigned, selectedAuctionHouse],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AUCTION_API_URL
          }/lotenquiries?auctionHouseId=${selectedAuctionHouse}&pending=${selectedAssigned}&maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          setTotalRows(res.data.totalResults);
          return res.data.lotEnquiries;
        }),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAuctionhouseChange = (event, id) => {
    if (id.props.value) {
      setSelectedAuctionHouse(id.props.value);
      localStorage.setItem("lot_enquiry_auction_house_id", id.props.value);
    }
  };

  const handleAssignedChange = (event, selected) => {
    setSelectedAssigned(selected.props.value);
  };

  return (
    <>
      {auctionHousesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error ? (
        "An error has occurred: " + auctionHousesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Manage Lot Enquiries</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container item xs={12} spacing={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="auction-house-label">
                    Auction House
                  </InputLabel>
                  <Select
                    labelId="auction-house-label"
                    label="Auction House"
                    onChange={handleAuctionhouseChange}
                    value={selectedAuctionHouse}
                  >
                    {auctionHousesQuery.data.map((auctionHouse) => {
                      return (
                        <MenuItem key={auctionHouse.id} value={auctionHouse.id}>
                          {auctionHouse.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>Select the Auction House</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="query-status-label">Query Status</InputLabel>

                  <Select
                    labelId="query-status-label"
                    label="Query Status"
                    value={selectedAssigned}
                    onChange={handleAssignedChange}
                  >
                    <MenuItem value={true} selected>
                      Pending
                    </MenuItem>
                    <MenuItem value={false}>Assigned</MenuItem>
                  </Select>
                  <FormHelperText>
                    Select the status of the Query
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Box>
          </Grid>
          {lotEnquiryQuery.isLoading ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <EnhancedTableToolbar title={"Lot Enquiries"} />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Lot Number</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell>Assigned</TableCell>
                        <TableCell>Public</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lotEnquiryQuery.data.map((lotEnquiry) => {
                        return (
                          <TableRow key={lotEnquiry.id} hover tabIndex={-1}>
                            <TableCell>{lotEnquiry.id}</TableCell>
                            <TableCell>{lotEnquiry.location}</TableCell>
                            <TableCell>{`${lotEnquiry.lotNo}${lotEnquiry.a}`}</TableCell>
                            <TableCell>{lotEnquiry.question}</TableCell>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={lotEnquiry.responseUserId != null}
                              />
                            </TableCell>
                            <TableCell padding="checkbox">
                              <Checkbox checked={lotEnquiry.public} />
                            </TableCell>
                            <TableCell>
                              <Link to={`/lot-enquiries/${lotEnquiry.id}`}>
                                View
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>

              <SuccessAlert
                openSuccess={openSubscriptionSuccess}
                setOpenSuccess={setOpenSubscriptionSuccess}
                message={`Subscribed to Auction House: ${
                  auctionHousesQuery.data.filter(
                    (auctionHouse) => auctionHouse.id === selectedAuctionHouse
                  )[0]
                    ? auctionHousesQuery.data.filter(
                        (auctionHouse) =>
                          auctionHouse.id === selectedAuctionHouse
                      )[0].name
                    : ""
                }`}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default ManageLotEnquiries;
