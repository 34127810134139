import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/lab/Alert";
import { getDecodedAccessToken } from "../utils/Tokens";
import { formatDate } from "../utils/formatTime";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  buttons: { marginLeft: "20px", height: "100%" },
}));

const CustomersAddToQueue = () => {
  const [invoiceId, setInvoiceId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const decodedToken = getDecodedAccessToken();
  const classes = useStyles();

  const submitInvoiceMutation = useMutation(
    ({ forced }) =>
      axios
        .put(`${process.env.REACT_APP_AOMS_API_URL}/collections/create`, {
          invoiceId,
          forced,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setErrorMessage("");
        setOpenSuccess(true);
      },
      onError: (err) => {
        if (err.response.data) {
          setErrorMessage(err.response.data.message);
        }
      },
    }
  );

  const getAvailablePickingSlots = useMutation(
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/bookings/all-available-picking-slots/${invoiceId}`
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setErrorMessage("");
        setSuccessMessage("");
      },
      onError: (err) => {
        if (err.response.data) {
          setErrorMessage(err.response.data.message);
        }
      },
    }
  );

  const bookMutations = useMutation(
    ({ slotId, selectedDate }) =>
      axios
        .post(`${process.env.REACT_APP_AOMS_API_URL}/bookings/${invoiceId}`, {
          slotId,
          selectedDate,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setErrorMessage("");
        setSuccessMessage(
          "Invoice booked successfully! Please wait for the confirmation email."
        );
        getAvailablePickingSlots.reset();
      },
      onError: (err) => {
        if (err.response.data) {
          setErrorMessage(err.response.data.message);
        }
      },
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Add To Queue</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                label="Invoice Id"
                type="text"
                name="invoiceId"
                value={invoiceId}
                variant="outlined"
                onChange={(e) => {
                  setErrorMessage("");
                  setSuccessMessage("");
                  setInvoiceId(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setErrorMessage("");
                    setSuccessMessage("");
                    getAvailablePickingSlots.mutate();
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttons}
                onClick={getAvailablePickingSlots.mutate}
                title="Find Slot"
              >
                Find Slot
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttons}
                onClick={() => submitInvoiceMutation.mutate({ forced: false })}
                title="Add to Queue"
              >
                Submit
              </Button>
              {decodedToken.permissions.roles.includes(
                "collections: collections-admin"
              ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttons}
                  onClick={() => submitInvoiceMutation.mutate({ forced: true })}
                  title="Add to queue even if the deadline has passed"
                >
                  Force
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            {errorMessage && (
              <Alert severity="error" onClick={() => setErrorMessage("")}>
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" onClick={() => setSuccessMessage("")}>
                {successMessage}
              </Alert>
            )}
          </Grid>
          {submitInvoiceMutation.isLoading && <CircularProgress />}
        </Box>
      </Grid>
      {getAvailablePickingSlots.isLoading && <CircularProgress />}
      {getAvailablePickingSlots.data &&
        getAvailablePickingSlots.data.results && (
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Slot</TableCell>
                      <TableCell>Picking Lots</TableCell>
                      <TableCell>Remaining Lots After Invoice</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getAvailablePickingSlots.data.results.map((el) => (
                      <TableRow key={el.id}>
                        <TableCell>{formatDate(el.date)}</TableCell>
                        <TableCell>{el.slot}</TableCell>
                        <TableCell>{el.totalLots}</TableCell>
                        <TableCell>{el.remainingLots}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              bookMutations.mutate({
                                slotId: el.slotId,
                                selectedDate: el.date,
                              })
                            }
                          >
                            Book
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Invoice added to queue successfully."
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again."
      />
    </Grid>
  );
};

export default CustomersAddToQueue;
