import {
  AppBar,
  Divider,
  Grid,
  Paper,
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Card,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Breadcrumbs,
  FormControlLabel,
  Checkbox,
  FormControl,
  Collapse,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AutoComplete from "@mui/lab/Autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import React, { useState, useEffect, Fragment, useCallback } from "react";
import TabPanel from "../components/misc/TabPanel";
import fileDownload from "js-file-download";
import jwt_decode from "jwt-decode";
import { Link, useParams } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import RefundForm from "../components/Refunds/RefundForm/RefundForm";
import emailConversationSplitter from "../utils/emailConversationSplitter";

const internalPaperStyle = {
  background: "#F9DC5C",
};

const ResponseForm = (props) => {
  const { label } = props;
  const [value, setValue] = useState("");
  const responses = props.responses !== undefined ? props.responses : [];
  const [responseSearch, setResponseSearch] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    props.onSubmit(value);
    setValue("");
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "message":
        setValue(value);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} hidden={responses.length === 0}>
          <AutoComplete
            id="lbl-standard-responses"
            name="standardResponse"
            options={responses || []}
            getOptionLabel={(option) => option.name || ""}
            inputValue={responseSearch}
            onChange={(e, v) => setValue(v?.message || "")}
            onInputChange={(e, value) => setResponseSearch(value)}
            renderInput={(params) => (
              <TextField {...params} label="Standard Responses..." />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={label}
            multiline
            fullWidth
            name="message"
            value={value}
            onChange={handleInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const Conversation = ({ conversation }) => {
  const [showFull, setShowFull] = useState(false);
  const style = conversation.type === 0 ? {} : internalPaperStyle;

  const downloadFile = async (filename) => {
    const response = await axios.get(
      `${process.env.REACT_APP_QMS_API_URL}/attachments/${filename}`,
      { responseType: "blob" }
    );
    fileDownload(response.data, filename);
  };

  return (
    <Grid item xs={12}>
      <Paper style={style}>
        <Box mb={1} p={2}>
          <Grid container>
            <Grid item md={9}>
              <b>
                {conversation.type === 0
                  ? "Public Communication"
                  : "Internal Communication"}
              </b>
              {conversation.agentId !== null ? (
                <Typography>Agent: {conversation.agentId}</Typography>
              ) : (
                ""
              )}
              {conversation.requesterId !== null ? (
                <Typography>Requester: {conversation.requesterId}</Typography>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={3} style={{ textAlign: "right" }}>
              {new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(conversation.creationDate))}
            </Grid>
            <Grid item md={12} style={{ overflow: "auto" }}>
              {conversation.firstMessage ? (
                <>
                  <ReactMarkdown>{conversation.firstMessage}</ReactMarkdown>
                  <Typography
                    onClick={() => setShowFull(!showFull)}
                    style={{ cursor: "pointer" }}
                  >
                    {showFull
                      ? ">> Hide full conversation <<"
                      : "<< Show full conversation >>"}
                  </Typography>
                  <Collapse in={showFull}>
                    <ReactMarkdown>{conversation.rest}</ReactMarkdown>
                  </Collapse>
                </>
              ) : (
                <ReactMarkdown>{conversation.message}</ReactMarkdown>
              )}
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            {conversation.attachments.map((a) => {
              return (
                <Grid item md={12} key={`attachment-${a.filename}`}>
                  <Box pt={2}>
                    <b>Attachment:</b> {a.filename} <br />
                    <Button
                      edge="end"
                      onClick={() => downloadFile(a.filename)}
                      variant="outlined"
                    >
                      Download
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

const QmsView = (props) => {
  const { id } = useParams();
  const [qmsTab, setQmsTab] = useState(0);
  const [assignedAgentId, setAssignedAgentId] = useState(0);
  const [assignedRole, setAssignedRole] = useState(0);
  const [topicId, setTopicId] = useState("");
  const [status, setStatus] = useState(0);
  const [logs, setLogs] = useState([]);
  const [requester, setRequester] = useState({});
  const [title, setTitle] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [modificationDate, setModificationDate] = useState("");
  const [conversations, setConversations] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundRequired, setRefundRequired] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [refundCauser, setRefundCauser] = useState("");
  const [refundId, setRefundId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [responseSent, setResponseSent] = useState(false);
  const [internalResponseSent, setInternalResponseSent] = useState(false);
  const [notes, setNotes] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [inBoundDate, setInBoundDate] = useState(new Date());
  const [existingReturn, setExistingReturn] = useState(false);
  const [returnId, setReturnId] = useState("");
  const [printServices, setPrintServices] = useState([]);
  const [printServiceId, setPrintServiceId] = useState("");
  const [printers, setPrinters] = useState([]);
  const [printerId, setPrinterId] = useState("");
  const [auctionHouses, setAuctionHouses] = useState([]);
  const [auctionHouseId, setAuctionHouseId] = useState("");
  const [returnCreated, setReturnCreated] = useState(false);
  const [printCreated, setPrintCreated] = useState(false);
  const [lotsId, setLotsId] = useState("");
  const [authUserId, setAuthUserId] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const token = jwt_decode(sessionStorage.getItem("ACCESS_TOKEN"));
  const currentAgent = [
    { id: token.userId, name: `${token.firstName} ${token.lastName}` },
  ];

  const asBindData = async () => {
    let ticketId = null;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_QMS_API_URL}/tickets/${id}`
      );
      const data = result.data;
      ticketId = data.id;
      setAssignedAgentId(data.assignedAgentId);
      setAssignedRole(data.assignedRole);
      setTopicId(data.topicId);
      setRefundRequired(data.refundRequired);
      setInvoiceId(data.invoiceId);
      setRefundAmount(data.refundAmount);
      setRefundCauser(data.refundCauser);
      setRefundReason(data.refundReason);
      setRefundId(data.refundId);
      setStatus(data.status);
      setLogs(data.logs);
      setRequester(data.requester);
      sessionStorage.setItem("USER_SEARCH_QUERY", data.requester.email);
      setTitle(data.title);
      setCreationDate(data.creationDate);
      setModificationDate(data.modificationDate);
      setConversations(emailConversationSplitter(data.conversations));
      setLotsId(data.lotsId);
    } catch (err) {
      console.error("bindData error:", err);
    }
    try {
      const returnResult = await axios.get(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns/ticket/${ticketId}`
      );
      const rData = returnResult.data;
      if (rData.id != null) {
        setReturnId(rData.id);
        setExistingReturn(true);
      }
    } catch (err) {}
  };

  const bindData = useCallback(asBindData, [id]);

  const usersResponseQuery = useQuery(
    "qmsUsersResponse",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/applications/12/users`)
        .then((res) => {
          const agents = res.data.map((u) => {
            return {
              name: u.firstName + " " + u.lastName,
              id: u.id,
            };
          });
          return agents;
        }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const topicsResponseQuery = useQuery(
    "qmsTopicsResponse",
    () =>
      axios.get(`${process.env.REACT_APP_QMS_API_URL}/topics`).then((res) => {
        const topics = res.data.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
        return topics;
      }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const responsesResponseQuery = useQuery(
    "qmsResponsesResponse",
    () =>
      axios
        .get(`${process.env.REACT_APP_QMS_API_URL}/responses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const rolesResponseQuery = useQuery(
    "qmsRolesResponse",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`)
        .then((res) => {
          const roles = res.data
            .filter((r) => r.applicationId === 12 && r.name.includes("dept:"))
            .map((r) => r.name);
          return roles;
        }),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  useEffect(() => {
    bindData();
  }, [bindData]);

  const internalStyle = internalPaperStyle;

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 1:
        return "Pending";
      case 2:
        return "Closed";
      default:
        break;
    }
  };

  const handleTabChange = (evt, value) => {
    if (value === 2) {
      // Get relevant returns data
      (async function getReturnsData() {
        const ps = await axios.get(
          `${process.env.REACT_APP_PRINTERS_API_URL}/printservices`
        );
        const printServices = ps.data.printerService.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
        setPrintServices(printServices);

        setPrintServiceId(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));
        getPrinters(sessionStorage.getItem("SELECTED_PRINTER_SERVICE"));

        const ah = await axios.get(
          `${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`
        );
        const auctionHouses = ah.data.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
        setAuctionHouses(auctionHouses);
      })();
    }
    setQmsTab(value);
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "topic":
        setTopicId(value);
        break;
      case "agent":
        setAssignedAgentId(value);
        break;
      case "role":
        setAssignedRole(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "refundRequired":
        setRefundRequired(evt.target.checked);
        break;
      case "invoiceId":
        setInvoiceId(value);
        break;
      case "refundReason":
        setRefundReason(value);
        break;
      case "refundAmount":
        setRefundAmount(value);
        break;
      case "refundCauser":
        setRefundCauser(value);
        break;
      case "location":
        setShopLocation(value);
        break;
      case "notes":
        setNotes(value);
        break;
      case "printServiceLocation":
        setPrintServiceId(value);
        setPrinterId("");
        getPrinters(value);
        break;
      case "printerId":
        setPrinterId(value);
        break;
      case "auctionhouse":
        setAuctionHouseId(value);
        break;
      case "lotsId":
        setLotsId(value);
        break;
      case "userId":
        setAuthUserId(value);
        break;
      default:
        break;
    }
  };

  const getPrinters = async (printerId) => {
    let printers = [];
    if (printerId) {
      const ps = await axios.get(
        `${process.env.REACT_APP_PRINTERS_API_URL}/printers/${printerId}`
      );
      if (ps.data.printers != null) {
        printers = ps.data.printers.map((t) => {
          return {
            id: t.id,
            name: t.name,
          };
        });
      }
    }
    setPrinters(printers);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const data = {
      invoiceId: invoiceId !== "" ? invoiceId : null,
      lotsId: lotsId !== "" ? lotsId : null,
      topicId,
      assignedAgentId,
      assignedRole,
      status,
      refundRequired,
    };

    if (refundRequired) {
      data.refundAmount = refundAmount;
      data.refundReason = refundReason;
      data.refundCauser = refundCauser;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_QMS_API_URL}/tickets/${id}`,
        data
      );
      setOpenSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const downloadFile = async (filename) => {
  //   const response = await axios.get(
  //     `${process.env.REACT_APP_QMS_API_URL}/attachments/${filename}`,
  //     { responseType: "blob" }
  //   );
  //   fileDownload(response.data, filename);
  // };

  const handleResponseUpdate = async (value, attempt) => {
    if (attempt === undefined) {
      attempt = 0;
    }
    try {
      const data = {
        conversation: {
          message: value,
          type: 0,
        },
      };

      // Get the associated userId if it exists
      const params = new URLSearchParams();
      params.append("email", requester.email);
      const requesterUserIdData = await axios.get(
        `${process.env.REACT_APP_AUTH_API_URL}/users/email?${params.toString()}`
      );
      if (requesterUserIdData.data.userId) {
        data.requesterUserId = requesterUserIdData.data.userId;
      }

      await axios.put(
        `${process.env.REACT_APP_QMS_API_URL}/tickets/${id}`,
        data
      );
      setResponseSent(true);
      await bindData();
    } catch (error) {
      if (attempt === 0) {
        await handleResponseUpdate(value, attempt + 1);
      }
    }
  };

  const generateReturn = async (evt) => {
    evt.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_RETURNS_API_URL}/returns`,
        {
          ticketId: id != null ? id : undefined,
          location: shopLocation.length > 0 ? shopLocation : undefined,
          status: 0,
          // creationUserId: currentAgent[0].id,
          inBoundDate: inBoundDate,
          auctionHouseId: auctionHouseId,
          notes: notes,
        }
      );

      setReturnCreated(true);

      await axios.post(
        `${process.env.REACT_APP_PRINTERS_API_URL}/print/returns`,
        {
          printerId,
          returns: [
            {
              id: response.data.id != null ? response.data.id : undefined,
              inBoundDate,
            },
          ],
        }
      );

      setPrintCreated(true);

      sessionStorage.setItem("SELECTED_PRINTER_SERVICE", printServiceId);

      await axios.put(`${process.env.REACT_APP_QMS_API_URL}/tickets/${id}`, {
        conversation: {
          message: `${currentAgent[0].id} Generated a return with the ID: ${response.data.id}`,
          type: 1,
        },
      });
      setInternalResponseSent(true);
      await bindData();
    } catch (error) {
      console.error("internal update error:", error);
    }
  };

  const handleInternalResponseUpdate = async (value, attempt) => {
    if (attempt === undefined) {
      attempt = 0;
    }
    try {
      const data = {
        conversation: {
          message: value,
          type: 1,
        },
      };

      // Get the associated userId if it exists
      const params = new URLSearchParams();
      params.append("email", requester.email);
      const requesterUserIdData = await axios.get(
        `${process.env.REACT_APP_AUTH_API_URL}/users/email?${params.toString()}`
      );
      if (requesterUserIdData.data.userId) {
        data.requesterUserId = requesterUserIdData.data.userId;
      }

      await axios.put(
        `${process.env.REACT_APP_QMS_API_URL}/tickets/${id}`,
        data
      );
      setInternalResponseSent(true);
      await bindData();
    } catch (error) {
      console.error("internal update error:", error);
      if (attempt === 0) {
        await handleInternalResponseUpdate(value, attempt + 1);
      }
    }
  };

  const getLogType = (type) => {
    switch (type) {
      case 0:
        return "Created";
      case 1:
        return "Update";
      case 2:
        return "Closed";
      default:
        return null;
    }
  };

  const handleViewingDateChange = (date) => {
    setInBoundDate(date);
  };

  const permissions = [
    ...token.permissions.applications,
    ...token.permissions.roles,
  ];

  const handleLinkUser = async (e) => {
    e.preventDefault();
    if (!authUserId) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/users/${authUserId}/validate`
      );
      const isValid = response.data?.isValid ?? false;
      if (!isValid) return setError("User not found");

      await axios.put(
        `${process.env.REACT_APP_QMS_API_URL}/requesters/${requester.id}/userId`,
        {
          authUserId,
        }
      );
      setRequester({ ...requester, authUserId });
      setAuthUserId("");
      setSuccess("User linked successfully");
    } catch (error) {
      console.error("Link user error", error);
      return setError(error?.response?.data?.message ?? "An error occurred");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/qms/tickets">Tickets</Link>
          <Typography color="textPrimary">View Ticket</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item sm={12} md={3} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSave}>
                <Box p={2} spacing={2}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="lbl-assigned-agent">
                      Assigned Agent
                    </InputLabel>
                    <Select
                      id="lbl-assigned-agent"
                      value={assignedAgentId}
                      onChange={handleChange}
                      name="agent"
                    >
                      <MenuItem value={0}>Unassigned</MenuItem>
                      {usersResponseQuery.data ? (
                        usersResponseQuery.data.map((a) => {
                          return (
                            <MenuItem key={`agent-${a.id}`} value={a.id}>
                              {a.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          key={`agent-${token.userId}`}
                          value={token.userId}
                        >
                          {`${token.firstName} ${token.lastName}`}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="lbl-assigned-role">
                      Assigned Department
                    </InputLabel>
                    <Select
                      id="lbl-assigned-role"
                      value={assignedRole}
                      onChange={handleChange}
                      name="role"
                    >
                      <MenuItem value={0}>Unassigned</MenuItem>
                      {rolesResponseQuery.data?.map((r) => {
                        return (
                          <MenuItem key={`assigned-role-${r}`} value={r}>
                            {r}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="lbl-assigned-topic">Topic</InputLabel>
                    <Select
                      id="lbl-assigned-topic"
                      name="topic"
                      value={topicId}
                      onChange={handleChange}
                    >
                      {topicsResponseQuery.data?.map((r) => {
                        return (
                          <MenuItem key={`topic-${r.id}`} value={r.id}>
                            {r.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Divider />
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="lbl-assigned-status">Status</InputLabel>
                    <Select
                      id="lbl-assigned-status"
                      value={status}
                      name="status"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>Open</MenuItem>
                      <MenuItem value={1}>Pending</MenuItem>
                      <MenuItem value={2}>Closed</MenuItem>
                    </Select>
                  </FormControl>
                  <Divider />
                  <TextField
                    name="invoiceId"
                    label="Invoice ID"
                    fullWidth
                    variant="standard"
                    onChange={handleChange}
                    value={invoiceId}
                  />
                  <TextField
                    name="lotsId"
                    label="Lots Id"
                    fullWidth
                    variant="standard"
                    onChange={handleChange}
                    value={lotsId}
                  />

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${invoiceId}`}
                    hidden={invoiceId === null || invoiceId === ""}
                  >
                    Go to Epos
                  </a>
                  <Divider />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={refundRequired}
                        onChange={handleChange}
                        name="refundRequired"
                      />
                    }
                    label="Refund Required?"
                    color="primary"
                  />
                  {refundRequired && (
                    <Fragment>
                      <TextField
                        name="refundAmount"
                        label="Refund Hammer"
                        fullWidth
                        onChange={handleChange}
                        value={refundAmount}
                        variant="standard"
                        required
                      />
                      <TextField
                        name="refundReason"
                        label="Reason"
                        fullWidth
                        onChange={handleChange}
                        value={refundReason}
                        variant="standard"
                        required
                      />
                      <TextField
                        name="refundCauser"
                        label="Caused By"
                        fullWidth
                        onChange={handleChange}
                        value={refundCauser}
                        variant="standard"
                        required
                      />
                      <Divider />
                    </Fragment>
                  )}
                  <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary">
                      Save Changes
                    </Button>
                  </Box>
                </Box>
              </form>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {requester && !requester.authUserId && (
              <Card>
                <form onSubmit={handleLinkUser}>
                  <Box p={2} spacing={2}>
                    <Typography>
                      No SC user is linked for this requester.
                    </Typography>
                    <TextField
                      label="User Id"
                      name="userId"
                      onChange={handleChange}
                      value={authUserId}
                      fullWidth
                      variant="outlined"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      Link User
                    </Button>
                  </Box>
                </form>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={9} lg={9}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={8}>
                <Typography component="h2" variant="h6" color="inherit">
                  Ticket: #{id}
                </Typography>
                <Typography component="h3" variant="h5">
                  Title: {title}
                </Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                {requester ? (
                  requester.authUserId ? (
                    <div>
                      <p style={{ margin: 0 }}>
                        Customer: {requester.firstName} {requester.lastName}
                        <br />
                        Email: {requester.email}
                      </p>
                      <Link
                        to={`/users/${requester.authUserId}`}
                        style={{ margin: 0 }}
                      >
                        View User
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <p style={{ margin: 0 }}>
                        Customer: {requester.firstName} {requester.lastName}
                        <br />
                        Email: {requester.email}
                      </p>
                      <p style={{ color: "red", margin: 0, cursor: "pointer" }}>
                        No user has been linked
                      </p>
                    </div>
                  )
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={4}>
                <b>Status:</b> {getStatus(status)}
              </Grid>
              <Grid item xs={12} sm={4}>
                <b>Created:</b>{" "}
                {creationDate
                  ? new Intl.DateTimeFormat("en-GB", {
                      dateStyle: "short",
                      timeStyle: "medium",
                    }).format(new Date(creationDate))
                  : ""}
              </Grid>
              <Grid item xs={12} sm={4}>
                <b>Last Modified:</b>{" "}
                {modificationDate
                  ? new Intl.DateTimeFormat("en-GB", {
                      dateStyle: "short",
                      timeStyle: "medium",
                    }).format(new Date(modificationDate))
                  : ""}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <AppBar position="static">
                <Tabs
                  value={qmsTab}
                  onChange={handleTabChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  aria-label="response tab options"
                >
                  <Tab label="Public Response" />
                  <Tab label="Internal Response" />
                  <Tab
                    label={existingReturn ? "View Return" : "Generate Return"}
                  />
                  {permissions.includes("qms: Administrator") && refundId ? (
                    <Link
                      style={{ color: "white" }}
                      to={`/qms/refunds/${refundId}/edit`}
                    >
                      <Tab label="View Refund" />
                    </Link>
                  ) : permissions.includes("qms: Administrator") ? (
                    <Tab label="Generate Refund" />
                  ) : null}
                </Tabs>
              </AppBar>
              <TabPanel value={qmsTab} index={0}>
                <ResponseForm
                  label="Enter Public Response"
                  onSubmit={handleResponseUpdate}
                  responses={responsesResponseQuery.data}
                />
              </TabPanel>
              <TabPanel style={internalStyle} value={qmsTab} index={1} p={0}>
                <Box p={2} component={Paper} style={internalStyle}>
                  <ResponseForm
                    label="Enter Internal Response"
                    onSubmit={handleInternalResponseUpdate}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={qmsTab} index={2}>
                {existingReturn ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Link to={"/returns/view/" + returnId}>
                        <Button variant="contained" color="primary">
                          {" "}
                          Go to return{" "}
                        </Button>
                      </Link>
                    </Grid>
                    <Divider />
                    <Grid item xs={12}>
                      <Link to={"/users"}>
                        <Button variant="contained" color="primary">
                          {" "}
                          Search Users for payment{" "}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                ) : (
                  <form onSubmit={generateReturn}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography color="textPrimary">
                          Return Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            label="Select the inbound Date:"
                            value={inBoundDate}
                            onChange={handleViewingDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid container item spacing={4}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Location"
                            name="location"
                            onChange={handleChange}
                            value={shopLocation}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Notes"
                          name="notes"
                          multiline
                          variant="outlined"
                          fullWidth={true}
                          value={notes}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="lbl-auctionhouse">
                          Auction House:
                        </InputLabel>
                        <Select
                          id="lbl-auctionhouse"
                          value={auctionHouseId}
                          name="auctionhouse"
                          fullWidth
                          onChange={handleChange}
                        >
                          {auctionHouses.map((r) => {
                            return (
                              <MenuItem
                                key={`auctionhouse-${r.id}`}
                                value={r.id}
                              >
                                {r.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputLabel id="lbl-printLocation">
                          Print Location:
                        </InputLabel>
                        <Select
                          id="lbl-printLocation"
                          value={printServiceId}
                          name="printServiceLocation"
                          fullWidth
                          onChange={handleChange}
                        >
                          {printServices.map((r) => {
                            return (
                              <MenuItem
                                key={`printservice-${r.id}`}
                                value={r.id}
                              >
                                {r.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputLabel id="lbl-printer">Printer:</InputLabel>
                        <Select
                          id="lbl-printer"
                          value={printerId}
                          name="printerId"
                          fullWidth
                          onChange={handleChange}
                        >
                          {printers.map((r) => {
                            return (
                              <MenuItem key={`printer-${r.id}`} value={r.id}>
                                {r.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Generate Return
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </TabPanel>
              <TabPanel value={qmsTab} index={3} p={0}>
                {qmsTab === 3 && (
                  <RefundForm
                    refund={{
                      ticketId: id,
                      amount: refundAmount,
                      reason: refundReason,
                      invoiceId,
                      refundUserEmail: requester.email,
                    }}
                  />
                )}
              </TabPanel>
            </Card>
          </Grid>
          <Divider />
          <Grid item md={9}>
            <Grid container>
              <Grid item sm={12}>
                <Typography variant="h5">Conversations</Typography>
              </Grid>
            </Grid>
            {conversations && conversations.length > 0 ? (
              <Grid container>
                {conversations.map((c) => (
                  <Conversation key={`conversation-${c.id}`} conversation={c} />
                ))}

                {/* {conversations.map((c) => {
                  const style = c.type === 0 ? {} : internalStyle;
                  return (
                    <Grid item xs={12} key={`conversation-${c.id}`}>
                      <Paper style={style}>
                        <Box mb={1} p={2}>
                          <Grid container m={2}>
                            <Grid item md={9}>
                              <b>
                                {c.type === 0
                                  ? "Public Communication"
                                  : "Internal Communication"}
                              </b>
                              {c.agentId !== null ? (
                                <Typography>Agent: {c.agentId}</Typography>
                              ) : (
                                ""
                              )}
                              {c.requesterId !== null ? (
                                <Typography>
                                  Requester: {c.requesterId}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item md={3} style={{ textAlign: "right" }}>
                              {new Intl.DateTimeFormat("en-GB", {
                                dateStyle: "short",
                                timeStyle: "medium",
                              }).format(new Date(c.creationDate))}
                            </Grid>
                            <Grid item md={12} style={{ overflow: "auto" }}>
                              <ReactMarkdown>{c.message}</ReactMarkdown>
                            </Grid>
                            <Grid item md={12}>
                              <Divider />
                            </Grid>
                            {c.attachments.map((a) => {
                              return (
                                <Grid
                                  item
                                  md={12}
                                  key={`attachment-${a.filename}`}
                                >
                                  <Box pt={2}>
                                    <b>Attachment:</b> {a.filename} <br />
                                    <Button
                                      edge="end"
                                      // onClick={() => downloadFile(a.filename)}
                                      variant="outlined"
                                    >
                                      Download
                                    </Button>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Paper>
                    </Grid>
                  );
                })} */}
              </Grid>
            ) : (
              <Typography variant="h6">No matching results</Typography>
            )}
          </Grid>
          <Grid item md={3}>
            <Grid container>
              <Grid item sm={12}>
                <Typography variant="h5">Logs</Typography>
              </Grid>
            </Grid>
            <Grid container>
              {logs.map((c) => {
                return (
                  <Grid key={`log-${c.id}`} item xs={12}>
                    <Paper>
                      <Box mb={1} ml={1} p={1}>
                        <Grid container>
                          <Grid item xs={3}>
                            <b>{getLogType(c.type)}</b>
                          </Grid>
                          <Grid item xs={9} style={{ textAlign: "right" }}>
                            {new Intl.DateTimeFormat("en-GB", {
                              dateStyle: "short",
                              timeStyle: "medium",
                            }).format(new Date(c.creationDate))}
                          </Grid>
                          <Grid item xs={12}>
                            {c.log}
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Ticket Updated"
      />
      <SuccessAlert
        openSuccess={responseSent}
        setOpenSuccess={setResponseSent}
        message="Response Sent"
      />
      <SuccessAlert
        openSuccess={internalResponseSent}
        setOpenSuccess={setInternalResponseSent}
        message="Internal Conversation Added"
      />
      <SuccessAlert
        openSuccess={returnCreated}
        setOpenSuccess={setReturnCreated}
        message="New Return Created"
      />
      <SuccessAlert
        openSuccess={printCreated}
        setOpenSuccess={setPrintCreated}
        message="New Print Created"
      />
      <SuccessAlert
        openSuccess={success}
        setOpenSuccess={setSuccess}
        message={success}
      />
      <ErrorAlert openError={error} setOpenError={setError} message={error} />
    </Grid>
  );
};

export default QmsView;
