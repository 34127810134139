import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Alert from "@mui/lab/Alert";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import MarkdownEditor from "../components/misc/MarkdownEditor";
import SuccessAlert from "../components/misc/SuccessAlert";

const auctionTypes = Object.freeze({
  LIVE_AUCTION: 0,
  TIMED_AUCTION: 1,
  HYBRID_AUCTION: 2,
});

const CreateAuction = () => {
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const categoriesQuery = useQuery(
    "scCategories",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/categories?full=true`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const aomsChargeTypesQuery = useQuery(
    "aomsChargeTypes",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/aoms-charge-types`)
        .then((res) => res.data),
    { staleTime: 1000 * 60 * 10 } // 10 minutes
  );

  const [auctionHouseId, setAuctionHouseId] = useState("");
  const [auctionHouseTemplate, setAuctionHouseTemplate] = useState("");
  const [auctionHouseTemplates, setAuctionHouseTemplates] = useState([]);
  const [type, setType] = useState(auctionTypes.TIMED_AUCTION);
  const [auctionRef, setAuctionRef] = useState("");
  const [venue, setVenue] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [collectionDeadline, setCollectionDeadline] = useState(null);
  const [depositRequired, setDepositRequired] = useState(false);
  const [buyersPremium, setBuyersPremium] = useState("");
  const [vat, setVat] = useState("");
  const [internetPremium, setInternetPremium] = useState("");
  const [collectionTimes, setCollectionTimes] = useState("");
  const [viewingTimes, setViewingTimes] = useState("");
  const [importantNotes, setImportantNotes] = useState("");
  const [auctionTerms, setAuctionTerms] = useState("");
  const [openForBids, setOpenForBids] = useState(true);
  const [autoUpdateLots, setAutoUpdateLots] = useState(false);
  const [endTimeExtension, setEndTimeExtension] = useState("00:00");
  const [premium, setPremium] = useState(false);
  const [onlineOnly, setOnlineOnly] = useState(true);
  const [startLotFilter, setStartLotFilter] = useState("0");
  const [endLotFilter, setEndLotFilter] = useState("100000");
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [postalOnly, setPostalOnly] = useState(false);
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [defaultBuyersPremiumId, setDefaultBuyersPremiumId] = useState("");
  const [userFeedback, setUserFeedback] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "auctionRef":
        setAuctionRef(value);
        break;
      case "venue":
        setVenue(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      case "collectionDeadline":
        setCollectionDeadline(value);
        break;
      case "type":
        setType(value);
        break;
      case "depositRequired":
        setDepositRequired(e.target.checked);
        break;
      case "autoUpdateLots":
        setAutoUpdateLots(e.target.checked);
        break;
      case "postalOnly":
        setPostalOnly(e.target.checked);
        break;
      case "onlineOnly":
        setOnlineOnly(e.target.checked);
        break;
      case "premium":
        setPremium(e.target.checked);
        break;
      case "viewingTimes":
        setViewingTimes(value);
        break;
      case "collectionTimes":
        setCollectionTimes(value);
        break;
      case "buyersPremium":
        setBuyersPremium(value);
        break;
      case "defaultBuyersPremiumId":
        setDefaultBuyersPremiumId(value);
        setBuyersPremium(
          aomsChargeTypesQuery.data.filter(
            (aomsChargeType) => aomsChargeType.id === value
          )[0].value
        );
        break;
      case "vat":
        setVat(value);
        break;
      case "internetPremium":
        setInternetPremium(value);
        break;
      case "startLotFilter":
        setStartLotFilter(value);
        break;
      case "endLotFilter":
        setEndLotFilter(value);
        break;
      case "openForBids":
        setOpenForBids(e.target.checked);
        break;
      case "endTimeExtension":
        setEndTimeExtension(value);
        break;
      case "importantNotes":
        setImportantNotes(value);
        break;
      case "auctionTerms":
        setAuctionTerms(value);
        break;
      case "primaryCategoryId":
        setPrimaryCategoryId(value);
        break;
      case "auctionHouseId":
        setAuctionHouseId(value);
        getAuctionTemplates(value);
        setStartTime(null);
        setEndTime(null);
        const selectedAuctionHouse = auctionHousesQuery.data?.filter(
          (auctionHouse) => auctionHouse.id === value
        )[0];
        setVenue(
          `${selectedAuctionHouse.name}, ${selectedAuctionHouse.street}, ${
            selectedAuctionHouse.address2
              ? `${selectedAuctionHouse.address2}, `
              : ""
          } ${selectedAuctionHouse.city}, ${selectedAuctionHouse.country}, ${
            selectedAuctionHouse.postCode
          }`
        );
        break;
      case "auctionHouseTemplate":
        setAuctionHouseTemplate(value);
        getAuctionTemplate(value);
        break;
      case "verificationRequired":
        setVerificationRequired(e.target.checked);
        break;
      case "userFeedback":
        setUserFeedback(e.target.checked);
        break;
      default:
        break;
    }
  };

  const getAuctionTemplates = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses/${id}/auctiontemplates`
      )
      .then((res) => {
        setAuctionHouseTemplates(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAuctionTemplate = (id) => {
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctiontemplates/${id}`)
      .then((res) => {
        const data = res.data;
        setType(data.type);
        setTitle(data.title);
        setSubTitle(data.subTitle);
        setDepositRequired(data.depositRequired);
        setBuyersPremium(data.buyersPremium);
        setVat(data.vat);
        setInternetPremium(data.internetPremium);
        setCollectionTimes(data.collectionTimes);
        setViewingTimes(data.viewingTimes);
        setImportantNotes(data.importantNotes);
        setAuctionTerms(data.auctionTerms);
        setEndTimeExtension(data.endTimeExtension);
        setPremium(data.premium);
        setOnlineOnly(data.onlineOnly);
        setPostalOnly(data.postalOnly);
        aomsChargeTypesQuery.data?.find(
          (c) => c.value === data.buyersPremium
        ) &&
          setDefaultBuyersPremiumId(
            aomsChargeTypesQuery.data?.find(
              (c) => c.value === data.buyersPremium
            ).id
          );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {
      auctionHouseId: parseInt(auctionHouseId),
      type: parseInt(type),
      auctionRef,
      venue,
      title,
      subTitle,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      collectionDeadline: new Date(collectionDeadline).toISOString(),
      visible: true,
      depositRequired,
      buyersPremium: parseFloat(buyersPremium),
      defaultBuyersPremiumId,
      vat: parseFloat(vat),
      internetPremium: parseFloat(internetPremium),
      collectionTimes,
      viewingTimes,
      importantNotes,
      auctionTerms,
      openForBids,
      autoUpdateLots,
      endTimeExtension,
      premium,
      onlineOnly,
      startLotFilter: parseInt(startLotFilter),
      endLotFilter: parseInt(endLotFilter),
      primaryCategoryId: parseInt(primaryCategoryId),
      postalOnly,
      verificationRequired,
      userFeedback,
    };

    axios
      .post(`${process.env.REACT_APP_AUCTION_API_URL}/auctions`, data)
      .then((res) => {
        setOpenSuccess(true);
        setAuctionHouseId(0);
        setType(1);
        setAuctionRef("");
        setVenue("");
        setTitle("");
        setSubTitle("");
        setStartTime(null);
        setEndTime(null);
        setCollectionDeadline(null);
        setDepositRequired(false);
        setBuyersPremium("");
        setDefaultBuyersPremiumId("");
        setVat("");
        setInternetPremium("");
        setCollectionTimes("");
        setViewingTimes("");
        setImportantNotes("");
        setAuctionTerms("");
        setOpenForBids(false);
        setAutoUpdateLots(false);
        setEndTimeExtension("");
        setPremium(false);
        setOnlineOnly(false);
        setStartLotFilter("");
        setEndLotFilter("");
        setPrimaryCategoryId("");
        setPostalOnly(false);
        setVerificationRequired(false);
        setUserFeedback(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);

    const collection = new Date(date);
    collection.setHours(collection.getHours() + 48);
    collection.setMinutes(0, 0, 0);
    collection.setHours(10);
    if (collection.getDay() === 6) {
      collection.setDate(collection.getDate() + 2);
    }
    if (collection.getDay() === 0) {
      collection.setDate(collection.getDate() + 1);
    }
    setCollectionDeadline(collection);
  };

  const handleCollectionDeadline = (date) => {
    if (!endTime) return setErrorMessage("Please select an end date and time!");
    const end = new Date(endTime);
    const hours = (date - end) / 3600000;
    if (hours <= 24) {
      return setErrorMessage(
        "Collection deadline must be a minimum of 24 hours after auction end time!"
      );
    }
    setCollectionDeadline(date);
    setErrorMessage("");
  };

  return (
    <>
      {auctionHousesQuery.isLoading || categoriesQuery.isLoading ? (
        <CircularProgress />
      ) : auctionHousesQuery.error || categoriesQuery.error ? (
        "An error has occurred: " +
        auctionHousesQuery.error.message +
        categoriesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auctions">Manage Auctions</Link>
              <Typography color="textPrimary">Create New Auction</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid
                container
                item
                spacing={4}
                component="form"
                onSubmit={handleCreate}
              >
                <Grid item>
                  <Typography variant="h5">New Auction</Typography>
                </Grid>
                <Grid container item spacing={8}>
                  <Grid container item spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="auction-house-id-label">
                          Auction House Id
                        </InputLabel>
                        <Select
                          labelId="auction-house-id-label"
                          label="Auction House Id"
                          name="auctionHouseId"
                          value={auctionHouseId}
                          onChange={handleInput}
                          required
                        >
                          {auctionHousesQuery.data.map((auctionHouse) => {
                            return (
                              <MenuItem
                                key={auctionHouse.id}
                                value={auctionHouse.id}
                              >
                                {auctionHouse.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="auction-house-templates-label">
                          Template
                        </InputLabel>
                        <Select
                          labelId="auction-house-templates-label"
                          label="Template"
                          name="auctionHouseTemplate"
                          value={auctionHouseTemplate}
                          onChange={handleInput}
                          required
                        >
                          {auctionHouseTemplates.map((template) => {
                            return (
                              <MenuItem key={template.id} value={template.id}>
                                {template.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Title"
                        name="title"
                        value={title}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Sub Title"
                        name="subTitle"
                        value={subTitle}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Auction Ref"
                        name="auctionRef"
                        value={auctionRef}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="default-buyers-premium-id-label">
                          Default Buyers Premium ID
                        </InputLabel>
                        <Select
                          labelId="default-buyers-premium-id-label"
                          label="Default Buyers Premium ID"
                          name="defaultBuyersPremiumId"
                          value={defaultBuyersPremiumId}
                          onChange={handleInput}
                          required
                        >
                          {aomsChargeTypesQuery.data?.map((aomsChargeType) => {
                            return (
                              <MenuItem
                                key={aomsChargeType.id}
                                value={aomsChargeType.id}
                              >
                                {aomsChargeType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Venue"
                        name="venue"
                        value={venue}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container justifyContent="space-around">
                        <DatePicker
                          disableToolbar
                          name="startTime"
                          inputVariant="outlined"
                          format="yyyy-MM-dd"
                          placeholder="yyyy-MM-dd"
                          margin="normal"
                          label="Start Date:"
                          value={startTime}
                          onChange={setStartTime}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                        <TimePicker
                          margin="normal"
                          name="startTime"
                          label="Start Time:"
                          value={startTime}
                          defaultValue="00:00"
                          placeholder="--:--"
                          onChange={setStartTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          inputVariant="outlined"
                          ampm={false}
                          required
                        />
                        <DatePicker
                          disableToolbar
                          name="endTime"
                          inputVariant="outlined"
                          format="yyyy-MM-dd"
                          placeholder="yyyy-MM-dd"
                          margin="normal"
                          label="End Date:"
                          value={endTime}
                          required
                          onChange={handleEndTimeChange}
                        />
                        <TimePicker
                          margin="normal"
                          name="endTime"
                          label="End Time:"
                          placeholder="--:--"
                          value={endTime}
                          defaultValue="00:00"
                          onChange={handleEndTimeChange}
                          required
                          inputVariant="outlined"
                          ampm={false}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container justifyContent="space-around">
                          <DatePicker
                            disableToolbar
                            name="collectionDeadline"
                            inputVariant="outlined"
                            format="yyyy-MM-dd"
                            placeholder="yyyy-MM-dd"
                            margin="normal"
                            label="Collection Deadline Date:"
                            value={collectionDeadline}
                            required
                            onChange={handleCollectionDeadline}
                          />
                          <TimePicker
                            margin="normal"
                            name="collectionDeadline"
                            label="Collection Deadline Time:"
                            placeholder="--:--"
                            value={collectionDeadline}
                            defaultValue="00:00"
                            onChange={handleCollectionDeadline}
                            required
                            inputVariant="outlined"
                            ampm={false}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </Grid>
                    {errorMessage && (
                      <Grid item xs={12}>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="endTimeExtension"
                        label="Time Extension"
                        type="time"
                        value={endTimeExtension}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleInput}
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="auction-type-label">
                          Auction Type
                        </InputLabel>
                        <Select
                          labelId="auction-type-label"
                          label="Auction Type"
                          name="type"
                          value={type}
                          onChange={handleInput}
                          required
                        >
                          <MenuItem value={auctionTypes.LIVE_AUCTION}>
                            Live Auction
                          </MenuItem>
                          <MenuItem value={auctionTypes.TIMED_AUCTION}>
                            Timed Auction
                          </MenuItem>
                          <MenuItem value={auctionTypes.HYBRID_AUCTION}>
                            Hybrid Auction
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="primary-category-id-label">
                          Primary Category ID
                        </InputLabel>
                        <Select
                          labelId="primary-category-id-label"
                          label="Primary Category ID"
                          name="primaryCategoryId"
                          value={primaryCategoryId}
                          onChange={handleInput}
                          required
                        >
                          {categoriesQuery.data.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Buyers Premium"
                        name="buyersPremium"
                        value={buyersPremium}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="VAT"
                        name="vat"
                        value={vat}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Internet Premium"
                        name="internetPremium"
                        value={internetPremium}
                        onChange={handleInput}
                        variant="outlined"
                        fullWidth
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={openForBids}
                            onChange={handleInput}
                            name="openForBids"
                            color="primary"
                          />
                        }
                        label="Open For Bids"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={depositRequired}
                            onChange={handleInput}
                            name="depositRequired"
                            color="primary"
                          />
                        }
                        label="Deposit Required"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoUpdateLots}
                            onChange={handleInput}
                            name="autoUpdateLots"
                            color="primary"
                          />
                        }
                        label="Auto Update Lots"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={premium}
                            onChange={handleInput}
                            name="premium"
                            color="primary"
                          />
                        }
                        label="Premium"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={onlineOnly}
                            onChange={handleInput}
                            name="onlineOnly"
                            color="primary"
                          />
                        }
                        label="Online Only"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={postalOnly}
                            onChange={handleInput}
                            name="postalOnly"
                            color="primary"
                          />
                        }
                        label="Postal Only"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={verificationRequired}
                            onChange={handleInput}
                            name="verificationRequired"
                            color="primary"
                          />
                        }
                        label="Requires ID Verification"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={userFeedback}
                            onChange={handleInput}
                            name="userFeedback"
                            color="primary"
                          />
                        }
                        label="User Feedback"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Auction OMS Pull Lot Filters
                      </Typography>
                    </Grid>
                    <Grid container item spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Start Lot No"
                          name="startLotFilter"
                          value={startLotFilter}
                          onChange={handleInput}
                          variant="outlined"
                          fullWidth
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="End Lot No"
                          name="endLotFilter"
                          value={endLotFilter}
                          onChange={handleInput}
                          variant="outlined"
                          fullWidth
                          size="small"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <MarkdownEditor
                    title="Viewing Times"
                    name="viewingTimes"
                    markdown={viewingTimes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Collection Times"
                    name="collectionTimes"
                    markdown={collectionTimes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Important Notes"
                    name="importantNotes"
                    markdown={importantNotes}
                    handleInput={handleInput}
                  />
                  <MarkdownEditor
                    title="Auction Terms"
                    name="auctionTerms"
                    markdown={auctionTerms}
                    handleInput={handleInput}
                  />
                </Grid>
                <Grid item xs={12} className="">
                  <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    disabled={isLoading}
                  >
                    Create
                  </Button>
                  {isLoading && <CircularProgress size={20} />}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Auction Created"
      />
    </>
  );
};

export default CreateAuction;
