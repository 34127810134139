
import { Box } from '@mui/material';


const TabPanel = (props) => {
    return (
        <div role="tabpanel"
            hidden={props.value !== props.index}
            id={`tab-panel-${props.index}`}
            aria-labelledby={`tab-${props.index}`}
        >
            <Box p={3}>
                {props.children}
            </Box>

        </div>
    )
};

export default TabPanel;