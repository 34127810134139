import { Fragment, useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, Redirect } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import HelpIcon from "@mui/icons-material/Help";
import RedeemIcon from "@mui/icons-material/Redeem";
import SubjectIcon from "@mui/icons-material/Subject";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EventIcon from "@mui/icons-material/Event";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PrintIcon from "@mui/icons-material/Print";
import ManageSearchIcon from "@mui/icons-material/ImageSearch";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BuildIcon from "@mui/icons-material/Build";
import QueueIcon from "@mui/icons-material/Queue";
import NotificationsNoneIcon from "@mui/icons-material/Notifications";
import StopCircleIcon from "@mui/icons-material/Stop";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import Assignment from "@mui/icons-material/Assignment";
import UpdateIcon from "@mui/icons-material/Update";
import RestoreIcon from "@mui/icons-material/Restore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AccountBalance,
  Category,
  ExpandLess,
  ExpandMore,
  Gavel,
  Info,
  Note,
  Panorama,
  ViewList,
} from "@mui/icons-material";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import { getAccessToken, getDecodedAccessToken } from "../utils/Tokens";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuList = ({ handleCloseDrawer }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");

  const [menu, setMenu] = useState({
    content: false,
    b2b: false,
    qms: false,
    returns: false,
    printers: false,
    lotEnquiries: false,
    lotViewBooking: false,
    aomsTools: false,
    customersQueue: false,
    notifications: false,
    randomSearch: false,
    reports: false,
    timesheets: false,
    stats: false,
    // products: false,
  });

  const handleOpen = (name) => {
    setMenu({ ...menu, [name]: !menu[name] });
  };

  const handleSelect = (title) => {
    setSelected(title);
  };

  const accessToken = getAccessToken();
  if (!accessToken) {
    return <Redirect to="/" />;
  }

  const decodedToken = getDecodedAccessToken();
  let permissions = [
    ...decodedToken.permissions.applications,
    ...decodedToken.permissions.roles,
  ];

  const menuItems = [
    {
      title: "Dashboard",
      icon: <DashboardCustomizeIcon />,
      link: "/dashboard",
      permissions: ["auth"],
    },
    {
      title: "Users",
      icon: <PeopleIcon />,
      link: "/users",
      permissions: ["auth"],
    },
    {
      title: "QMS",
      icon: <HelpIcon />,
      link: "#",
      permissions: ["qms"],
      click: "qms",
      children: [
        {
          title: "Create New Ticket",
          icon: <AddCircleIcon />,
          link: "/qms/create",
          permissions: ["qms"],
        },
        {
          title: "Tickets",
          icon: <ListIcon />,
          link: "/qms/tickets",
          permissions: ["qms"],
        },
        {
          title: "Manage Topics",
          icon: <FormatListBulletedIcon />,
          link: "/qms/topics",
          permissions: ["qms"],
        },
        {
          title: "Manage Responses",
          icon: <SubjectIcon />,
          link: "/qms/responses",
          permissions: ["qms"],
        },
        {
          title: "Refunds",
          icon: <MoneyOffIcon />,
          link: "/qms/refunds",
          permissions: ["qms: Administrator"],
        },
      ],
    },
    {
      title: "Content",
      icon: <ViewList />,
      link: "#",
      permissions: ["scweb: Manage Content"],
      click: "content",
      children: [
        {
          title: "Articles",
          icon: <SubjectIcon />,
          link: "/articles",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "Pages",
          icon: <Note />,
          link: "/pages",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "Help Articles",
          icon: <Info />,
          link: "/help-articles",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "Banners",
          icon: <Panorama />,
          link: "/banners",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "SEO Banners",
          icon: <Panorama />,
          link: "/content/seo-banners",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "Categories",
          icon: <Category />,
          link: "/categories",
          permissions: ["scweb: Manage Categories"],
        },
        {
          title: "Landing Pages",
          icon: <Note />,
          link: "/content/landing-pages",
          permissions: ["scweb: Manage Content"],
        },
      ],
    },
    {
      title: "B2B",
      icon: <ViewList />,
      link: "#",
      permissions: ["scweb: Manage Content"],
      click: "b2b",
      children: [
        {
          title: "Blog Articles",
          icon: <SubjectIcon />,
          link: "/b2b/blog-articles",
          permissions: ["scweb: Manage Content"],
        },
        {
          title: "Category Articles",
          icon: <SubjectIcon />,
          link: "/b2b/category-articles",
          permissions: ["scweb: Manage Content"],
        },
      ],
    },
    {
      title: "Auctions",
      icon: <Gavel />,
      link: "/auctions",
      permissions: ["scweb: Manage Auctions"],
    },
    {
      title: "Auction Templates",
      icon: <Assignment />,
      link: "/auctiontemplates",
      permissions: ["scweb: Manage Auctions"],
    },
    {
      title: "Auction Houses",
      icon: <AccountBalance />,
      link: "/auction-houses",
      permissions: ["scweb: Manage Auction Houses"],
    },
    {
      title: "Lot Enquiries",
      icon: <QuestionAnswerIcon />,
      link: "#",
      permissions: ["scweb: Manage Lot Enquiries"],
      click: "lotEnquiry",
      children: [
        {
          title: "Manage Enquiries",
          icon: <ListIcon />,
          link: "/lot-enquiries",
          permissions: ["scweb: Manage Lot Enquiries"],
        },
        {
          title: "Manage Responses",
          icon: <SubjectIcon />,
          link: "/lot-enquiries-responses",
          permissions: ["scweb: Manage Lot Enquiries"],
        },
      ],
    },
    {
      title: "Lot Viewings",
      icon: <DateRangeIcon />,
      link: "#",
      permissions: ["scweb: Manage Viewings"],
      click: "lotViewBooking",
      children: [
        {
          title: "Viewings",
          icon: <EventIcon />,
          link: "/lot-viewings",
          permissions: ["scweb: Manage Viewings"],
        },
        {
          title: "Recurring Viewings",
          icon: <DateRangeIcon />,
          link: "/recurring-lot-viewings",
          permissions: ["scweb: Manage Viewings"],
        },
        {
          title: "View Bookings",
          icon: <EventBusyIcon />,
          link: "/lot-view-booking",
          permissions: ["scweb: Manage Viewings"],
        },
      ],
    },
    {
      title: "Auction Statistics",
      icon: <EqualizerIcon />,
      link: "/auction-stats",
      permissions: ["scweb: View Auction Stats"],
    },
    {
      title: "Returns",
      icon: <RedeemIcon />,
      link: "#",
      permissions: ["qms"],
      click: "returns",
      children: [
        {
          title: "Create New Return",
          icon: <AddCircleIcon />,
          link: "/returns/create",
          permissions: ["qms"],
        },
        {
          title: "Returns",
          icon: <ListIcon />,
          link: "/returns",
          permissions: ["qms"],
        },
      ],
    },
    {
      title: "Printers",
      icon: <PrintIcon />,
      link: "#",
      permissions: ["printers"],
      click: "printers",
      children: [
        {
          title: "Create New Printer",
          icon: <AddCircleIcon />,
          link: "/printers/create",
          permissions: ["printers"],
        },
        {
          title: "Printers",
          icon: <ListIcon />,
          link: "/printers",
          permissions: ["printers"],
        },
      ],
    },
    {
      title: "Aoms Tools",
      icon: <BuildIcon />,
      link: "#",
      permissions: ["auth"],
      click: "aomsTools",
      children: [
        {
          title: "Lot Sticker Print",
          icon: <PrintIcon />,
          link: "/aoms/lot-stickers",
          permissions: ["aoms"],
        },
        {
          title: "Close Auctions",
          icon: <ListIcon />,
          link: "/aoms/close-auctions",
          permissions: ["aoms: Close Auctions"],
        },
        {
          title: "SBI Print Batcher",
          icon: <PrintIcon />,
          link: "/aoms/sbi-print-batcher",
          permissions: ["aoms: Accounts"],
        },
        {
          title: "Stock Check",
          icon: <ManageSearchIcon />,
          link: "/stock-check",
          permissions: ["auth"],
        },
        {
          title: "Assign Users",
          icon: <PeopleIcon />,
          link: "/assign-users",
          permissions: ["auth"],
        },
        {
          title: "Create Users",
          icon: <PeopleIcon />,
          link: "/create-users",
          permissions: ["auth: createUsers"],
        },
        {
          title: "Reset Lot",
          icon: <UpdateIcon />,
          link: "/reset-lot",
          permissions: ["auth: resetLot"],
        },
        {
          title: "Reinstate Invoice",
          icon: <RestoreIcon />,
          link: "/reinstate-invoice",
          permissions: ["auth: resetLot"],
        },
        {
          title: "Invoice Dispatcher",
          icon: <PeopleIcon />,
          link: "/aoms/invoice-dispatcher",
          permissions: ["aoms: Accounts"],
        },
        {
          title: "Invoice Lotters",
          icon: <PeopleIcon />,
          link: "/aoms/invoice-lotters",
          permissions: ["aoms: Accounts"],
        },
      ],
    },
    {
      title: "Notifications",
      icon: <NotificationsNoneIcon />,
      link: "#",
      permissions: ["notifications: admin"],
      click: "notifications",
      children: [
        {
          title: "Create Notification",
          icon: <AddCircleIcon />,
          link: "/notifications/create",
          permissions: ["notifications: admin"],
        },
        {
          title: "Notifications",
          icon: <FormatListBulletedIcon />,
          link: "/notifications",
          permissions: ["notifications: admin"],
        },
      ],
    },
    {
      title: "Customers Queue",
      icon: <QueueIcon />,
      link: "#",
      permissions: ["collections"],
      click: "customersQueue",
      children: [
        {
          title: "Queue",
          icon: <PeopleIcon />,
          link: "/queue",
          permissions: ["collections"],
        },
        {
          title: "Bookings",
          icon: <DateRangeIcon />,
          link: "/queue/bookings",
          permissions: ["collections: collections-admin"],
        },
        {
          title: "Add to Queue",
          icon: <AddCircleIcon />,
          link: "/queue/add",
          permissions: ["collections"],
        },
        {
          title: "Picking Slots",
          icon: <ViewList />,
          link: "/queue/picking-slots",
          permissions: ["collections: collections-admin"],
        },
        {
          title: "Invoices",
          icon: <ReceiptIcon />,
          link: "/queue/invoices",
          permissions: ["collections: collections-admin"],
        },
        {
          title: "Reprimand",
          icon: <Gavel />,
          link: "/queue/reprimand",
          permissions: ["collections: collections-admin"],
        },
        {
          title: "Variables",
          icon: <SettingsIcon />,
          link: "/queue/variables",
          permissions: ["collections: collections-admin"],
        },
      ],
    },
    {
      title: "Reports",
      icon: <EqualizerIcon />,
      link: "#",
      permissions: ["reports"],
      click: "reports",
      children: [
        {
          title: "Weekly Totals",
          icon: <EqualizerIcon />,
          link: "/reports/weekly-totals",
          permissions: ["aoms: Final Reports"],
        },
        {
          title: "Monthly Totals",
          icon: <EqualizerIcon />,
          link: "/reports/monthly-totals",
          permissions: ["aoms: Final Reports"],
        },
        {
          title: "Estates Report",
          icon: <CurrencyPoundIcon />,
          link: "/reports/estates-cost",
          permissions: ["reports: estate"],
        },
        {
          title: "Lots Report",
          icon: <EqualizerIcon />,
          link: "/reports/lots-range",
          permissions: ["reports"],
        },
        {
          title: "Lots Condition",
          icon: <EqualizerIcon />,
          link: "/reports/lots-condition",
          permissions: ["reports"],
        },
        {
          title: "Stocks Report",
          icon: <EqualizerIcon />,
          link: "/reports/stocks",
          permissions: ["reports"],
        },
        {
          title: "Stock Integrity Check",
          icon: <CropFreeIcon />,
          link: "/reports/stockintegrity",
          permissions: ["reports"],
        },
        {
          title: "Collections Report",
          icon: <EqualizerIcon />,
          link: "/reports/collections",
          permissions: ["reports"],
        },
        {
          title: "Pick Issues Report",
          icon: <EqualizerIcon />,
          link: "/reports/pick-issues",
          permissions: ["reports"],
        },
        {
          title: "Inbound Report",
          icon: <EqualizerIcon />,
          link: "/reports/inbound",
          permissions: ["reports"],
        },
        {
          title: "Dispatch Report",
          icon: <EqualizerIcon />,
          link: "/reports/dispatch",
          permissions: ["reports"],
        },
      ],
    },
    {
      title: "Stats",
      icon: <EqualizerIcon />,
      link: "#",
      permissions: ["reports"],
      click: "stats",
      children: [
        {
          title: "Stats Report",
          icon: <EqualizerIcon />,
          link: "/stats/stats",
          permissions: ["reports"],
        },
        {
          title: "Users Stats Report",
          icon: <EqualizerIcon />,
          link: "/stats/users-stats",
          permissions: ["reports"],
        },
        {
          title: "Lotter Performance Report",
          icon: <EqualizerIcon />,
          link: "/stats/lotter-performance",
          permissions: ["reports"],
        },
        {
          title: "Dispatcher Performance Report",
          icon: <EqualizerIcon />,
          link: "/stats/dispatcher-performance",
          permissions: ["reports"],
        },
        {
          title: "Picker Performance Report",
          icon: <EqualizerIcon />,
          link: "/stats/picker-performance",
          permissions: ["reports"],
        },
        {
          title: " Dispatch",
          icon: <EqualizerIcon />,
          link: "/stats/dispatch",
          permissions: ["reports"],
        },
      ],
    },
    {
      title: "Sales Stats",
      icon: <EqualizerIcon />,
      link: "/stats/sales",
      permissions: ["aoms: Sales Reports"],
      children: [
        {
          title: "Inbound Report",
          icon: <EqualizerIcon />,
          link: "/stats/inbound",
          permissions: ["aoms: Sales Reports"],
        },
        {
          title: "Auction House Inbound Report",
          icon: <EqualizerIcon />,
          link: "/stats/auctionhouses/estates",
          permissions: ["aoms: Sales Reports"],
        },
        {
          title: "Vendor Inbound Report",
          icon: <EqualizerIcon />,
          link: "/stats/vendors/estates",
          permissions: ["aoms: Sales Reports"],
        },
        {
          title: "Vendor Inbound By Date Range",
          icon: <EqualizerIcon />,
          link: "/stats/inbound/vendors",
          permissions: ["aoms: Sales Reports"],
        },
        {
          title: "Vendor Hammer Report",
          icon: <EqualizerIcon />,
          link: "/stats/vendors/hammer",
          permissions: ["aoms: Sales Reports"],
        },
        {
          title: "Vendor Top Ten",
          icon: <EqualizerIcon />,
          link: "/stats/vendors/topten",
          permissions: ["aoms: Sales Reports"],
        },
      ],
    },
    {
      title: "Time Sheets",
      icon: <PunchClockIcon />,
      link: "#",
      permissions: ["timesheet"],
      click: "timesheets",
      children: [
        {
          title: "Attendance",
          icon: <PeopleIcon />,
          link: "/timesheets/attendance",
          permissions: ["timesheet: Timesheet Admin"],
        },
        {
          title: "Time Sheets",
          icon: <ViewList />,
          link: "/timesheets",
          permissions: ["timesheet: Timesheet Admin"],
        },
        {
          title: "Add Time Sheet",
          icon: <AddCircleIcon />,
          link: "/timesheets/add",
          permissions: ["timesheet: Timesheet Admin"],
        },
        {
          title: "My Time Sheet",
          icon: <AddCircleIcon />,
          link: "/timesheets/my-timesheet",
          permissions: ["timesheet"],
        },
        {
          title: "Add Employee",
          icon: <AddCircleIcon />,
          link: "/timesheets/add-employee",
          permissions: ["timesheet: Timesheet Admin"],
        },
        {
          title: "Manage Employees",
          icon: <PeopleIcon />,
          link: "/timesheets/manage-employees",
          permissions: ["timesheet: Timesheet Admin"],
        },
        //Disabled for now
        // {
        //   title: "Holidays",
        //   icon: <EventIcon />,
        //   link: "/timesheets/holidays",
        //   permissions: ["timesheet: Timesheet Holidays"],
        // },
        // {
        //   title: "Add Holiday",
        //   icon: <AddCircleIcon />,
        //   link: "/timesheets/add-holiday",
        //   permissions: ["timesheet: Timesheet Holidays"],
        // },
        {
          title: "Shift Patterns",
          icon: <EventIcon />,
          link: "/timesheets/shift-patterns",
          permissions: ["timesheet: Timesheet Admin"],
        },
      ],
    },
    {
      title: "Random Search",
      icon: <StopCircleIcon />,
      link: "/random-search",
      permissions: ["auth"],
    },
    // {
    //   title: "Products",
    //   icon: <InventoryIcon />,
    //   link: "#",
    //   permissions: ["auth"],
    //   click: "products",
    //   children: [
    //     {
    //       title: "Products",
    //       icon: <ListIcon />,
    //       link: "/products",
    //       permissions: ["auth"],
    //     },
    //     {
    //       title: "Title Template",
    //       icon: <TitleIcon />,
    //       link: "/products/title-template",
    //       permissions: ["auth"],
    //     },
    //     {
    //       title: "Descriptions",
    //       icon: <DescriptionIcon />,
    //       link: "/products/description",
    //       permissions: ["auth"],
    //     },
    //   ],
    // },
  ];

  return (
    <List>
      {menuItems.map((item) => {
        const hasPermission = item.permissions.some((permission) =>
          permissions.includes(permission)
        );
        if (hasPermission) {
          return (
            <Fragment key={item.title}>
              <Link to={item.link} onClick={() => handleSelect(item.title)}>
                <ListItem
                  button
                  onClick={() => handleOpen(item.click)}
                  selected={!item.children && selected === item.title}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                  {item.children &&
                    (menu[item.click] ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
              </Link>
              <Collapse in={menu[item.click]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children?.map((child) => {
                    const hasPermission = child.permissions?.some(
                      (permission) => permissions.includes(permission)
                    );
                    if (hasPermission) {
                      return (
                        <Link
                          to={child.link}
                          key={child.title}
                          onClick={() => handleSelect(child.title)}
                        >
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={handleCloseDrawer}
                            selected={selected === child.title}
                          >
                            <ListItemIcon>{child.icon}</ListItemIcon>
                            <ListItemText primary={child.title} />
                          </ListItem>
                        </Link>
                      );
                    } else return null;
                  })}
                </List>
              </Collapse>
            </Fragment>
          );
        }
        return null;
      })}
    </List>
  );
};

export default MenuList;
