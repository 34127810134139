import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Grid, Typography, CircularProgress } from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

import Articles from "../components/B2B/Articles";

const B2BEditBlogArticle = () => {
  const { id } = useParams();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { data, isLoading, error } = useQuery(
    "b2b-blog-article",
    () =>
      axios
        .get(`${process.env.REACT_APP_CONTENT_API_URL}/b2b-blog-articles/${id}`)
        .then((res) => res.data),
    {
      enabled: !!id,
    }
  );

  const updateBlogEntryMutation = useMutation(
    ({ title, summary, articleDate, mainImage, content, showContactUs }) =>
      axios
        .put(
          `${process.env.REACT_APP_CONTENT_API_URL}/b2b-blog-articles/${id}`,
          {
            title,
            summary,
            articleDate,
            mainImage,
            content,
            showContactUs,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: () => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/b2b/blog-articles">Blog Articles</Link>
          <Typography color="textPrimary">Edit</Typography>
        </Breadcrumbs>
      </Grid>
      {isLoading && <CircularProgress />}
      {error && `Error: ${error.message}`}
      {data && data.result.length > 0 && (
        <Articles
          data={data.result[0]}
          submitMutation={updateBlogEntryMutation}
        />
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Article Created"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error Updating Article"
      />
    </Grid>
  );
};

export default B2BEditBlogArticle;
