import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Breadcrumbs,
} from "@mui/material";

const ReportStatsStockContainers = ({ location }) => {
  const [type, setType] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(20);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("type")) setType(query.get("type"));
    if (query.get("locationId")) setLocationId(query.get("locationId"));
  }, [location.search]);

  const { data, isLoading, error } = useQuery(
    ["report-stocks", type, locationId, page, maximumRows],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/stats/stocks-container?type=${type}&locationId=${locationId}&startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}`
        )
        .then((res) => res.data),
    {
      enabled: !!type && !!locationId,
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/stats/stats">Stats Report</Link>
          <Link
            to={`/stats/stock-container?type=${type}&locationId=${locationId}`}
          >
            Stock Integrity Checks
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <h1>Stats Report</h1>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <Typography>Error: {error.message}</Typography>}
          {data && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>StockId</TableCell>
                    <TableCell>LotNo</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.results.map((el) => (
                    <TableRow key={el.stockId}>
                      <TableCell>
                        <a
                          href={`${process.env.REACT_APP_IMAGE_CDN_URL}/thumbnails/${el.imageId}.webp`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE_CDN_URL}/thumbnails/${el.imageId}-480.webp`}
                            style={{ width: "80px", height: "80px" }}
                            alt="stock"
                          />
                        </a>
                      </TableCell>
                      <TableCell>{el.stockId}</TableCell>
                      <TableCell>{el.lotNo}</TableCell>
                      <TableCell>{el.productTitle}</TableCell>
                      <TableCell>{el.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={data?.totalRows || 0}
                rowsPerPage={maximumRows}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportStatsStockContainers;
