import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SuccessAlert from "../components/misc/SuccessAlert";

const VerificationIds = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openSuccess, setOpenSuccess] = useState(false);

  const verificationIdsQuery = useQuery(
    ["verificationIds", page],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AUTH_API_URL
          }/account/verification-ids?maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          return res.data;
        }),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {verificationIdsQuery.isLoading ? (
        <CircularProgress />
      ) : verificationIdsQuery.error ? (
        "An error has occurred: " + verificationIdsQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">
                IDs Requiring Verification
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Date Added</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {verificationIdsQuery?.data.verificationIds.map(
                      (verificationId) => {
                        return (
                          <TableRow key={verificationId.id} hover>
                            <TableCell>{verificationId.id}</TableCell>
                            <TableCell>
                              {formatTime(verificationId.creationDate)}
                            </TableCell>
                            <TableCell>{verificationId.userId}</TableCell>
                            <TableCell>
                              <Link
                                to={`/verification-ids/${verificationId.id}/${verificationId.userId}`}
                              >
                                <Button startIcon={<VisibilityIcon />}>
                                  View
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={verificationIdsQuery?.data.totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            message={"Subscribed to ID Verification Notifications"}
          />
        </Grid>
      )}
    </>
  );
};

export default VerificationIds;
