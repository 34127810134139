import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SmallStat from "./SmallStat";
import { formatTime } from "../../../utils/formatTime";
import { getKeyByValue } from "../../../utils/getKeyByValue";

const estateStatuses = Object.freeze({
  PRE_UPDATE: 0,
  BOOKED_IN: 1,
  ARRIVED: 2,
  SETTING_OUT: 3,
  CATALOGUING: 4,
  SALE_READY: 5,
});

const Inbound = ({ currentAuctionHouse, showTable }) => {
  const [status, setStatus] = useState("");

  const { data, isLoading, error } = useQuery(
    ["inbound", currentAuctionHouse, showTable, status],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/dashboard/inbound?auctionHouseId=${
            currentAuctionHouse?.id || ""
          }&status=${status}&getTable=${showTable}`
        )
        .then((res) => res.data)
  );

  const handleSetStatus = (val) => {
    if (val === status) setStatus("");
    else setStatus(val);
  };

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <>
          <Grid item xs={12}>
            <Box p={3} component={Paper}>
              <Grid item xs={12}>
                <Typography variant="h5">Inbound</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Booked In"
                    value={data?.booked_in}
                    onClick={() => handleSetStatus(estateStatuses.BOOKED_IN)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Arrived"
                    value={data?.arrived}
                    onClick={() => handleSetStatus(estateStatuses.ARRIVED)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SmallStat
                    title="Setting Out"
                    value={data?.setting_out}
                    onClick={() => handleSetStatus(estateStatuses.SETTING_OUT)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SmallStat
                    title="Lotting"
                    value={data?.cataloguing}
                    onClick={() => handleSetStatus(estateStatuses.CATALOGUING)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SmallStat
                    title="Sale Ready"
                    value={data?.sale_ready}
                    onClick={() => handleSetStatus(estateStatuses.SALE_READY)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {showTable && data?.estates && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Estate No</TableCell>
                      <TableCell>Vendor</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Booked In</TableCell>
                      <TableCell>Booked Out</TableCell>
                      <TableCell>Arrived</TableCell>
                      <TableCell>Processed Stock</TableCell>
                      <TableCell>Total Stock</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.estates.map((estate) => (
                      <TableRow key={estate.id}>
                        <TableCell>{estate.estateNo}</TableCell>
                        <TableCell>{estate.vendorName}</TableCell>
                        <TableCell>
                          {getKeyByValue(estateStatuses, estate.status)}
                        </TableCell>
                        <TableCell>
                          {estate.bookedInDate &&
                            formatTime(estate.bookedInDate)}
                        </TableCell>
                        <TableCell>
                          {estate.bookedOutDate &&
                            formatTime(estate.bookedOutDate)}
                        </TableCell>
                        <TableCell>
                          {estate.arrivalDate && formatTime(estate.arrivalDate)}
                        </TableCell>
                        <TableCell>{estate.processedStock}</TableCell>
                        <TableCell>{estate.totalStocks}</TableCell>
                        <TableCell>{estate.location}</TableCell>
                        <TableCell>
                          <a
                            href={`https://portal.auctionoms.com/inbound/estates/${estate.id}/edit`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Inbound;
