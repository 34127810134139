import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSVLink } from "react-csv";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";
import { formatTime } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const ReportCollections = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    name: "Simon Charles Stanley Court",
    id: 10,
  });
  const [csvData, setCsvData] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const classes = useStyles();
  const csvRef = useRef();

  const { data, loading, error } = useQuery(
    ["asCollectionStats", page, rowsPerPage, generate],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/collections/get-all-collections-stats?search=&active=&status=&startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => res.data),
    {
      enabled: generate,
    }
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const cvsDataMutation = useMutation(({ maxRows }) =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/collections/get-all-collections-stats?search=&active=&status=&startRowIndex=
          0&maximumRows=${maxRows}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setCsvData(res.data.result);
        csvRef.current.link.click();
        setOpenSuccess(true);
      })
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
    setGenerate(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Collections Report</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {auctionHousesQuery.data && (
            <Grid container spacing={7}>
              <Grid item xs={3} className={classes.center}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Auction House</InputLabel>
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={3}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    autoOk={true}
                    label="Start date:"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e);
                      setGenerate(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd-MM-yyyy"
                    margin="normal"
                    autoOk={true}
                    label="End date:"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e);
                      setGenerate(false);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "end date",
                    }}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={3} className={classes.center}>
                {!generate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setGenerate(true)}
                  >
                    Generate Report
                  </Button>
                ) : (
                  <>
                    <CSVLink
                      ref={csvRef}
                      data={csvData}
                      filename={`collection-report-${currentAuctionHouse.name}-${startDate}-${endDate}.csv`}
                      target="_blank"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        cvsDataMutation.mutateAsync({ maxRows: data.count })
                      }
                    >
                      {cvsDataMutation.isLoading
                        ? "Loading..."
                        : "Download Report"}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            "An error occurred" + error
          ) : !data ? (
            "No data!"
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>InvoiceNo</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Picker</TableCell>
                    <TableCell>ColPoint</TableCell>
                    <TableCell>Bay</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Updated At</TableCell>
                    <TableCell>Picking Start</TableCell>
                    <TableCell>Picking End</TableCell>
                    <TableCell>TotalLots</TableCell>
                    <TableCell>MissingLots</TableCell>
                    <TableCell>InvoiceDate</TableCell>
                    <TableCell>SuspendReason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.result.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.invoiceId}</TableCell>
                      <TableCell>{el.active}</TableCell>
                      <TableCell>{el.status}</TableCell>
                      <TableCell>{el.customerName}</TableCell>
                      <TableCell>{el.pickerName}</TableCell>
                      <TableCell>{el.collectionPoint}</TableCell>
                      <TableCell>{el.collectionBay}</TableCell>
                      <TableCell>{formatTime(el.creationDate)}</TableCell>
                      <TableCell>{formatTime(el.updatedAt)}</TableCell>
                      <TableCell>
                        {el.pickingStart && formatTime(el.pickingStart)}
                      </TableCell>
                      <TableCell>
                        {el.pickingEnd && formatTime(el.pickingEnd)}
                      </TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                      <TableCell>{el.lotsLeftUnpicked}</TableCell>
                      <TableCell>
                        {el.invoiceDate && formatTime(el.invoiceDate)}
                      </TableCell>
                      <TableCell>{el.suspendReason}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ReportCollections;
