import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../utils/formatCurrency";
import { capitalise, deUrlify } from "../utils/urls";

const AuctionBuyerStats = () => {
  const { id, auctionTitle } = useParams();

  const { isLoading, error, data } = useQuery([`auctionBuyerStats-${id}`], () =>
    axios
      .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionstats/${id}/buyers`)
      .then((res) => res.data)
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/auction-stats">Auction Statistics</Link>
              <Typography color="textPrimary">
                Auction Buyer Statistics
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={`${capitalise(
                  deUrlify(auctionTitle)
                )} - Buyer Statistics`}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Total Lots Winning</TableCell>
                      <TableCell>Total Hammer</TableCell>
                      <TableCell>Total Previous Registered Auctions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((auctionBuyerStat) => {
                      return (
                        <TableRow key={auctionBuyerStat.userId} hover>
                          <TableCell>
                            <Link to={`/users/${auctionBuyerStat.userId}`}>
                              {auctionBuyerStat.userId}
                            </Link>
                          </TableCell>
                          <TableCell>{auctionBuyerStat.name}</TableCell>
                          <TableCell>{auctionBuyerStat.totalLots}</TableCell>
                          <TableCell>
                            {formatCurrency(auctionBuyerStat.totalHammer)}
                          </TableCell>
                          <TableCell>
                            {auctionBuyerStat.totalAuctionsRegistered}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AuctionBuyerStats;
