import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ResetLot = () => {
  const [lotId, setLotId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetLotMutation = useMutation(
    () =>
      axios
        .put(`${process.env.REACT_APP_AOMS_API_URL}/lots/${lotId}/reset`)
        .then((res) => res.data),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: (error) => {
        setOpenError(true);
        setErrorMessage(error.response.data.message);
      },
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Reset Lot</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                id="lot-id"
                label="Scwebv7 Lot Id"
                variant="outlined"
                value={lotId}
                onChange={(e) => setLotId(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    resetLotMutation.mutate();
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={resetLotMutation.mutate}
                disabled={lotId === ""}
              >
                Reset Lot
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Lot has been reset!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Grid>
  );
};

export default ResetLot;
