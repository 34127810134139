import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ReportLotsRange = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [estateNo, setEstateNo] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [lotsData, setLotsData] = useState([]);
  const [CVSFilename, setCVSFilename] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { data, isLoading, error, mutate } = useMutation(
    () => {
      let extraQuery = "";
      if (estateNo !== "") extraQuery += `&estateNo=${estateNo}`;
      if (vendorId !== "") extraQuery += `&vendorId=${vendorId}`;

      return axios.get(
        `${
          process.env.REACT_APP_AOMS_API_URL
        }/reports/lots/range-analysis?startDate=${startDate}&endDate=${endDate}&page=${
          page * rowsPerPage
        }&maximumRows=${rowsPerPage}${extraQuery}`
      );
    },
    {
      onSettled: (data, error) => {
        if (data) {
          setOpenSuccess(true);
          setLotsData([]);
        }
        if (error) setOpenError(true);
      },
    }
  );

  const generateFullReportMutation = useMutation(
    ({ maxRows }) => {
      let extraQuery = "";
      if (estateNo !== "") extraQuery += `&estateNo=${estateNo}`;
      if (vendorId !== "") extraQuery += `&vendorId=${vendorId}`;

      return axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/lots/get-data?startDate=${startDate}&endDate=${endDate}&page=0&maximumRows=${maxRows}${extraQuery}`
      );
    },
    {
      onSettled: (data, error) => {
        if (data) {
          setOpenSuccess(true);
          setLotsData(data.data);
        }
        if (error) setOpenError(true);
      },
    }
  );

  const getVendorsMutation = useMutation(
    () =>
      axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/lots/get-vendors?startDate=${startDate}&endDate=${endDate}&status=1`
      ),
    {
      onSettled: (data, error) => {
        if (data) setOpenSuccess(true);
        if (error) setOpenError(true);
      },
    }
  );

  useEffect(() => {
    if (startDate && endDate) {
      const start = startDate.toISOString().split("T")[0];
      const end = endDate.toISOString().split("T")[0];
      setCVSFilename(`lots-report-${start}_${end}.csv`);
      getVendorsMutation.reset();
      setVendorId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    mutate();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    mutate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Lots Range Report</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!getVendorsMutation.data ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getVendorsMutation.mutate()}
                >
                  Get Vendors
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={mutate}>
                  Generate Report
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                id="estateNo"
                name="estateNo"
                label="Estate No..."
                value={estateNo}
                onChange={(e) => setEstateNo(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="vendorId">Vendor</InputLabel>
                <Select
                  labelId="vendorId"
                  id="vendorId"
                  name="vendorId"
                  label="Vendor Id"
                  value={vendorId}
                  fullWidth
                  onChange={(e) => setVendorId(e.target.value)}
                >
                  {getVendorsMutation?.data?.data?.vendors.map((vendor) => {
                    return (
                      <MenuItem value={vendor.id} key={vendor.id}>
                        {vendor.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {lotsData.length === 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={data?.data?.count > 0 ? false : true}
                  onClick={() =>
                    generateFullReportMutation.mutate({
                      maxRows: data?.data?.count,
                    })
                  }
                >
                  {generateFullReportMutation.isLoading
                    ? "Loading..."
                    : "Request Download"}
                </Button>
              ) : (
                <CSVLink
                  data={
                    generateFullReportMutation.data?.data
                      ? generateFullReportMutation.data.data.lots
                      : []
                  }
                  filename={CVSFilename}
                >
                  <Button variant="contained" color="primary">
                    Download Ready
                  </Button>
                </CSVLink>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            "An error has occurred: " + error.message
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>LotNo</TableCell>
                    <TableCell>a</TableCell>
                    <TableCell>Item Ref</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Product Title</TableCell>
                    <TableCell>Self Billing Invoice Id</TableCell>
                    <TableCell>Estate No</TableCell>
                    <TableCell>Auction Ref</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Auction Type</TableCell>
                    <TableCell>Vendor Ref</TableCell>
                    <TableCell>Hammer Price</TableCell>
                    <TableCell>Hammer VAT</TableCell>
                    <TableCell>Commission</TableCell>
                    <TableCell>Commission VAT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.lots?.map((lot) => (
                    <TableRow key={lot.id}>
                      <TableCell>{lot.lotNo}</TableCell>
                      <TableCell>{lot.a}</TableCell>
                      <TableCell>{lot.itemReference}</TableCell>
                      <TableCell>{lot.productCode}</TableCell>
                      <TableCell>{lot.productTitle}</TableCell>
                      <TableCell>{lot.selfBillingInvoiceId}</TableCell>
                      <TableCell>{lot.estateNo}</TableCell>
                      <TableCell>{lot.auctionRef}</TableCell>
                      <TableCell>{lot.invoiceDate}</TableCell>
                      <TableCell>{lot.auctionType}</TableCell>
                      <TableCell>{lot.vendorReference}</TableCell>
                      <TableCell>{lot.hammerPrice}</TableCell>
                      <TableCell>{lot.hammerVAT}</TableCell>
                      <TableCell>{lot.commission}</TableCell>
                      <TableCell>{lot.commissionVAT}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.data?.count ? data.data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Estate cost report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="An error has occurred"
      />
    </Grid>
  );
};

export default ReportLotsRange;
