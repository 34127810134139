import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import { getKeyByValue } from "../utils/getKeyByValue";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const status = Object.freeze({
  PENDING: 0,
  ENDED: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddStaffModal = ({
  open,
  setOpen,
  value,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-cost-report"
    >
      <Box sx={style}>
        <Typography variant="h5" component="h2">
          Add Staff
        </Typography>
        <Grid container spacing={3} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              value={value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const ReportEstateCost = () => {
  const [totalCost, setTotalCost] = useState(0);
  const [soldLots, setSoldLots] = useState(0);
  const [unsoldLots, setUnsoldLots] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [staffInput, setStaffInput] = useState("");
  const [reportId, setReportId] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { estateNo } = useParams();

  const costTypes = useQuery("asCostTypes", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/reports/estate/report-types`)
      .then((res) => res.data)
  );

  const { data, isLoading, error, refetch } = useQuery(
    "asCostEstate",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/reports/estates/${estateNo}`
        )
        .then((res) => res.data),
    { enabled: !!estateNo }
  );

  const lotsIncome = useQuery("asLotsIncome", () =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/estates/${estateNo}/lots-income`
      )
      .then((res) => res.data)
  );

  useEffect(() => {
    if (!data) return;
    if (data.result?.length > 0) {
      const totalCost = data.result.reduce((acc, curr) => {
        return acc * 1 + curr.cost * 1;
      }, 0);
      setTotalCost(totalCost.toFixed(2));
      setSoldLots(data.result[0].soldLots);
      setUnsoldLots(data.result[0].unsoldLots);
    }
  }, [data]);

  const generateReportMutation = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/estates/${estateNo}/generate-report`
      ),
    {
      onSettled: (data, error) => {
        if (data) {
          setOpenSuccess(true);
          refetch();
        }
        if (error) setOpenError(true);
      },
    }
  );

  const updateStaffMutation = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/reports/estates/update-staff`,
        {
          staff: staffInput,
          id: reportId,
        }
      ),
    {
      onSettled: (data, error) => {
        if (data) {
          setOpenSuccess(true);
          generateReportMutation.mutate();
          refetch();
          setShowModal(false);
        }

        if (error) setOpenError(true);
      },
    }
  );

  const handleGetType = (typeId) => {
    return costTypes.data?.result.find((el) => el.id === typeId).name;
  };

  const handleAverage = (value, props) => {
    const type = costTypes.data?.result.find((el) => el.id === props.type).name;
    if (type === "POSTAL") return (value / props.totalPostalLots).toFixed(2);
    if (type === "COLLECTION")
      return (value / props.totalCollectionLots).toFixed(2);
    return (value / props.soldLots).toFixed(2);
  };

  const handleGetRate = (props) => {
    const type = costTypes.data?.result.find((el) => el.id === props.type).name;
    if (type === "STORAGE") return `£${props.rate}/Day`;
    if (type === "SERVER") return `£${props.rate}/Lot`;
    return `£${props.rate}/Hour`;
  };

  const handleCurrencyFormat = (value) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value);
  };

  return (
    <Grid container spacing={3}>
      <AddStaffModal
        open={showModal}
        setOpen={setShowModal}
        value={staffInput}
        handleChange={(e) => setStaffInput(e.target.value)}
        handleSubmit={() => updateStaffMutation.mutate()}
      />
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/reports/estates-cost">Cost Reports</Link>
          <Typography color="textPrimary">Cost Report Details</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            "An error has occurred: " + error.message
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Type</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Status</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Total Hours</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Avg Hours</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Cost</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Avg Cost</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Rate</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Staff</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.result?.map((el, i) => (
                    <TableRow key={el.id}>
                      <TableCell>{handleGetType(el.type)}</TableCell>
                      <TableCell>{getKeyByValue(status, el.status)}</TableCell>
                      <TableCell>{el.hours}</TableCell>
                      <TableCell>{handleAverage(el.hours, el)}</TableCell>
                      <TableCell>{handleCurrencyFormat(el.cost)}</TableCell>
                      <TableCell>
                        {handleCurrencyFormat(handleAverage(el.cost, el))}
                      </TableCell>
                      <TableCell>{handleGetRate(el)}</TableCell>
                      <TableCell
                        onClick={() => {
                          setStaffInput(el.staff);
                          setShowModal(true);
                          setReportId(el.id);
                        }}
                      >
                        {el.staff}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Grid
            item
            xs={12}
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {generateReportMutation.isLoading && (
              <CircularProgress size={24} style={{ marginRight: "1rem" }} />
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={generateReportMutation.mutate}
            >
              Generate Report
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {lotsIncome.isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sold Lots</TableCell>
                    <TableCell>Unsold Lots</TableCell>
                    <TableCell>Buyers Premium</TableCell>
                    <TableCell>Commission</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lotsIncome.data?.result.map((el, i) => (
                    <TableRow key={i}>
                      <TableCell>{soldLots}</TableCell>
                      <TableCell>{unsoldLots}</TableCell>
                      <TableCell>
                        {handleCurrencyFormat(el.buyersPremium)}
                      </TableCell>
                      <TableCell>
                        {handleCurrencyFormat(el.commission)}
                      </TableCell>
                      <TableCell>{handleCurrencyFormat(totalCost)}</TableCell>
                      <TableCell>
                        {handleCurrencyFormat(
                          el.buyersPremium * 1 +
                            el.commission * 1 -
                            totalCost * 1
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Report generated successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ReportEstateCost;
