import { useState } from "react";
import {
  Breadcrumbs,
  Grid,
  InputLabel,
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";

const UserTickets = (props) => {
  const { id } = useParams();

  const [status, setStatus] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const { data } = useQuery(["tickets", page, rowsPerPage, status, id], () =>
    axios
      .get(
        `${process.env.REACT_APP_QMS_API_URL}/tickets?startRowIndex=${
          page * rowsPerPage
        }&maximumRows=${rowsPerPage}&status=${status}&userId=${id}`
      )
      .then((res) => {
        setTotalRecords(res.data.recordCount);
        return res.data;
      })
  );

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 2:
        return "Closed";
      default:
        break;
    }
  };

  const handleChange = async (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    switch (name) {
      case "status":
        setStatus(value);
        break;
      default:
        break;
    }
  };
  const handleChangePage = async (evt, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/users">Users</Link>
          <Link to={`/users/${id}`}>Manage User</Link>
          <Typography color="textPrimary">User Tickets</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item>
              <InputLabel id="lbl-status">Status:</InputLabel>
            </Grid>
            <Grid item>
              <Select
                labelId="lbl-status"
                id="select-status"
                name="status"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value={0}>Open</MenuItem>
                <MenuItem value={2}>Closed</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Link
                to={{
                  pathname: `/qms/create`,
                  state: {
                    userId: id,
                  },
                }}
              >
                Create New Ticket
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {data && data.results.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((r) => {
                    return (
                      <TableRow key={`cell-${r.id}`} hover>
                        <TableCell>{r.id}</TableCell>
                        <TableCell>{r.email}</TableCell>
                        <TableCell>{r.title}</TableCell>
                        <TableCell>{getStatus(r.status)}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-GB", {
                            dateStyle: "short",
                            timeStyle: "medium",
                          }).format(new Date(r.creationDate))}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-GB", {
                            dateStyle: "short",
                            timeStyle: "medium",
                          }).format(new Date(r.modificationDate))}
                        </TableCell>
                        <TableCell>
                          <Link to={`/qms/tickets/${r.id}`}>View</Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 50]}
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : (
            <Typography variant="h6">No matching results</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserTickets;
