import { TableCell } from "@mui/material";
import { withStyles } from "@mui/styles";

const RoleTableCell = withStyles({
  body: {
    borderBottom: 0,
  },
})(TableCell);

export default RoleTableCell;
