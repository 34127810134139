import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { makeStyles } from "@mui/styles";
import { getKeyByValue } from "../utils/getKeyByValue";
import { PieChart } from "react-minimal-pie-chart";
import HelpIcon from "@mui/icons-material/Help";

const feedbackTypes = Object.freeze({
  sentiment_very_dissatisfied_black_48dp: 1,
  sentiment_dissatisfied_black_48dp: 2,
  sentiment_neutral_black_48dp: 3,
  sentiment_satisfied_black_48dp: 4,
  sentiment_very_satisfied_black_48dp: 5,
});

const feedbackTypeColors = Object.freeze({
  "#e73100": 1,
  "#f68e36": 2,
  "#ffce31": 3,
  "#44de80": 4,
  "#00bd62": 5,
});

const useStyles = makeStyles(() => ({
  feedbackIcon: {
    padding: "4px",
    borderRadius: "0.25rem",
  },
  feedbackStats: {
    maxHeight: "100px",
  },
  sentiment_very_dissatisfied_black_48dp: {
    background: "#e73100",
  },
  sentiment_dissatisfied_black_48dp: {
    background: "#f68e36",
  },
  sentiment_neutral_black_48dp: {
    background: "#ffce31",
  },
  sentiment_satisfied_black_48dp: {
    background: "#44de80",
  },
  sentiment_very_satisfied_black_48dp: {
    background: "#00bd62",
  },
}));

const ManageUserFeedback = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const userFeedbackQuery = useQuery(
    ["verificationIds", page],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AUTH_API_URL
          }/feedback?maximumRows=${rowsPerPage}&startRowIndex=${
            page * rowsPerPage
          }`
        )
        .then((res) => {
          return res.data;
        }),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {userFeedbackQuery.isLoading ? (
        <CircularProgress />
      ) : userFeedbackQuery.error ? (
        "An error has occurred: " + userFeedbackQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">User Feedback</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container direction="row" item xs={12}>
                <Grid container item alignContent="center" xs={12} sm={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{`${userFeedbackQuery.data.totalCount} responses`}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  alignContent="end"
                  xs={12}
                  sm={10}
                >
                  {userFeedbackQuery.data &&
                    userFeedbackQuery.data.typeCounts.map((typeCount) => (
                      <Grid
                        container
                        item
                        xs={12}
                        sm={4}
                        key={typeCount.type}
                        md={2}
                        className={`${classes.feedbackIcon} ${
                          classes[getKeyByValue(feedbackTypes, typeCount.type)]
                        }`}
                        alignItems="center"
                      >
                        <Grid item>
                          <img
                            src={`/icons/${getKeyByValue(
                              feedbackTypes,
                              typeCount.type
                            )}.svg`}
                            alt="Feedback icon"
                          />
                        </Grid>
                        <Grid item>
                          <Box ml={1}>
                            <Typography variant="h5">
                              <strong>{typeCount.count}</strong>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    alignItems="center"
                    className={classes.feedbackStats}
                  >
                    <PieChart
                      animate
                      data={userFeedbackQuery.data.typeCounts.map(
                        (typeCount) => {
                          return {
                            value: typeCount.count,
                            color: getKeyByValue(
                              feedbackTypeColors,
                              typeCount.type
                            ),
                          };
                        }
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Invoice ID</TableCell>
                      <TableCell>Feedback</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userFeedbackQuery?.data.feedback.map((userFeedback) => {
                      return (
                        <TableRow key={userFeedback.id} hover>
                          <TableCell>
                            <img
                              src={`/icons/${getKeyByValue(
                                feedbackTypes,
                                userFeedback.type
                              )}.svg`}
                              alt="Feedback icon"
                              className={`${classes.feedbackIcon} ${
                                classes[
                                  getKeyByValue(
                                    feedbackTypes,
                                    userFeedback.type
                                  )
                                ]
                              }`}
                            />
                          </TableCell>
                          <TableCell>
                            {formatTime(userFeedback.submittedDate)}
                          </TableCell>
                          <TableCell>
                            <Link to={`/users/${userFeedback.userId}`}>
                              {`${userFeedback.firstName} ${userFeedback.lastName}`}
                            </Link>
                          </TableCell>
                          <TableCell>{userFeedback.invoiceId}</TableCell>
                          <TableCell>{userFeedback.feedback}</TableCell>
                          <TableCell>
                            <Link
                              to={{
                                pathname: `/qms/create`,
                                state: {
                                  userId: userFeedback.userId,
                                  invoiceId: userFeedback.invoiceId,
                                  feedback: `Hi ${
                                    userFeedback.firstName
                                  }\n\n\nYour feedback (submited ${formatTime(
                                    userFeedback.submittedDate
                                  )}): \n\n${userFeedback.feedback}`,
                                },
                              }}
                            >
                              <Button startIcon={<HelpIcon />}>Respond</Button>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/feedback/${userFeedback.id}`}>
                              <Button startIcon={<VisibilityIcon />}>
                                View
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={userFeedbackQuery?.data.totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ManageUserFeedback;
