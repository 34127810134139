import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Paper,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";

const IpAddresses = (props) => {
  const { ipAddress } = useParams();

  const { isLoading, error, data } = useQuery("usersByIpAddress", () =>
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/ip-addresses/${ipAddress}`)
      .then((res) => res.data)
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${props.location.state.userId}`}>
                Manage User
              </Link>
              <Typography color="textPrimary">IP Address</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                {`Users Linked To IP Address ${ipAddress}`}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Total IP Usage</TableCell>
                      <TableCell>Last Used</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((user) => {
                        return (
                          <TableRow key={user} hover>
                            <TableCell>{user.totalIps}</TableCell>
                            <TableCell>{formatTime(user.lastUsed)}</TableCell>
                            <TableCell>{user.creationUserId}</TableCell>
                            <TableCell>
                              <Link
                                to={`/users/${user.creationUserId}`}
                              >{`${user.firstName} ${user.lastName}`}</Link>
                            </TableCell>
                            <TableCell>{user.email}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                ></TablePagination>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default IpAddresses;
