import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";

const SuccessAlert = ({ openSuccess, setOpenSuccess, message }) => {
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  return (
    <Snackbar
      open={openSuccess}
      onClose={handleCloseSuccess}
      autoHideDuration={3000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleCloseSuccess}
        severity="success"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SuccessAlert;
