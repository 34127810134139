import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import SuccessAlert from "../components/misc/SuccessAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";

const transactionTypes = Object.freeze({
  INVOICE: 0,
  DEPOSIT: 1,
  MEMBERSHIP: 2,
  MANUAL: 3,
  DEPOSIT_REFUND: 4,
  DEPOSIT_HELD: 5,
});

const transactionStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  FAILED: 2,
});

const UserTransactions = () => {
  const { userId } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isCreatingTransaction, setIsCreatingTransaction] = useState(false);
  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [openSuccess, setOpenSuccess] = useState(false);
  const [type, setType] = useState(3);

  const { isLoading, error, data } = useQuery(
    ["scUserTransactions", page],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_PAYMENTS_API_URL
          }/users/${userId}/transactions?startRowIndex=${
            page * rowsPerPage
          }&maximumRows=${rowsPerPage}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "comment":
        setComment(value);
        break;
      case "amount":
        setAmount(value);
        break;
      case "expirationDate":
        setExpirationDate(value);
        break;
      case "type":
        setType(value);
        break;
      default:
        break;
    }
  };

  const handleCreateNewTransaction = (e) => {
    const data = {
      userId,
      comment,
      amount: parseFloat(amount),
      expirationDate,
      type: parseInt(type),
    };

    axios
      .post(`${process.env.REACT_APP_PAYMENTS_API_URL}/transactions/new`, data)
      .then((res) => {
        setIsCreatingTransaction(false);
        setComment("");
        setAmount("");
        setType(0);
        setExpirationDate(new Date());
        setOpenSuccess(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDateChange = (time) => {
    setExpirationDate(time);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleNewTransaction = () => {
    setIsCreatingTransaction(!isCreatingTransaction);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/users">Users</Link>
                <Link to={`/users/${data.user.id}`}>{data.user.id}</Link>
                <Typography color="textPrimary">Transactions</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Account Details
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      label="User ID"
                      value={data.user.id}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      label="Name"
                      value={`${data.user.firstName} ${data.user.lastName}`}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      label="Email"
                      value={data.user.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Grid container xs={12} justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      User Transactions
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={toggleNewTransaction}
                      variant="outlined"
                    >
                      Create new transaction
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={isCreatingTransaction}
                  maxWidth="lg"
                  onClose={toggleNewTransaction}
                  aria-labelledby="new-comment"
                  fullWidth
                >
                  <DialogTitle id="form-dialog-title">
                    Add New Transaction
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="lbl-assigned-status">Type</InputLabel>
                          <Select
                            labelId="lbl-assigned-status"
                            label="Type"
                            value={type}
                            name="type"
                            onChange={handleInput}
                          >
                            <MenuItem value={3}>Manual</MenuItem>
                            <MenuItem value={4}>Deposit Refund</MenuItem>
                            <MenuItem value={5}>Deposit Held</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Comment"
                          name="comment"
                          value={comment}
                          onChange={handleInput}
                          size="small"
                          required
                          fullWidth
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Amount"
                          name="amount"
                          value={amount}
                          onChange={handleInput}
                          size="small"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MM-yyyy"
                            margin="normal"
                            label="Expiration Date"
                            value={expirationDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Box mr={4}>
                      <Button onClick={toggleNewTransaction} color="primary">
                        Cancel
                      </Button>
                    </Box>
                    <Button
                      onClick={handleCreateNewTransaction}
                      disabled={
                        comment === "" || amount === "" || expirationDate === ""
                      }
                    >
                      Create New Transaction
                    </Button>
                  </DialogActions>
                </Dialog>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Invoice ID</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.transactions.map((transaction) => {
                        return (
                          <TableRow key={transaction.id} hover>
                            <TableCell>{transaction.id}</TableCell>
                            <TableCell>
                              {formatTime(transaction.creationDate)}
                            </TableCell>
                            <TableCell>{transaction.authUserId}</TableCell>
                            <TableCell>
                              {getKeyByValue(
                                transactionTypes,
                                transaction.type
                              )}
                            </TableCell>
                            <TableCell>
                              {transaction.aomsInvoiceId ? (
                                <a
                                  href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${transaction.aomsInvoiceId}`}
                                >
                                  {transaction.aomsInvoiceId}
                                </a>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>{transaction.comments || "-"}</TableCell>
                            <TableCell>{transaction.amount}</TableCell>
                            <TableCell>
                              {getKeyByValue(
                                transactionStatuses,
                                transaction.status
                              )}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/users/${transaction.authUserId}/transactions/${transaction.id}`}
                              >
                                View
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={data.totalResults || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            message="Transaction Created"
          />
        </>
      )}
    </>
  );
};

export default UserTransactions;
