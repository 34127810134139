import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CloseAuctionJobStatuses from "../constants/closeauctionjobstatuses";

const AomsCloseAuctionsView = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [auctionHouseId, setAuctionHouseId] = useState(null);
  const [comments, setComments] = useState(null);
  const [status, setStatus] = useState(null);
  const getJobStatus = (status) => {
    switch (status) {
      case CloseAuctionJobStatuses.FAILED:
        return "Failed";
      case CloseAuctionJobStatuses.PENDING:
        return "Pending";
      case CloseAuctionJobStatuses.PROCESSING:
        return "Processing";
      case CloseAuctionJobStatuses.SUCCESS:
        return "Success";
      default:
        return "Unknown";
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/closeauctionjobs/${id}`)
      .then((res) => {
        console.log(res.data);
        const { startTime, endTime, auctionHouseId, comments, status } =
          res.data;
        setStartTime(new Date(startTime).toLocaleDateString());
        setEndTime(new Date(endTime).toLocaleDateString());
        setAuctionHouseId(auctionHouseId);
        setComments(comments);
        setStatus(getJobStatus(status));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/aoms/close-auctions">Close Auctions</Link>
          <Typography color="textPrimary">Close Auction Job {id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Close Auction Job {id}</h1>
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Start Time"
                  name="startTime"
                  readOnly={true}
                  value={startTime}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="End Time"
                  name="endTime"
                  readOnly={true}
                  value={endTime}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Auction House ID"
                  name="auctionHouseId"
                  readOnly={true}
                  value={auctionHouseId}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Status"
                  name="status"
                  readOnly={true}
                  value={status}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <h3>Notes</h3>
                {comments}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AomsCloseAuctionsView;
