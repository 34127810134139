import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import ErrorAlert from "../components/misc/ErrorAlert";
import SuccessAlert from "../components/misc/SuccessAlert";
import { formatTime } from "../utils/formatTime";
import { useHistory } from "react-router-dom";

const ManageRecurringViewing = () => {
  const { id } = useParams();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const auctionHousesQuery = useQuery(
    "scAuctionHouses",
    () =>
      axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/auctionhouses`)
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );
  const viewingQuery = useQuery(
    ["scRecurringViewing", id],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_BOOKING_API_URL}/recurring-viewings/${id}`
        )
        .then((res) => {
          return res.data;
        }),
    { staleTime: 1000 * 60 * 10, keepPreviousData: true } // 10 minutes
  );

  const deleteViewing = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BOOKING_API_URL}/recurring-viewings/${id}`
      )
      .then((res) => {
        setSuccessMessage(
          `Recurring view Deleted Successfully. Cancelled ${res.data.cancelledBookingsCount} bookings.`
        );
        setOpenSuccess(true);
        handleClose();
        history.push("/recurring-lot-viewings");
      })
      .catch((err) => {
        setErrorMessage(
          "Recurring viewing: " + err.response
            ? err.response.data.message
            : err.message
        );
        setOpenError(true);
      });
  };

  return (
    <Fragment>
      {auctionHousesQuery.isLoading || viewingQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/recurring-lot-viewings">Manage Recurring Viewing</Link>
              <Typography color="textPrimary">
                Recurring Viewing {id}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar title={"Recurring viewing Details"} />
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Auction House"
                    value={
                      auctionHousesQuery.data.filter(
                        (auctionHouse) =>
                          auctionHouse.id === viewingQuery.data.auctionHouseId
                      )[0].name
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Start Date"
                    value={formatTime(viewingQuery.data.startDate).slice(0, 10)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="End Date"
                    value={formatTime(viewingQuery.data.endDate).slice(0, 10)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Start Time"
                    value={viewingQuery.data.startTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="End Time"
                    value={viewingQuery.data.endTime}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Duration"
                    value={viewingQuery.data.duration}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Buffer"
                    value={viewingQuery.data.buffer}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Number of Viewings"
                    value={viewingQuery.data.viewings.length}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Number of Bookigs"
                    value={viewingQuery.data.bookingsCount}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="multiView"
                        checked={viewingQuery.data.multiView}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    }
                    label="Multiple people allowed at a time"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    onClick={handleClickOpen}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Delete Viewing
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  {"Delete Viewing?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    By deleting this recurring viewing you will be deleting{" "}
                    {viewingQuery.data.viewings.length} viewing
                    {viewingQuery.data.viewings.length > 1 ? "s" : ""} there by
                    cancelling{" "}
                    {viewingQuery.data.bookingsCount
                      ? viewingQuery.data.bookingsCount
                      : "no"}{" "}
                    booking{viewingQuery.data.bookingsCount > 1 ? "s" : ""} by
                    customer{viewingQuery.data.bookingsCount > 1 ? "s" : ""}.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={deleteViewing} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar
                title={"Viewngs"}
                createTitle="Add New Lot to Viewing"
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Viewing Date</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Number of Bookings</TableCell>
                      <TableCell>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewingQuery.data.viewings.map((viewing) => {
                      return (
                        <TableRow key={viewing.id} hover tabIndex={-1}>
                          <TableCell>{viewing.id}</TableCell>
                          <TableCell>
                            {formatTime(viewing.viewingDate).slice(0, 10)}
                          </TableCell>
                          <TableCell>{formatTime(viewing.startTime)}</TableCell>
                          <TableCell>{formatTime(viewing.endTime)}</TableCell>
                          <TableCell>{viewing.duration}</TableCell>
                          <TableCell>{viewing.bookingCount}</TableCell>
                          <TableCell>
                            <Link to={`/lot-viewings/${viewing.id}`}>
                              Go to Viewing
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message={successMessage}
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage}
      />
    </Fragment>
  );
};

export default ManageRecurringViewing;
