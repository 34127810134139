import axios from "axios";
import { useState } from "react";
import styles from "./AomsInvoiceDispatch.module.scss";

const AomsInvoiceDispatcher = () => {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [dispatchInfo, setDispatchInfo] = useState(null);
  const [error, setError] = useState(null);

  const getDispatcher = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AOMS_API_URL}/invoices/${invoiceId}/info/dispatcher`
      );
      setDispatchInfo(response.data);
    } catch (error) {
      console.log(error);
      setError("Error: retrieving dispatcher info");
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setError(null);
    setDispatchInfo(null);
    const id = parseInt(invoiceNo);
    if (!isNaN(id) && id > 0) {
      getDispatcher(invoiceNo);
    } else {
      console.log("Invalid invoice number");
      setError("Invalid invoice number");
    }
  };

  return (
    <div className={styles.page}>
      {error && <div className="alert alert-danger">{error}</div>}
      <h1>Aoms Invoice Dispatcher</h1>
      <div className={styles.search}>
        <form onSubmit={handleSearch} className="form">
          <label htmlFor="invoiceNo">Invoice No:</label>
          <input
            type="text"
            name="invoiceNo"
            id="invoiceNo"
            value={invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </form>
      </div>
      {dispatchInfo && (
        <div className={styles.dispatchInfo}>
          <h2>Dispatch Info</h2>
          <div className={styles.dispatchInfoItems}>
            <div className={styles.dispatchInfoItem}>
              <div className={styles.dispatchInfoItemLabel}>Auction House:</div>
              <div className={styles.dispatchInfoItemValue}>
                {dispatchInfo.auctionHouse}
              </div>
            </div>
            <div className={styles.dispatchInfoItem}>
              <div className={styles.dispatchInfoItemLabel}>Dispatcher:</div>
              <div className={styles.dispatchInfoItemValue}>
                {dispatchInfo.dispatcher}
              </div>
            </div>
            <div className={styles.dispatchInfoItem}>
              <div className={styles.dispatchInfoItemLabel}>
                Start Process Date:
              </div>
              <div className={styles.dispatchInfoItemValue}>
                {dispatchInfo.startProcessDate
                  ? new Date(dispatchInfo.startProcessDate).toLocaleString()
                  : ""}
              </div>
            </div>
            <div className={styles.dispatchInfoItem}>
              <div className={styles.dispatchInfoItemLabel}>
                Processed Date:
              </div>
              <div className={styles.dispatchInfoItemValue}>
                {dispatchInfo.processedDate
                  ? new Date(dispatchInfo.processedDate).toLocaleString()
                  : ""}
              </div>
            </div>
            <div className={styles.dispatchInfoItem}>
              <div className={styles.dispatchInfoItemLabel}>
                Dispatch Note ID:
              </div>
              <div className={styles.dispatchInfoItemValue}>
                {dispatchInfo.dispatchNoteId}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AomsInvoiceDispatcher;
