import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
    padding: theme.spacing(2, 4, 3),
    "@media (max-width: 1240px)": {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddShiftPatternModal = ({
  open,
  setOpen,
  refetch,
  setOpenSuccess,
  setOpenError,
  setErrorMessage,
}) => {
  const [name, setName] = useState("");
  const [workTimes, setWorkTimes] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [addDay, setAddDay] = useState("");
  const [addStart, setAddStart] = useState("");
  const [addEnd, setAddEnd] = useState("");

  const classes = useStyles();

  const addMutation = useMutation(
    () =>
      axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-pattern`,
        {
          name,
          workTimes,
        }
      ),
    {
      onSuccess: () => {
        refetch();
        setOpen(false);
        setName("");
        setWorkTimes([]);
        setOpenSuccess(true);
      },
      onError: (err) => {
        setOpenError(true);
        setErrorMessage(err.response.data);
      },
    }
  );

  useEffect(() => {
    const availableDays = daysOfWeek
      .map((_el, i) => i + 1)
      .filter((el) => {
        return !workTimes.find((el2) => el2.day === el);
      });
    setAvailableDays(availableDays);
  }, [workTimes]);

  const handleAdd = () => {
    const newWorkTimes = [
      ...workTimes,
      { day: addDay, start: addStart, end: addEnd },
    ].sort((a, b) => a.day - b.day);
    setWorkTimes(newWorkTimes);
    setAddDay("");
    setAddStart("");
    setAddEnd("");
  };

  const handleDelete = (index) => {
    const newWorkTimes = [...workTimes];
    newWorkTimes.splice(index, 1);
    setWorkTimes(newWorkTimes);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className={classes.modal}>
        <Typography variant="h6" component="h2" gutterBottom>
          Update Shift Pattern
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Shift Pattern Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              Work Times
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workTimes.map((el, i) => (
                    <TableRow key={i}>
                      <TableCell>{daysOfWeek[el.day - 1]}</TableCell>
                      <TableCell>{el.start}</TableCell>
                      <TableCell>{el.end}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(i)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel>Day</InputLabel>
                        <Select
                          value={addDay}
                          onChange={(e) => setAddDay(e.target.value)}
                        >
                          {availableDays.map((el) => (
                            <MenuItem key={el} value={el}>
                              {daysOfWeek[el - 1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={addStart}
                        onChange={(e) => setAddStart(e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={addEnd}
                        onChange={(e) => setAddEnd(e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={addMutation.mutate}
            >
              Add shift pattern
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const UpdateShiftPatternModal = ({
  open,
  setOpen,
  shiftPattern,
  setOpenSuccess,
  setOpenError,
  setOpenErrorMessage,
}) => {
  const [data, setData] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [addDay, setAddDay] = useState("");
  const [addStart, setAddStart] = useState("");
  const [addEnd, setAddEnd] = useState("");

  const classes = useStyles();

  const { refetch } = useQuery(
    ["shiftPatternWorkTime", shiftPattern],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-pattern/${shiftPattern?.id}`
        )
        .then((res) => res.data),
    {
      enabled: !!shiftPattern?.id,
      onSuccess: (res) => setData(res.results),
    }
  );

  const updateMutation = useMutation(
    () =>
      axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-pattern/${shiftPattern?.id}`,
        { workTimes: data }
      ),
    {
      onSuccess: () => setOpenSuccess(true),
      onError: (err) => {
        setOpenError(true);
        setOpenErrorMessage(err.response.data);
      },
    }
  );

  const addMutation = useMutation(
    () =>
      axios.post(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-pattern/${shiftPattern?.id}`,
        {
          day: addDay,
          start: addStart,
          end: addEnd,
        }
      ),
    {
      onSuccess: () => {
        setAddDay("");
        setAddStart("");
        setAddEnd("");
        refetch();
      },
      onError: (err) => {
        setOpenError(true);
        setOpenErrorMessage(err.response.data);
      },
    }
  );

  const deleteMutation = useMutation(
    (id) =>
      axios.delete(
        `${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-pattern/${id}`
      ),
    {
      onSuccess: () => refetch(),
      onError: (err) => {
        setOpenError(true);
        setOpenErrorMessage(err.response.data);
      },
    }
  );

  useEffect(() => {
    const availableDays = daysOfWeek
      .map((_el, i) => i + 1)
      .filter((el) => {
        return !data.find((el2) => el2.day === el);
      });
    setAvailableDays(availableDays);
  }, [data]);

  const handleChange = (value, day, position) => {
    const newData = data.map((el) => {
      if (el.day === day) {
        el[position] = value;
        return el;
      }
      return el;
    });
    setData(newData);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className={classes.modal}>
        <Typography variant="h6" component="h2" gutterBottom>
          Update Shift Pattern
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Shift Pattern Name"
              value={shiftPattern?.name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              Work Times
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{daysOfWeek[el.day - 1]}</TableCell>
                      <TableCell>
                        <TextField
                          value={el.start}
                          onChange={(e) =>
                            handleChange(e.target.value, el.day, "start")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={el.end}
                          onChange={(e) =>
                            handleChange(e.target.value, el.day, "end")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => deleteMutation.mutate(el.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {availableDays.length > 0 && (
                    <TableRow>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel>Day</InputLabel>
                          <Select
                            value={addDay}
                            onChange={(e) => setAddDay(e.target.value)}
                          >
                            {availableDays.map((el) => (
                              <MenuItem key={el} value={el}>
                                {daysOfWeek[el - 1]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={addStart}
                          onChange={(e) => setAddStart(e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={addEnd}
                          onChange={(e) => setAddEnd(e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={addMutation.mutate}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={updateMutation.mutate}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const TimesheetShiftPatterns = () => {
  const [shiftPattern, setShiftPattern] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const classes = useStyles();

  const { data, isLoading, error, refetch } = useQuery(["shiftPatterns"], () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/timesheet/shift-patterns`)
      .then((res) => res.data)
  );

  return (
    <Grid container spacing={3}>
      <AddShiftPatternModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        refetch={refetch}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        setErrorMessage={setErrorMessage}
      />
      <UpdateShiftPatternModal
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        shiftPattern={shiftPattern}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        setErrorMessage={setErrorMessage}
      />

      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/timesheets">Time Sheets</Link>
          <Typography color="textPrimary">Shift Patterns</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAddModal(true)}
          >
            Add Shift Pattern{" "}
          </Button>
        </Box>
      </Grid>
      {isLoading && <Typography>Loading...</Typography>}
      {error && <Typography>Error: {error.message}</Typography>}
      {data && (
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Shift Pattern</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.name}</TableCell>
                      <TableCell className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setOpenUpdateModal(true);
                            setShiftPattern(el);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      )}
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Employee updated Successfully"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message={errorMessage?.message || "Error updating status"}
      />
    </Grid>
  );
};

export default TimesheetShiftPatterns;
