import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "../utils/formatCurrency";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import SuccessAlert from "../components/misc/SuccessAlert";
import { Alert } from "@mui/lab";

const invoiceStatuses = Object.freeze({
  OUTSTANDING: 0,
  COMPLETE: 1,
  CANCELLED: 2,
});

const UserInvoices = () => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [maximumRows, setMaximumRows] = useState(10);
  const [isNewInvoiceDialogOpen, setIsNewInvoiceDialogOpen] = useState(false);
  const [isNewPostalInvoiceDialogOpen, setIsNewPostalInvoiceDialogOpen] =
    useState(false);
  const [auctionId, setAuctionId] = useState(null);
  const [chargeTypeId, setChargeTypeId] = useState(null);
  const [amount, setAmount] = useState("");
  const [originalInvoiceId, setOriginalInvoiceId] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const isoTimeAtMidnight = new Date(
    new Date().setHours(0, 0, 0, 0)
  ).toISOString();

  const invoicesQuery = useQuery(
    [`userInvoices:${id}`, page, maximumRows],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/buyers/${id}/invoices?startRowIndex=${
            page * maximumRows
          }&maximumRows=${maximumRows}`
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

  const auctionsQuery = useQuery(["scAuctions"], () =>
    axios
      .get(
        `${process.env.REACT_APP_AUCTION_API_URL}/auctions?startTime=${isoTimeAtMidnight}`
      )
      .then((res) => {
        return res.data.auctions;
      })
  );

  const chargeTypesQuery = useQuery(["aomsChargeTypes"], () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/chargetypes`)
      .then((res) => {
        return res.data.chargeTypes;
      })
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setMaximumRows(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "auctionId":
        setAuctionId(value);
        break;
      case "chargeTypeId":
        setChargeTypeId(value);
        break;
      case "amount":
        setAmount(value);
        break;
      case "originalInvoiceId":
        setOriginalInvoiceId(value);
        break;
      default:
        break;
    }
  };

  const handleCreateNewInvoice = async () => {
    try {
      const data = {
        authUserId: id,
        auctionId,
        chargeTypeId,
        amount,
      };
      await axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/invoices/blank`,
        data
      );
      toggleNewInvoice();
      setOpenSuccess(true);
      invoicesQuery.refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateNewPostalInvoice = async () => {
    try {
      const data = {
        authUserId: id,
        originalInvoiceId,
      };
      await axios.put(
        `${process.env.REACT_APP_AOMS_API_URL}/invoices/postal`,
        data
      );
      toggleNewPostalInvoice();
      setOpenSuccess(true);
      invoicesQuery.refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleNewInvoice = () => {
    setIsNewInvoiceDialogOpen(!isNewInvoiceDialogOpen);
  };

  const toggleNewPostalInvoice = () => {
    setIsNewPostalInvoiceDialogOpen(!isNewPostalInvoiceDialogOpen);
  };

  return (
    <>
      {invoicesQuery.isLoading ? (
        <CircularProgress />
      ) : invoicesQuery.error ? (
        "An error has occurred: " + invoicesQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${id}`}>Manage User</Link>
              <Link to={`/users/${id}/summary`}>Summary</Link>
              <Typography color="textPrimary">Invoices</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6" gutterBottom>
                All Invoices
              </Typography>
              <Button
                startIcon={<AddIcon />}
                onClick={toggleNewInvoice}
                variant="outlined"
              >
                Create new invoice
              </Button>
              <Button
                startIcon={<AddIcon />}
                onClick={toggleNewPostalInvoice}
                variant="outlined"
              >
                Create new postal invoice
              </Button>
              <Dialog
                open={isNewInvoiceDialogOpen}
                maxWidth="lg"
                onClose={toggleNewInvoice}
                aria-labelledby="new-comment"
                fullWidth
              >
                <DialogTitle id="form-dialog-title">
                  Create New Invoice
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="auction">Auction</InputLabel>
                        <Select
                          labelId="auction"
                          label="Auction"
                          name="auctionId"
                          value={auctionId}
                          onChange={handleInput}
                        >
                          {auctionsQuery?.data?.map((auction, i) => (
                            <MenuItem
                              key={auction.id}
                              value={auction.id}
                            >{`${formatTime(auction.endTime)} - ${
                              auction.title
                            }`}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="chargeType">Charge Type</InputLabel>
                        <Select
                          labelId="chargeType"
                          label="Charge Type"
                          name="chargeTypeId"
                          value={chargeTypeId}
                          onChange={handleInput}
                        >
                          {chargeTypesQuery?.data?.map((chargeType) => (
                            <MenuItem key={chargeType.id} value={chargeType.id}>
                              {chargeType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Amount"
                        name="amount"
                        value={amount}
                        onChange={handleInput}
                        size="small"
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box mr={4}>
                    <Button onClick={toggleNewInvoice} color="primary">
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    onClick={handleCreateNewInvoice}
                    disabled={!auctionId || !chargeTypeId || !amount}
                  >
                    Create New Invoice
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isNewPostalInvoiceDialogOpen}
                maxWidth="lg"
                onClose={toggleNewPostalInvoice}
                aria-labelledby="new-comment"
                fullWidth
              >
                <DialogTitle id="form-dialog-title">
                  Create New Postal Invoice
                </DialogTitle>
                <DialogContent>
                  <Alert severity="info">
                    Create postage payment for collection invoice that had
                    optional postage
                  </Alert>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        label="Invoice ID"
                        name="originalInvoiceId"
                        value={originalInvoiceId}
                        onChange={handleInput}
                        size="small"
                        required
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box mr={4}>
                    <Button onClick={toggleNewPostalInvoice} color="primary">
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    onClick={handleCreateNewPostalInvoice}
                    disabled={!originalInvoiceId}
                  >
                    Create New Invoice
                  </Button>
                </DialogActions>
              </Dialog>
              {invoicesQuery.data.totalResults > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice No</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell>Auction ID</TableCell>
                        <TableCell>Buyer Ref</TableCell>

                        <TableCell>Hammer Price</TableCell>
                        <TableCell>Total Due</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoicesQuery.data.invoices.map((invoice) => {
                        return (
                          <TableRow key={invoice.id} hover>
                            <TableCell>
                              <a
                                href={`${process.env.REACT_APP_AOMS_PORTAL_URL}/accounts/invoices/${invoice.id}`}
                              >
                                {invoice.id}
                              </a>
                            </TableCell>
                            <TableCell>
                              {formatTime(invoice.invoiceDate)}
                            </TableCell>
                            <TableCell>
                              <Link to={`/auctions/${invoice.auctionId}`}>
                                {invoice.auctionId}
                              </Link>
                            </TableCell>
                            <TableCell>{invoice.buyerRef}</TableCell>
                            <TableCell>
                              {formatCurrency(invoice.hammerPrice)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(invoice.totalDue)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(invoice.balance)}
                            </TableCell>
                            <TableCell>
                              {getKeyByValue(invoiceStatuses, invoice.status)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={invoicesQuery.data.totalResults}
                    rowsPerPage={maximumRows}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </TableContainer>
              ) : (
                <Grid container item>
                  <Typography varaint="body1">
                    No invoices for this user.
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
          <SuccessAlert
            openSuccess={openSuccess}
            setOpenSuccess={setOpenSuccess}
            message="Invoice Created"
          />
        </Grid>
      )}
    </>
  );
};

export default UserInvoices;
