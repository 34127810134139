import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  TextField,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatTime } from "../utils/formatTime";

const useStyles = makeStyles((theme) => ({
  marginZero: {
    margin: 0,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: "scroll",
    overflowX: "hidden",
    padding: theme.spacing(2, 4, 3),
  },
}));

const InvoiceDetailsModal = ({ invoiceId, open, handleClose }) => {
  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    "asInvoiceDetails",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AOMS_API_URL}/collections/invoice/${invoiceId}`
        )
        .then((res) => res.data),
    {
      enabled: open === true && invoiceId !== "",
    }
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="invoiceDetailsModal"
      disableScrollLock={true}
    >
      <Box className={classes.modal}>
        {isLoading && <CircularProgress />}
        {error && `An error has occurred: ${error.message}`}
        {data && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>Image</TableCell>
                <TableCell>StockId</TableCell>
                <TableCell>LotNo</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Container</TableCell>
              </TableHead>
              <TableBody>
                {data.result.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <a
                        href={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`https://scwebv7cdn.azureedge.net/thumbnails/${item.images[0]}`}
                          alt={item.productTitle}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </a>
                    </TableCell>
                    <TableCell>{item.stockId}</TableCell>
                    <TableCell>{item.lotNo}</TableCell>
                    <TableCell>{item.productTitle}</TableCell>
                    <TableCell>{item.containerName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};

const CustomerInvoices = () => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState({
    id: 10,
    name: "Simon Charles Stanley Court",
  });
  const [totalLots, setTotalLots] = useState(12);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");

  const classes = useStyles();

  const { data, isLoading, error } = useQuery(
    [
      "asCustomerInvoices",
      currentAuctionHouse,
      startDate,
      endDate,
      page,
      rowsPerPage,
      totalLots,
    ],
    () =>
      axios
        .get(
          `${
            process.env.REACT_APP_AOMS_API_URL
          }/bookings/invoices-awaiting-collection?auctionHouseId=${
            currentAuctionHouse.id
          }&totalLots=${totalLots}&startDate=${startDate}&endDate=${endDate}&startRowIndex=${
            page * rowsPerPage
          }&maxRows=${rowsPerPage}`
        )
        .then((res) => res.data)
  );

  const auctionHousesQuery = useQuery("asAuctionHouses", () =>
    axios
      .get(`${process.env.REACT_APP_AOMS_API_URL}/auctionhouses`)
      .then((res) => res.data)
  );

  const handleSelect = (e) => {
    const value = e.target.value;
    const house = auctionHousesQuery.data.find((el) => el.name === value);
    setCurrentAuctionHouse(house);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <InvoiceDetailsModal
        invoiceId={invoiceId}
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/queue">Queue</Link>
          <Typography color="textPrimary">Invoices</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Auction House</InputLabel>
                {auctionHousesQuery.data && (
                  <Select
                    value={currentAuctionHouse.name}
                    label="Auction Houses"
                    onChange={handleSelect}
                  >
                    <MenuItem value=""></MenuItem>
                    {auctionHousesQuery.data.map((house) => {
                      return (
                        <MenuItem value={house.name} key={house.id}>
                          {house.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="Start date:"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  className={classes.marginZero}
                  disableToolbar
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  autoOk={true}
                  label="End date:"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="standard"
                label="Total Lots"
                value={totalLots}
                onChange={(e) =>
                  !e.target.value
                    ? setTotalLots(0)
                    : setTotalLots(e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {isLoading && <CircularProgress />}
      {error && `An error has occurred: ${error.message}`}
      <Grid item xs={12}>
        <Box p={2} component={Paper}>
          {data && data.results.length === 0 && `No results found`}
          {data && data.results.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice No</TableCell>
                    <TableCell>Lots</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Collection Deadline</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map((el) => (
                    <TableRow key={el.id}>
                      <TableCell>{el.id}</TableCell>
                      <TableCell>{el.totalLots}</TableCell>
                      <TableCell>{formatTime(el.creationDate)}</TableCell>
                      <TableCell>{formatTime(el.collectionDeadline)}</TableCell>
                      <TableCell>{el.customerName}</TableCell>
                      <TableCell>{el.email}</TableCell>
                      <TableCell>{el.tel}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setInvoiceId(el.id);
                            setShowModal(true);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={data?.count ? data.count : 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomerInvoices;
