import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Paper,
} from "@mui/material";
import axios from "axios";
import { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import RoleTableCell from "../components/misc/RoleTableCell";

const Permissions = (props) => {
  const userId = props?.location?.state?.userId;

  const permissionsQuery = useQuery(
    "userPermissionsData",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/permissions`
        )
        .then((res) => res.data),
    {
      enabled: !!userId,
    }
  );

  const applicationsQuery = useQuery("allApplicationsData", () =>
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/permissions/applications`)
      .then((res) => res.data)
  );

  const rolesQuery = useQuery("allRolesData", () =>
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/permissions/roles`)
      .then((res) => res.data)
  );

  const [newApplicationId, setNewApplicationId] = useState(0);
  const [newRoleId, setNewRoleId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddPermission = (type) => {
    switch (type) {
      case "application":
        axios
          .post(
            `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/applications`,
            {
              applicationId: newApplicationId,
            }
          )
          .then((res) => {
            permissionsQuery.refetch();
            setNewApplicationId(0);
          })
          .catch((err) => console.error(err));
        break;
      case "role":
        axios
          .post(`${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/roles`, {
            roleId: newRoleId,
          })
          .then((res) => {
            permissionsQuery.refetch();
            setNewRoleId(0);
            setErrorMessage("");
          })
          .catch((err) => {
            console.error(err);
            if (err.response.status === 403) {
              setErrorMessage(err.response.data.message);
            }
          });
        break;

      default:
        break;
    }
  };

  const handleRemovePermission = (type, permissionName) => {
    switch (type) {
      case "application":
        const applicationIdToRemove = applicationsQuery.data.filter(
          (application) => application.name === permissionName
        )[0].id;

        axios
          .delete(
            `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/applications/${applicationIdToRemove}`
          )
          .then(() => {
            permissionsQuery.refetch();
          })
          .catch((err) => console.error(err));
        break;
      case "role":
        const roleIdToRemove = rolesQuery.data.filter(
          (role) => role.name === permissionName
        )[0].id;

        axios
          .delete(
            `${process.env.REACT_APP_AUTH_API_URL}/users/${userId}/roles/${roleIdToRemove}`
          )
          .then(() => {
            permissionsQuery.refetch();
          })
          .catch((err) => console.error(err));
        break;

      default:
        break;
    }
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "newApplication":
        setNewApplicationId(value);
        break;
      case "newRole":
        setNewRoleId(value);
        break;
      default:
        break;
    }
  };

  const isLoading =
    permissionsQuery.isLoading ||
    applicationsQuery.isLoading ||
    rolesQuery.isLoading;
  const error =
    permissionsQuery.error || applicationsQuery.error || rolesQuery.error;

  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        "An error has occurred: " + error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/users">Users</Link>
              <Link to={`/users/${userId}`}>Manage User</Link>
              <Typography color="textPrimary">Permissions</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Typography variant="h6">User Permissions</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Applications</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsQuery.data.applications.map((application) => {
                      return (
                        <TableRow key={application} hover>
                          <TableCell>{application}</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              onClick={() =>
                                handleRemovePermission(
                                  "application",
                                  application
                                )
                              }
                              variant="outlined"
                            >
                              Remove
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Table size="small">
                              <TableBody>
                                {permissionsQuery.data.roles
                                  .filter((role) => role.includes(application))
                                  .map((role) => {
                                    const roleName = role.slice(
                                      role.indexOf(":") + 2
                                    );
                                    return (
                                      <TableRow key={role} hover>
                                        <RoleTableCell>
                                          {roleName}
                                        </RoleTableCell>
                                        <RoleTableCell>
                                          <Button
                                            size="small"
                                            onClick={() =>
                                              handleRemovePermission(
                                                "role",
                                                roleName
                                              )
                                            }
                                            variant="outlined"
                                          >
                                            Remove
                                          </Button>
                                        </RoleTableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h6">Add Permissions</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="application-label">
                      New Application
                    </InputLabel>
                    <Select
                      labelId="application-label"
                      label="New Application"
                      name="newApplication"
                      value={newApplicationId}
                      onChange={handleInput}
                    >
                      <MenuItem value={0}>Select an application...</MenuItem>
                      {applicationsQuery.data.map((application) => {
                        return (
                          <MenuItem key={application.id} value={application.id}>
                            {application.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() => handleAddPermission("application")}
                    >
                      Add Application
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="role-label">New Role</InputLabel>
                    <Select
                      labelId="role-label"
                      label="New Role"
                      name="newRole"
                      value={newRoleId}
                      onChange={handleInput}
                    >
                      <MenuItem value={0}>Select a role...</MenuItem>
                      {rolesQuery.data.map((role) => {
                        return (
                          <MenuItem key={role.id} value={role.id}>
                            {
                              applicationsQuery.data.filter(
                                (application) =>
                                  application.id === role.applicationId
                              )[0].name
                            }
                            : {role.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() => handleAddPermission("role")}
                    >
                      Add Role
                    </Button>
                  </Box>
                  <Box mt={2}>
                    {errorMessage && (
                      <Typography color="textSecondary">
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Permissions;
