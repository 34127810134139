import React, { useState } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import SuccessAlert from "../components/misc/SuccessAlert";
import ErrorAlert from "../components/misc/ErrorAlert";

const ContentLandingPage = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(
    "getLandingPages",
    async () =>
      await axios
        .get(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage`)
        .then((res) => res.data)
  );

  const deletePageMutation = useMutation(
    ({ id }) =>
      axios
        .patch(`${process.env.REACT_APP_AUCTION_API_URL}/landingPage/${id}`)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        const newData = data.result.filter((item) => item.id !== res.id);
        queryClient.setQueryData("getLandingPages", { result: newData });
        setOpenSuccess(true);
      },
      onError: (error) => setOpenError(true),
    }
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Landing Pages</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item spacing={3} xs={12}>
        <Box
          p={2}
          component={Paper}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Link to="/content/landing-pages/add">
            <Button variant="contained" color="primary">
              Create Landing Page
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item spacing={3} xs={12}>
        <Box p={2} component={Paper}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="inherit">
              Manage Pages
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <CircularProgress />
            ) : error ? (
              "An error has occurred: " + error.message
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.result?.map((landingPage) => (
                      <TableRow key={landingPage.id}>
                        <TableCell>{landingPage.id}</TableCell>
                        <TableCell>{landingPage.title}</TableCell>
                        <TableCell>{landingPage.CDescription}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/content/landing-pages/update/${landingPage.id}`}
                          >
                            Update
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              deletePageMutation.mutate({ id: landingPage.id })
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Box>
      </Grid>
      <SuccessAlert
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        message="Successfully deleted!"
      />
      <ErrorAlert
        openError={openError}
        setOpenError={setOpenError}
        message="Error! Please try again"
      />
    </Grid>
  );
};

export default ContentLandingPage;
