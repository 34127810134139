import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import {
  DatePicker,
  MobileTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { useState, useEffect } from "react";
import EnhancedTableToolbar from "../components/misc/EnhancedTableToolbar";
import { formatTime } from "../utils/formatTime";
import { formatCurrency } from "../utils/formatCurrency";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { urlify } from "../utils/urls";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const AuctionStats = () => {
  const [startTime, setStartTime] = useState(
    new Date(new Date().toISOString().split("T")[0] + "T00:00:00")
  );
  const [endTime, setEndTime] = useState(null);
  const [auctionStatsQuery, setAuctionStatsQuery] = useState({
    isLoading: true,
    data: [],
  });
  const [auctionStatsSummary, setAuctionStatsSumary] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setAuctionStatsQuery({ isLoading: true, data: [] });
    axios
      .get(
        `${
          process.env.REACT_APP_AUCTION_API_URL
        }/auctionstats?startTime=${startTime.toISOString()}`
      )
      .then((res) => {
        setAuctionStatsQuery({ isLoading: false, data: res.data.stats });
      });
  }, [startTime]);

  useEffect(() => {
    if (!auctionStatsQuery.isLoading) {
      let { totalLots, lotWithBids, totalHammer, registeredUsers } = 0;
      totalLots = lotWithBids = totalHammer = registeredUsers = 0.0;
      for (const auctionStat of auctionStatsQuery.data) {
        totalLots += auctionStat.totalLots;
        lotWithBids += auctionStat.lotWithBids;
        totalHammer +=
          auctionStat.totalHammer != null
            ? Number.parseFloat(auctionStat.totalHammer)
            : 0;
        registeredUsers += auctionStat.registeredUsers;
      }
      setAuctionStatsSumary({
        totalAuctions: auctionStatsQuery.data.length,
        totalLots: totalLots,
        lotWithBids: lotWithBids,
        totalHammer: totalHammer,
        registeredUsers: registeredUsers,
      });
    }
  }, [auctionStatsQuery.data, auctionStatsQuery.isLoading]);

  const handleStartDateChange = (time) => {
    setStartTime(time);
    setAuctionStatsQuery({ isLoading: true, data: [] });
    axios
      .get(
        `${
          process.env.REACT_APP_AUCTION_API_URL
        }/auctionstats?startTime=${time.toISOString()}&${
          endTime ? `endTime=${endTime.toISOString()}` : ""
        }`
      )
      .then((res) => {
        setAuctionStatsQuery({ isLoading: false, data: res.data.stats });
      });
  };

  const handleEndDateChange = (time) => {
    //var d = new Date();
    if (time.getHours() === 0 && time.getMinutes() === 0) {
      time.setHours(23, 55, 0, 0);
    }
    setEndTime(time);
    setAuctionStatsQuery({ isLoading: true, data: [] });
    axios
      .get(
        `${
          process.env.REACT_APP_AUCTION_API_URL
        }/auctionstats?startTime=${startTime.toISOString()}${
          time ? `&endTime=${time.toISOString()}` : ""
        }`
      )
      .then((res) => {
        setAuctionStatsQuery({ isLoading: false, data: res.data.stats });
      });
  };

  return (
    <>
      {auctionStatsQuery.isLoading ? (
        <CircularProgress />
      ) : auctionStatsQuery.error ? (
        "An error has occurred: " + auctionStatsQuery.error.message
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Auction Statistics</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <EnhancedTableToolbar title={"Select Filter Dates"} />
                <Grid container justifyContent="space-around">
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Auction End Date From:"
                    value={startTime}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <MobileTimePicker
                    margin="normal"
                    label="Auction End Time From:"
                    value={startTime}
                    defaultValue="00:00"
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    label="Auction End Date Till:"
                    value={endTime}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />

                  <MobileTimePicker
                    margin="normal"
                    label="Auction End Time Till:"
                    value={endTime}
                    defaultValue="23:55"
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    ampm={false}
                  />
                </Grid>
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar title={"Summary"} />
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container justifyContent="space-around" spacing={4}>
                  <TextField
                    label="Total Auctions"
                    value={auctionStatsSummary.totalAuctions}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label="Total Lots"
                    value={auctionStatsSummary.totalLots}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label="Total Lots With Bids"
                    value={auctionStatsSummary.lotWithBids}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label="Total Hammer"
                    value={formatCurrency(auctionStatsSummary.totalHammer)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    label="Total User Registrations"
                    value={auctionStatsSummary.registeredUsers}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <EnhancedTableToolbar title={"Auction Statistics"} />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Auction House</TableCell>
                      <TableCell>Auction</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Total Lots</TableCell>
                      <TableCell>Total Lots with Bids</TableCell>
                      <TableCell>Total Hammer</TableCell>
                      <TableCell>Total Registered Users</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auctionStatsQuery.data
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((auctionStat) => {
                        return (
                          <TableRow key={auctionStat.id} hover tabIndex={-1}>
                            <TableCell>{auctionStat.id}</TableCell>
                            <TableCell>{auctionStat.auctionHouse}</TableCell>
                            <TableCell>{auctionStat.auction}</TableCell>
                            <TableCell>
                              {formatTime(auctionStat.endTime)}
                            </TableCell>
                            <TableCell>{auctionStat.totalLots}</TableCell>
                            <TableCell>{auctionStat.lotWithBids}</TableCell>
                            <TableCell>
                              {formatCurrency(auctionStat.totalHammer)}
                            </TableCell>
                            <TableCell>{auctionStat.registeredUsers}</TableCell>
                            <TableCell>
                              <Link
                                to={`/auction-stats/${auctionStat.id}/${urlify(
                                  auctionStat.auction
                                )}/buyers`}
                              >
                                <Button startIcon={<VisibilityIcon />}>
                                  View Buyers
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AuctionStats;
