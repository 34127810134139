import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getDecodedAccessToken } from "../../utils/Tokens";

const PrivateRoute = ({
  component: Component,
  requiredPermission,
  ...rest
}) => {
  const decodedToken = getDecodedAccessToken();

  let permissions = [
    ...decodedToken.permissions.applications,
    ...decodedToken.permissions.roles,
  ];

  return (
    // Show the component only when the user has the correct permissions, otherwise, redirect the user to home page
    <Route
      {...rest}
      render={(props) =>
        permissions.includes(requiredPermission) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default PrivateRoute;
